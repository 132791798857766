// Copyright Northcote Technology Ltd
import { keyBy } from 'lodash'

export default function updateGradingObservations(grading, observations) {
  const existingObsByBehaviour = keyBy(grading.observations, 'behaviourId')
  const persistedBehaviourIds = new Set(
    grading.observations
      .filter(({ id }) => id)
      .map(({ behaviourId }) => behaviourId)
  )

  const newObservations = observations.map(ob => {
    const { behaviourId, mood } = ob
    const existingOb = existingObsByBehaviour[behaviourId]

    persistedBehaviourIds.delete(behaviourId)

    if (existingOb) {
      // If there's an existing OB with this behaviour then adopt its id (if
      // present), update its mood, and unflag it for destroy (if present).
      const newOb = {
        ...existingOb,
        mood,
      }
      delete newOb._destroy
      return newOb
    } else {
      return ob
    }
  })

  // The remaining entries in persistedBehaviourIds are OBs that have an id (so
  // have been persisted on the server) but are no longer selected in the UI so
  // should be flagged for destroy.
  persistedBehaviourIds.forEach(behaviourId => {
    const existingOb = existingObsByBehaviour[behaviourId]
    newObservations.push({
      ...existingOb,
      _destroy: true,
    })
  })

  return {
    ...grading,
    observations: newObservations,
  }
}
