// Copyright Northcote Technology Ltd
import React from 'react'
import { connect } from 'react-redux'
import QuantumLayout from '../../QuantumLayout'
import EntityFields from '../../common/EntityFields'
import InfoIcon from '@material-ui/icons/Info'
import DescriptionIcon from '@material-ui/icons/Description'
import HeatmapReportsVisualisation from './HeatmapReportsVisualisation'
import HeatmapReportsTable from './HeatmapReportsTable'
import NotificationModal from '../../common/NotificationModal'
import {
  getHeatmapReportsStore,
  getEditionStore,
  getCreationStore,
} from '../../../redux/selectors'
import {
  getHeatmapReportsUpdate,
  getHeatmapReportsItem,
  setHeatmapReportsItem,
} from '../../../redux/actions'
import { isPersonInGroups } from '../../../src/lib/peopleHelper'
import { get_request } from '../../../src/lib/requestToServer'

class Module extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gradersOptions: null,
      gradersFilteredByGroup: null,
      loading: false,
      peopleOptions: UBF.personGroupsMandatory
        ? []
        : props.peopleDropdownOptions,
    }
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const gradersOptions = await get_request('/heatmap_reports/', {
      get_graders: true,
    })
    this.setState({ gradersOptions, loading: false })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { editState, createState, heatmapReportsData } = newProps
    const { gradersOptions } = this.state
    if (heatmapReportsData?.info && !editState?.info && !createState?.info) {
      this.setState({ gradersFilteredByGroup: gradersOptions })
    }
    if (editState?.info) {
      const group_ids = heatmapReportsData.info?.group_ids.map(e =>
        e.toString()
      )
      const currentGraderOptions = gradersOptions
        .filter(p => isPersonInGroups(p, group_ids))
        .sort((a, b) => (a.label > b.label ? 1 : -1))

      this.setState({ gradersFilteredByGroup: currentGraderOptions })
    }
    const loading = !gradersOptions && (editState?.info || createState?.info)
    this.setState({ loading: loading })
  }

  additionalButtonsBehaviours = (buttonClicked, context) => {
    if (buttonClicked == this.props.translations.ubf.new_model.heatmap) {
      this.setState({ gradersFilteredByGroup: [], peopleOptions: [] })
    }
    if (buttonClicked == this.props.translations.ubf.edit) {
      this.updateDropdowns(context.state.info.group_ids.map(g => g.toString()))
    }
  }

  iconFactory = node => {
    if (node.getId() === 'visualisation') {
      return <InfoIcon />
    } else if (node.getId() === 'info') {
      return <DescriptionIcon />
    }
  }

  factory = node => {
    const {
      translations,
      canEdit,
      canCreate,
      canDelete,
      userTypeOptions,
      userTypeDropdownOptions,
      peopleDropdownOptions,
      heatmapReportsData,
      setHeatmapReportsItem,
      getHeatmapReportsItem,
      getHeatmapReportsUpdate,
    } = this.props

    const component = node.getComponent()
    const modelName = 'heatmap'
    const moduleName = 'heatmaps'
    const controllerUrl = '/heatmap_reports/'

    if (component === 'Table') {
      return (
        <HeatmapReportsTable
          translations={translations}
          userTypeOptions={userTypeOptions}
          userTypeDropdownOptions={userTypeDropdownOptions}
          peopleDropdownOptions={peopleDropdownOptions}
        />
      )
    } else if (component === 'HeatmapReportsDetails') {
      return (
        <div className="outer-container-right">
          <EntityFields
            entityData={heatmapReportsData}
            translations={translations}
            canEdit={canEdit}
            canCreate={canCreate}
            canDelete={canDelete}
            modelName={modelName}
            moduleName={moduleName}
            setEntityItem={setHeatmapReportsItem}
            getEntityItem={getHeatmapReportsItem}
            getEntityUpdate={getHeatmapReportsUpdate}
            controllerUrl={controllerUrl}
            fields={this.fields()}
            handleInputChange={this.customHandleInputChange}
            additionalButtonsBehaviours={this.additionalButtonsBehaviours}
            extraPadding={true}
          />
        </div>
      )
    } else if (component === 'HeatmapReportsVisualisation') {
      const heatmapInfo = heatmapReportsData.info
      const referenceGraderName =
        heatmapInfo &&
        peopleDropdownOptions.find(
          personOption => personOption.value == heatmapInfo.reference_grader_id
        )?.label

      return (
        <div className="outer-container-right">
          <HeatmapReportsVisualisation
            heatmapInfo={heatmapInfo}
            heatmapReportsData={heatmapReportsData}
            referenceGraderName={referenceGraderName || ''}
            translations={translations}
          />
        </div>
      )
    } else {
      return (
        <div className="outer-container-right">
          <div className="inner-container">{node.getName()}</div>
        </div>
      )
    }
  }

  customHandleInputChange = (context, event) => {
    const target = event.target
    const name = target.name
    let stopRemoteFunction = false

    if (name == 'group_ids') {
      stopRemoteFunction = true
      const group_ids = target.value
      const result = this.updateDropdowns(group_ids)
      const peopleOptions = result[0]
      const gradersOptions = result[1]

      let { grader_ids, person_ids } = context.state.info
      grader_ids =
        grader_ids?.filter(gid =>
          gradersOptions.map(g => g.value).includes(gid)
        ) || []
      person_ids =
        person_ids?.filter(pid =>
          peopleOptions.map(p => p.value).includes(pid)
        ) || []

      this.props.setHeatmapReportsItem({
        ...context.state.info,
        grader_ids,
        person_ids,
        group_ids,
      })
    }

    return stopRemoteFunction
  }

  updateDropdowns = group_ids => {
    const { peopleDropdownOptions } = this.props
    let { gradersOptions } = this.state

    const peopleOptions = peopleDropdownOptions
      .filter(p => isPersonInGroups(p, group_ids))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
    gradersOptions = gradersOptions
      .filter(p => isPersonInGroups(p, group_ids))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
    this.setState({ peopleOptions, gradersFilteredByGroup: gradersOptions })

    return [peopleOptions, gradersOptions]
  }

  fields = () => {
    const { gradersFilteredByGroup, peopleOptions } = this.state

    return {
      name: { type: 'text', mandatory: true },
      session_template_ids: {
        type: 'selector',
        options: {
          values: this.props.gradingSessionTemplateDropdownOptions,
          name: 'session_template_ids',
          label:
            this.props.translations.activerecord.attributes.heatmap
              .session_template_ids,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap
              .session_template_ids,
          multi: true,
        },
      },
      qualification_ids: {
        type: 'selector',
        options: {
          values: this.props.qualificationDropdownOptions,
          name: 'qualification_ids',
          label:
            this.props.translations.activerecord.attributes.heatmap
              .qualifications,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap
              .qualifications,
          multi: true,
        },
      },
      grouping: {
        type: 'selector',
        mandatory: true,
        options: {
          values: this.props.groupingDropdownOptions,
          name: 'grouping',
          label:
            this.props.translations.activerecord.attributes.heatmap.grouping,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap
              .qualifications +
            ' / ' +
            this.props.translations.activerecord.attributes.heatmap.activity,
          multi: false,
        },
      },
      reference_grader_id: {
        type: 'selector',
        mandatory: false,
        options: {
          values: gradersFilteredByGroup || [],
          name: 'reference_grader_id',
          label:
            this.props.translations.activerecord.attributes.heatmap
              .reference_grader_id,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap
              .reference_grader_id,
          multi: false,
        },
      },
      start_date: { type: 'date' },
      end_date: { type: 'date' },
      group_ids: {
        type: 'selector',
        mandatory: UBF.personGroupsMandatory,
        options: {
          values: this.props.groupsDropdownOptions,
          name: 'group_ids',
          label: this.props.translations.activerecord.attributes.heatmap.groups,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap.groups,
          multi: true,
        },
      },
      grader_ids: {
        type: 'selector',
        options: {
          values: gradersFilteredByGroup || [],
          name: 'grader_ids',
          label:
            this.props.translations.activerecord.attributes.heatmap.graders,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap.graders,
          multi: true,
        },
      },
      person_ids: {
        type: 'selector',
        options: {
          values: peopleOptions || [],
          name: 'person_ids',
          label: this.props.translations.activerecord.attributes.heatmap.people,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap.people,
          multi: true,
        },
      },
      role_ids: {
        type: 'selector',
        options: {
          values: this.props.rolesDropdownOptions,
          name: 'role_ids',
          label: this.props.translations.activerecord.attributes.heatmap.roles,
          noResultsText: '',
          placeholderText:
            this.props.translations.activerecord.attributes.heatmap.roles,
          multi: true,
        },
      },
    }
  }

  render() {
    const { backUrl, translations } = this.props

    let leftPaneComponents = [
      {
        id: 'table',
        type: 'tab',
        name: '',
        component: 'Table',
      },
    ]

    let rightPaneComponents = [
      {
        id: 'info',
        name: translations.titles.admin.heatmaps.details,
        component: 'HeatmapReportsDetails',
      },
      {
        id: 'visualisation',
        name: translations.titles.admin.heatmaps.visualisation,
        component: 'HeatmapReportsVisualisation',
      },
    ]

    return (
      <>
        <NotificationModal
          notificationType="loading"
          visible={this.state.loading}
        />
        <QuantumLayout
          backAction={() => (window.location = backUrl)}
          title={translations.titles.admin.heatmaps.main}
          leftPaneComponents={leftPaneComponents}
          rightPaneComponents={rightPaneComponents}
          customFactory={this.factory}
          customIconFactory={this.iconFactory}
          enableMaximizeRight={true}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  const heatmapReportsData = getHeatmapReportsStore(state)
  const editState = getEditionStore(state)
  const createState = getCreationStore(state)
  return { heatmapReportsData, editState, createState }
}

export default connect(mapStateToProps, {
  getHeatmapReportsUpdate,
  getHeatmapReportsItem,
  setHeatmapReportsItem,
})(Module)
