// Copyright Northcote Technology Ltd
import { get, keyBy, sortBy, uniq } from 'lodash'
import React, { Fragment, useContext, useReducer, useState } from 'react'

import TranslationsContext from './contexts/Translations'

const CHARACTERISTICS = [
  'immediacy',
  'complexity',
  'degradation',
  'loss',
  'management',
]

function AircraftTypeCharacteristicsTable({
  aircraftTypes,
  data,
  errors,
  onChange,
}) {
  const sortedAircraftTypes = sortBy(aircraftTypes, 'name')
  const uniqueErrorMessages = uniq(
    Object.values(errors).flatMap(mchErrors => Object.values(mchErrors).flat())
  )

  return (
    <div className="malfunction-characteristics-picker__tableWrapper">
      <table className="malfunction-characteristics-picker__table table table-compact table-maxContent">
        <thead>
          <tr>
            <th />
            {sortedAircraftTypes.map(({ id, name }) => (
              <th key={id}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {CHARACTERISTICS.map(name => (
            <CharacteristicsRow
              aircraftTypeIds={sortedAircraftTypes.map(({ id }) => id)}
              data={data}
              errors={errors}
              key={name}
              name={name}
              onChange={onChange}
            />
          ))}
        </tbody>
      </table>
      {uniqueErrorMessages.length > 0 ? (
        <div className="error">{uniqueErrorMessages.join(', ')}</div>
      ) : null}
    </div>
  )
}

function CharacteristicsRow({ aircraftTypeIds, data, errors, name, onChange }) {
  const translations = useContext(TranslationsContext)
  const label =
    translations.activerecord.attributes.malfunction_characteristic[name]

  return (
    <tr>
      <th>{label}</th>
      {aircraftTypeIds.map(aircraftTypeId => (
        <td key={aircraftTypeId}>
          <input
            className={
              get(errors, [aircraftTypeId, name])
                ? 'malfunction-characteristics-picker__error'
                : null
            }
            inputMode="numeric"
            maxLength="1"
            onChange={({ target: { value } }) =>
              onChange(aircraftTypeId, name, value)
            }
            size="1"
            type="text"
            value={get(data, [aircraftTypeId, name]) || ''}
          />
        </td>
      ))}
    </tr>
  )
}

function Inputs({ data, malfunction }) {
  return Object.entries(data).map(([aircraftTypeId, obj], index) => {
    const { id } = obj
    const allEmptyOrZeroValues = CHARACTERISTICS.map(name => obj[name]).every(
      value => (parseInt(value, 10) || 0) === 0
    )

    if (!id && allEmptyOrZeroValues) return null

    return (
      <Fragment key={index}>
        {id ? (
          <input
            name={`activity[malfunction_characteristics_attributes][${index}][id]`}
            type="hidden"
            value={id}
          />
        ) : null}
        {!malfunction || allEmptyOrZeroValues ? (
          <input
            name={`activity[malfunction_characteristics_attributes][${index}][_destroy]`}
            type="hidden"
            value="1"
          />
        ) : null}
        <input
          name={`activity[malfunction_characteristics_attributes][${index}][aircraft_type_id]`}
          type="hidden"
          value={aircraftTypeId}
        />
        {CHARACTERISTICS.map(name => (
          <input
            key={name}
            name={`activity[malfunction_characteristics_attributes][${index}][${name}]`}
            type="hidden"
            value={obj[name] || ''}
          />
        ))}
      </Fragment>
    )
  })
}

function MalfunctionCheckboxRow({ checked, onChange }) {
  const translations = useContext(TranslationsContext)
  const label = translations.ubf.malfunction_characteristics

  return (
    <tr>
      <th>
        <label htmlFor="activity_malfunction">{label}</label>
      </th>
      <td>
        <input
          checked={checked}
          id="activity_malfunction"
          onChange={onChange}
          type="checkbox"
          value="1"
        />
      </td>
    </tr>
  )
}

function reducer(state, action) {
  const { aircraftTypeId, name, value } = action
  const newAircraftTypeData = {
    ...state[aircraftTypeId],
    [name]: value,
  }
  return {
    ...state,
    [aircraftTypeId]: newAircraftTypeData,
  }
}

function MalfunctionCharacteristicsPicker(props) {
  const [malfunction, setMalfunction] = useState(
    props.malfunction_characteristics.length > 0
  )
  const [characteristicsData, dispatch] = useReducer(
    reducer,
    props.malfunction_characteristics,
    state => keyBy(state, 'aircraft_type_id')
  )

  return (
    <>
      <MalfunctionCheckboxRow
        checked={malfunction}
        onChange={() => setMalfunction(value => !value)}
      />
      {malfunction ? (
        <tr>
          <td colSpan="2">
            <AircraftTypeCharacteristicsTable
              aircraftTypes={props.aircraft_types}
              data={characteristicsData}
              errors={props.errors}
              onChange={(aircraftTypeId, name, value) =>
                dispatch({ aircraftTypeId, name, value })
              }
            />
          </td>
        </tr>
      ) : null}
      <Inputs data={characteristicsData} malfunction={malfunction} />
    </>
  )
}

export default function Wrapper(props) {
  const { translations, ...rest } = props
  return (
    <TranslationsContext.Provider value={translations}>
      <MalfunctionCharacteristicsPicker {...rest} />
    </TranslationsContext.Provider>
  )
}
