// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React from 'react'
import { intersection } from 'lodash'
import MoodOption from './MoodOption'
import { NEGATIVE, NEUTRAL, POSITIVE } from '../src/lib/behaviourUtils'

const MOODS = [NEGATIVE, NEUTRAL, POSITIVE]

function MoodSelector2(props) {
  const { multiple, onChange, options, value } = props
  const values = new Set(Array.isArray(value) ? value : [value])

  function handleChange(mood, bool) {
    if (multiple) {
      const newValues = new Set(values)

      if (bool) {
        newValues.add(mood)
      } else {
        newValues.delete(mood)
      }

      onChange(Array.from(newValues))
    } else {
      onChange(bool ? mood : null)
    }
  }

  return (
    <div className="mood__selector">
      {intersection(MOODS, options).map(mood => (
        <MoodOption
          checked={values.has(mood)}
          editable={!!onChange}
          key={mood}
          onChange={handleChange}
          value={mood}
        />
      ))}
    </div>
  )
}

MoodSelector2.propTypes = {
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}

export default MoodSelector2
