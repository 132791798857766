// Copyright Northcote Technology Ltd
import { GET_EDIT_STATE } from '../actionTypes'

const edition = (state = { info: null }, action) => {
  switch (action.type) {
    case GET_EDIT_STATE: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default edition
