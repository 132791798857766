// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class NestedHeadingBox extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    hasLowerLevels: PropTypes.bool.isRequired,
    isFirstInLevel: PropTypes.bool.isRequired,
    isTopLevel: PropTypes.bool.isRequired,
    lastInLevelRecords: PropTypes.arrayOf(PropTypes.bool).isRequired,
    level: PropTypes.number.isRequired,
  }

  render() {
    const {
      children,
      isTopLevel,
      hasLowerLevels,
      isFirstInLevel,
      lastInLevelRecords,
      level,
    } = this.props

    const levelPadders = lastInLevelRecords.map((isLastInLevel, index) => {
      const isThisLevel = index === level
      const isFirstBlock = index == 0
      const firstAndNotLast = isFirstInLevel && !isLastInLevel

      const fullVertical = !isFirstBlock && !isLastInLevel
      const halfBottom =
        !isFirstBlock && isThisLevel && firstAndNotLast && !isTopLevel
      const halfTop = !isFirstBlock && isThisLevel && isLastInLevel
      const halfHorizontal = isThisLevel

      return (
        <div
          key={index}
          className={classNames({
            'nested-heading-box__line-block': true,
            'nested-heading-box__line-block--full-vertical': fullVertical,
            'nested-heading-box__line-block--half-bottom': halfBottom,
            'nested-heading-box__line-block--half-top': halfTop,
            'nested-heading-box__line-block--half-horizontal': halfHorizontal,
          })}
        ></div>
      )
    })

    const descender = hasLowerLevels ? (
      <div
        className={classNames({
          'nested-heading-box__line-block': true,
          'nested-heading-box__line-block--descender': true,
          'nested-heading-box__line-block--half-bottom': true,
        })}
      ></div>
    ) : null

    return (
      <div className="nested-heading-box">
        {levelPadders}

        <div className="nested-heading-box__content">
          {descender}

          <div className="nested-heading-box__box">
            <div>{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default NestedHeadingBox
