// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import translate from 'src/lib/translate'
import gradingScaleProps from '../proptypes/gradingScaleProps'

import DateInput from './DateInput'
import FileDirectUploader from './FileDirectUploader'
import GradeInput from './GradeInput'
import GradingAdditionalInfoFields from './GradingAdditionalInfoFields'
import ObservationSelectorCompat from './ObservationSelectorCompat'

function ObservationInputs(props) {
  const { name, observations } = props

  return observations.map(({ _destroy, behaviourId, id, mood }, index) => (
    <Fragment key={[id, behaviourId, mood, _destroy].join('-')}>
      {id ? (
        <input type="hidden" value={id} name={name + `[${index}][id]`} />
      ) : null}
      <input
        type="hidden"
        value={behaviourId}
        name={name + `[${index}][behaviour_id]`}
      />
      <input type="hidden" value={mood} name={name + `[${index}][mood]`} />
      {_destroy ? (
        <input
          type="hidden"
          value={_destroy}
          name={name + `[${index}][_destroy]`}
        />
      ) : null}
    </Fragment>
  ))
}

export default class GradeInputWrapperClassic extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    gradedDate: PropTypes.string.isRequired,
    grading: PropTypes.shape({
      activityId: PropTypes.number,
      additionalInfo: PropTypes.object,
      allowUploads: PropTypes.bool,
      files: PropTypes.array.isRequired,
      firstAttempt: PropTypes.string,
      grade: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      observations: PropTypes.arrayOf(
        PropTypes.shape({
          _destroy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
          behaviourId: PropTypes.number.isRequired,
          id: PropTypes.number,
          mood: PropTypes.number.isRequired,
        })
      ).isRequired,
      qualificationId: PropTypes.number,
      repeated: PropTypes.bool,
      deferred: PropTypes.bool,
      hasApplicableFromDate: PropTypes.bool,
      hasApplicableUntilDate: PropTypes.bool,
      applicableFromDate: PropTypes.string,
      applicableUntilDate: PropTypes.string,
      activityIdentifier: PropTypes.string,
      qualificationIdentifier: PropTypes.string,
      sessionDate: PropTypes.string,
      personIdentifier: PropTypes.string,
      counter: PropTypes.number,
    }).isRequired,
    gradingNames: PropTypes.shape({
      activityId: PropTypes.string,
      additionalInfo: PropTypes.object.isRequired,
      firstAttempt: PropTypes.string,
      observations: PropTypes.string,
      documentId: PropTypes.string,
      counter: PropTypes.string,
      grade: PropTypes.string.isRequired,
      id: PropTypes.string,
      prefix: PropTypes.string,
      qualificationId: PropTypes.string,
      repeated: PropTypes.string.isRequired,
      deferred: PropTypes.string.isRequired,
      applicableFromDate: PropTypes.string,
      applicableUntilDate: PropTypes.string,
    }).isRequired,
    deferable: PropTypes.bool.isRequired,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    hidden: PropTypes.bool,
    includeObservationInputs: PropTypes.bool,
    label: PropTypes.string,
    onAdditionalInfoUpdate: PropTypes.func,
    onDocumentsUpdate: PropTypes.func,
    onInitFileDirectUploader: PropTypes.func,
    onLastAttemptUpdate: PropTypes.func,
    onGradeUpdate: PropTypes.func,
    onRepeatedUpdate: PropTypes.func,
    onDeferredUpdate: PropTypes.func,
    onApplicableFromDateUpdate: PropTypes.func,
    onApplicableUntilDateUpdate: PropTypes.func,
    onCounterUpdate: PropTypes.func,
    onObservationsChange: PropTypes.func,
    onUpdate: PropTypes.func,
    translations: PropTypes.object.isRequired,
    errorsByQualification: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.divApplicableUntilDate = React.createRef()
    this.refgradeInput = React.createRef()
  }

  componentDidMount = () => {
    this.checkApplicableUntilDate()
  }

  handleLastAttemptUpdate = (grade, category) => {
    const { grading, onLastAttemptUpdate, onUpdate } = this.props

    if (onLastAttemptUpdate) {
      onLastAttemptUpdate(grade, category)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        category,
        grade,
      })
    }
  }

  handleObservationsChange = newObservations => {
    const { onObservationsChange } = this.props

    if (onObservationsChange) {
      onObservationsChange(newObservations)
    }
  }

  onAdditionalInfoFieldUpdate = additionalInfo => {
    const { grading, onAdditionalInfoUpdate, onUpdate } = this.props

    if (onAdditionalInfoUpdate) {
      onAdditionalInfoUpdate(additionalInfo)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        additionalInfo,
      })
    }
  }

  onDocumentsUpdate = (inputRef, documentId, fileUploadName) => {
    const { grading, onDocumentsUpdate, onUpdate } = this.props

    if (onDocumentsUpdate) {
      onDocumentsUpdate(inputRef, documentId, fileUploadName)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        files: grading.files.concat({
          ref: inputRef,
          id: documentId,
          name: fileUploadName,
        }),
      })
    }
  }

  onGradeUpdate = (grade, category) => {
    const { grading, onGradeUpdate, onUpdate } = this.props

    if (onGradeUpdate) {
      onGradeUpdate(grade, category)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        grade,
        category,
      })
    }
  }

  onCounterUpdate = counter => {
    const { grading, onUpdate, onCounterUpdate } = this.props

    if (onCounterUpdate) {
      onCounterUpdate(counter)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        counter,
      })
    }
  }

  onRepeatedUpdate = repeated => {
    const { grading, onRepeatedUpdate, onUpdate } = this.props

    if (onRepeatedUpdate) {
      onRepeatedUpdate(repeated)
    }

    if (onUpdate) {
      const { firstAttempt, grade } = grading

      onUpdate({
        ...grading,
        firstAttempt: repeated ? grade : null,
        grade: repeated ? null : firstAttempt,
        repeated,
      })
    }
  }

  onDeferredUpdate = deferred => {
    const { grading, onDeferredUpdate, onUpdate } = this.props

    if (onDeferredUpdate) {
      onDeferredUpdate(deferred)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        deferred,
      })
    }
  }

  onApplicableFromDateUpdate = applicableFromDate => {
    const { grading, onApplicableFromDateUpdate, onUpdate } = this.props

    if (onApplicableFromDateUpdate) {
      onApplicableFromDateUpdate(applicableFromDate)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        applicableFromDate,
      })
    }
  }

  onApplicableUntilDateUpdate = applicableUntilDate => {
    const { grading, onApplicableUntilDateUpdate, onUpdate } = this.props

    if (onApplicableUntilDateUpdate) {
      onApplicableUntilDateUpdate(applicableUntilDate)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        applicableUntilDate,
      })
    }
  }

  onInitFileDirectUploader = (ref, documentId) => {
    const { onInitFileDirectUploader } = this.props

    if (onInitFileDirectUploader) {
      onInitFileDirectUploader(ref, documentId)
    }
  }

  checkApplicableUntilDate = () => {
    this.refgradeInput.current.checkApplicableUntilDate()
  }

  render() {
    const {
      directUploadsUrl,
      gradedDate,
      grading: {
        activityId,
        additionalInfo,
        allowUploads,
        files,
        firstAttempt,
        grade,
        id,
        observations,
        qualificationId,
        repeated,
        deferred,
        hasApplicableFromDate,
        hasApplicableUntilDate,
        applicableFromDate,
        applicableUntilDate,
        activityIdentifier,
        qualificationIdentifier,
        sessionDate,
        personIdentifier,
        counter,
      },
      gradingNames: {
        activityId: activityIdName,
        additionalInfo: additionalInfoNames,
        documentId: documentIdName,
        firstAttempt: firstAttemptName,
        grade: gradeName,
        counter: counterName,
        id: idName,
        prefix: prefixName,
        observations: observationsName,
        qualificationId: qualificationIdName,
        repeated: repeatedName,
        deferred: deferredName,
        applicableFromDate: applicableFromDateName,
        applicableUntilDate: applicableUntilDateName,
      },
      gradingScale,
      hidden,
      includeObservationInputs,
      label,
      translations,
      errorsByQualification,
      deferable,
    } = this.props

    const { behaviours } = gradingScale
    const bevavioursSelect =
      !hidden && behaviours.length ? (
        <ObservationSelectorCompat
          behaviours={behaviours}
          label={translations.activerecord.attributes.grading.behaviour}
          noResultsText={translations.ubf.behavavioursNoneFound}
          onChange={this.handleObservationsChange}
          placeholder={translations.forms.placeholders.grading.behaviours}
          value={observations}
        />
      ) : null

    const additionalInfoInputs = (
      <GradingAdditionalInfoFields
        additionalInfo={additionalInfo}
        fieldNames={additionalInfoNames}
        hidden={hidden}
        onUpdate={this.onAdditionalInfoFieldUpdate}
      />
    )

    var fileDirectUploadInputs = []

    if (allowUploads) {
      files.forEach((file, index) => {
        fileDirectUploadInputs.push(
          <FileDirectUploader
            directUploadsUrl={directUploadsUrl}
            fileCounter={index + 1}
            documentId={file.id}
            fileUploadName={file.name}
            hidden={hidden}
            id={index + prefixName}
            name={documentIdName}
            activityIdentifier={activityIdentifier}
            qualificationIdentifier={qualificationIdentifier}
            sessionDate={sessionDate}
            personIdentifier={personIdentifier}
            onUploadDocument={this.onDocumentsUpdate}
            onInitFileDirectUploader={this.onInitFileDirectUploader}
          />
        )
      })

      fileDirectUploadInputs.push(
        <FileDirectUploader
          directUploadsUrl={directUploadsUrl}
          fileCounter={files.length + 1}
          hidden={hidden}
          id={files.length + prefixName}
          name={documentIdName}
          activityIdentifier={activityIdentifier}
          qualificationIdentifier={qualificationIdentifier}
          sessionDate={sessionDate}
          personIdentifier={personIdentifier}
          onUploadDocument={this.onDocumentsUpdate}
          onInitFileDirectUploader={this.onInitFileDirectUploader}
        />
      )
    }

    const applicableFromDateInput = hasApplicableFromDate ? (
      <DateInput
        label={translate(
          'forms.labels.grading.applicableFromDate',
          translations
        )}
        name={applicableFromDateName}
        value={applicableFromDate || gradedDate}
        onUpdate={this.onApplicableFromDateUpdate}
      />
    ) : null

    const applicableUntilDateInput = hasApplicableUntilDate ? (
      <DateInput
        label={translate(
          'forms.labels.grading.applicableUntilDate',
          translations
        )}
        name={applicableUntilDateName}
        value={applicableUntilDate || ''}
        onUpdate={this.onApplicableUntilDateUpdate}
        ref={this.divApplicableUntilDate}
      />
    ) : null
    var error = []

    if (errorsByQualification) {
      errorsByQualification.forEach(errors => {
        if (errors[id]) {
          errors[id]['errorMessages'].forEach((errorMessage, index) => {
            error.push(
              <span key={index} className="error">
                {errorMessage}
              </span>
            )
          })
        }
      })
    }

    return (
      <div className="grade-input-wrapper mb30">
        <input type="hidden" name={idName} value={id || ''} />
        <input type="hidden" name={activityIdName} value={activityId || ''} />
        <input
          type="hidden"
          name={qualificationIdName}
          value={qualificationId || ''}
        />
        {includeObservationInputs ? (
          <ObservationInputs
            name={observationsName}
            observations={observations}
          />
        ) : null}

        <div>
          <label>{label}</label>
        </div>

        <div className={'first_attempt_grade_input'}>
          <GradeInput
            disabled={repeated}
            grade={repeated ? firstAttempt : grade}
            repeated={repeated || false}
            deferred={deferred}
            deferable={deferable}
            gradingScale={gradingScale}
            gradeName={repeated ? firstAttemptName : gradeName}
            onGradeUpdate={this.onGradeUpdate}
            onRepeatedUpdate={this.onRepeatedUpdate}
            onDeferredUpdate={this.onDeferredUpdate}
            repeatedName={repeatedName}
            deferredName={deferredName}
            counterName={counterName}
            counter={counter}
            onCounterUpdate={this.onCounterUpdate}
            refApplicableUntilDate={this.divApplicableUntilDate}
            ref={this.refgradeInput}
            gradingId={id}
          />
        </div>

        {repeated ? (
          <div className="last_attempt_grade_input">
            <label>{translations.ubf.lastAttempt}</label>
            <GradeInput
              counterName={counterName}
              deferable={deferable}
              deferredName={deferredName}
              grade={grade}
              gradeName={gradeName}
              gradingId={id}
              gradingScale={gradingScale}
              hideRepeated={true}
              onCounterUpdate={() => {}}
              onGradeUpdate={this.handleLastAttemptUpdate}
              repeatedName={repeatedName}
            />
          </div>
        ) : (
          <input name={firstAttemptName} type="hidden" value="" />
        )}

        <div className={grade || repeated ? null : 'hidden'}>
          {applicableFromDateInput}
          {applicableUntilDateInput}
          {fileDirectUploadInputs}
          {bevavioursSelect}
          {additionalInfoInputs}
          {error}
        </div>
      </div>
    )
  }
}
