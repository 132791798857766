// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'
import AsyncDynamicSelect from './AsyncDynamicSelect'

export default class GroupGraderPeopleSelector extends Component {
  static propTypes = {
    translations: PropTypes.object,
    groupsMandatory: PropTypes.bool.isRequired,
    groups: PropTypes.array.isRequired,
    selectedGroup: PropTypes.number,
    graderLabel: PropTypes.string,
    graderField: PropTypes.string,
    selectedGrader: PropTypes.object,
    selectedPeople: PropTypes.array,
    noResultsGroups: PropTypes.string,
    noResultsPeople: PropTypes.string,
    peopleFieldName: PropTypes.string,
    fieldNamePrefix: PropTypes.string,
    graderError: PropTypes.array,
    peopleError: PropTypes.array,
    groupError: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedGroup: props.selectedGroup?.toString(),
      selectedGrader: props.selectedGrader,
      selectedPeople: props.selectedPeople,
    }
  }

  updateGroup = event => {
    this.setState({
      selectedGroup: event.target.value,
      selectedGrader: null,
      selectedPeople: [],
    })
  }

  updateGrader = event => {
    this.setState({ selectedGrader: event.target.change })
  }

  updatePeople = event => {
    this.setState({ selectedPeople: event.target.change })
  }

  endPointSearchParams = (search, offset) => {
    let searchParams = new URLSearchParams()

    searchParams.set('search', search)
    searchParams.set('offset', offset)
    if (this.props.groupsMandatory) {
      const group = this.state.selectedGroup ? this.state.selectedGroup : 'none'
      searchParams.set('group_id', group)
    }

    return searchParams.toString()
  }

  peopleApiEndPoint = (search, offset) => {
    return '/qtm/trainees?' + this.endPointSearchParams(search, offset)
  }

  graderApiEndPoint = (search, offset) => {
    return '/qtm/graders?' + this.endPointSearchParams(search, offset)
  }

  render = () => {
    const {
      groups,
      groupsMandatory,
      translations,
      noResultsPeople,
      noResultsGroups,
      peopleFieldName,
      graderError,
      peopleError,
      groupError,
      fieldNamePrefix,
      graderLabel,
      graderField,
    } = this.props

    const { selectedGroup, selectedGrader, selectedPeople } = this.state

    return (
      <>
        {groupsMandatory && (
          <tr>
            <th className="required-field">
              {translations.activerecord.attributes.gradingSession.group}
            </th>
            <td>
              <DynamicSelect
                clearable={true}
                name={`${fieldNamePrefix}[group_id]`}
                currentValue={selectedGroup}
                noResultsText={noResultsGroups}
                onSelect={this.updateGroup}
                placeholderText={
                  translations.activerecord.attributes.gradingSession.group
                }
                values={groups}
                styleVersion={2}
                prefixClass={'select'}
              />
              {groupError.toString() !== '' && (
                <span className="error">{groupError}</span>
              )}
            </td>
          </tr>
        )}

        <tr>
          <th className="required-field">
            {graderLabel ||
              translations.activerecord.attributes.gradingSession.grader}
          </th>
          <td>
            <AsyncDynamicSelect
              key={this.state.selectedGroup}
              apiEndPoint={this.graderApiEndPoint}
              clearable={true}
              name={`${fieldNamePrefix}[${graderField || 'grader_id'}]`}
              currentValue={selectedGrader}
              noResultsText={noResultsPeople}
              onSelect={this.updateGrader}
              placeholder={
                graderLabel ||
                translations.activerecord.attributes.gradingSession.graderId
              }
              styleVersion={2}
              prefixClass={'select'}
            />
            {graderError.toString() !== '' && (
              <span className="error">{graderError}</span>
            )}
          </td>
        </tr>

        <tr>
          <th className="required-field">
            {translations.activerecord.attributes.gradingSession.people}
          </th>
          <td>
            <AsyncDynamicSelect
              key={this.state.selectedGroup}
              apiEndPoint={this.peopleApiEndPoint}
              clearable={true}
              name={peopleFieldName}
              currentValue={selectedPeople}
              noResultsText={noResultsPeople}
              onSelect={this.updatePeople}
              placeholder={
                translations.activerecord.attributes.gradingSession.people
              }
              styleVersion={2}
              prefixClass={'select'}
              multi={true}
            />
            {peopleError && peopleError.toString() !== '' && (
              <span className="error">{peopleError}</span>
            )}
          </td>
        </tr>
      </>
    )
  }
}
