// Copyright Northcote Technology Ltd
import { UPDATE_PEOPLE_TABLE, PERSON_DELETED } from '../actionTypes'

const people = (state = { info: null, deletedPersonId: null }, action) => {
  switch (action.type) {
    case UPDATE_PEOPLE_TABLE: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case PERSON_DELETED: {
      return Object.assign({}, state, {
        deletedPersonId: action.payload.personId,
      })
    }
    default: {
      return state
    }
  }
}

export default people
