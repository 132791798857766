// Copyright Northcote Technology Ltd
import React, { useState } from 'react'
import { isEmpty } from 'lodash'

function TimeInput({ label, name, onChange, value }) {
  const parts = (value || '').split(':')
  const [hours, setHours] = useState(parts[0] || '')
  const [minutes, setMinutes] = useState(parts[1] || '')
  const hiddenValue =
    isEmpty(hours) && isEmpty(minutes) ? '' : `${hours}:${minutes}`

  const handleHoursChange = e => {
    const value = e.target.value
    if (value >= 0 && value <= 23) {
      setHours(value)
      triggerOnChange(value, minutes)
    }
  }

  const handleMinutesChange = e => {
    const value = e.target.value
    if (value >= 0 && value <= 59) {
      setMinutes(value)
      triggerOnChange(hours, value)
    }
  }

  const triggerOnChange = (hours, minutes) => {
    if (!onChange) return

    if (isEmpty(hours) && isEmpty(minutes)) {
      onChange({ target: { value: '' } })
    } else {
      onChange({
        target: {
          value: `${hours}:${minutes}`,
        },
      })
    }
  }

  return (
    <div className="time-input">
      <input
        id={`cf-${label}-hours`}
        type="number"
        value={hours}
        onChange={handleHoursChange}
        maxLength="2"
        min="0"
        max="23"
      />
      <span>:</span>
      <input
        id={`cf-${label}-minutes`}
        type="number"
        value={minutes}
        onChange={handleMinutesChange}
        maxLength="2"
        min="0"
        max="59"
      />
      {name ? <input type="hidden" name={name} value={hiddenValue} /> : null}
    </div>
  )
}

export default TimeInput
