// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React, { useMemo, useReducer, useState } from 'react'
import { difference, intersection, isEmpty, keyBy, uniq } from 'lodash'
import { createPortal } from 'react-dom'

import ActivityPicker from './ActivityPicker'
import AircraftTypeSelect from './TemplateEditor/AircraftTypeSelect'
import MalfunctionCharacteristics from './TemplateEditor/MalfunctionCharacteristics'
import TimelineEditor from './Timeline/Editor'
import TrainingTopics from './TemplateEditor/TrainingTopics'

import TranslationsContext from './contexts/Translations'
import reducer, { prepareEvents } from './Timeline/reducer'

const timelineActivityIds = eventsData =>
  uniq(
    Object.values(eventsData).flatMap(({ events }) =>
      events
        .filter(({ _destroy }) => !_destroy)
        .map(({ activityId }) => activityId)
    )
  )

function Portal({ children, selector }) {
  const element = document.querySelector(selector)
  return element ? createPortal(children, element) : null
}

export default function TemplateEditor(props) {
  const {
    activities,
    aircraft_types,
    malfunction_characteristics,
    qualifications,
    timeline,
    training_topics,
    translations,
  } = props
  const [selectedActivityIds, setSelectedActivityIds] = useState(
    activities.value
  )
  const [selectedAircraftId, setSelectedAircraftId] = useState(
    aircraft_types.value
  )
  const [selectedQualificationIds, setSelectedQualificationIds] = useState(
    qualifications.value
  )
  const [eventsData, dispatchChangeTimeline] = useReducer(
    reducer,
    timeline?.events || [],
    prepareEvents
  )
  const [validationErrors, setValidationErrors] = useState({
    activities: activities.errors,
    qualifications: qualifications.errors,
  })
  const handleChangeTimeline = state => {
    setValidationErrors({})
    dispatchChangeTimeline(state)
  }
  const selectableTimelineActivitiesData = useMemo(
    () =>
      keyBy(
        selectedActivityIds.map(id => activities.data[id]),
        'id'
      ),
    [selectedActivityIds]
  )
  const handleChangeActivityIds = ids => {
    const errors = {}
    const removedIds = difference(selectedActivityIds, ids)

    if (removedIds.length > 0) {
      const timelineIds = timelineActivityIds(eventsData)

      if (intersection(removedIds, timelineIds).length > 0) {
        errors.activities = [
          translations.activerecord.errors.models.grading_session_template
            .attributes.activities.used_in_timeline,
        ]
      }
    }

    setValidationErrors(errors)

    if (isEmpty(errors)) setSelectedActivityIds(ids)
  }
  const handleChangeQualificationIds = ids => {
    const errors = {}
    const removedIds = difference(selectedQualificationIds, ids)

    if (removedIds.length > 0) {
      const timelineIds = timelineActivityIds(eventsData)
      const removedActivityIds = uniq(
        removedIds.flatMap(id => qualifications.data[id]?.activity_ids)
      )

      if (intersection(removedActivityIds, timelineIds).length > 0) {
        errors.qualifications = [
          translations.activerecord.errors.models.grading_session_template
            .attributes.qualifications.used_in_timeline,
        ]
      }
    }

    setValidationErrors(errors)

    if (isEmpty(errors)) setSelectedQualificationIds(ids)
  }

  return (
    <TranslationsContext.Provider value={translations}>
      <AircraftTypeSelect
        data={aircraft_types.data}
        name={aircraft_types.name}
        onChange={setSelectedAircraftId}
        value={selectedAircraftId}
      />

      <ActivityPicker
        activitiesData={activities.data}
        activitiesErrors={validationErrors.activities}
        activitiesName={activities.name}
        onChangeActivities={handleChangeActivityIds}
        onChangeQualifications={handleChangeQualificationIds}
        qualificationsData={qualifications.data}
        qualificationsName={qualifications.name}
        qualificationsErrors={validationErrors.qualifications}
        selectedActivityIds={selectedActivityIds}
        selectedQualificationIds={selectedQualificationIds}
      />

      <Portal selector={malfunction_characteristics.selector}>
        <MalfunctionCharacteristics
          activitiesData={activities.data}
          activityIds={selectedActivityIds}
          aircraftTypeId={selectedAircraftId}
          malfunctionCharacteristics={malfunction_characteristics.data}
        />
      </Portal>

      <Portal selector={training_topics.selector}>
        <TrainingTopics
          activitiesData={activities.data}
          activityIds={selectedActivityIds}
          trainingTopicsData={training_topics.data}
        />
      </Portal>

      {timeline ? (
        <Portal selector={timeline.selector}>
          <TimelineEditor
            activitiesData={selectableTimelineActivitiesData}
            eventsData={eventsData}
            onChange={handleChangeTimeline}
          />
        </Portal>
      ) : null}
    </TranslationsContext.Provider>
  )
}

TemplateEditor.propTypes = {
  activities: PropTypes.shape({
    data: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        training_topic_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
      }).isRequired
    ).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  aircraft_types: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
  }).isRequired,
  malfunction_characteristics: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        activity_id: PropTypes.number.isRequired,
        aircraft_type_id: PropTypes.number.isRequired,
        complexity: PropTypes.number,
        degradation: PropTypes.number,
        immediacy: PropTypes.number,
        loss: PropTypes.number,
        management: PropTypes.number,
      }).isRequired
    ).isRequired,
    selector: PropTypes.string.isRequired,
  }).isRequired,
  qualifications: PropTypes.shape({
    data: PropTypes.objectOf(
      PropTypes.shape({
        activity_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  timeline: PropTypes.shape({
    events: PropTypes.arrayOf(
      PropTypes.shape({
        _destroy: PropTypes.bool,
        activityId: PropTypes.number,
        categoryAc: PropTypes.string,
        categoryNav: PropTypes.string,
        categoryTraining: PropTypes.string,
        categoryWeather: PropTypes.string,
        column: PropTypes.number.isRequired,
        description: PropTypes.string,
        estimatedTime: PropTypes.string,
        id: PropTypes.number,
        row: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    selector: PropTypes.string.isRequired,
  }),
  training_topics: PropTypes.shape({
    data: PropTypes.objectOf(
      PropTypes.shape({
        frequency: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    selector: PropTypes.string.isRequired,
  }),
  translations: PropTypes.object.isRequired,
}
