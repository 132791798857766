// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  AWARD_ROW_PADDING,
  GRADING_ROW_HEIGHT,
  awardStreamsFrom,
} from './AwardRow'

import { AWARD_TIMELINE_HEIGHT, AWARD_TIMELINE_PADDING } from './AwardTimeline'

import qualificationProps from 'proptypes/qualificationProps'

import NestedHeadingBox from './NestedHeadingBox'

class AwardRowHeading extends Component {
  static propTypes = {
    isFirstRow: PropTypes.bool.isRequired,
    lastInLevelRecords: PropTypes.arrayOf(PropTypes.bool).isRequired,
    level: PropTypes.number.isRequired,
    qualification: PropTypes.shape(qualificationProps),
  }

  render() {
    const { isFirstRow, lastInLevelRecords, level, qualification } = this.props

    const { isGradable, name, subqualifications } = qualification

    const subqualificationCount = subqualifications.length
    const subHeadings = subqualifications.map((subqualification, index) => {
      const isLastRow = index === subqualificationCount - 1
      return (
        <AwardRowHeading
          key={subqualification.id}
          isFirstRow={index === 0}
          lastInLevelRecords={lastInLevelRecords.concat([isLastRow])}
          level={level + 1}
          qualification={subqualification}
        />
      )
    })

    const awardStreamCount = awardStreamsFrom(
      qualification,
      qualification.awards
    ).length

    const timeLineHeight = AWARD_TIMELINE_HEIGHT + 2 * AWARD_TIMELINE_PADDING
    const rowHeight = timeLineHeight * awardStreamCount + 2 * AWARD_ROW_PADDING
    const height = isGradable ? rowHeight + GRADING_ROW_HEIGHT : rowHeight

    return (
      <div className="award-row-heading">
        <div
          className="award-row-heading__content"
          style={{
            height: height,
          }}
        >
          <NestedHeadingBox
            isTopLevel={true}
            hasLowerLevels={subqualificationCount > 0}
            isFirstInLevel={isFirstRow}
            lastInLevelRecords={lastInLevelRecords}
            level={level}
          >
            {name}
          </NestedHeadingBox>
        </div>

        {subHeadings}
      </div>
    )
  }
}

export default AwardRowHeading
