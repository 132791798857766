// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Link from './Link'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Menu from './Menu'
import WifiIcon from '@material-ui/icons/Wifi'
import WifiOffIcon from '@material-ui/icons/WifiOff'
import Logo from '../../../assets/images/quantum_logo.png'
import { onlineSelector } from '../../redux/selectors'

function HeaderProgress() {
  const progress = useSelector(state => state.loading.progress)

  if (!progress) return null

  const style = {
    width: (progress || 0) + '%',
  }

  return <div className="header__progress" style={style} />
}

class Header extends Component {
  static propTypes = {
    backAction: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    titleLogo: PropTypes.bool,
    greeting: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    menuElements: PropTypes.array.isRequired,
    adminMenuElements: PropTypes.array.isRequired,
    subtitle: PropTypes.string,
    disableHome: PropTypes.bool,
    disableBack: PropTypes.bool,
    disableGreeting: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      logout: false,
    }
  }

  showLogout = () => {
    this.setState({ logout: !this.state.logout })
  }

  render() {
    const {
      backAction,
      online,
      title,
      subtitle,
      userName,
      userType,
      greeting,
      menuElements,
      adminMenuElements,
      disableBack,
      disableHome,
      disableGreeting,
    } = this.props

    const popover = (
      <Popover
        className={online ? 'quantum-menu' : 'hidden'}
        style={{ transform: 'unset' }}
      >
        <Popover.Content>
          <Menu
            menuElements={menuElements}
            adminMenuElements={adminMenuElements}
            online={online}
          />
        </Popover.Content>
      </Popover>
    )

    return (
      <div className="header">
        <HeaderProgress />

        <div className="header__links">
          <OverlayTrigger
            trigger={online ? 'click' : ''}
            placement="bottom"
            overlay={popover}
          >
            <span
              className={`header__links--on-top btn icon icon--bars ${
                online ? '' : 'btn--disabled header__links--disabled'
              }`}
              overlay={popover}
            />
          </OverlayTrigger>

          {disableBack ? null : (
            <a
              className="header__links--back btn icon icon--angle-left"
              onClick={backAction}
            ></a>
          )}

          {disableHome ? null : (
            <Link
              url={'/' + UBF.locale}
              classes="header__links--home btn icon icon--home"
            />
          )}
        </div>

        <div className="header__title">
          <h1 className="header__title--main">
            {this.props.titleLogo ? (
              <img
                className="header__title--img"
                src={UBF.customLogoUrl || Logo}
                alt="Logo"
              />
            ) : (
              title
            )}
          </h1>
          {subtitle && !this.props.titleLogo ? (
            <h4 className="header__title--sub">{subtitle}</h4>
          ) : (
            ''
          )}
        </div>

        <div className="header__right">
          <div onClick={this.showLogout}>
            <div className="header__right--connection">
              {online ? <WifiIcon /> : <WifiOffIcon />}
            </div>
            {disableGreeting ? null : (
              <div className="header__right--user">
                <div className="header__right--user-name">
                  {greeting} <span className="bold">{userName}</span>
                </div>
                <div className="header__right--user-type">{userType}</div>
              </div>
            )}
            {this.state.logout ? (
              <span className="icon icon--chevron-up header__right-icon"></span>
            ) : (
              <span className="icon icon--chevron-down header__right-icon"></span>
            )}
          </div>
          <div
            className={
              'header__right-dropdown ' +
              (this.state.logout ? 'header__right-show' : '')
            }
          >
            <a href={'/' + UBF.locale + '/users/sign_out'}>{UBF.lang.logout}</a>
          </div>
        </div>
      </div>
    )
  }
}

function HeaderWithOnline(props) {
  const online = useSelector(onlineSelector)

  return <Header {...props} online={online} />
}

export default HeaderWithOnline
