// Copyright Northcote Technology Ltd
import React from 'react'
import classnames from 'classnames'

export default function Icon({ color, icon, size }) {
  const className = classnames({
    icon: true,
    [`icon--${color}`]: color,
    [`icon--${icon}`]: icon,
  })

  const style = size ? { '--size': size } : null

  return <span className={className} style={style} />
}
