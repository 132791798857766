// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { saveIdb, getIdb } from '../src/lib/idb/common'

export default class SubmitSessionButton extends Component {
  handleClick = e => {
    const { sessionId, formName } = this.props
    e.preventDefault()
    getIdb('unsubmitted_sessions', sessionId).then(function (result) {
      if (!result) {
        saveIdb('unsubmitted_sessions', {
          session_id: sessionId,
          status: 'submitted',
        }).then(function () {
          document.getElementById(formName).submit()
        })
      } else {
        document.getElementById(formName).submit()
      }
    })
  }

  render() {
    const { formName, text } = this.props

    return (
      <Fragment>
        <input
          className="btn submit-btn right positive"
          form={formName}
          type="submit"
          value={text}
          onClick={this.handleClick}
        />
      </Fragment>
    )
  }
}

SubmitSessionButton.propTypes = {
  text: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
}
