// Copyright Northcote Technology Ltd
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setOnline } from '../redux/actions'

export default function OnlineWatcher() {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleOffline = () => dispatch(setOnline(false))
    const handleOnline = () => dispatch(setOnline(true))

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  return null
}
