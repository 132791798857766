// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useMemo, useReducer } from 'react'
import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { partition } from 'lodash'

import DynamicSelect from './DynamicSelect'
import IconButton from './IconButton'

function createIdNameLookup(collection) {
  return collection.reduce((obj, { id, name }) => {
    obj[id] = name
    return obj
  }, {})
}

function prepareState(state, templateNames) {
  return state.map(item => {
    const id = item.grading_session_template_id
    item.name = templateNames[id]
    return item
  })
}

function reducer(state, action) {
  switch (action.type) {
    case 'add': {
      const { id, name } = action
      const index = state.findIndex(
        ({ grading_session_template_id }) => grading_session_template_id === id
      )
      const newState = [...state]

      if (index === -1) {
        newState.push({ grading_session_template_id: id, name })
      } else {
        // The template is already present in the data, it must be flagged for
        // removal so unflag it and move it to the end of the list.
        const [item] = newState.splice(index, 1)
        const newItem = { ...item }
        delete newItem._destroy
        newState.push(newItem)
      }

      return newState
    }
    case 'move': {
      const { activeId, overId } = action

      // No change.
      if (activeId === overId) return state

      const activeIndex = state.findIndex(
        ({ grading_session_template_id }) =>
          grading_session_template_id == activeId
      )
      let overIndex = state.findIndex(
        ({ grading_session_template_id }) =>
          grading_session_template_id == overId
      )

      // Just in case.
      if (activeIndex === -1 || overIndex === -1) return state

      const newState = [...state]
      const [activeItem] = newState.splice(activeIndex, 1)

      // Just in case.
      if (!activeItem) return state

      newState.splice(overIndex, 0, activeItem)

      return newState
    }
    case 'remove': {
      const { id } = action
      const index = state.findIndex(
        ({ grading_session_template_id }) => grading_session_template_id === id
      )

      // Just in case.
      if (index === -1) return state

      const item = state[index]
      const newState = [...state]

      if (item.id) {
        // Exists in the backend so must be flagged to remove.
        newState[index] = { ...item, _destroy: true }
      } else {
        // Does not exist in the backend so can immediately be removed.
        newState.splice(index, 1)
      }

      return newState
    }
    default:
      return state
  }
}

function Inputs({ name, rows }) {
  const [destroyed, others] = partition(rows, '_destroy')

  return (
    <>
      {destroyed.map(({ id }, i) => (
        <Fragment key={`destroyed-${id}`}>
          <input name={`${name}[${i}][_destroy]`} type="hidden" value="1" />
          <input name={`${name}[${i}][id]`} type="hidden" value={id} />
        </Fragment>
      ))}
      {others.map(({ grading_session_template_id, id }, index) => {
        const i = index + destroyed.length
        return (
          <Fragment key={grading_session_template_id}>
            {id ? (
              <input name={`${name}[${i}][id]`} type="hidden" value={id} />
            ) : null}
            <input
              name={`${name}[${i}][grading_session_template_id]`}
              type="hidden"
              value={grading_session_template_id}
            />
            <input
              name={`${name}[${i}][position]`}
              type="hidden"
              value={index}
            />
          </Fragment>
        )
      })}
    </>
  )
}

function Row(props) {
  const { item, onRemove, translations } = props
  const { grading_session_template_id, name } = item
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: grading_session_template_id })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : null,
  }

  return (
    <div className="SortableTemplatePicker--row" ref={setNodeRef} style={style}>
      <IconButton
        aria-label={translations.ubf.move}
        cursor="move"
        icon="bars"
        {...attributes}
        {...listeners}
      />
      <span>{name}</span>
      <IconButton
        aria-label={translations.ubf.remove}
        icon="trash"
        onClick={() => onRemove(grading_session_template_id)}
      />
    </div>
  )
}

function Rows({ onMove, onRemove, rows, translations }) {
  return (
    <div className="SortableTemplatePicker--rows">
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[
          restrictToParentElement,
          restrictToVerticalAxis,
          restrictToWindowEdges,
        ]}
        onDragEnd={onMove}
      >
        <SortableContext
          items={rows.map(
            ({ grading_session_template_id }) => grading_session_template_id
          )}
          strategy={verticalListSortingStrategy}
        >
          {rows.map(item => (
            <Row
              item={item}
              key={item.grading_session_template_id}
              onRemove={onRemove}
              translations={translations}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
}

export default function SortableTemplatePicker(props) {
  const {
    name,
    no_results_text,
    placeholder,
    selected,
    templates,
    translations,
  } = props
  const templateNames = useMemo(
    () => createIdNameLookup(templates),
    [templates]
  )
  const [rows, dispatch] = useReducer(reducer, selected, state =>
    prepareState(state, templateNames)
  )

  const handleAdd = useCallback(event => {
    const id = parseInt(event.target.value)
    const name = templateNames[id]

    // Just in case.
    if (!name) return

    dispatch({ id, name, type: 'add' })
  }, [])
  const handleMove = useCallback(dragEvent => {
    const activeId = dragEvent.active.id
    const overId = dragEvent.over.id
    dispatch({ activeId, overId, type: 'move' })
  }, [])
  const handleRemove = useCallback(id => {
    dispatch({ id, type: 'remove' })
  }, [])

  const notDestroyed = rows.filter(({ _destroy }) => !_destroy)
  const existingIds = new Set(
    notDestroyed.map(
      ({ grading_session_template_id }) => grading_session_template_id
    )
  )
  const options = templates
    .filter(({ id }) => !existingIds.has(id))
    .map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }))

  return (
    <>
      <Inputs name={name} rows={rows} />
      <Rows
        onMove={handleMove}
        onRemove={handleRemove}
        rows={notDestroyed}
        translations={translations}
      />
      <DynamicSelect
        clearable={true}
        currentValue={null}
        multi={false}
        name="SortableTemplatePicker"
        noResultsText={no_results_text}
        onSelect={handleAdd}
        placeholderText={placeholder}
        styleVersion={2}
        values={options}
      />
    </>
  )
}

SortableTemplatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  no_results_text: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      _destroy: PropTypes.bool,
      grading_session_template_id: PropTypes.number.isRequired,
      id: PropTypes.number,
    }).isRequired
  ).isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  translations: PropTypes.shape({
    ubf: PropTypes.shape({
      move: PropTypes.string.isRequired,
      remove: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
