// Copyright Northcote Technology Ltd
import React, { useContext, useReducer, useState } from 'react'
import { sortBy } from 'lodash'

import TranslationsContext from './contexts/Translations'
import { activitiesDataToSelectValues } from '../src/lib/activitiesHelper'
import { personName } from '../src/lib/peopleHelper'

import Button from './Button'
import DynamicSelect from './DynamicSelect'
import ObservationSelector from './ObservationSelector'
import PersonSelector from './next/PersonSelector'
import RecordingFormTemsModal from './RecordingFormTemsModal'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import AutoExpandingTextarea from './AutoExpandingTextarea'

function applyCrewObsToAllPeople(state, crewId, personIds) {
  const crewObs = state.recordingPersonBehaviours.find(
    ({ personId }) => personId === crewId
  )
  const newRecordingPersonBehaviours = [crewObs]

  const otherPersonIds = new Set(personIds)
  otherPersonIds.delete(crewId)
  otherPersonIds.forEach(personId => {
    newRecordingPersonBehaviours.push({
      observations: crewObs.observations,
      personId,
    })
  })

  return {
    ...state,
    recordingPersonBehaviours: newRecordingPersonBehaviours,
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'observations': {
      const { observations, personId } = action
      const newRecordingPersonBehaviours =
        state.recordingPersonBehaviours.filter(
          personObs => personObs.personId !== personId
        )

      if (observations.length > 0) {
        newRecordingPersonBehaviours.push({
          observations,
          personId,
        })
      }

      return {
        ...state,
        recordingPersonBehaviours: newRecordingPersonBehaviours,
      }
    }
    default:
      return { ...state, ...action }
  }
}

export default function RecordingForm(props) {
  const { initialState, onCancel, onSave, session } = props
  const translations = useContext(TranslationsContext)
  const [state, updateState] = useReducer(reducer, initialState)
  const [showTemsModal, setShowTemsModal] = useState(false)
  const people = session.gradingSessionResults.map(({ person }) => person)
  const {
    gradingSessionTemplate: { gradeAsCrew },
  } = session
  const orderedPeople = gradeAsCrew
    ? [{ id: people[0].id, name: translations.ubf.crew }]
    : sortBy(people, person => personName(person))
  const [personId, setPersonId] = useState(orderedPeople[0].id)
  const [hasErrors, setHasErrors] = useState(false)

  const handleSave = () => {
    const invalid = state.comments.trim().length === 0
    setHasErrors(invalid)
    if (invalid) return

    if (gradeAsCrew) {
      const personIds = people.map(({ id }) => id)
      const newState = applyCrewObsToAllPeople(state, personId, personIds)
      onSave(newState)
    } else {
      onSave(state)
    }
  }

  const handleUpdateTems = recordingTems => {
    updateState({ recordingTems })
    setShowTemsModal(false)
  }

  const getTemFilledClass = (categoryId, categoryName) => {
    const containCategory = state.recordingTems.find(element =>
      element.temCategoriesId.includes(categoryId)
    )
    if (!containCategory) return null
    return `recording-form__tems-${categoryName}`
  }

  const getPersonObservations = () => {
    const personObs = state.recordingPersonBehaviours.find(
      personObs => personObs.personId === personId
    )
    return personObs ? personObs.observations : []
  }

  return (
    <div className="recording-form">
      {hasErrors ? (
        <div className="sessions__summary--warning">
          <ReportProblemIcon className="quantum-layout__notification--small quantum-layout__notification--icon-yellow" />
          <div className="sessions__summary--warning-message">
            {
              translations.activerecord.errors.models.recording.attributes
                .comment
            }
          </div>
        </div>
      ) : null}

      {showTemsModal ? (
        <RecordingFormTemsModal
          onCancel={() => setShowTemsModal(false)}
          onSave={handleUpdateTems}
          recording={state}
          session={session}
        />
      ) : null}

      <DynamicSelect
        additionalClass="recording-form__select"
        clearable={true}
        name={translations.activerecord.attributes.recording.activity_id}
        currentValue={state.activityId?.toString()}
        noResultsText=""
        onSelect={({ target: { value } }) =>
          updateState({ activityId: value ? parseInt(value) : null })
        }
        placeholderText={
          translations.forms.placeholders.activity_search.activity_name
        }
        values={activitiesDataToSelectValues(session.activitiesData)}
      />

      <div className="recording-form__comment">
        <label className="label-tag--orca">
          {translations.activerecord.attributes.recording.comment}
        </label>
        <AutoExpandingTextarea
          name="comment"
          onChange={({ target: { value } }) => updateState({ comments: value })}
          placeholder={translations.forms.placeholders.recordings.comment}
          value={state.comments}
        />
      </div>

      <div
        className="recording-form__tems"
        onClick={() => setShowTemsModal(true)}
      >
        {session.temsData.temCategories.map((category, index) => {
          return (
            <span
              key={index}
              className={getTemFilledClass(
                category.id,
                category.name.toLowerCase()
              )}
            >
              {
                translations.activerecord.attributes.recording.tem[
                  category.name.toLowerCase()
                ]
              }
            </span>
          )
        })}
      </div>

      <PersonSelector
        crewName={translations.ubf.crew}
        gradeAsCrew={gradeAsCrew}
        onUpdate={id => setPersonId(id)}
        people={orderedPeople}
        selectedId={personId}
      />

      <ObservationSelector
        availableBehaviourIds={Object.values(session.behavioursData).map(
          ({ id }) => id
        )}
        behavioursData={session.behavioursData}
        label={translations.activerecord.attributes.recording.behaviour}
        noResultsText=""
        onChange={observations =>
          updateState({ observations, personId, type: 'observations' })
        }
        placeholder={translations.forms.placeholders.recordings.behaviour}
        value={getPersonObservations()}
      />

      <div className="recording-form__actions">
        <Button onClick={() => onCancel()}>{translations.ubf.cancel}</Button>
        <Button onClick={handleSave}>{translations.ubf.save}</Button>
      </div>
    </div>
  )
}
