// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { lightColors } from '../src/lib/overallGradingHelper'

export default class OverallGradingEdit extends Component {
  static propTypes = {
    gradingSessionResult: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedOptionId: props.gradingSessionResult.overall_grading_option_id,
    }
  }

  optionSelected(optionId) {
    this.setState({ selectedOptionId: optionId })
  }

  render() {
    const { options, translations } = this.props

    const { selectedOptionId } = this.state

    const optionClass = 'overall-grading-result-edit__option'

    return (
      <div className="overall-grading-result-edit">
        <input
          type="hidden"
          name="overall_grading_option_id"
          value={selectedOptionId}
        />

        <div className="mb20">
          {
            translations.activerecord.attributes.gradingSessionResult
              .overallPositiveResult
          }
        </div>

        {options
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(option => {
            const selected = selectedOptionId === option.id
            return (
              <div
                key={option.id}
                className={`${optionClass} ${optionClass}--${
                  selected ? 'selected' : 'inactive'
                }`}
                style={{
                  borderColor: option.color,
                  backgroundColor: selected ? option.color : '#1a1a1a',
                  color:
                    selected && lightColors.includes(option.color)
                      ? 'black'
                      : 'white',
                }}
                onClick={() => this.optionSelected(option.id)}
              >
                {option.label}
              </div>
            )
          })}
      </div>
    )
  }
}
