// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import translate from 'src/lib/translate'
import RoleQualificationHistory from './RoleQualificationHistory'

class RoleQualificationApp extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
  }

  render() {
    const { translations, ...otherProps } = this.props

    const curriedTranslate = path => translate(path, translations)

    return (
      <div className="role-qualification-app">
        <RoleQualificationHistory t={curriedTranslate} {...otherProps} />
      </div>
    )
  }
}

export default RoleQualificationApp
