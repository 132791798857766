// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { sortBy, uniq } from 'lodash'

import DynamicSelect from './DynamicSelect'

import TranslationsContext from './contexts/Translations'

const toOptions = list =>
  sortBy(
    list.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    })),
    'label'
  )

function Errors({ messages }) {
  if (!messages || messages.length === 0) return null

  return <span className="error">{messages.join(', ')}</span>
}

export default function ActivityPicker(props) {
  const { activitiesData, qualificationsData, selectedQualificationIds } = props
  const translations = useContext(TranslationsContext)
  const qualificationsOptions = useMemo(
    () => toOptions(Object.values(qualificationsData)),
    [qualificationsData]
  )
  const selectableActivityOptions = useMemo(
    () =>
      toOptions(
        uniq(
          selectedQualificationIds.flatMap(
            id => qualificationsData[id]?.activity_ids
          )
        ).map(id => activitiesData[id])
      ),
    [selectedQualificationIds]
  )
  const templateTranslations =
    translations.activerecord.attributes.grading_session_template
  const handleUpdateActivityIds = event =>
    props.onChangeActivities(event.target.value.map(id => parseInt(id)))
  const handleUpdateQualificationIds = event =>
    props.onChangeQualifications(event.target.value.map(id => parseInt(id)))

  return (
    <>
      <tr>
        <th>
          <label className="required-field" htmlFor="">
            {templateTranslations.qualifications}
          </label>
        </th>
        <td>
          <DynamicSelect
            clearable={true}
            currentValue={props.selectedQualificationIds.map(id =>
              id.toString()
            )}
            multi={true}
            name={props.qualificationsName}
            noResultsText={translations.messages.qualifications.not_found}
            onSelect={handleUpdateQualificationIds}
            placeholderText={templateTranslations.qualifications}
            values={qualificationsOptions}
          />
          <Errors messages={props.qualificationsErrors} />
        </td>
      </tr>
      <tr>
        <th>
          <label className="required-field" htmlFor="">
            {templateTranslations.activities}
          </label>
        </th>
        <td>
          <DynamicSelect
            clearable={true}
            currentValue={props.selectedActivityIds.map(id => id.toString())}
            multi={true}
            name={props.activitiesName}
            noResultsText={translations.messages.activities.not_found}
            onSelect={handleUpdateActivityIds}
            placeholderText={templateTranslations.activities}
            values={selectableActivityOptions}
          />
          <Errors messages={props.activitiesErrors} />
        </td>
      </tr>
    </>
  )
}

ActivityPicker.propTypes = {
  activitiesData: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  activitiesErrors: PropTypes.arrayOf(PropTypes.string),
  activitiesName: PropTypes.string.isRequired,
  onChangeActivities: PropTypes.func.isRequired,
  onChangeQualifications: PropTypes.func.isRequired,
  qualificationsData: PropTypes.objectOf(
    PropTypes.shape({
      activity_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  qualificationsErrors: PropTypes.arrayOf(PropTypes.string),
  qualificationsName: PropTypes.string.isRequired,
  selectedActivityIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedQualificationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}
