// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { EntitiesSelector } from '../common/EntitiesSelector'
import { isPersonInGroups } from '../../src/lib/peopleHelper'

export default class GroupPeopleSelector extends Component {
  static propTypes = {
    children: PropTypes.node,
    groupOptions: PropTypes.object,
    peopleOptions: PropTypes.object,
    onUpdate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentPeopleOptions: [],
    }
  }

  onGroupSelect = event => {
    const { peopleOptions, onUpdate } = this.props

    const groupsSelected = [event.target.value]
    let currentPeopleOptions = []

    if (groupsSelected) {
      currentPeopleOptions = { ...peopleOptions }
      currentPeopleOptions.values = peopleOptions.values.filter(p =>
        isPersonInGroups(p, groupsSelected)
      )
    }

    this.setState({ currentPeopleOptions })
    onUpdate(event)
  }

  onPeopleUpdate = event => {
    const { onUpdate } = this.props
    onUpdate(event)
  }

  render() {
    const { children, groupOptions } = this.props
    const { currentPeopleOptions } = this.state
    const anyPeople = currentPeopleOptions.values.length > 0

    return (
      <>
        <EntitiesSelector
          currentValues={[]}
          info={groupOptions}
          mainClass="quantum-layout__field"
          prefixClass="select"
          onUpdate={this.onGroupSelect}
        >
          {anyPeople ? null : children}
        </EntitiesSelector>

        {anyPeople ? (
          <EntitiesSelector
            currentValues={[]}
            info={currentPeopleOptions}
            mainClass="quantum-layout__field"
            prefixClass="select"
            onUpdate={this.onPeopleUpdate}
          >
            {children}
          </EntitiesSelector>
        ) : null}
      </>
    )
  }
}
