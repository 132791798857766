// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const DEFAULT_POLE_LENGTH = 2

class FlagLabel extends Component {
  static propTypes = {
    flagPoleLength: PropTypes.number,
    isVisible: PropTypes.bool,
    pointing: PropTypes.oneOf(['left', 'right']).isRequired,
    textLines: PropTypes.arrayOf(PropTypes.string).isRequired,
    verticalAlignment: PropTypes.oneOf(['bottom', 'top']).isRequired,
  }

  render() {
    const {
      flagPoleLength,
      isVisible,
      pointing,
      textLines,
      verticalAlignment,
    } = this.props

    const text = textLines.map((line, index) => {
      return (
        <div className="flag-label__text-line" key={index}>
          {line}
        </div>
      )
    })

    const classes = classNames({
      'flag-label': true,
      [`flag-label--${verticalAlignment}`]: true,
      [`flag-label--pointing-${pointing}`]: true,
      [`flag-label--is-visible`]: isVisible,
    })

    const poleLength = flagPoleLength || DEFAULT_POLE_LENGTH
    const isAlignedTop = verticalAlignment === 'top'
    const isAlignedBottom = verticalAlignment === 'bottom'

    return (
      <div
        className={classes}
        role="dialog"
        style={{
          paddingTop: isAlignedBottom ? poleLength : 0,
          paddingBottom: isAlignedTop ? poleLength : 0,
        }}
      >
        <div className="flag-label__text-lines">{text}</div>
      </div>
    )
  }
}

export default FlagLabel
