// Copyright Northcote Technology Ltd
import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { debounceSearchRender } from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { muiStyles } from '../../styles/MuiDataTable'
import RefreshIcon from '@material-ui/icons/Refresh'

export default class Table extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    actionOnCellClick: PropTypes.func,
    actionOnRowClick: PropTypes.func,
    translations: PropTypes.object.isRequired,
    reduceHeightPx: PropTypes.number,
    showToolbar: PropTypes.bool,
  }

  constructor(props) {
    super(props)
  }

  theme = () => createMuiTheme(muiStyles)

  render() {
    const { columns, title, translations, data, reduceHeightPx, showToolbar } =
      this.props

    const datatab = data.map(function (x) {
      var res = []
      for (var i in x) {
        res.push(x[i])
      }
      return res
    })

    const minusHeigh = reduceHeightPx ? reduceHeightPx : 0
    const toolbar = showToolbar == false ? false : true

    const options = {
      filterType: 'dropdown',
      search: toolbar,
      viewColumns: toolbar,
      filter: toolbar,
      print: false,
      download: false,
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight:
        (window.innerHeight - 410 - minusHeigh > 200
          ? window.innerHeight - 410 - minusHeigh
          : 200) + 'px',
      selectableRows: 'none',
      selectableRowsHideCheckboxes: true,
      responsive: 'standard',
      rowsPerPageOptions: [],
      customSearchRender: debounceSearchRender(300),
      textLabels: {
        filter: {
          all: translations.ubf.all,
          title: '', //translations.ubf.filters, //no title for now
          reset: <RefreshIcon />,
        },
      },
      onCellClick: (cellData, cellMeta) => {
        const { actionOnCellClick } = this.props
        if (actionOnCellClick)
          actionOnCellClick(cellData, cellMeta, data[cellMeta.dataIndex])
      },
      onRowClick: rowData => {
        const { actionOnRowClick } = this.props
        if (actionOnRowClick) actionOnRowClick(rowData[0])
      },
      selectableRowsHeader: false,
    }

    return (
      <div className="outer-container-left">
        <div>
          <MuiThemeProvider theme={this.theme()}>
            <MUIDataTable
              title={title}
              data={datatab}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </div>
    )
  }
}
