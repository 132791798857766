// Copyright Northcote Technology Ltd
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from '../../redux/store'

import Module from './Module'

import TranslationsContext from '../contexts/Translations'
import registerServiceWorker from '../../src/registerServiceWorker'

export default function Wrapper(props) {
  useEffect(() => registerServiceWorker(), [])

  return (
    <Provider store={store}>
      <TranslationsContext.Provider value={props.translations}>
        <Module {...props} />
      </TranslationsContext.Provider>
    </Provider>
  )
}
