// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import translate from 'src/lib/translate'
import { get_request } from '../src/lib/requestToServer'

export default class HeatmapRow extends Component {
  static propTypes = {
    heatmapId: PropTypes.number.isRequired,
    row: PropTypes.array.isRequired,
    header: PropTypes.array.isRequired,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  state = {
    showObservations: false,
    currentObservations: [],
    prevGradeSelected: '',
    currentGradeSelected: '',
  }

  round = number => {
    return Math.round((number + Number.EPSILON) * 100) / 100
  }

  percentage = cell => {
    return cell.total == 0 ? 0.0 : (1.0 * cell.number) / cell.total
  }

  intensity = cell => {
    return 100 * this.percentage(cell)
  }

  additionalClassFor = cell => {
    const forGrade = cell.for_grade
      ? ' heatmap__cell--' + Math.trunc(this.intensity(cell))
      : ''
    const isReference = cell.is_reference ? ' heatmap__cell--reference' : ''
    return forGrade + isReference
  }

  setCurrentObsevations = async (header, cell) => {
    const { heatmapId } = this.props

    let observations = await get_request('/heatmap_reports', {
      id: heatmapId,
      observations: true,
      grade: header,
      label_id: cell.label_id,
      grouping: cell.grouping,
    })

    if (!observations) return

    observations = observations.map(o => {
      return {
        ...o,
        behaviour_id: o.behaviour.id,
        behaviour_name: o.behaviour.name,
        mood: o.mood_text,
      }
    })
    var obPerMood = observations.reduce((ob, { id, behaviour_id, mood }) => {
      if (!ob[mood]) ob[mood] = []
      ob[mood].push({ id: id, behaviour_id: behaviour_id })
      return ob
    }, {})

    var obPerId = observations.reduce(
      (ob, { id, behaviour_name, behaviour_id, mood }) => {
        if (!ob[behaviour_id]) ob[behaviour_id] = []
        ob[behaviour_id].push({
          id: id,
          behaviour_name: behaviour_name,
          mood: mood,
        })
        return ob
      },
      {}
    )

    const behaviourIds = Object.keys(obPerId)
    var obHTML = []
    var quantityPerMoodPerOb = []
    var ammountPerMood = 0
    behaviourIds.forEach(behaviourId => {
      const moods = Object.keys(obPerMood)
      moods.forEach(currentMood => {
        ammountPerMood = obPerId[behaviourId].filter(
          o => o.mood == currentMood
        ).length
        quantityPerMoodPerOb.push({ mood: currentMood, total: ammountPerMood })
        ammountPerMood = 0
      })
      var percentage = 0

      obHTML.push(
        <div key={behaviourId} className="grid-row">
          <div className="grid-column--3">
            {obPerId[behaviourId][0].behaviour_name}
          </div>
          <div className="grid-column--6 content-alignment">
            {quantityPerMoodPerOb.map((elem, index) => {
              percentage = (elem.total * 100) / obPerId[behaviourId].length
              if (percentage == 0) {
                return ''
              }
              return (
                <div
                  key={index}
                  className={
                    'mood-progress-bar mood-progress-bar--' + elem.mood
                  }
                  style={{ width: percentage + '%' }}
                ></div>
              )
            })}
          </div>
          <div className="grid-column--3">
            {translate(
              'forms.labels.heatmap.behaviours.total_times',
              this.props.translations
            )}{' '}
            {obPerId[behaviourId].length} [
            {this.round(
              (obPerId[behaviourId].length * 100) / observations.length
            )}
            %]
          </div>
        </div>
      )
      quantityPerMoodPerOb = []
    })

    this.setState({
      showObservations: !(this.state.prevGradeSelected == header),
      currentObservations: obHTML,
      prevGradeSelected: header == this.state.prevGradeSelected ? '' : header,
    })
  }

  setSelectedGrade = evt => {
    const { currentGradeSelected } = this.state

    if (
      currentGradeSelected &&
      currentGradeSelected.getAttribute('index') !=
        evt.currentTarget.getAttribute('index')
    ) {
      currentGradeSelected.classList.remove('heatmap-grade--selected')
    }

    this.setState({ currentGradeSelected: evt.currentTarget })

    evt.currentTarget.classList.contains('heatmap-grade--selected')
      ? evt.currentTarget.classList.remove('heatmap-grade--selected')
      : evt.currentTarget.classList.add('heatmap-grade--selected')
  }

  render() {
    const { row, header } = this.props

    const rowHTML = []
    row.forEach((cell, index) => {
      index == 0
        ? rowHTML.push(<th key={index}>{cell.content}</th>)
        : rowHTML.push(
            <td
              key={index}
              index={index}
              onClick={env => {
                this.setSelectedGrade(env)
                this.setCurrentObsevations(header[index].content, cell)
              }}
              style={{ lineHeight: '2.5rem' }}
            >
              <div className={'heatmap__cell' + this.additionalClassFor(cell)}>
                {this.round(100 * this.percentage(cell)) +
                  '% [' +
                  cell.number +
                  ']'}
              </div>
            </td>
          )
    })

    return (
      <Fragment>
        <tr className="heatmap__row">{rowHTML}</tr>
        <tr>
          <td
            className={
              this.state.showObservations &&
              this.state.currentObservations.length != 0
                ? 'heatmap-behaviours'
                : ''
            }
            colSpan={this.props.header.length}
          >
            {this.state.showObservations
              ? this.state.currentObservations
              : null}
          </td>
        </tr>
      </Fragment>
    )
  }
}
