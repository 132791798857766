// Copyright Northcote Technology Ltd
import React from 'react'
import { sortBy } from 'lodash'

import Event from './Event'

export default function Row({ events, ...rest }) {
  return (
    <div className="timeline__row">
      {sortBy(events, 'column').map((event, i) => (
        <Event event={event} key={event.cid || i} {...rest} />
      ))}
    </div>
  )
}
