// Copyright Northcote Technology Ltd

export function optionHasRule(option) {
  return (
    option.minDisqualifyingGrades != null ||
    option.minFailingGrades != null ||
    option.minMinimumAcceptableGrades != null
  )
}

export function tallyGradingCategories(gradings) {
  let results = {}
  gradings.map(g => {
    results[g.category] = (results[g.category] || 0) + 1
  })
  return results
}

export function getTheoricalOptionId(options, gradingCategories) {
  let theoricalOptionId = null

  options.forEach(option => {
    if (theoricalOptionId == null && isRuleMet(option, gradingCategories))
      theoricalOptionId = option.id
  })

  return theoricalOptionId
}

export function isRuleMet(option, gradingCategories) {
  return (
    (gradingCategories['disqualifying'] &&
      option.minDisqualifyingGrades &&
      gradingCategories['disqualifying'] >= option.minDisqualifyingGrades) ||
    (gradingCategories['failing'] &&
      option.minFailingGrades &&
      gradingCategories['failing'] >= option.minFailingGrades) ||
    (gradingCategories['minimum_acceptable'] &&
      option.minMinimumAcceptableGrades &&
      gradingCategories['minimum_acceptable'] >=
        option.minMinimumAcceptableGrades &&
      !(
        option.minDisqualifyingGrades === 0 &&
        gradingCategories['disqualifying'] > 0
      ) &&
      !(option.minFailingGrades === 0 && gradingCategories['failing'] > 0) &&
      !(
        option.minMinimumAcceptableGrades === 0 &&
        gradingCategories['minimum_acceptable'] > 0
      ))
  )
}

export const lightColors = [
  getComputedStyle(document.documentElement).getPropertyValue(
    '--grade-other-color'
  ),
  getComputedStyle(document.documentElement).getPropertyValue(
    '--grade-exemplary-color'
  ),
]
