// Copyright Northcote Technology Ltd
import React, { useContext, useEffect, useRef, useState } from 'react'
import SignaturePad from 'signature_pad'

import TranslationsContext from '../contexts/Translations'
import { personDisplay } from '../../src/lib/peopleHelper'

import Button from '../Button'
import Modal from '../Modal2'
import Spinner from '../Spinner'

export default function CandidateSignature({
  onCancel,
  onSave,
  result,
  signatureDeclaration,
}) {
  const translations = useContext(TranslationsContext)
  const canvasRef = useRef(null)
  const signatureRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [networkError, setNetworkError] = useState(false)

  function loadExistingSignature() {
    const { signatureData, signatureUrl } = result
    const signaturePad = signatureRef.current

    if (signatureData) {
      signaturePad
        .fromDataURL(signatureData)
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
    } else if (signatureUrl) {
      const image = new Image()
      image.onerror = () => {
        setLoading(false)
        setNetworkError(true)
      }
      image.onload = () => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        const devicePixelRatio = window.devicePixelRatio || 1
        context.drawImage(
          image,
          0,
          0,
          image.width / devicePixelRatio,
          image.height / devicePixelRatio
        )
        setLoading(false)
      }
      // In production the image URL will be redirected to the S3 bucket and
      // therefore a different origin. Whilst the image can still be drawn on
      // the canvas the canvas itself becomes tainted such that toDataURL()
      // will throw a SecurityError. Setting a crossOrigin attribute is enough
      // to fix this.
      //
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-crossorigin
      // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
      image.crossOrigin = 'anonymous'
      image.src = signatureUrl
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    const signaturePad = new SignaturePad(canvas)
    signatureRef.current = signaturePad

    const devicePixelRatio = window.devicePixelRatio || 1
    canvas.width = canvas.offsetWidth * devicePixelRatio
    canvas.height = canvas.offsetHeight * devicePixelRatio
    canvas.getContext('2d').scale(devicePixelRatio, devicePixelRatio)

    signaturePad.clear()

    loadExistingSignature()
  }, [])

  const handleClear = () => signatureRef.current.clear()

  const handleSave = () => {
    const signaturePad = signatureRef.current
    onSave(result, signaturePad.isEmpty() ? null : signaturePad.toDataURL())
  }

  return (
    <Modal full={true}>
      <div className="candidate-signature">
        <h2>{personDisplay(result.person)}</h2>

        <div className="candidate-signature--declaration">
          {signatureDeclaration}
        </div>

        <div className="candidate-signature--canvas">
          <canvas ref={canvasRef} />

          {loading && (
            <div className="candidate-signature--overlay">
              <Spinner />
            </div>
          )}

          {networkError && (
            <div className="candidate-signature--overlay">
              <p className="candidate-signature--networkError">
                <span className="icon icon--alert-error" />{' '}
                {translations.ubf.error_loading_signature}
              </p>
            </div>
          )}
        </div>

        <div className="candidate-signature--buttons">
          <Button disabled={loading} onClick={handleSave}>
            {translations.ubf.save}
          </Button>
          <Button disabled={loading} onClick={handleClear}>
            {translations.ubf.clear}
          </Button>
          <Button onClick={onCancel}>{translations.ubf.cancel}</Button>
        </div>
      </div>
    </Modal>
  )
}
