// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export default class NotificationModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onSaveModal: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    notificationType: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
  }

  saveModal = () => {
    const { onSaveModal } = this.props

    onSaveModal()
  }

  closeModal = () => {
    const { onCloseModal } = this.props

    onCloseModal()
  }

  contentModal() {
    const { model } = this.props

    let messageHead = ''
    let messageBody = ''

    let translations = this.props.translations.messages.actions

    switch (this.props.notificationType) {
      case 'create':
        messageHead = model
        messageBody = translations.create_success
        break
      case 'update':
        messageHead = model
        messageBody = translations.update_success
        break
      case 'delete':
        messageHead = model
        messageBody = translations.delete_success
        break
      default:
        messageHead = 'Operation'
        messageBody = 'Succeded'
        break
    }
    return (
      <div className="quantum-layout__notification">
        <h3>{messageHead}</h3>
        <h1>{messageBody}</h1>
        <CheckCircleIcon className="quantum-layout__notification--icon-green" />
      </div>
    )
  }

  render() {
    const showHideClassName = this.props.visible
      ? 'modal modal-block'
      : 'modal modal-none'

    return (
      <div className={showHideClassName}>
        <div className="modal-notification" onClick={this.closeModal}>
          <div className="modal-content">{this.contentModal()}</div>
        </div>
      </div>
    )
  }
}
