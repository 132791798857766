// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export default class Calendar extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.string,
    translations: PropTypes.object.isRequired,
    position: PropTypes.string,
    className: PropTypes.string,
  }
  constructor(props) {
    super(props)

    this.state = {
      currentValue: props.selected ? props.selected : null,
    }
  }

  inputChange = event => {
    let newEvent = {
      target: {
        value: moment(event).format('YYYY-MM-DD'),
        type: 'date',
        name: this.props.name,
      },
    }
    this.setState({ currentValue: event })
    this.props.onChange(newEvent)
  }

  range = (start, end, increment) => {
    var ans = []
    for (let i = start; i <= end; i = i + increment) {
      ans.push(i)
    }
    return ans
  }

  render() {
    const years = this.range(1900, new Date().getFullYear() + 50, 1)
    const { translations } = this.props
    const months = [
      translations.activemodel.attributes.calendar.january,
      translations.activemodel.attributes.calendar.february,
      translations.activemodel.attributes.calendar.march,
      translations.activemodel.attributes.calendar.april,
      translations.activemodel.attributes.calendar.may,
      translations.activemodel.attributes.calendar.june,
      translations.activemodel.attributes.calendar.july,
      translations.activemodel.attributes.calendar.august,
      translations.activemodel.attributes.calendar.september,
      translations.activemodel.attributes.calendar.october,
      translations.activemodel.attributes.calendar.november,
      translations.activemodel.attributes.calendar.december,
    ]

    const { currentValue } = this.state
    const format = UBF.globalDateFormat.replace('DD', 'dd')

    return (
      <div className="input-datepicker">
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
          }) => (
            <div className="react-datepicker__ui-container">
              <button
                className="react-datepicker__button-container--select"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </button>
              <select
                className="react-datepicker__month-dropdown-container--select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                className="react-datepicker__year-dropdown-container--select"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <button
                className="react-datepicker__button-container--select"
                onClick={increaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          )}
          dateFormat={format}
          selected={
            currentValue && null != currentValue ? new Date(currentValue) : null
          }
          onChange={this.inputChange}
          popperPlacement={this.props.position ? this.props.position : 'auto'}
          className={this.props.className ? this.props.className : ''}
          placeholderText={this.props.translations.ubf.date}
        />
      </div>
    )
  }
}
