// Copyright Northcote Technology Ltd
const COLOURS = {
  GRAY: '#969799',
  DARK_GRAY: '#484849',
  SUPER_DARK_GRAY: '#2c2c2c',
  DARKER_GRAY: '#222222',
  DARKEST_GRAY: '#111111',
}

export const treeStyles = {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: COLOURS.SUPER_DARK_GRAY,
      margin: 0,
      padding: 0,
      color: '#9DA5AB',
      fontSize: '14px',
    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        padding: '0px 5px',
        display: 'block',
      },
      activeLink: {
        background: COLOURS.DARK_GRAY,
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top',
          marginLeft: '-5px',
          height: '24px',
          width: '24px',
        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-7px 0 0 -7px',
          height: '14px',
        },
        height: 14,
        width: 14,
        arrow: {
          fill: COLOURS.GRAY,
          strokeWidth: 0,
        },
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'top',
          color: COLOURS.GRAY,
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px',
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle',
        },
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '5px',
      },
    },
  },
}
