// Copyright Northcote Technology Ltd
import { combineReducers } from 'redux'

import data from './data'
import errors from './errors'
import network from './network'

export default combineReducers({
  data,
  errors,
  network,
})
