// Copyright Northcote Technology Ltd
import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../redux/store'
import Module from './Module'

export default function Wrapper(props) {
  return (
    <Provider store={store}>
      <Module {...props} />
    </Provider>
  )
}
