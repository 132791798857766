// Copyright Northcote Technology Ltd
export const csrfToken = document.getElementsByName('csrf-token')[0]

export function save_request(url, params) {
  return new Promise((resolve, reject) => {
    if (!UBF.online) {
      reject('')
    } else {
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken ? csrfToken.content : '',
        },
        body: JSON.stringify(params),
      })
        .then(function (response) {
          if (response.status == 404 || response.status == 500)
            resolve(response)
          resolve(response.json())
        })
        .catch(function (ex) {
          reject(ex)
        })
    }
  })
}

export function update_request(url, params, parameter) {
  return new Promise((resolve, reject) => {
    if (!UBF.online) {
      reject('')
    } else {
      fetch(url + parameter, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken ? csrfToken.content : '',
        },
        body: JSON.stringify(params),
      })
        .then(function (response) {
          if (response.status == 404 || response.status == 500)
            resolve(response)
          resolve(response.json())
        })
        .catch(function (ex) {
          reject(ex)
        })
    }
  })
}

export function delete_request(url, parameter) {
  return new Promise((resolve, reject) => {
    if (!UBF.online) {
      reject('')
    } else {
      fetch(url + parameter, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken ? csrfToken.content : '',
        },
      })
        .then(function (response) {
          if (response.status == 404 || response.status == 500)
            resolve(response)
          resolve(response)
        })
        .catch(function (ex) {
          reject(ex)
        })
    }
  })
}

export function get_request(url, params) {
  return new Promise((resolve, reject) => {
    if (!UBF.online) {
      reject('')
    } else {
      let params_string = new URLSearchParams(params).toString()
      fetch(url + '?' + params_string, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken ? csrfToken.content : '',
        },
      })
        .then(function (response) {
          if (response.status == 404 || response.status == 500)
            resolve(response)
          resolve(response.json())
        })
        .catch(function (ex) {
          console.log(ex)
          reject(ex)
        })
    }
  })
}
