// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import nameToId from 'src/lib/nameToId'
import DynamicSelect from './DynamicSelect'
import CreatableListInput from './CreatableListInput'

export default class CustomFieldTypePicker extends Component {
  static propTypes = {
    fieldTypes: PropTypes.array.isRequired,
    fieldTypeName: PropTypes.string.isRequired,
    fieldTypeNoResultsText: PropTypes.string.isRequired,
    fieldTypeCurrentValue: PropTypes.string,
    fieldTypePlaceholderText: PropTypes.string.isRequired,
    fieldTypeLabel: PropTypes.string.isRequired,
    creatableListInputName: PropTypes.string.isRequired,
    creatableListInputCurrentValues: PropTypes.array,
    creatableListInputPlaceholder: PropTypes.string.isRequired,
    fieldTypesRequireOtherField: PropTypes.array,
  }

  constructor(props) {
    super(props)
    const hasCreatableList = props.fieldTypesRequireOtherField.includes(
      props.fieldTypeCurrentValue
    )
      ? [true]
      : []
    this.state = {
      currentValue: props.fieldTypeCurrentValue,
      selectInput: hasCreatableList,
    }
  }

  updateSelectInput = event => {
    const { fieldTypesRequireOtherField } = this.props

    const addCreatableListInput = []
    if (fieldTypesRequireOtherField.includes(event.target.value)) {
      addCreatableListInput.push(true)
    }

    this.setState({
      currentValue: event.target.value,
      selectInput: addCreatableListInput,
    })
  }

  render() {
    const {
      fieldTypes,
      fieldTypeLabel,
      fieldTypeName,
      fieldTypePlaceholderText,
      fieldTypeNoResultsText,
      creatableListInputName,
      creatableListInputCurrentValues,
      creatableListInputPlaceholder,
    } = this.props

    return (
      <Fragment>
        <label htmlFor={nameToId(fieldTypeName)}>{fieldTypeLabel}</label>
        <DynamicSelect
          clearable={true}
          name={fieldTypeName}
          currentValue={this.state.currentValue}
          noResultsText={fieldTypeNoResultsText}
          onSelect={this.updateSelectInput}
          placeholderText={fieldTypePlaceholderText}
          values={fieldTypes}
        />
        <div>
          {this.state.selectInput.map(number => (
            <CreatableListInput
              key={number}
              currentValues={creatableListInputCurrentValues}
              name={creatableListInputName}
              placeholderText={creatableListInputPlaceholder}
            />
          ))}
        </div>
      </Fragment>
    )
  }
}
