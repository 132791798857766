// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getComplianceStore, getFlexLayoutStore } from '../../redux/selectors'
import { setBottomButtons } from '../../redux/actions'
import PropTypes from 'prop-types'
import { get_request } from '../../src/lib/requestToServer'
import translate from 'src/lib/translate'

class Regulations extends Component {
  static propTypes = {
    comData: PropTypes.object,
    translations: PropTypes.object.isRequired,
    setBottomButtons: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      info: props.comData.info,
      texts: [],
    }
  }

  componentDidMount() {
    this.getRegulationTexts()
  }

  getRegulationTexts() {
    const context = this
    get_request('/admin/compliance', { texts: true }).then(function (response) {
      context.setState({ texts: response })
      context.setState({ refs: context.getReferences() })
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      info: newProps.comData.info,
    })

    if (newProps.comData.info) this.scrollTo(newProps.comData.info.id)

    if (
      newProps.flexlayout &&
      newProps.flexlayout.tabSelected == 'regulations'
    ) {
      this.getRegulationTexts()
      this.props.setBottomButtons([])
    }
  }

  textFieldNames = () => {
    return [
      'text',
      'remarks',
      'category',
      'amending_regulation',
      'revision',
      'document_reference',
    ]
  }

  getReferences = () => {
    const { texts } = this.state
    let refs = []
    texts.forEach(item => (refs[item.id] = React.createRef()))
    return refs
  }

  scrollTo = id => {
    const { refs } = this.state

    if (refs && refs[id]) {
      refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  content() {
    const { texts, info, refs } = this.state
    const { translations } = this.props

    return (
      <div>
        {texts.length == 0
          ? translate('retrievingInformation', translations)
          : texts.map(item => {
              let selectedClass =
                info && info.id == item.id
                  ? 'compliance__regulations--selected'
                  : ''
              let refId =
                refs && refs[item.id] ? refs[item.id] : React.createRef()
              return (
                <div
                  ref={refId}
                  key={refId + item.id}
                  className="compliance__regulations--scroll-position"
                >
                  <div
                    key={item.id}
                    className={'compliance__regulations--item ' + selectedClass}
                  >
                    <div className="compliance__regulations--label">
                      <span className={'margin-' + item.depth}></span>
                      <span>{item.name}</span>
                    </div>
                    <div className="compliance__regulations--text">
                      {item.text}
                    </div>
                  </div>
                </div>
              )
            })}
      </div>
    )
  }

  render() {
    return (
      <div className="compliance__regulations outer-container-right">
        <div className="compliance__regulations--contents inner-container">
          {this.content()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const comData = getComplianceStore(state)
  const flexlayout = getFlexLayoutStore(state)
  return { comData, flexlayout }
}

export default connect(mapStateToProps, { setBottomButtons })(Regulations)
