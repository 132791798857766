// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  GRADING_TIMELINE_HEIGHT,
  GRADING_TIMELINE_PADDING,
} from './GradingTimeline'

import qualificationProps from 'proptypes/qualificationProps'
import AwardTimeline from './AwardTimeline'
import GradingTimeline from './GradingTimeline'

export const AWARD_ROW_PADDING = 14

const GRADING_ROW_PADDING = 0
export const GRADING_ROW_HEIGHT =
  2 * GRADING_ROW_PADDING +
  2 * GRADING_TIMELINE_PADDING +
  GRADING_TIMELINE_HEIGHT

const awardPeriodFor = award => {
  return {
    start: [award.validFromDate, award.awardedDate].sort()[0],
    finish: award.lapsesDate,
  }
}

const awardsOverlap = (award1, award2) => {
  const period1 = awardPeriodFor(award1)
  const period2 = awardPeriodFor(award2)

  return (
    (period2.start <= period1.finish && period2.start >= period1.start) ||
    (period1.start <= period2.finish && period1.start >= period2.start)
  )
}

export const awardStreamsFrom = (qualification, awards) => {
  return qualification.hasFiniteValidity
    ? streamsOfNonOverlappingAwards(awards)
    : [awards]
}

const indexOfFirstStreamWithoutOverlap = (streams, award) => {
  const foundIndex = streams.findIndex(stream => {
    const lastAwardInStream = stream.slice(-1)[0]
    return !awardsOverlap(lastAwardInStream, award)
  })

  return foundIndex >= 0 ? foundIndex : streams.length
}

const streamsOfNonOverlappingAwards = awards => {
  if (awards.length <= 1) return [awards]

  return awards.reduce((streams, award) => {
    const streamIndex = indexOfFirstStreamWithoutOverlap(streams, award)
    streams[streamIndex] = streams[streamIndex] || []
    streams[streamIndex].push(award)
    return streams
  }, [])
}

class AwardRow extends Component {
  static propTypes = {
    qualification: PropTypes.shape(qualificationProps),
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { qualification, scale, t } = this.props

    const { awards, isGradable, gradings, subqualifications } = qualification

    const subHeadings = subqualifications.map(subqualification => {
      return (
        <AwardRow
          key={subqualification.id}
          qualification={subqualification}
          scale={scale}
          t={t}
        />
      )
    })

    const gradingRow = isGradable ? (
      <div
        className="award-row__gradings"
        style={{
          paddingBottom: GRADING_ROW_PADDING,
          paddingTop: GRADING_ROW_PADDING,
          width: width,
        }}
      >
        <GradingTimeline gradings={gradings} scale={scale} t={t} />
      </div>
    ) : null

    const width = scale.range()[1]

    const awardStreams = awardStreamsFrom(qualification, awards)

    const timelines = awardStreams.map((streamOfAwards, index) => {
      return (
        <AwardTimeline
          key={index}
          qualificationId={qualification.id}
          awards={streamOfAwards}
          scale={scale}
          t={t}
        />
      )
    })

    return (
      <div className="award-row">
        <div
          className="award-row__content"
          style={{
            paddingBottom: AWARD_ROW_PADDING,
            paddingTop: AWARD_ROW_PADDING,
            width: width,
          }}
        >
          <div className="award-row__awards">{timelines}</div>

          {gradingRow}
        </div>

        {subHeadings}
      </div>
    )
  }
}

export default AwardRow
