// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import translate from '../src/lib/translate'

import Button from './Button'
import FileDirectUploader from './FileDirectUploader'
import Modal from './Modal'

export default class PersonDocuments extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    urlToSubmit: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    name: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      files: [],
    }
    this.refFormDocuments = React.createRef()
    this.refFirstInputDocument = React.createRef()
  }

  handleSaveModal = () => {
    if (this.state.files.length === 0) return

    this.refFormDocuments.current.submit()
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  contentModal = () => {
    const { directUploadsUrl, urlToSubmit, name, authenticityToken } =
      this.props

    var fileDirectUploadInputs = []

    this.state.files.forEach((file, index) => {
      fileDirectUploadInputs.push(
        <FileDirectUploader
          directUploadsUrl={directUploadsUrl}
          documentId={file.id}
          fileUploadName={file.name}
          hidden={false}
          id={index}
          name={name}
          onUploadDocument={this.onUpload}
        />
      )
    })
    fileDirectUploadInputs.push(
      <FileDirectUploader
        directUploadsUrl={directUploadsUrl}
        hidden={false}
        id={this.state.files.length}
        name={name}
        ref={this.refFirstInputDocument}
        onUploadDocument={this.onUpload}
      />
    )

    if (this.state.files.length == 0 && this.state.showModal) {
      document
        .getElementById(this.refFirstInputDocument.current.props.id)
        .click()
    }

    return (
      <form
        ref={this.refFormDocuments}
        method="post"
        action={urlToSubmit}
        acceptCharset="UTF-8"
      >
        <input name="utf8" type="hidden" value="✓"></input>
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        ></input>
        <input type="hidden" name="_method" value="PATCH"></input>

        {fileDirectUploadInputs}
      </form>
    )
  }
  handleClick = () => {
    this.setState({ showModal: true })
  }

  onUpload = (inputRef, fileId, fileName) => {
    var findReplace = this.state.files.find(element => {
      return element.ref.id == inputRef.id
    })

    if (findReplace) {
      findReplace.id = fileId
      findReplace.name = fileName
      this.setState({ files: this.state.files })
    } else {
      this.setState({
        files: this.state.files.concat({
          ref: inputRef,
          id: fileId,
          name: fileName,
        }),
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Modal
          title={translate(
            'ubf.modal.upload_document',
            this.props.translations
          )}
          visible={this.state.showModal}
          buttonSave={true}
          onSaveModal={this.handleSaveModal}
          onCloseModal={this.handleCloseModal}
          translations={this.props.translations}
        >
          {this.contentModal()}
        </Modal>
        <div className="right">
          <Button onClick={this.handleClick}>
            {translate('ubf.addFile', this.props.translations)}
          </Button>
        </div>
      </Fragment>
    )
  }
}
