// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getFlexLayoutStore,
  getHeatmapReportsStore,
} from '../../../redux/selectors'
import {
  getHeatmapReportsItem,
  getHeatmapReportsUpdate,
} from '../../../redux/actions'
import { get_request } from '../../../src/lib/requestToServer'
import Table from '../../common/Table'

class HeatmapReportsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      maximized: false,
    }

    get_request('/heatmap_reports/', null).then(response => {
      this.setState({
        data: response,
      })
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { flexlayout, heatmapReportsData } = newProps
    const { maximized, data } = this.state

    if (
      flexlayout &&
      flexlayout.maximized != null &&
      flexlayout.maximized != maximized
    ) {
      this.setState({ maximized: flexlayout.maximized })
    }

    if (
      heatmapReportsData &&
      heatmapReportsData.data != null &&
      heatmapReportsData.data != data
    ) {
      this.setState({ data: heatmapReportsData.data })
    }
  }

  refGrader = grader => {
    const { peopleDropdownOptions } = this.props
    return grader
      ? peopleDropdownOptions.find(x => x.value == grader)?.label
      : ''
  }

  datatable = () => {
    const { data } = this.state

    const { translations } = this.props
    const context = this
    const columns = [
      {
        name: 'Id',
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.name,
        options: {
          filter: true,
          filterType: 'select',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.start_date,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.end_date,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.reference_grader,
        options: {
          filter: true,
          filterType: 'select',
          customBodyRender: value => {
            let grader = value ? value : ''
            return context.refGrader(grader)
          },
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.graders,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.people,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.roles,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.qualifications,
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.heatmap.number_of_sessions,
        options: {
          filter: false,
          //filterType: "textField",
          display: 'excluded',
        },
      },
    ]

    return {
      columns: columns,
      data: data,
    }
  }

  actionOnRowClick = param => {
    this.props.getHeatmapReportsItem(param)
    this.props.getHeatmapReportsUpdate()
  }

  render() {
    const datatable = this.datatable()
    if (this.state.data)
      return (
        <Table
          data={datatable.data}
          columns={datatable.columns}
          translations={this.props.translations}
          actionOnRowClick={this.actionOnRowClick}
        />
      )
    else return ''
  }
}

const mapStateToProps = state => {
  const flexlayout = getFlexLayoutStore(state)
  const heatmapReportsData = getHeatmapReportsStore(state)
  return { flexlayout, heatmapReportsData }
}

export default connect(mapStateToProps, {
  getHeatmapReportsItem,
  getHeatmapReportsUpdate,
})(HeatmapReportsTable)
