// Copyright Northcote Technology Ltd
import idbReady from 'safari-14-idb-fix'
import { openDB } from 'idb'
import { BUCKET_SESSION_CHANGES } from '../lib/idb/common'

export const IDB_VERSION = 7
export const IDB_NAME = 'QUANTUM_IDB'

const v1Stores = [
  {
    store: '/admin/gradings/',
    options: { keyPath: 'id', autoIncrement: false },
  },
  {
    store: '/admin/recordings/',
    options: { keyPath: 'id', autoIncrement: false },
  },
  {
    store: '/admin/results/',
    options: { keyPath: 'id', autoIncrement: false },
  },
]

const v2Stores = [
  {
    store: 'unsubmitted_sessions',
    options: { keyPath: 'session_id', autoIncrement: false },
  },
  { store: 'common', options: { keyPath: 'key', autoIncrement: false } },
]

const v4Stores = [
  { store: 'sessions', options: { keyPath: 'id', autoIncrement: false } },
  {
    store: 'template_session_samples',
    options: { keyPath: 'id', autoIncrement: false },
  },
  { store: 'people', options: { keyPath: 'id', autoIncrement: false } },
]

const v7Stores = [
  {
    store: BUCKET_SESSION_CHANGES.table,
    options: { keyPath: 'id', autoIncrement: false },
  },
]

export async function setupIdb() {
  console.log('Setting IDB v' + IDB_VERSION)
  if (!window.indexedDB) {
    console.warn('Browser does not support IndexedDB')
    return
  }

  await idbReady()

  const db = await openDB(IDB_NAME, IDB_VERSION, {
    upgrade: async (db, oldVersion, _newVersion, transaction) => {
      switch (oldVersion) {
        case 0:
          upgradeToV1(db)
        // falls through
        case 1:
          upgradeToV2(db)
        // falls through
        case 2:
          upgradeToV3(db, transaction)
        // falls through
        case 3:
          upgradeToV4(db, transaction)
        // falls through
        case 4:
        case 5:
        case 6:
          clearSessionTemplateSamples(db, transaction)
          upgradeToV7(db, transaction)
          break // leave just the last break
        default:
          console.error('unknown IDB version')
      }
    },
  })
  db.close()
}

function upgradeToV1(db) {
  // Add first stores
  console.log('Upgrading IDB to v1...')
  v1Stores.map(s => {
    db.createObjectStore(s.store, s.options)
  })
}

function upgradeToV2(db) {
  // Add new stores
  console.log('Upgrading IDB to v2...')
  v2Stores.map(s => {
    db.createObjectStore(s.store, s.options)
  })
}

function upgradeToV3(_db, transaction) {
  // Clear common store
  console.log('Upgrading IDB to v3...')
  var objectStore = transaction.objectStore('common')
  objectStore.clear()
}

function upgradeToV4(db) {
  // Add new stores
  console.log('Upgrading IDB to v4...')
  v4Stores.map(s => {
    db.createObjectStore(s.store, s.options)
  })
}

function upgradeToV7(db) {
  // Add new stores
  console.log('Upgrading IDB to v7...')
  v7Stores.map(s => {
    db.createObjectStore(s.store, s.options)
  })
}

function clearSessionTemplateSamples(_, transaction) {
  console.log('Upgrading IDB to v6...')
  transaction.objectStore('template_session_samples').clear()
}
