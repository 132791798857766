// Copyright Northcote Technology Ltd
import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { debounce } from 'lodash'

import translate from 'src/lib/translate'
import gradingScaleProps from '../proptypes/gradingScaleProps'
import TranslationsContext from './contexts/Translations'

import DateInput from './DateInput'
import DirectUploadButton from './DirectUploadButton'
import GradeInput from './GradeInput'
import GradingAdditionalInfoFields from './GradingAdditionalInfoFields'
import IconAction from './IconAction'
import ObservationSelector from './ObservationSelector'

const GradingFileAttachment = ({ file, onRemove }) => {
  const translations = useContext(TranslationsContext)

  const className = classnames({
    'grading-file-attachment': true,
    'grading-file-attachment--removed': file._destroy,
  })

  const handleRemove = () => {
    if (confirm(translations.ubf.are_you_sure)) {
      onRemove(file)
    }
  }

  return (
    <div className={className}>
      <span>{file.name}</span>{' '}
      {!file._destroy && (
        <button
          className="icon icon--trash"
          onClick={handleRemove}
          type="button"
        />
      )}
    </div>
  )
}

export default class GradeInputWrapperOrca extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    grading: PropTypes.shape({
      activityId: PropTypes.number,
      additionalInfo: PropTypes.object,
      files: PropTypes.array.isRequired,
      grade: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      incomplete: PropTypes.bool,
      qualificationId: PropTypes.number,
      repeated: PropTypes.bool,
      deferred: PropTypes.bool,
      applicableFromDate: PropTypes.string,
      applicableUntilDate: PropTypes.string,
      observations: PropTypes.array.isRequired,
      counter: PropTypes.number,
      firstAttempt: PropTypes.string,
    }).isRequired,
    gradingNames: PropTypes.shape({
      activityId: PropTypes.string,
      additionalInfo: PropTypes.object.isRequired,
      documentId: PropTypes.string,
      counter: PropTypes.string,
      grade: PropTypes.string.isRequired,
      id: PropTypes.string,
      prefix: PropTypes.string,
      qualificationId: PropTypes.string,
      repeated: PropTypes.string.isRequired,
      deferred: PropTypes.string.isRequired,
      applicableFromDate: PropTypes.string,
      applicableUntilDate: PropTypes.string,
    }).isRequired,
    incompleteMode: PropTypes.bool,
    deferable: PropTypes.bool.isRequired,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    hidden: PropTypes.bool,
    label: PropTypes.string,
    onAdditionalInfoUpdate: PropTypes.func,
    onDocumentRemove: PropTypes.func,
    onDocumentsUpdate: PropTypes.func,
    onGradeUpdate: PropTypes.func,
    onIncompleteUpdate: PropTypes.func,
    onRepeatedUpdate: PropTypes.func,
    onDeferredUpdate: PropTypes.func,
    onApplicableFromDateUpdate: PropTypes.func,
    onApplicableUntilDateUpdate: PropTypes.func,
    onBehaviourIdsUpdate: PropTypes.func,
    onToggleCurrentGradings: PropTypes.func,
    onCounterUpdate: PropTypes.func,
    onUpdate: PropTypes.func,
    online: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    gradingSession: PropTypes.object,
    errorsByGradings: PropTypes.string,
    onLastAttemptUpdate: PropTypes.func,
    hideFirstAttempt: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    // Only fire after N milliseconds of inactivity.
    this.onAdditionalInfoFieldUpdate = debounce(
      this.onAdditionalInfoFieldUpdate,
      300
    )

    this.divApplicableUntilDate = React.createRef()
    this.refgradeInput = React.createRef()
    this.state = { expandObservations: false }
  }

  componentDidMount = () => {
    this.checkApplicableUntilDate()
  }

  handleDocumentRemove = fileToRemove => {
    const { grading, onDocumentRemove, onUpdate } = this.props

    if (onDocumentRemove) {
      onDocumentRemove(fileToRemove)
    }

    if (onUpdate) {
      const newFiles = grading.files.map(file =>
        file.id === fileToRemove.id ? { ...file, _destroy: true } : file
      )

      onUpdate({
        ...grading,
        document: newFiles
          .filter(({ _destroy }) => !_destroy)
          .map(({ id }) => id),
        files: newFiles,
      })
    }
  }

  handleFileUpload = (error, blob) => {
    if (error) {
      console.error(error)
      return
    }

    const { grading, onDocumentsUpdate, onUpdate } = this.props
    const newFile = {
      id: blob.signed_id,
      name: blob.filename,
    }

    if (onDocumentsUpdate) {
      onDocumentsUpdate(newFile)
    }

    if (onUpdate) {
      const newFiles = grading.files.concat(newFile)

      onUpdate({
        ...grading,
        document: newFiles
          .filter(({ _destroy }) => !_destroy)
          .map(({ id }) => id),
        files: newFiles,
      })
    }
  }

  handleToggleIncomplete = () => {
    const { grading, onIncompleteUpdate, onUpdate } = this.props
    const incomplete = !grading.incomplete

    if (onIncompleteUpdate) {
      onIncompleteUpdate(incomplete)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        incomplete,
      })
    }
  }

  onAdditionalInfoFieldUpdate = additionalInfo => {
    const { grading, onAdditionalInfoUpdate, onUpdate } = this.props

    if (onAdditionalInfoUpdate) {
      onAdditionalInfoUpdate(additionalInfo)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        additionalInfo,
      })
    }
  }

  onBehaviourIdsUpdate = observations => {
    const { grading, onBehaviourIdsUpdate, onUpdate } = this.props

    if (onBehaviourIdsUpdate) {
      onBehaviourIdsUpdate(observations)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        observations,
      })
    }
  }

  onGradeUpdate = (grade, category) => {
    const { grading, onGradeUpdate, onUpdate } = this.props

    if (onGradeUpdate) {
      onGradeUpdate(grade, category)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        grade,
        category,
      })
    }
  }

  onLastAttemptUpdate = (grade, category) => {
    const { grading, onLastAttemptUpdate, onUpdate } = this.props

    if (onLastAttemptUpdate) {
      onLastAttemptUpdate(grade, category)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        category,
        grade,
      })
    }
  }

  onCounterUpdate = counter => {
    const { grading, onUpdate, onCounterUpdate } = this.props

    if (onCounterUpdate) {
      onCounterUpdate(counter)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        counter,
      })
    }
  }

  onRepeatedUpdate = repeated => {
    const { grading, onRepeatedUpdate, onUpdate } = this.props

    if (onRepeatedUpdate) {
      onRepeatedUpdate(repeated)
    }

    if (onUpdate) {
      const { firstAttempt, grade } = grading

      onUpdate({
        ...grading,
        firstAttempt: repeated ? grade : null,
        grade: repeated ? null : firstAttempt,
        repeated,
      })
    }
  }

  onDeferredUpdate = deferred => {
    const { grading, onDeferredUpdate, onUpdate } = this.props

    if (onDeferredUpdate) {
      onDeferredUpdate(deferred)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        deferred,
      })
    }
  }

  onApplicableFromDateUpdate = applicableFromDate => {
    const { grading, onApplicableFromDateUpdate, onUpdate } = this.props

    if (onApplicableFromDateUpdate) {
      onApplicableFromDateUpdate(applicableFromDate)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        applicableFromDate,
      })
    }
  }

  onApplicableUntilDateUpdate = applicableUntilDate => {
    const { grading, onApplicableUntilDateUpdate, onUpdate } = this.props

    if (onApplicableUntilDateUpdate) {
      onApplicableUntilDateUpdate(applicableUntilDate)
    }

    if (onUpdate) {
      onUpdate({
        ...grading,
        applicableUntilDate,
      })
    }
  }

  showObservations = () => {
    this.setState({ expandObservations: !this.state.expandObservations })
  }

  hasObservations = () => {
    const {
      grading: { additionalInfo },
      gradingScale: {
        allowUploads,
        behaviourIds,
        hasApplicableFromDate,
        hasApplicableUntilDate,
      },
    } = this.props

    return (
      behaviourIds.length > 0 ||
      allowUploads ||
      hasApplicableFromDate ||
      hasApplicableUntilDate ||
      Object.keys(additionalInfo).length > 0
    )
  }

  containsObservations = () => {
    const {
      grading: { additionalInfo, files, applicableUntilDate, observations },
    } = this.props

    var conditionAditionalInfo = false
    if (additionalInfo) {
      Object.keys(additionalInfo).forEach(key => {
        if (additionalInfo[key] != '' && additionalInfo[key] != undefined) {
          conditionAditionalInfo = true
        }
      })
    }
    return (
      conditionAditionalInfo ||
      files.length > 0 ||
      (applicableUntilDate != null && applicableUntilDate != '') ||
      observations.length > 0
    )
  }

  addClassSelected = () => {
    return this.containsObservations() ? 'icon--plus-circle-filled' : ''
  }

  checkApplicableUntilDate = () => {
    this.refgradeInput.current.checkApplicableUntilDate()
  }

  render() {
    const {
      directUploadsUrl,
      grading: {
        activityId,
        additionalInfo,
        files,
        grade,
        id,
        incomplete,
        repeated,
        deferred,
        applicableFromDate,
        applicableUntilDate,
        observations,
        counter,
        firstAttempt,
      },
      gradingNames: {
        additionalInfo: additionalInfoNames,
        grade: gradeName,
        counter: counterName,
        repeated: repeatedName,
        deferred: deferredName,
        applicableFromDate: applicableFromDateName,
        applicableUntilDate: applicableUntilDateName,
      },
      gradingScale,
      hidden,
      incompleteMode,
      label,
      online,
      translations,
      gradingSession: { behavioursData, gradedDate },
      onToggleCurrentGradings,
      hideFirstAttempt,
      deferable,
    } = this.props

    const {
      allowUploads,
      behaviourIds,
      hasApplicableFromDate,
      hasApplicableUntilDate,
    } = gradingScale

    const observationSelect = !hidden && behaviourIds.length > 0 && (
      <ObservationSelector
        availableBehaviourIds={behaviourIds}
        behavioursData={behavioursData}
        label={translations.activerecord.attributes.grading.behaviour}
        noResultsText={translations.messages.behaviours.notFound}
        onChange={this.onBehaviourIdsUpdate}
        placeholder={translations.forms.placeholders.grading.behaviours}
        value={observations}
      />
    )

    const additionalInfoInputs = (
      <GradingAdditionalInfoFields
        additionalInfo={additionalInfo}
        fieldNames={additionalInfoNames}
        hidden={hidden}
        onUpdate={this.onAdditionalInfoFieldUpdate}
      />
    )

    const applicableFromDateInput = hasApplicableFromDate ? (
      <DateInput
        label={translate(
          'forms.labels.grading.applicableFromDate',
          translations
        )}
        name={applicableFromDateName}
        value={applicableFromDate || gradedDate}
        onUpdate={this.onApplicableFromDateUpdate}
      />
    ) : null

    const applicableUntilDateInput = hasApplicableUntilDate ? (
      <DateInput
        label={translate(
          'forms.labels.grading.applicableUntilDate',
          translations
        )}
        name={applicableUntilDateName}
        value={applicableUntilDate || ''}
        onUpdate={this.onApplicableUntilDateUpdate}
        ref={this.divApplicableUntilDate}
      />
    ) : null

    const className = classnames({
      'grade-input-wrapper-orca': true,
      'grade-input-wrapper-orca--by-activity': label && activityId,
      'grade-input-wrapper-orca--incomplete': incomplete,
      'grade-input-wrapper-orca--incompleteOutline':
        incomplete || incompleteMode,
    })

    return (
      <div>
        {label && activityId ? (
          <div className="grade-input-group--connector-line"></div>
        ) : null}
        <div className={className}>
          <div className="grade-input-wrapper-orca__header">
            <label className="label-tag--orca">{label}</label>

            {this.props.errorsByGradings ? (
              <>
                <i className="ml10 mr5 icon icon--alert-error"></i>
                <span>{this.props.errorsByGradings}</span>
              </>
            ) : null}

            {this.hasObservations() ? (
              <IconAction
                color={
                  this.containsObservations() && !incomplete ? 'green' : null
                }
                disabled={incomplete}
                icon="plus-circle"
                inline={true}
                onClick={this.showObservations}
              >
                {translations.activerecord.attributes.grading.observation}
              </IconAction>
            ) : null}
          </div>

          <div
            className={
              hideFirstAttempt && firstAttempt
                ? 'hidden'
                : 'first_attempt_grade_input'
            }
          >
            <GradeInput
              grade={incomplete ? null : repeated ? firstAttempt : grade}
              repeated={incomplete ? false : repeated}
              deferred={deferred}
              deferable={deferable}
              gradingScale={gradingScale}
              gradeName={gradeName}
              onGradeUpdate={this.onGradeUpdate}
              onRepeatedUpdate={this.onRepeatedUpdate}
              onDeferredUpdate={this.onDeferredUpdate}
              repeatedName={repeatedName}
              deferredName={deferredName}
              counterName={counterName}
              counter={counter}
              onCounterUpdate={this.onCounterUpdate}
              refApplicableUntilDate={this.divApplicableUntilDate}
              ref={this.refgradeInput}
              orca={true}
              gradingId={id}
              disabled={incomplete || repeated || false}
              hideRepeated={hideFirstAttempt}
            />
          </div>

          {!incomplete && repeated ? (
            <div className="last_attempt_grade_input">
              {hideFirstAttempt ? null : (
                <label className="label-tag--orca">
                  {translations.ubf.lastAttempt}
                </label>
              )}
              <GradeInput
                grade={grade}
                deferable={deferable}
                gradingScale={gradingScale}
                gradeName={gradeName + 'last_attempt'}
                onGradeUpdate={this.onLastAttemptUpdate}
                repeatedName={repeatedName}
                deferredName={deferredName}
                counterName={counterName}
                onCounterUpdate={() => {}}
                orca={true}
                gradingId={id}
                hideRepeated={true}
              />
            </div>
          ) : null}

          {this.state.expandObservations && !incomplete ? (
            <div>
              {applicableFromDateInput}
              {applicableUntilDateInput}
              {files.map((file, index) => (
                <GradingFileAttachment
                  file={file}
                  key={`file${index}`}
                  onRemove={this.handleDocumentRemove}
                />
              ))}
              {allowUploads && (
                <div className="grade-input-wrapper-orca--upload-button-wrapper">
                  <DirectUploadButton
                    directUploadsUrl={directUploadsUrl}
                    disabled={!online}
                    onUpload={this.handleFileUpload}
                  >
                    {translations.ubf.uploadAFile}
                  </DirectUploadButton>
                </div>
              )}
              {this.state.expandObservations && observationSelect}
              {additionalInfoInputs}
            </div>
          ) : null}

          {UBF.gradableActivitySubqualifications && !incomplete && !label ? (
            <div
              className="icon icon--angle-down grading_arrow_button right pointer grade-input-wrapper-orca--icon-qualification-down"
              onClick={onToggleCurrentGradings}
            />
          ) : null}

          {incomplete || incompleteMode ? (
            <div className="grade-input-wrapper-orca--incompleteIndicator">
              {incompleteMode ? (
                <button onClick={this.handleToggleIncomplete} type="button">
                  {translations.ubf.gradingSessionStatuses.incomplete}
                </button>
              ) : (
                <span>
                  {translations.ubf.gradingSessionStatuses.incomplete}
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
