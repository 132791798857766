// Copyright Northcote Technology Ltd
import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import QuantumLayout from './QuantumLayout'
import translate from 'src/lib/translate'
import Data from './compliance/Data'
import Tree from './compliance/Tree'
import Regulations from './compliance/Regulations'

export default class ComplianceModule extends React.Component {
  constructor(props) {
    super(props)
  }

  factory = node => {
    const { translations, options, tree, editable } = this.props

    var component = node.getComponent()
    if (component === 'Tree') {
      return <Tree tree={tree} translations={translations} />
    } else if (component === 'Data') {
      return (
        <Data
          options={options}
          translations={translations}
          editable={editable}
        />
      )
    } else if (component === 'Regulations') {
      return <Regulations translations={translations} />
    } else {
      return <div>{node.getName()}</div>
    }
  }

  render() {
    const { backUrl, translations } = this.props

    let leftPaneComponents = [
      {
        id: 'tree',
        type: 'tab',
        name: translate(
          'layout.tabs.compliance.items',
          this.props.translations
        ),
        component: 'Tree',
      },
    ]

    let rightPaneComponents = [
      {
        id: 'regulations',
        type: 'tab',
        name: translate(
          'layout.tabs.compliance.regulations',
          this.props.translations
        ),
        component: 'Regulations',
      },
      {
        id: 'data',
        type: 'tab',
        name: translate('layout.tabs.compliance.data', this.props.translations),
        component: 'Data',
      },
    ]

    return (
      <Provider store={store}>
        <QuantumLayout
          backAction={() => (window.location = backUrl)}
          title={translate('compliance', translations)}
          leftPaneComponents={leftPaneComponents}
          rightPaneComponents={rightPaneComponents}
          customFactory={this.factory}
        />
      </Provider>
    )
  }
}
