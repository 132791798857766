// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import nameToId from 'src/lib/nameToId'
import DynamicSelect from './DynamicSelect'

export default class OverallGradeOptionSelector extends Component {
  static propTypes = {
    currentTemplateId: PropTypes.number,
    currentOptionIds: PropTypes.array,
    templates: PropTypes.array.isRequired,
    templateName: PropTypes.string.isRequired,
    templateNoResultsText: PropTypes.string.isRequired,
    templatePlaceholderText: PropTypes.string.isRequired,
    templateLabel: PropTypes.string.isRequired,
    optionsName: PropTypes.string.isRequired,
    optionsNoResultsText: PropTypes.string.isRequired,
    optionsPlaceholderText: PropTypes.string.isRequired,
    optionsLabel: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    const selectedTemplate = props.templates.find(
      t => t.value == props.currentTemplateId
    )
    const selectedOptionIds = props.currentOptionIds || []

    this.state = {
      selectedTemplate,
      selectedOptionIds,
    }
  }

  handleSelectTemplate = event => {
    this.setState({
      selectedTemplate: this.props.templates.find(
        t => t.value == event.target.value
      ),
    })
  }

  handleSelectOptions = event => {
    this.setState({
      selectedOptionIds: event.target.value,
    })
  }

  render() {
    const {
      templates,
      templateLabel,
      templateName,
      templatePlaceholderText,
      templateNoResultsText,
      optionsLabel,
      optionsName,
      optionsPlaceholderText,
      optionsNoResultsText,
    } = this.props

    const { selectedTemplate, selectedOptionIds } = this.state

    return (
      <>
        <label htmlFor={nameToId(templateName)}>{templateLabel}</label>
        <DynamicSelect
          clearable={true}
          name={templateName}
          currentValue={selectedTemplate?.value}
          noResultsText={templateNoResultsText}
          placeholderText={templatePlaceholderText}
          values={templates}
          onSelect={this.handleSelectTemplate}
        />
        {selectedTemplate?.value && (
          <>
            <label htmlFor={nameToId(optionsName)}>{optionsLabel}</label>
            <DynamicSelect
              clearable={true}
              multi={true}
              name={optionsName}
              currentValue={selectedOptionIds}
              noResultsText={optionsNoResultsText}
              placeholderText={optionsPlaceholderText}
              values={selectedTemplate.gradingOptions}
              onSelect={this.handleSelectOptions}
            />
          </>
        )}
      </>
    )
  }
}
