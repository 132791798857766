// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ROW_QUALIFICATION_ROW_HEIGHT } from './RoleQualificationRow'
import roleProps from 'proptypes/roleProps'

import AwardRowHeading from './AwardRowHeading'
import NestedHeadingBox from './NestedHeadingBox'

class RoleQualificationRowHeading extends Component {
  static propTypes = {
    isFirstRow: PropTypes.bool.isRequired,
    role: PropTypes.shape(roleProps).isRequired,
  }

  render() {
    const { isFirstRow, role } = this.props

    const { name, requiredQualifications } = role

    const level = 0
    const requirementCount = requiredQualifications.length
    const awardRowHeadings = requiredQualifications.map(
      (qualification, index) => {
        const isLastInLevel = index === requirementCount - 1
        return (
          <AwardRowHeading
            isFirstRow={index === 0}
            key={qualification.id}
            level={level + 1}
            lastInLevelRecords={[true, isLastInLevel]}
            qualification={qualification}
          />
        )
      }
    )

    return (
      <div className="role-qualification-row-heading">
        <div
          className="role-qualification-row-heading__content"
          style={{
            height: ROW_QUALIFICATION_ROW_HEIGHT,
          }}
        >
          <NestedHeadingBox
            hasLowerLevels={requirementCount > 0}
            isFirstInLevel={isFirstRow}
            isTopLevel={false}
            lastInLevelRecords={[true]}
            level={level}
          >
            {name}
          </NestedHeadingBox>
        </div>

        {awardRowHeadings}
      </div>
    )
  }
}

export default RoleQualificationRowHeading
