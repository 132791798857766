// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import nameToId from 'src/lib/nameToId'
import AsyncPaginate from 'react-select-async-paginate'

import { selectStyles, selectStylesV2, theme } from '../styles/ReactSelect'

const PAGE_SIZE = 100

export default class DynamicSelectAsync extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(change) {
    const { name } = this.props
    const value = change
      ? this.props.multi
        ? change.map(c => c.value)
        : change.value
      : null
    const payload = { target: { name, value } }
    this.props.onSelect(payload)
  }

  loadOptions = async (search, prevOptions) => {
    const { values } = this.props
    let filteredOptions
    if (!search) {
      filteredOptions = values
    } else {
      const searchLower = search.toLowerCase()

      filteredOptions = values.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      )
    }

    const hasMore = filteredOptions.length > prevOptions.length + PAGE_SIZE
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + PAGE_SIZE
    )

    return {
      options: slicedOptions,
      hasMore,
    }
  }

  render() {
    const {
      clearable,
      currentValue,
      multi,
      name,
      noResultsText,
      placeholderText,
      prefixClass,
      styleVersion,
      values,
    } = this.props

    const valuesArray = multi ? currentValue : [currentValue]
    const selectedOptions = values.filter(option =>
      valuesArray.includes(option.value)
    )
    const styles = styleVersion === 2 ? selectStylesV2 : selectStyles

    return (
      <div className="dynamic-select" data-label={this.props.placeholderText}>
        <AsyncPaginate
          loadOptions={this.loadOptions}
          className={prefixClass || 'Select'}
          classNamePrefix={prefixClass || 'Select'}
          isClearable={clearable}
          id={nameToId(name)}
          isMulti={multi}
          name={name}
          noOptionsMessage={() => noResultsText}
          onChange={this.onChange}
          placeholder={placeholderText}
          styles={styles}
          theme={theme}
          value={selectedOptions}
        />
      </div>
    )
  }
}

DynamicSelectAsync.defaultProps = {
  multi: false,
}

DynamicSelectAsync.propTypes = {
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  multi: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  prefixClass: PropTypes.string,
  styleVersion: PropTypes.number,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}
