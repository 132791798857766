// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import HeatmapRow from './HeatmapRow'

export default class HeatmapTable extends Component {
  static propTypes = {
    heatmapId: PropTypes.number.isRequired,
    header: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { header, rows, translations, heatmapId } = this.props

    const headerHTML = []
    header.forEach((cell, index) => {
      headerHTML.push(<th key={index}>{cell.content}</th>)
    })

    const rowsHTML = []
    rows.forEach((row, index) => {
      rowsHTML.push(
        <HeatmapRow
          heatmapId={heatmapId}
          key={index}
          header={header}
          row={row}
          translations={translations}
        />
      )
    })

    return (
      <Fragment>
        <hr />
        <div className="heatmap-container">
          <table className="heatmap">
            <thead className="heatmap__thead inner-container">
              <tr className="heatmap__row--header header_text">{headerHTML}</tr>
            </thead>
            <tbody>{rowsHTML}</tbody>
          </table>
        </div>
      </Fragment>
    )
  }
}
