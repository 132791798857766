// Copyright Northcote Technology Ltd
import {
  DELETE_SESSION_SUCCESS,
  FETCH_SESSION,
  FETCH_SESSION_SUCCESS,
  SAVE_SESSION,
  SAVE_SESSION_SUCCESS,
} from '../../actionTypes'

const initialState = {}

const removeKey = (state, key) => {
  const newState = { ...state }
  delete newState[key]
  return newState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION:
    case SAVE_SESSION:
      return {
        ...state,
        [action.id]: true,
      }
    case DELETE_SESSION_SUCCESS: {
      return removeKey(action.id)
    }
    case FETCH_SESSION_SUCCESS:
    case SAVE_SESSION_SUCCESS: {
      return removeKey(action.payload.id)
    }
    default:
      return state
  }
}
