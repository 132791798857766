// Copyright Northcote Technology Ltd
import React, { useContext } from 'react'
import classnames from 'classnames'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { isEmpty } from 'lodash'

import TranslationsContext from '../contexts/Translations'
import simpleFormat from '../../src/lib/simpleFormat'

import Category from './Category'
import IconButton from '../IconButton'

export default function Event({
  activitiesData,
  draggable,
  editable,
  event,
  onDelete,
  onEdit,
}) {
  const translations = useContext(TranslationsContext)
  const {
    activityId,
    categoryWeather,
    categoryAc,
    categoryNav,
    categoryTraining,
    cid,
    description,
    estimatedTime,
  } = event
  const activity = activitiesData[activityId]
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ disabled: !draggable, id: cid })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: draggable && isDragging ? 1 : null,
  }

  const handleDelete = () => {
    if (confirm(translations.ubf.are_you_sure)) {
      onDelete(cid)
    }
  }
  const handleEdit = () => onEdit(cid)

  const className = classnames({
    timeline__event: true,
    'timeline__event-noActivity': !activity,
  })

  return (
    <article className={className} ref={setNodeRef} style={style}>
      <header className="no-grid">
        {activity ? <h1>{activity.name}</h1> : null}

        {editable ? (
          <div className="timeline__event-actions">
            {draggable ? (
              <IconButton
                aria-label={translations.ubf.move}
                cursor="move"
                icon="bars"
                transparent
                {...attributes}
                {...listeners}
              />
            ) : null}
            <IconButton
              aria-label={translations.ubf.delete}
              icon="trash"
              onClick={handleDelete}
              transparent
            />
            <IconButton
              aria-label={translations.ubf.edit}
              icon="pencil"
              onClick={handleEdit}
              transparent
            />
          </div>
        ) : null}
      </header>

      <div className="timeline__event-meta">
        {isEmpty(description) ? null : (
          <div className="timeline__event-description">
            {simpleFormat(description)}
          </div>
        )}
        <Category type="weather">{categoryWeather}</Category>
        <Category type="ac">{categoryAc}</Category>
        <Category type="nav">{categoryNav}</Category>
        <Category type="training">{categoryTraining}</Category>
        <Category type="estimated-time">{estimatedTime}</Category>
      </div>
    </article>
  )
}
