// Copyright Northcote Technology Ltd
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import nameToId from 'src/lib/nameToId'

export default class AwardNotificationOptions extends PureComponent {
  static propTypes = {
    metadataGivenLabel: PropTypes.string.isRequired,
    metadataGivenName: PropTypes.string.isRequired,
    metadataGivenValue: PropTypes.bool.isRequired,
    metadataOnlyChangesLabel: PropTypes.string.isRequired,
    metadataOnlyChangesName: PropTypes.string.isRequired,
    metadataOnlyChangesValue: PropTypes.bool.isRequired,
    nameClass: PropTypes.string.isRequired,
    onlyChangesOptions: PropTypes.array.isRequired,
    givenRemovedOptions: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      only_changes_checked: this.props.metadataOnlyChangesValue,
      given_removed_checked: this.props.metadataGivenValue,
    }
  }

  handleGivenRemovedChange = event => {
    this.setState({
      only_changes_checked: this.state.only_changes_checked,
      given_removed_checked: event.target.checked,
    })
  }

  handleOnlyChangesChange = event => {
    this.setState({
      only_changes_checked: event.target.checked,
      given_removed_checked: this.state.given_removed_checked,
    })
  }

  render() {
    const {
      metadataGivenLabel,
      metadataGivenName,
      metadataGivenValue,
      metadataOnlyChangesLabel,
      metadataOnlyChangesName,
      metadataOnlyChangesValue,
      nameClass,
      onlyChangesOptions,
      givenRemovedOptions,
    } = this.props

    return (
      <div>
        <div>
          <input
            type="hidden"
            id={metadataGivenName}
            name={metadataGivenName}
            value="0"
          />
          <input
            type="checkbox"
            id={nameToId(metadataGivenName)}
            name={metadataGivenName}
            className="hide"
            value="1"
            defaultChecked={metadataGivenValue}
            onChange={this.handleGivenRemovedChange}
            ref={this.refGiven}
          />
          <label className={nameClass} htmlFor={nameToId(metadataGivenName)}>
            {metadataGivenLabel}
          </label>

          <div className="ml20">
            {this.state.given_removed_checked
              ? givenRemovedOptions.map(buttonInfo => (
                  <Fragment key={buttonInfo.name}>
                    <input
                      type="hidden"
                      id={buttonInfo.name}
                      name={buttonInfo.name}
                      value="0"
                    />
                    <input
                      type="checkbox"
                      id={nameToId(buttonInfo.name)}
                      name={buttonInfo.name}
                      className="hide"
                      defaultChecked={buttonInfo.value}
                      value="1"
                    />
                    <label
                      className={nameClass}
                      htmlFor={nameToId(buttonInfo.name)}
                    >
                      {buttonInfo.label}
                    </label>
                  </Fragment>
                ))
              : null}
          </div>
        </div>
        <div>
          <input
            type="hidden"
            id={metadataOnlyChangesName}
            name={metadataOnlyChangesName}
            value="0"
          />
          <input
            type="checkbox"
            id={nameToId(metadataOnlyChangesName)}
            name={metadataOnlyChangesName}
            className="hide"
            defaultChecked={metadataOnlyChangesValue}
            value="1"
            onChange={this.handleOnlyChangesChange}
            ref={this.refOnly}
          />
          <label
            className={nameClass}
            htmlFor={nameToId(metadataOnlyChangesName)}
          >
            {metadataOnlyChangesLabel}
          </label>

          <div className="ml20">
            {this.state.only_changes_checked
              ? onlyChangesOptions.map(buttonInfo => (
                  <Fragment key={buttonInfo.name}>
                    <input
                      type="hidden"
                      id={buttonInfo.name}
                      name={buttonInfo.name}
                      value="0"
                    />
                    <input
                      type="checkbox"
                      id={nameToId(buttonInfo.name)}
                      name={buttonInfo.name}
                      className="hide"
                      defaultChecked={buttonInfo.value}
                      value="1"
                    />
                    <label
                      className={nameClass}
                      htmlFor={nameToId(buttonInfo.name)}
                    >
                      {buttonInfo.label}
                    </label>
                  </Fragment>
                ))
              : null}
          </div>
        </div>
      </div>
    )
  }
}
