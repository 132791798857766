// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/lib/Creatable'

import { selectStyles, theme, selectStylesV2 } from 'styles/ReactSelect'

import nameToId from 'src/lib/nameToId'

const createOption = label => ({
  label,
  value: label,
})

export default class CreatableListInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      value: props.currentValues
        .filter(value => {
          return value !== ''
        })
        .map(v => createOption(v)),
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.currentValues.map(v => createOption(v)) })
  }

  onChange = value => {
    const notOptionValue = [...value].map(o => o.value)

    this.setState({ value })
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: this.props.retrieveOption ? notOptionValue : value,
          type: 'selector',
          name: this.props.name,
        },
      })
    }
  }

  onInputChange = inputValue => {
    this.setState({ inputValue })
  }

  onKeyDown = event => {
    switch (event.key) {
      case ',':
      case ';':
      case ' ':
      case 'Enter':
      case 'Tab': {
        this._updateOptionsFromInputValue()
        event.preventDefault()
      }
    }
  }

  onBlur = () => {
    this._updateOptionsFromInputValue()
  }

  _updateOptionsFromInputValue = () => {
    const { inputValue, value } = this.state

    const { retrieveOption } = this.props

    if (!inputValue) return
    const notOptionValue = [...value].map(o => o.value)

    this.setState({
      inputValue: '',
      value: retrieveOption
        ? [...notOptionValue, inputValue]
        : [...value, createOption(inputValue)],
    })
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: retrieveOption
            ? [...notOptionValue, inputValue]
            : [...value, createOption(inputValue)],
          type: 'selector',
          name: this.props.name,
        },
      })
    }
  }

  render() {
    const { name, placeholderText, styleVersion } = this.props

    const { inputValue, value } = this.state

    return (
      <div className="create-list-input" data-label={placeholderText}>
        <CreatableSelect
          className="Select"
          classNamePrefix="Select"
          components={{ DropdownIndicator: null }}
          id={nameToId(name)}
          inputValue={inputValue}
          isClearable={true}
          isMulti={true}
          menuIsOpen={false}
          name={name}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onInputChange={this.onInputChange}
          onKeyDown={this.onKeyDown}
          placeholder={placeholderText}
          styles={styleVersion == 2 ? selectStylesV2 : selectStyles}
          theme={theme}
          value={value}
        />
      </div>
    )
  }
}

CreatableListInput.propTypes = {
  currentValues: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  styleVersion: PropTypes.number,
  retrieveOption: PropTypes.bool,
}
