// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'

import Button from './Button'

export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    buttonSave: PropTypes.bool,
    onSaveModal: PropTypes.func,
    children: PropTypes.node,
    onCloseModal: PropTypes.func,
    translations: PropTypes.object.isRequired,
    saveButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    hideButtonCancel: PropTypes.bool,
    zIndex: PropTypes.number,
    center: PropTypes.bool,
    extraClasses: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  saveModal = () => {
    const { onSaveModal } = this.props

    onSaveModal()
  }

  closeModal = () => {
    const { onCloseModal } = this.props

    if (onCloseModal) {
      onCloseModal()
    }
  }

  render() {
    const { translations, zIndex, center, extraClasses } = this.props
    const showHideClassName = this.props.visible
      ? 'modal modal-block ' +
        (extraClasses || '') +
        (zIndex ? ' modal--' + zIndex : '') +
        (center ? ' modal-center' : '')
      : 'modal modal-none'
    const saveText = this.props.saveButtonText
      ? this.props.saveButtonText
      : translations.ubf.save
    const cancelText = this.props.cancelButtonText
      ? this.props.cancelButtonText
      : translations.ubf.cancel

    return (
      <div className={showHideClassName}>
        <div className="modal-main">
          {this.props.onCloseModal ? (
            <div className="modal-column modal-close">
              <a title="Close" onClick={this.closeModal}>
                <CancelIcon />
              </a>
              <span className="modal-normal-text">Close</span>
            </div>
          ) : null}
          <div className="modal-header modal-title">
            <h3>{this.props.title}</h3>
          </div>

          <div className="modal-content">{this.props.children}</div>
          <div className="modal-footer">
            {!this.props.hideButtonCancel ? (
              <Button onClick={this.closeModal}>{cancelText}</Button>
            ) : null}
            {this.props.buttonSave ? (
              <Button onClick={this.saveModal}>{saveText}</Button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
