// Copyright Northcote Technology Ltd
import {
  RESOLVED_GET_HEATMAP_REPORTS_ITEM,
  UPDATE_HEATMAP_REPORTS_ITEM,
  UPDATE_HEATMAP_REPORTS_TABLE,
} from '../actionTypes'

const heatmapReport = (state = { info: null, data: null }, action) => {
  switch (action.type) {
    case RESOLVED_GET_HEATMAP_REPORTS_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case UPDATE_HEATMAP_REPORTS_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case UPDATE_HEATMAP_REPORTS_TABLE: {
      return Object.assign({}, state, {
        data: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default heatmapReport
