// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const isBlank = value => !value && value !== 0

export default class DurationInput extends PureComponent {
  static propTypes = {
    days: PropTypes.number,
    daysLabel: PropTypes.string.isRequired,
    months: PropTypes.number,
    monthsLabel: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    years: PropTypes.number,
    yearsLabel: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      days: props.days,
      months: props.months,
      years: props.years,
    }
  }

  allValuesBlank = () => {
    const { days, months, years } = this.state

    return isBlank(days) && isBlank(months) && isBlank(years)
  }

  handleDaysChange = event => {
    this.setState({ days: parseInt(event.target.value, 10) })
  }

  handleMonthsChange = event => {
    this.setState({ months: parseInt(event.target.value, 10) })
  }

  handleYearsChange = event => {
    this.setState({ years: parseInt(event.target.value, 10) })
  }

  render() {
    const { inputName, daysLabel, monthsLabel, yearsLabel } = this.props

    const { days, months, years } = this.state

    const allBlank = this.allValuesBlank()
    const isoDuration = allBlank
      ? ''
      : `P${days || 0}D${months || 0}M${years || 0}Y`

    return (
      <div className="duration-input">
        <div className="duration-input__fields">
          <label
            className="duration-input__label"
            htmlFor={`${inputName}_years`}
          >
            {yearsLabel}
          </label>

          <input
            className="duration-input__input"
            id={`${inputName}_years`}
            type="number"
            min="0"
            ref={this.yearsInput}
            placeholder={allBlank ? '∞' : '-'}
            value={isBlank(years) ? '' : years}
            onChange={this.handleYearsChange}
          />

          <label
            className="duration-input__label"
            htmlFor={`${inputName}_months`}
          >
            {monthsLabel}
          </label>

          <input
            className="duration-input__input"
            id={`${inputName}_months`}
            type="number"
            min="0"
            ref={this.monthsInput}
            placeholder={allBlank ? '∞' : '-'}
            value={isBlank(months) ? '' : months}
            onChange={this.handleMonthsChange}
          />

          <label
            className="duration-input__label"
            htmlFor={`${inputName}_days`}
          >
            {daysLabel}
          </label>

          <input
            className="duration-input__input"
            id={`${inputName}_days`}
            type="number"
            min="0"
            ref={this.daysInput}
            placeholder={allBlank ? '∞' : '-'}
            value={isBlank(days) ? '' : days}
            onChange={this.handleDaysChange}
          />
        </div>

        <input
          type="hidden"
          name={inputName}
          readOnly={true}
          value={isoDuration || ''}
        />
      </div>
    )
  }
}
