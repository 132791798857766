// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  reset() {
    const inputs = Array.from(this.element.querySelectorAll('input'))

    // Clear all <input>s within this controller (<input>s without a name aren't
    // included when submitting a form).
    inputs.filter(input => input.name).forEach(input => (input.value = ''))

    // Submit the <form>.
    if (inputs.length > 0) inputs[0].form.requestSubmit()
  }

  toggle() {
    this.element.classList.toggle('filters-open')
  }
}
