// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'controls']

  connect() {
    if (!this.hasControlsTarget) return

    const tab = this.initialTab()

    // No tabs?
    if (!tab) return

    tab.click()
  }

  initialTab() {
    const tabs = this.tabs()
    const defaultTab = tabs[0]
    const firstError = this.element.querySelector('.error')

    // No errors, return the first tab.
    if (!firstError) return defaultTab

    const tabContent = firstError.closest('[data-tab]')

    // Not in a tab?
    if (!tabContent) return defaultTab

    const tabId = tabContent.getAttribute('data-tab')
    const tabWithError = tabs.find(
      tab => tab.getAttribute('data-tab') === tabId
    )

    // Can't find connected tab.
    if (!tabWithError) return defaultTab

    return tabWithError
  }

  showTab(event) {
    const { target } = event
    const clickedTab = target.getAttribute('data-tab')

    this.tabs().forEach(element =>
      element.classList.toggle('tabs--active', element === target)
    )

    this.contentTargets.forEach(element => {
      element.classList.toggle(
        'tabs--hidden',
        element.getAttribute('data-tab') !== clickedTab
      )
    })
  }

  tabs() {
    return Array.from(this.controlsTarget.querySelectorAll('[data-tab]'))
  }
}
