// Copyright Northcote Technology Ltd
export default function mergeCustomFieldChanges(existing, additional) {
  const index = existing.findIndex(
    ({ customFieldId }) => customFieldId === additional.customFieldId
  )

  if (index === -1) {
    return existing.concat(additional)
  } else {
    const newList = [...existing]
    newList[index] = {
      ...newList[index],
      value: additional.value,
    }
    return newList
  }
}
