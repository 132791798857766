// Copyright Northcote Technology Ltd
export function isOverallGradingActive() {
  try {
    JSON.parse(overallPositiveResultFailConditions())
  } catch (e) {
    return false
  }
  return true
}

export function overallPositiveResultFailConditions() {
  return UBF.overallPositiveResultFailCondition.replace(/&quot;/g, '"')
}
