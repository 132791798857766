// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getIdb } from '../src/lib/idb/common'

export default class GradeSessionResultButton extends Component {
  constructor(props) {
    super(props)
    this.state = { validate: '' }

    const context = this
    getIdb('unsubmitted_sessions', props.sessionId).then(function (result) {
      context.setState({ validate: result ? true : false })
    })
  }

  addValidation = () => {
    return this.state.validate ? '?validate=true' : ''
  }

  render() {
    const { url, text } = this.props

    return (
      <Fragment>
        <div className="unsubmitted-session-results__buttons">
          <a className="btn edit-btn" href={url + this.addValidation()}>
            {text}
          </a>
        </div>
      </Fragment>
    )
  }
}

GradeSessionResultButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
}
