// Copyright Northcote Technology Ltd
export default function translate(path, translations) {
  try {
    const result = path.split('.').reduce((lookup, key) => {
      return lookup[key]
    }, translations)

    if (typeof result !== 'string') {
      throw 'Path not specific enough'
    }

    return result
  } catch (e) {
    return undefined
  }
}
