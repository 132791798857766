// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Period from './Period'
import periodProps from 'proptypes/periodProps'

export const PERIOD_TIMELINE_HEIGHT = 14
export const PERIOD_TIMELINE_PADDING = 0

class PeriodTimeline extends Component {
  static propTypes = {
    qualifiedPeriods: PropTypes.arrayOf(PropTypes.shape(periodProps))
      .isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { qualifiedPeriods, scale, t } = this.props

    const periods = qualifiedPeriods.map((period, index) => {
      return (
        <Period
          finish={period.finish}
          key={index}
          scale={scale}
          start={period.start}
          t={t}
        />
      )
    })

    return (
      <div
        className="period-timeline"
        style={{
          height: PERIOD_TIMELINE_HEIGHT,
          paddingBottom: PERIOD_TIMELINE_PADDING,
          paddingTop: PERIOD_TIMELINE_PADDING,
          width: scale.range()[1],
        }}
      >
        {periods}
      </div>
    )
  }
}

export default PeriodTimeline
