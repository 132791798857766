// Copyright Northcote Technology Ltd
import React, { useEffect } from 'react'
import { Provider, useSelector } from 'react-redux'
import store from '../redux/store'
import FlexLayout from 'flexlayout-react'
import Header from './common/Header'
import DashboardGradingSessions from './dashboard/DashboardGradingSessions'
import Modal from './Modal'
import OnlineWatcher from './OnlineWatcher'
import TermsAcceptance from './common/TermsAcceptance'
import { sha256 } from 'js-sha256'
import { update_request } from '../src/lib/requestToServer'
import OfflinePin from './common/OfflinePin'
import MainMenuButtons from './MainMenuButtons'
import { onlineSelector } from '../redux/selectors'
import registerServiceWorker from '../src/registerServiceWorker'

class DashboardModule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pinMessage: null,
      askedPin: false,
    }
    this.pinInput = React.createRef()
    this.pinInputRepeat = React.createRef()

    var json = {
      global: {
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetHeaderHeight: 50,
        tabSetTabStripHeight: 50,
        tabEnableRename: false,
        splitterSize: 0,
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            children: [
              {
                type: 'tabset',
                enableMaximize: false,
                enableTabStrip: false,
                selected: 0,
                height: 100,
                children: [
                  {
                    type: 'tab',
                    name: 'topbar',
                    component: 'Header',
                  },
                ],
              },
              {
                type: 'tabset',
                enableMaximize: false,
                enableTabStrip: false,
                selected: 0,
                height: 280,
                children: [
                  {
                    id: 'buttons',
                    type: 'tab',
                    name: 'generalButtons',
                    component: 'Buttons',
                  },
                ],
              },
              {
                type: 'tabset',
                enableMaximize: false,
                enableTabStrip: false,
                selected: 0,
                children: [
                  {
                    id: 'table',
                    type: 'tab',
                    name: 'gradingSessionTable',
                    component: 'Table',
                  },
                ],
              },
            ],
          },
        ],
      },
    }

    this.state = { model: FlexLayout.Model.fromJson(json) }
  }

  backAction = () => {
    window.location.href = this.props.backUrl
  }

  factory(node) {
    const {
      translations,
      userTypeOptions,
      userTypeDropdownOptions,
      navItems,
      currentUser,
      currentUserType,
      enableWhenOffline,
      canGradeAllocatedUnsubmittedGradingSessions,
      online,
    } = this.props

    var component = node.getComponent()

    if (component === 'Header') {
      return (
        <Header
          backAction={this.backAction}
          titleLogo={true}
          title={translations.ubf.hello + ' ' + currentUser.name}
          subtitle={currentUserType}
          greeting={UBF.lang.greeting}
          userName={UBF.user.name}
          userType={UBF.user.type}
          menuElements={UBF.menu}
          adminMenuElements={UBF.adminMenu}
          disableHome={true}
          disableBack={true}
          disableGreeting={false}
        />
      )
    } else if (
      component === 'Table' &&
      canGradeAllocatedUnsubmittedGradingSessions
    ) {
      return (
        <div className="dashboard__sessions-container">
          <DashboardGradingSessions
            translations={translations}
            userTypeOptions={userTypeOptions}
            userTypeDropdownOptions={userTypeDropdownOptions}
          />
        </div>
      )
    } else if (component === 'Buttons') {
      return (
        <div className="dashboard__buttons-container">
          <MainMenuButtons
            enableWhenOffline={enableWhenOffline}
            buttonList={navItems}
            translation={translations.ubf}
            online={online}
          />
        </div>
      )
    } else {
      return null
    }
  }

  checkPin = () => {
    const { translations } = this.props
    const inputPin = this.pinInput.current.value
    const inputPinRepeat = this.pinInputRepeat.current.value
    if (!inputPin.match(/^(\d){4,}$/)) {
      this.setState({ pinMessage: translations.messages.pin.format })
      return
    }
    if (inputPin == inputPinRepeat) {
      update_request('/my_settings/', { offline_pin: sha256(inputPin) }, 1)
        .then(response => {
          if (response) {
            this.setState({ pinMessage: null, askedPin: true })
            alert(translations.messages.pin.correct_insertion)
            location.reload()
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      this.setState({ pinMessage: translations.messages.pin.wrong_repeat })
    }
  }

  sureClosePin = () => {
    const { translations } = this.props
    var res = confirm(translations.messages.pin.sure_close)
    if (res == true) {
      this.setState({ pinMessage: null, askedPin: true })
    }
  }

  cleanMessage = () => {
    this.setState({ pinMessage: null })
  }

  classMapper = className => {
    if (className == 'flexlayout__tab') {
      return 'flexlayout__tab_dashboard'
    }
    return className
  }

  render() {
    const {
      pinEnabled,
      hasOfflinePin,
      translations,
      termsAccepted,
      online,
      privacyLink,
      privacyLinkText,
      userPin,
      userEmailEncrypted,
    } = this.props

    return (
      <>
        <OnlineWatcher />
        <TermsAcceptance
          translations={translations}
          termsAccepted={termsAccepted}
          privacyLink={privacyLink}
          privacyLinkText={privacyLinkText}
        />
        <OfflinePin
          translations={translations}
          userPin={userPin}
          userEmailEncrypted={userEmailEncrypted}
          pinEnabled={pinEnabled}
          online={online}
        />
        <Modal
          title={translations.messages.pin.insert_title}
          visible={!hasOfflinePin && !this.state.askedPin && pinEnabled}
          buttonSave={true}
          onSaveModal={this.checkPin}
          onCloseModal={this.sureClosePin}
          translations={translations}
          cancelButtonText={translations.ubf.later}
        >
          <div className="offline-pin__error-message">
            {this.state.pinMessage ? this.state.pinMessage : null}
          </div>
          <input
            type="password"
            placeholder="PIN"
            onChange={this.cleanMessage}
            ref={this.pinInput}
            name="pin"
          />
          <input
            type="password"
            placeholder={translations.messages.pin.repeat_placeholder}
            ref={this.pinInputRepeat}
            onChange={this.cleanMessage}
            name="pinRepeat"
          />
        </Modal>
        <FlexLayout.Layout
          model={this.state.model}
          classNameMapper={this.classMapper}
          factory={this.factory.bind(this)}
        />
      </>
    )
  }
}

function DashboardModuleOnlineWrapper(props) {
  const online = useSelector(onlineSelector)

  return <DashboardModule {...props} online={online} />
}

function DashboardModuleProvider(props) {
  useEffect(() => registerServiceWorker(), [])

  return (
    <Provider store={store}>
      <DashboardModuleOnlineWrapper {...props} />
    </Provider>
  )
}

export default DashboardModuleProvider
