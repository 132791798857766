// Copyright Northcote Technology Ltd
import Rails from '@rails/ujs'
import ReactRailsUJS from 'react_ujs'
import '@hotwired/turbo'
import 'form-request-submit-polyfill'

import '../src/init/sentry'
import '../controllers' // Stimulus controllers.

// rails-ujs.
Rails.start()

// React-Rails integration and compatibilty with standard Turbo page loads.
ReactRailsUJS.useContext(require.context('components', true))
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
