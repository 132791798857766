// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class GradingCounter extends PureComponent {
  static propTypes = {
    counterName: PropTypes.string.isRequired,
    minCounter: PropTypes.number.isRequired,
    maxCounter: PropTypes.number.isRequired,
    defaultCounter: PropTypes.number.isRequired,
    currentCounter: PropTypes.number,
    onUpdate: PropTypes.func,
    orca: PropTypes.bool,
  }

  handleClick = behaviour => {
    const { minCounter, maxCounter, onUpdate, currentCounter, defaultCounter } =
      this.props
    var value = currentCounter ? currentCounter : defaultCounter

    if (behaviour == 'up') {
      if (value < maxCounter) {
        value++
      }
    } else {
      if (value > minCounter) {
        value--
      }
    }

    onUpdate(value)
  }

  classOrca = () => {
    const orca = this.props.orca ? '_orca' : ''
    return 'grading-counter' + orca
  }

  render() {
    const value = this.props.currentCounter
      ? this.props.currentCounter
      : this.props.defaultCounter

    return this.props.orca ? (
      <div className={this.classOrca()}>
        <input name={this.props.counterName} type={'hidden'} value={value} />

        <div className={this.classOrca() + '-container'}>
          <div
            className={this.classOrca() + '-action grading-counter-up'}
            onClick={() => {
              this.handleClick('up')
            }}
          >
            <span className="icon icon--black icon--plus"></span>
          </div>
          <div className={this.classOrca() + '-value'}>{value}</div>
          <div
            className={this.classOrca() + '-action grading-counter-down'}
            onClick={() => {
              this.handleClick('down')
            }}
          >
            <span className="icon icon--minus"></span>
          </div>
        </div>
      </div>
    ) : (
      <div className="grading-counter">
        <input name={this.props.counterName} type={'hidden'} value={value} />

        <table className="grading-counter-container">
          <tbody>
            <tr>
              <td className="grading-counter-value" rowSpan="2">
                {value}
              </td>
              <td
                className="grading-counter-action grading-counter-up"
                onClick={() => {
                  this.handleClick('up')
                }}
              >
                <span className="icon icon--chevron-up"></span>
              </td>
            </tr>
            <tr>
              <td
                className="grading-counter-action grading-counter-down"
                onClick={() => {
                  this.handleClick('down')
                }}
              >
                <span className="icon icon--chevron-down"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
