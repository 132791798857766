// Copyright Northcote Technology Ltd
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NotificationModal from '../common/NotificationModal'

import TranslationsContext from '../contexts/Translations'
import { loadSession } from '../../redux/app'
import {
  makeSessionSelector,
  makeSessionNetworkSelector,
} from '../../redux/selectors'

function Loading() {
  const translations = useContext(TranslationsContext)

  return (
    <NotificationModal
      notificationType="synchronising"
      translations={translations}
      visible={true}
    />
  )
}

export default function LoadSession(props) {
  const { children, sessionId } = props
  const dispatch = useDispatch()
  const session = useSelector(makeSessionSelector(sessionId))
  const network = useSelector(makeSessionNetworkSelector(sessionId))

  useEffect(() => {
    dispatch(loadSession(sessionId))
  }, [sessionId])

  if (!session || network) {
    return <Loading />
  }

  return children(session)
}
