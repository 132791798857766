// Copyright Northcote Technology Ltd
import React, { PureComponent, Fragment } from 'react'
import Logo from '../../../assets/images/ubf-white.png'

class Menu extends PureComponent {
  offlineDisabled = ['']

  constructor(props) {
    super(props)

    this.state = {
      adminMenu: false,
    }
  }

  checkAvailabilityOffline = path => {
    return this.offlineDisabled.includes(path)
  }

  linkTarget = path => {
    return path && this.props.online ? '_blank' : ''
  }

  linkPath = path => {
    if (this.props.online) {
      return path
    } else if (!this.offlineDisabled.includes(path)) {
      return path
    } else {
      return '#'
    }
  }

  linkClass = (translationKey, path) => {
    return (
      'quantum-menu__item quantum-menu__item--' +
      translationKey.split('.')[1].replace(/\W|_/g, '-') +
      (this.checkAvailabilityOffline(path) && !this.props.online
        ? ' menu__item--disabled'
        : '')
    )
  }

  subLinkClass = (translationKey, path) => {
    return (
      'quantum-menu__item-no-icon' +
      (this.checkAvailabilityOffline(path) && !this.props.online
        ? ' menu__item--disabled'
        : '')
    )
  }
  adminLinkClass = (translationKey, path) => {
    return (
      'quantum-menu__item-administer quantum-menu__item--' +
      (this.checkAvailabilityOffline(path) && !this.props.online
        ? ' menu__item--disabled'
        : '')
    )
  }

  menuContent = () => {
    const { menuElements, adminMenuElements } = this.props

    const { adminMenu } = this.state

    if (!adminMenu)
      return (
        <ul className="">
          {menuElements.map((item, index) => {
            if (!item.path.includes('admin'))
              return (
                <li key={index} className="">
                  <a
                    className={this.linkClass(item.translation_key, item.path)}
                    href={this.linkPath(item.path)}
                    target={this.linkTarget(item.new_tab)}
                  >
                    {item.title}
                  </a>
                </li>
              )
            else
              return (
                <li key={index} className="">
                  <a
                    className={this.linkClass(item.translation_key, item.path)}
                    href="#"
                    onClick={() => {
                      this.setState({ adminMenu: true })
                    }}
                    target={this.linkTarget(item.new_tab)}
                  >
                    {item.title}
                  </a>
                </li>
              )
          })}
        </ul>
      )
    else
      return (
        <ul className="">
          <li key={'administerList'} className="">
            <a
              className={this.linkClass(
                'ubf.administer',
                '/' + UBF.locale + '/admin'
              )}
              href="#"
              onClick={() => {
                this.setState({ adminMenu: false })
              }}
              target={this.linkTarget(false)}
            >
              {'Administer' /*TO BE REVIEWED*/}
            </a>
          </li>
          {adminMenuElements.map((item, index) => {
            return (
              <li key={index} className="">
                <a
                  className={this.subLinkClass(item.translation_key, item.path)}
                  href={this.linkPath(item.path)}
                  target={this.linkTarget(item.new_tab)}
                >
                  {item.title}
                </a>
              </li>
            )
          })}
        </ul>
      )
  }
  render() {
    return (
      <Fragment>
        {this.menuContent()}
        <div className="quantum-menu__footer">
          <div className="quantum-menu__separator" />
          <div className="quantum-menu__logo">
            <img src={Logo} />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Menu
