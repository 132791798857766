// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ProgressBar extends Component {
  static propTypes = {
    loaded: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }

  render() {
    const { loaded, total } = this.props
    const percentage_string = `${(loaded * 100) / (total || 1)}%`

    return (
      <div className="progress-bar">
        <span
          className="progress-bar__meter"
          style={{ width: percentage_string }}
        ></span>
      </div>
    )
  }
}
