// Copyright Northcote Technology Ltd
import React from 'react'
import Wrapped from '../GradeInputWrapperOrca'

function getGradingNames(grading) {
  const {
    activityId,
    additionalInfo,
    gradingSessionResultId,
    id,
    qualificationId,
  } = grading

  const prefix = [
    `s${gradingSessionResultId}`,
    id && `g${id}`,
    qualificationId && `q${qualificationId}`,
    `a${activityId}`,
  ]
    .filter(a => a)
    .join('_')

  const additionalInfoNames = Object.keys(additionalInfo).reduce(
    (memo, name) => {
      memo[name] = `${prefix}_additionalInfo_${name}`
      return memo
    },
    {}
  )

  return {
    activityId: `${prefix}_activityId`,
    additionalInfo: additionalInfoNames,
    applicableFromDate: `${prefix}_applicableFromDate`,
    applicableUntilDate: `${prefix}_applicableUntilDate`,
    counter: `${prefix}_counter`,
    deferred: `${prefix}_deferred`,
    documentId: `${prefix}_documentId`,
    grade: `${prefix}_grade`,
    id: `${prefix}_id`,
    observations: `${prefix}_observations`,
    prefix,
    qualificationId: `${prefix}_qualificationId`,
    repeated: `${prefix}_repeated`,
  }
}

// This wraps the common GradeInputWrapper component and takes on the job of
// generating `gradingNames` that must be unique across the UI.
export default function GradeInputWrapper(props) {
  const gradingNames = getGradingNames(props.grading)

  return <Wrapped {...props} gradingNames={gradingNames} />
}
