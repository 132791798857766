// Copyright Northcote Technology Ltd
import { BOTTOM_BUTTON_CLICKED, SET_BOTTOM_BUTTONS } from '../actionTypes'

const bottomButtons = (state = { buttons: null, clicked: null }, action) => {
  switch (action.type) {
    case BOTTOM_BUTTON_CLICKED: {
      return Object.assign({}, state, {
        clicked: action.payload.button,
      })
    }
    case SET_BOTTOM_BUTTONS: {
      return Object.assign({}, state, {
        buttons: action.payload.buttons,
      })
    }
    default: {
      return state
    }
  }
}

export default bottomButtons
