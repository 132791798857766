// Copyright Northcote Technology Ltd
import React, { useContext, useMemo } from 'react'
import { sortBy } from 'lodash'

import TranslationsContext from '../contexts/Translations'

const CHARACTERISTICS = [
  'immediacy',
  'complexity',
  'degradation',
  'loss',
  'management',
]

export default function MalfunctionCharacteristics(props) {
  const translations = useContext(TranslationsContext)
  const {
    activitiesData,
    activityIds,
    aircraftTypeId,
    malfunctionCharacteristics,
  } = props
  const rows = useMemo(() => {
    const activityIdsSet = new Set(activityIds)
    return sortBy(
      malfunctionCharacteristics
        .filter(
          ({ activity_id, aircraft_type_id }) =>
            aircraft_type_id === aircraftTypeId &&
            activityIdsSet.has(activity_id)
        )
        .map(characteristic => {
          const { activity_id } = characteristic
          const name = activitiesData[activity_id]?.name
          return { ...characteristic, name }
        }),
      'name'
    )
  }, [activityIds, aircraftTypeId])
  const totals = useMemo(() => {
    const zeros = CHARACTERISTICS.reduce((memo, key) => {
      memo[key] = 0
      return memo
    }, {})

    return rows.reduce((memo, row) => {
      CHARACTERISTICS.forEach(key => {
        memo[key] += row[key] || 0
      })
      return memo
    }, zeros)
  }, [rows])
  const totalsTotal = useMemo(
    () => Object.values(totals).reduce((memo, value) => memo + value, 0),
    [totals]
  )

  const messages = translations.messages.grading_session_templates
  if (!aircraftTypeId) {
    return <p className="centred-message">{messages.aircraft_type_required}</p>
  }
  if (rows.length === 0) {
    return <p className="centred-message">{messages.malfunctions_required}</p>
  }

  return (
    <table className="table table-compact table-maxContent">
      <thead className="table-slantedHeaders">
        <tr>
          <th />
          {CHARACTERISTICS.map(key => (
            <th key={key}>
              <div>
                <div>
                  {
                    translations.activerecord.attributes
                      .malfunction_characteristic[key]
                  }
                </div>
              </div>
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr key={row.activity_id}>
            <th>{row.name}</th>
            {CHARACTERISTICS.map(key => (
              <td key={key}>{row[key]}</td>
            ))}
            <td />
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>Total</th>
          {CHARACTERISTICS.map(key => (
            <td key={key}>{totals[key]}</td>
          ))}
          <td>{totalsTotal}</td>
        </tr>
      </tfoot>
    </table>
  )
}
