// Copyright Northcote Technology Ltd
import camelize from 'camelize'
import { snakeCase } from 'lodash'
import { snakeKeys } from 'js-convert-case'

import {
  getIdb,
  saveIdb,
  updateIdb,
  deleteIdb,
  BUCKET_GRADINGS,
  BUCKET_RECORDINGS,
  clearSession,
  getRecordings,
  getResult,
  getSession,
  getSessions,
  setResult,
  setSession,
} from './common'

export async function setOfflineSessions(sessions) {
  const previousSessions = await getSessions()
  const newSessionIds = sessions.map(s => s.id)
  await Promise.all(
    previousSessions.map(async session => {
      if (!newSessionIds.includes(session.id)) await clearSession(session.id)
    })
  )
  await Promise.all(
    sessions.map(async session => {
      await setSession(session)
    })
  )
}

export async function updateOfflineSessions(action, tableName, params) {
  let gradingUpdate = tableName == BUCKET_GRADINGS.table
  let recordingUpdate = tableName == BUCKET_RECORDINGS.table

  if (gradingUpdate || recordingUpdate) {
    // update sessions semaphore
    waitForSynchronization()
    UBF.updatingOfflineSessions = true

    let sessions = await getSessions()

    if (gradingUpdate) {
      sessions.forEach(session => {
        session.gradingSessionResults.forEach(result => {
          result.gradings.forEach(grading => {
            if (grading.id == params.id) {
              grading.category = params.category
              grading.grade = params.grade
              grading.incomplete = params.incomplete
              grading.observations = params.observations_attributes.map(
                ({ behaviour_id, id, mood }) => {
                  const observation = {
                    behaviourId: behaviour_id,
                    mood: mood,
                  }
                  if (id) observation.id = id
                  return observation
                }
              )
              grading.repeated = params.repeated
              grading.deferred = params.deferred
              grading.additionalInfo = params.additional_info
              grading.firstAttempt = params.first_attempt
            }
          })
        })
      })
    }

    if (recordingUpdate) {
      let updated = false
      sessions.map((session, a) => {
        if (action == 'delete') {
          const recordId = params.id || params
          sessions[a].recordings = sessions[a].recordings.filter(
            r => r.id != recordId
          )
        } else {
          session.recordings.map((recording, b) => {
            let newRecording = recording
            if (newRecording.id == params.id) {
              updated = true
              newRecording.activityId = params.activity_id
              newRecording.comments = params.comments
              newRecording.recordingPersonBehaviours = camelize(
                params.recording_person_behaviours_attributes
              )
              newRecording.recordingTems = camelize(
                params.recording_tems_attributes
              )
            }

            sessions[a].recordings[b] = newRecording
          })
        }
        if (!updated && action != 'delete') {
          params.recordingPersonBehaviours = camelize(
            params.recording_person_behaviours_attributes
          )
          params.recordingTems = camelize(params.recording_tems_attributes)
          if (session.id == params.grading_session_id) {
            sessions[a].recordings = sessions[a].recordings.concat(
              camelize(params)
            )
          }
        }
      })
    }

    await setOfflineSessions(sessions)

    UBF.updatingOfflineSessions = false
    console.log('Offline sessions updated!')
  }
}

export async function sendGrading(grading, session) {
  const additional_info = {}
  for (const key in grading.additionalInfo) {
    additional_info[snakeCase(key)] = grading.additionalInfo[key]
  }

  const observations_attributes = grading.observations.map(
    ({ behaviourId, id, mood }) => {
      const observation = {
        behaviour_id: behaviourId,
        mood,
      }
      if (id) observation.id = id
      return observation
    }
  )

  var grading_params = {
    id: grading.id,
    incomplete: grading.incomplete,
    qualification_id: grading.qualificationId,
    activity_id: grading.activityId,
    grading_session_result_id: grading.gradingSessionResultId,
    grade: grading.grade,
    category: grading.category,
    counter: grading.counter,
    observations_attributes,
    additional_info,
    document: grading.document,
    repeated: grading.repeated,
    deferred: grading.deferred,
    saved_on_server: false,
    applicable_from_date: grading.applicableFromDate,
    applicable_period: grading.applicableUntilDate,
    files: grading.files,
    first_attempt: grading.firstAttempt,
    action_server: 'update',
  }

  const equivalentGradings = session.gradingSessionTemplate.gradeAsCrew
    ? checkGradeAsCrew(grading, session, observations_attributes)
    : []

  if (!equivalentGradings.find(g => g.id == grading_params.id)) {
    equivalentGradings.push(grading_params)
  }

  await Promise.all(
    equivalentGradings.map(async equivalentGradingParam => {
      await updateIdb(BUCKET_GRADINGS.table, equivalentGradingParam)
    })
  )
}

function checkGradeAsCrew(grading, session, observations_attributes) {
  const otherResults = session.gradingSessionResults.filter(
    ({ id }) => id !== grading.gradingSessionResultId
  )
  const equivalentGradings = []

  otherResults.forEach(result => {
    result.gradings.forEach(otherGrading => {
      if (
        otherGrading.activityId === grading.activityId &&
        otherGrading.qualificationId === grading.qualificationId
      ) {
        equivalentGradings.push({
          // Equivalent grading meaningful ids (must remain).
          id: otherGrading.id,
          grading_session_result_id: otherGrading.gradingSessionResultId,

          // Local app sync flags.
          action_server: 'update',
          saved_on_server: false,

          // Copy everything else from the grading.
          activity_id: grading.activityId,
          additional_info: grading.additionalInfo,
          applicable_from_date: grading.applicableFromDate,
          applicable_period: grading.applicableUntilDate,
          category: grading.category,
          counter: grading.counter,
          deferred: grading.deferred,
          document: grading.document,
          files: grading.files,
          grade: grading.grade,
          observations_attributes,
          qualification_id: grading.qualificationId,
          repeated: grading.repeated,
        })
      }
    })
  })

  return equivalentGradings
}

export async function addSessionSubmitted(sessionId) {
  const tableName = 'unsubmitted_sessions'
  const result = await getIdb(tableName, sessionId)
  if (!result) {
    await saveIdb(tableName, { session_id: sessionId, status: 'submitted' })
  }
}

export async function removeOfflineSession(sessionId) {
  let sessions = await getSessions()
  let newSessions = sessions.filter(s => s.id != sessionId)
  await setOfflineSessions(newSessions)
}

export async function getIdbRecording(recordingId) {
  let recording = null
  try {
    recording = await getIdb('/admin/recordings/', recordingId)
  } catch (ex) {
    console.log(ex)
  }
  return new Promise(resolve => {
    resolve(recording)
  })
}

export function updateIdbRecording(recording, action) {
  const params = snakeKeys(recording, {
    recursive: true,
    recursiveInArray: true,
  })

  params.action_server = action

  if (params.recording_person_behaviours) {
    params.recording_person_behaviours_attributes =
      params.recording_person_behaviours
    delete params.recording_person_behaviours
  }

  if (params.recording_tems) {
    params.recording_tems_attributes = params.recording_tems
    delete params.recording_tems
  }

  return updateIdb('/admin/recordings/', params)
}

export async function deleteIdbRecording(recordingId) {
  return await deleteIdb('/admin/recordings/', recordingId)
}

export async function clearSessionRecordings(sessionId) {
  const recordings = await getRecordings()
  await Promise.all(
    recordings.map(async recording => {
      if (recording.grading_session_id == sessionId) {
        await deleteIdbRecording(recording.id)
      }
    })
  )
}

export async function getNewRecordingKey() {
  const recordings = await getRecordings()
  if (!recordings) return -1
  const keys = []
  recordings.map(r => {
    if (r.id < 0) keys.push(r.id)
  })
  if (keys.length == 0) return -1
  return Math.min(...keys) - 1
}

export async function getNewOfflineSessionId() {
  const sessions = await getSessions()
  if (!sessions) return -1
  const keys = []
  sessions.map(s => {
    if (s.id < 0) keys.push(s.id)
  })
  if (keys.length == 0) return -1
  return Math.min(...keys) - 1
}

export async function updateFieldGradingSessionResult(
  sessionId,
  resultId,
  field,
  content
) {
  // update offline sessions semaphore
  waitForSynchronization()
  UBF.updatingOfflineSessions = true
  const session = await getSession(sessionId)
  let result = session.gradingSessionResults.find(r => r.id == resultId)

  if (session.gradingSessionTemplate.gradeAsCrew) {
    session.gradingSessionResults = session.gradingSessionResults.map(
      result => {
        result[camelize(field)] = content
        return result
      }
    )
  }
  result[camelize(field)] = content
  result[field] = content

  await setSession(session)
  UBF.updatingOfflineSessions = false

  if (typeof result.id == 'string')
    // session created offline
    return

  result = await getResult(resultId)
  if (!result) {
    result = {
      id: resultId,
      action_server: 'update',
      saved_on_server: false,
    }
  }
  result[field] = content

  if (session.gradingSessionTemplate.gradeAsCrew) {
    session.gradingSessionResults = session.gradingSessionResults.forEach(
      async gradingResult => {
        result = await await getResult(gradingResult.id)
        if (!result) {
          result = {
            id: gradingResult.id,
            action_server: 'update',
            saved_on_server: false,
          }
        }
        result[field] = content
        await setResult(result)
      }
    )
  } else {
    await setResult(result)
  }
}

export async function addOfflineSession(session) {
  let sessions = await getSessions()
  sessions.push(session)
  await setOfflineSessions(sessions)
}

export async function waitForSynchronization() {
  while (UBF.updatingOfflineSessions) {
    await new Promise(r => setTimeout(r, 10))
  }
}
