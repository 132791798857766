// Copyright Northcote Technology Ltd
export default function mergeResultChanges(existing, changes) {
  const newList = [...existing]

  changes.forEach(params => {
    const index = existing.findIndex(({ id }) => id === params.id)

    if (index === -1) {
      newList.push(params)
    } else {
      newList[index] = {
        ...newList[index],
        ...params,
      }
    }
  })

  return newList
}
