// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { walk } from 'react-sortable-tree'
import SortableTree from 'react-sortable-tree'

const NODE_HEIGHT = 80
const SCAFFOLD_BLOCK_PX_WIDTH = 44

const NodeSubtitle = props => (
  <div className="qualification-node__subtitle">{props.qualification.name}</div>
)

const NodeTitle = props => (
  <div className="qualification-node__title" data-id={props.qualification.id}>
    {props.qualification.identifier} [v{props.qualification.version}]
  </div>
)

const NodeEditLink = props => (
  <a className="qualification-node__button" href={props.qualification.linkHref}>
    {props.qualification.linkText}
  </a>
)

const NodeEarlyRevalidationDurationLabel = props =>
  props.qualification.earlyRevalidationDuration ? (
    <div className="qualification-node__label qualification-node__label--early-revalidation-duration">
      {props.qualification.earlyRevalidationDuration}
    </div>
  ) : null

const NodeGradingScaleLabel = props =>
  props.qualification.gradingScale ? (
    <div className="qualification-node__label qualification-node__label--grading-scale">
      {props.qualification.gradingScale}
    </div>
  ) : null

const NodeMandatorySubqualificationsLabel = props =>
  props.qualification.mandatorySubqualifications ? (
    <div className="qualification-node__label qualification-node__label--mandatory-subqualification">
      {props.qualification.mandatorySubqualifications}
    </div>
  ) : null

const NodeValidForDurationLabel = props =>
  props.qualification.validForDuration ? (
    <div className="qualification-node__label qualification-node__label--valid-for-duration">
      {props.qualification.validForDuration}
    </div>
  ) : null

const countExpanded = treeData => {
  let counter = 0

  const incrementCounter = () => {
    counter++
  }

  walk({
    treeData,
    getNodeKey,
    callback: incrementCounter,
    ignoreCollapsed: true,
  })

  return counter
}

export const getNodeKey = node => node.id

export const getParentKey = node => node.parent_id

class QualificationTree extends Component {
  static propTypes = {
    changes: PropTypes.object.isRequired,
    isRearrangeable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onMoveNode: PropTypes.func,
    onlyExpandSearchedNodes: PropTypes.bool,
    onSearchFinish: PropTypes.func.isRequired,
    searchFocusIndex: PropTypes.number.isRequired,
    searchMethod: PropTypes.func.isRequired,
    searchString: PropTypes.string.isRequired,
    treeData: PropTypes.array.isRequired,
  }

  hasChanged = qualification => !!this.props.changes[qualification.id]

  generateNodeProps = ({ node }) => {
    const qualification = node
    return {
      className: classNames('qualification-tree__row', 'qualification-node', {
        'qualification-node--not-current': !qualification.isCurrent,
        'qualification-tree__row--changed': this.hasChanged(qualification),
      }),
      title: <NodeTitle qualification={qualification} />,
      subtitle: <NodeSubtitle qualification={qualification} />,
      buttons: [
        <NodeValidForDurationLabel
          key="validForDuration"
          qualification={qualification}
        />,
        <NodeEarlyRevalidationDurationLabel
          key="earlyRevalidationDuration"
          qualification={qualification}
        />,
        <NodeMandatorySubqualificationsLabel
          key="mandatorySubqualifications"
          qualification={qualification}
        />,
        <NodeGradingScaleLabel
          key="gradingScale"
          qualification={qualification}
        />,
        <NodeEditLink key="edit" qualification={qualification} />,
      ],
    }
  }

  render() {
    const {
      isRearrangeable,
      onChange,
      onMoveNode,
      onlyExpandSearchedNodes,
      onSearchFinish,
      searchFocusIndex,
      searchMethod,
      searchString,
      treeData,
    } = this.props

    const countOfExpanded = countExpanded(treeData)

    return (
      <div className="qualification-tree">
        <SortableTree
          treeData={treeData}
          canDrag={isRearrangeable}
          onChange={onChange}
          onlyExpandSearchedNodes={!!onlyExpandSearchedNodes}
          onMoveNode={onMoveNode}
          rowHeight={NODE_HEIGHT}
          scaffoldBlockPxWidth={SCAFFOLD_BLOCK_PX_WIDTH}
          style={{
            height: NODE_HEIGHT * (countOfExpanded + 0.5),
          }}
          generateNodeProps={this.generateNodeProps}
          searchFinishCallback={onSearchFinish}
          searchFocusOffset={searchFocusIndex}
          searchMethod={searchMethod}
          searchQuery={searchString}
        />
      </div>
    )
  }
}

export default QualificationTree
