// Copyright Northcote Technology Ltd
export const NEUTRAL = 0
export const POSITIVE = 1
export const NEGATIVE = 3

export const moodsLabels = {
  [NEUTRAL]: 'neutral',
  [POSITIVE]: 'positive',
  [NEGATIVE]: 'negative',
}

export function behavioursByMood(behaviours) {
  const lookup = {}

  behaviours.forEach(behaviour => {
    behaviour.moods.forEach(mood => {
      if (!lookup[mood]) lookup[mood] = []

      lookup[mood].push({
        id: behaviour.id,
        mood: mood.toString(),
        name: behaviour.name,
      })
    })
  })

  return lookup
}
