// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree'

import SearchableQualificationTree from './SearchableQualificationTree'
import QualificationHierarchyForm from './QualificationHierarchyForm'
import { getNodeKey, getParentKey } from './QualificationTree'

const getFlatFrom = treeData => {
  return getFlatDataFromTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
  })
}

const getTreeDataFrom = qualifications => {
  return getTreeFromFlatData({
    flatData: qualifications,
    getKey: getNodeKey,
    getParentKey,
    rootKey: null,
  })
}

const toChangedQualification = details => {
  const parentNode = details.parentNode
  const parentId = parentNode ? parentNode.id : null
  return { ...details.node, parent_id: parentId }
}

const toLookup = (changeStore, qualification) => {
  changeStore[qualification.id] = qualification
  return changeStore
}

export default class QualificationTreeSaveable extends Component {
  static propTypes = {
    authenticity_token: PropTypes.string.isRequired,
    isRearrangeable: PropTypes.bool.isRequired,
    qualifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    savePath: PropTypes.string.isRequired,
    saveText: PropTypes.string.isRequired,
    changesNotSavedWarning: PropTypes.string.isRequired,
    searchPlaceholderText: PropTypes.string.isRequired,
    undoText: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    const { qualifications } = props

    this.state = {
      changes: {},
      originalsLookup: qualifications.reduce(toLookup, {}),
      treeData: getTreeDataFrom(qualifications),
    }
  }

  getOriginal = qualification => this.state.originalsLookup[qualification.id]

  getChangesFrom = treeData => {
    return getFlatFrom(treeData)
      .map(toChangedQualification)
      .filter(this.hasChangedParent)
      .reduce(toLookup, {})
  }

  handleChange = treeData => {
    this.setState({
      changes: this.getChangesFrom(treeData),
      treeData: treeData,
    })
  }

  hasChangedParent = changedQualification => {
    const original = this.getOriginal(changedQualification)
    if (!original) {
      return false
    }
    return original.parent_id !== changedQualification.parent_id
  }

  render() {
    const {
      authenticity_token,
      changesNotSavedWarning,
      isRearrangeable,
      savePath,
      saveText,
      searchPlaceholderText,
      undoText,
    } = this.props

    const { changes, treeData } = this.state

    const qualificationChanges = Object.values(changes)

    const updatesForm = qualificationChanges.length ? (
      <QualificationHierarchyForm
        authenticity_token={authenticity_token}
        changesNotSavedWarning={changesNotSavedWarning}
        onUndo={this.undo}
        qualificationChanges={qualificationChanges}
        savePath={savePath}
        saveText={saveText}
        undoText={undoText}
      />
    ) : null

    return (
      <div className="qualification-tree-saveable">
        {updatesForm}

        <SearchableQualificationTree
          changes={changes}
          isRearrangeable={isRearrangeable}
          onChange={this.handleChange}
          searchPlaceholderText={searchPlaceholderText}
          treeData={treeData}
        />
      </div>
    )
  }

  undo = () => {
    this.setState({
      changes: {},
      treeData: getTreeDataFrom(this.props.qualifications),
    })
  }
}
