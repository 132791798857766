// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'

export default class RoleQualificationsSelector extends Component {
  static propTypes = {
    translations: PropTypes.object,
    roles: PropTypes.array.isRequired,
    selectedRoles: PropTypes.array,
    qualifications: PropTypes.array.isRequired,
    selectedQualifications: PropTypes.array,
    fieldNamePrefix: PropTypes.string,
    noResultsRoles: PropTypes.string,
    noResultsQualifications: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedRoles: props.selectedRoles || [],
      selectedQualifications: props.selectedQualifications || [],
      selectableQualifications: props.qualifications,
    }
  }

  updateRoles = event => {
    const { roles, qualifications } = this.props
    const { selectedQualifications } = this.state

    const selectedRoles = roles.filter(r =>
      event.target.value.includes(r.value)
    )
    const selectableQualifications =
      selectedRoles.length == 0
        ? qualifications
        : qualifications.filter(q =>
            selectedRoles
              .flatMap(r => r.qualificationIds)
              .includes(parseInt(q.value))
          )

    this.setState({
      selectedRoles: event.target.value,
      selectableQualifications,
      selectedQualifications: selectedQualifications.filter(q =>
        selectableQualifications.map(q => q.value).includes(q)
      ),
    })
  }

  updateQualifications = event => {
    this.setState({ selectedQualifications: event.target.value })
  }

  render = () => {
    const {
      roles,
      fieldNamePrefix,
      translations,
      noResultsRoles,
      noResultsQualifications,
    } = this.props

    const { selectedRoles, selectedQualifications, selectableQualifications } =
      this.state

    return (
      <>
        <DynamicSelect
          values={roles}
          clearable={true}
          name={`${fieldNamePrefix}[${'role_ids'}][]`}
          currentValue={selectedRoles}
          onSelect={this.updateRoles}
          placeholderText={
            translations.activemodel.attributes.personQualificationsReport
              .roleIds
          }
          noResultsText={noResultsRoles}
          multi={true}
        />
        <DynamicSelect
          values={selectableQualifications}
          clearable={true}
          name={`${fieldNamePrefix}[${'qualification_ids'}][]`}
          currentValue={selectedQualifications}
          onSelect={this.updateQualifications}
          placeholderText={
            translations.activemodel.attributes.personQualificationsReport
              .qualificationIds
          }
          noResultsText={noResultsQualifications}
          multi={true}
        />
      </>
    )
  }
}
