// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { DirectUpload } from '@rails/activestorage'

const ALLOWED_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'image/gif',
  'application/zip',
]

const IN_PROGRESS = 'IN_PROGRESS'

function DirectUploadButton({
  children,
  disabled,
  directUploadsUrl,
  onUpload,
  allowedTypes,
  additionalClassName,
}) {
  const inputRef = useRef(null)
  const [status, setStatus] = useState(null)

  const handleChange = event => {
    setStatus(IN_PROGRESS)

    const files = event.target.files

    if (files.length === 0) return

    new DirectUpload(files[0], directUploadsUrl).create((error, blob) => {
      inputRef.current.value = ''
      setStatus(null)
      onUpload(error, blob)
    })
  }

  const isInProgress = status === IN_PROGRESS

  const className = classnames({
    'direct-upload-button': true,
    'direct-upload-button--disabled': disabled,
    'direct-upload-button--progress': isInProgress,
  })

  return (
    <label className={`${className} ${additionalClassName}`}>
      <input
        accept={(allowedTypes || ALLOWED_TYPES).join(',')}
        disabled={disabled || isInProgress}
        onChange={handleChange}
        ref={inputRef}
        type="file"
      />
      <div>{children}</div>
    </label>
  )
}

DirectUploadButton.propTypes = {
  children: PropTypes.node,
  directUploadsUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  allowedTypes: PropTypes.array,
  additionalClassName: PropTypes.string,
}

export default DirectUploadButton
