// Copyright Northcote Technology Ltd
import React, { useContext, useState } from 'react'
import { sortBy } from 'lodash'
import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import IconButton from '../IconButton'
import FormModal from './FormModal'
import Inputs from './Inputs'
import Row from './Row'

import TranslationsContext from '../contexts/Translations'

const EMPTY_EVENT = {
  activityId: null,
  categoryAc: '',
  categoryNav: '',
  categoryTraining: '',
  categoryWeather: '',
  description: '',
  estimatedTime: '',
}
const MAX_COLUMNS = 4

const EditorRow = ({
  activitiesData,
  onAdd,
  onDelete,
  onEdit,
  onMove,
  row,
}) => {
  const translations = useContext(TranslationsContext)
  const { cid, events } = row
  const notDeletedEvents = events.filter(({ _destroy }) => !_destroy)

  if (notDeletedEvents.length === 0) return null

  const disabled = notDeletedEvents.length >= MAX_COLUMNS
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: cid })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : null,
  }

  return (
    <div className="timeline-editor__row" ref={setNodeRef} style={style}>
      <IconButton
        aria-label={translations.ubf.move}
        cursor="move"
        icon="bars"
        {...attributes}
        {...listeners}
      />

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[
          restrictToHorizontalAxis,
          restrictToParentElement,
          restrictToWindowEdges,
        ]}
        onDragEnd={event => onMove(row, event)}
      >
        <SortableContext
          items={sortBy(notDeletedEvents, 'column').map(({ cid }) => cid)}
          strategy={horizontalListSortingStrategy}
        >
          <Row
            activitiesData={activitiesData}
            draggable={notDeletedEvents.length > 1}
            editable={true}
            events={notDeletedEvents}
            onDelete={cid => onDelete(row, cid)}
            onEdit={cid => onEdit(row, cid)}
          />
        </SortableContext>
      </DndContext>

      <IconButton
        aria-label={translations.ubf.add_event}
        disabled={disabled}
        icon="plus"
        onClick={() => onAdd(row)}
      />
    </div>
  )
}

export default function Editor({ activitiesData, eventsData, onChange }) {
  const translations = useContext(TranslationsContext)
  const [formData, setFormData] = useState(null)

  function handleAddColumn(row) {
    setFormData({ event: EMPTY_EVENT, row, type: 'addColumn' })
  }

  function handleAddRow() {
    setFormData({ event: EMPTY_EVENT, type: 'addRow' })
  }

  function handleDeleteEvent(row, cid) {
    onChange({ cid, row, type: 'deleteEvent' })
  }

  function handleEditEvent(row, cid) {
    const event = row.events.find(event => event.cid === cid)
    if (event) setFormData({ event, row, type: 'updateEvent' })
  }

  function handleMoveColumn(row, dragEvent) {
    const activeId = dragEvent.active.id
    const overId = dragEvent.over.id
    onChange({ activeId, overId, row, type: 'moveColumn' })
  }

  function handleMoveRow(dragEvent) {
    const activeId = dragEvent.active.id
    const overId = dragEvent.over.id
    onChange({ activeId, overId, type: 'moveRow' })
  }

  function handleSaveEvent(event) {
    const { row, type } = formData
    onChange({ event, row, type })
    setFormData(null)
  }

  const rows = sortBy(Object.values(eventsData), 'row')

  return (
    <div className="timeline-editor">
      {formData ? (
        <FormModal
          activitiesData={activitiesData}
          event={formData.event}
          onCancel={() => setFormData(null)}
          onSave={handleSaveEvent}
        />
      ) : null}

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[
          restrictToParentElement,
          restrictToVerticalAxis,
          restrictToWindowEdges,
        ]}
        onDragEnd={handleMoveRow}
      >
        <SortableContext
          items={rows.map(({ cid }) => cid)}
          strategy={verticalListSortingStrategy}
        >
          {rows.map(row => (
            <EditorRow
              activitiesData={activitiesData}
              key={row.cid}
              onAdd={handleAddColumn}
              onDelete={handleDeleteEvent}
              onEdit={handleEditEvent}
              onMove={handleMoveColumn}
              row={row}
            />
          ))}
        </SortableContext>
      </DndContext>

      <IconButton
        aria-label={translations.ubf.add_event}
        icon="plus"
        onClick={handleAddRow}
      />

      <Inputs rows={rows} />
    </div>
  )
}
