// Copyright Northcote Technology Ltd
import React from 'react'
import classnames from 'classnames'

export default function Modal2({ children, className, full }) {
  const mainClassName = classnames(
    {
      'modal2--main': true,
      'modal2--full': full,
    },
    className
  )

  return (
    <div className="modal2">
      <div className={mainClassName}>{children}</div>
    </div>
  )
}
