// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import translate from 'src/lib/translate'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'

export default class PermissionGroup extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    permissionsInfo: PropTypes.array.isRequired,
    noResultsText: PropTypes.string.isRequired,
    placeholderText: PropTypes.string.isRequired,
    groupValues: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    templateValues: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    templatesNoResultsText: PropTypes.string.isRequired,
    templatesPlaceholderText: PropTypes.string.isRequired,
    currentPermissionChecked: PropTypes.object,
    currentPermissionGroups: PropTypes.object,
    currentPermissionTemplates: PropTypes.object,
    currentUserType: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      permissionChecked: props.currentPermissionChecked,
      currentGroups: props.currentPermissionGroups,
      currentTemplates: props.currentPermissionTemplates,
    }
  }

  handleToggle = permissionName => {
    var newPermissionChecked = { ...this.state.permissionChecked }
    newPermissionChecked[permissionName] = !newPermissionChecked[permissionName]

    this.setState({ permissionChecked: newPermissionChecked })
  }

  setCurrentGroups = (event, permissionName) => {
    var newCurrentGroups = { ...this.state.currentGroups }
    newCurrentGroups[permissionName] = event.target.value

    this.setState({ currentGroups: newCurrentGroups })
  }

  setCurrentTemplates = (event, permissionName) => {
    var newCurrentTemplates = { ...this.state.currentTemplates }
    newCurrentTemplates[permissionName] = event.target.value

    this.setState({ currentTemplates: newCurrentTemplates })
  }

  templateSelector = permission => {
    const { permissionName } = permission
    const permissionChecked =
      this.state.permissionChecked[permission.permissionName]

    if (
      !permissionChecked ||
      permissionName != 'can_edit_grading_session_results_for_templates'
    )
      return

    const { templatesNoResultsText, templatesPlaceholderText, templateValues } =
      this.props

    return (
      <div className="grid-form">
        {this.state.currentTemplates[permissionName].map(template_id => {
          return (
            <div key={'multiselect-' + permissionName + '-' + template_id}>
              <input
                type="hidden"
                name="permission_user_type_templates_attributes[][permission]"
                value={permissionName}
              />
              <input
                type="hidden"
                name="permission_user_type_templates_attributes[][grading_session_template_id]"
                value={template_id}
              />
              <input
                type="hidden"
                name="permission_user_type_templates_attributes[][user_type_id]"
                value={this.props.currentUserType}
              />
            </div>
          )
        })}

        <DynamicSelect
          clearable={true}
          currentValue={this.state.currentTemplates[permission.permissionName]}
          multi={true}
          name="dropdown"
          noResultsText={templatesNoResultsText}
          placeholderText={templatesPlaceholderText}
          values={templateValues}
          onSelect={event => {
            this.setCurrentTemplates(event, permission.permissionName)
          }}
        />
      </div>
    )
  }

  render() {
    const { permissionsInfo, noResultsText, placeholderText, groupValues } =
      this.props

    return (
      <Fragment>
        {permissionsInfo.map(permission => {
          var permissionChecked =
            this.state.permissionChecked[permission.permissionName]

          return (
            <div
              key={`checkbox-${permission.permissionName}`}
              data-permission-row={permission.permissionName}
            >
              <div className="grid-form">
                <input
                  type="hidden"
                  name={permission.permissionName}
                  value={permissionChecked}
                />
                <input
                  type="checkbox"
                  name={permission.permissionName}
                  id={permission.permissionName}
                  className="hide"
                  defaultChecked={permissionChecked}
                  onClick={() => {
                    this.handleToggle(permission.permissionName)
                  }}
                />
                <label
                  className="btn neutral"
                  htmlFor={permission.permissionName}
                >
                  {translate(
                    permission.permissionName,
                    this.props.translations.user_type
                  )}
                </label>
              </div>

              {permission.multiSelect && permissionChecked ? (
                <div className="grid-form">
                  {this.state.currentGroups[permission.permissionName].map(
                    group_id => {
                      return (
                        <div
                          key={
                            'multiselect-' +
                            permission.permissionName +
                            '-' +
                            group_id
                          }
                        >
                          <input
                            type="hidden"
                            name="permission_group_attributes[][permissions]"
                            value={permission.permissionName}
                          />
                          <input
                            type="hidden"
                            name="permission_group_attributes[][group_id]"
                            value={group_id}
                          />
                          <input
                            type="hidden"
                            name="permission_group_attributes[][user_type_id]"
                            value={this.props.currentUserType}
                          />
                        </div>
                      )
                    }
                  )}

                  <DynamicSelect
                    clearable={true}
                    currentValue={
                      this.state.currentGroups[permission.permissionName]
                    }
                    multi={true}
                    name="dropdown"
                    noResultsText={noResultsText}
                    placeholderText={placeholderText}
                    values={groupValues}
                    onSelect={event => {
                      this.setCurrentGroups(event, permission.permissionName)
                    }}
                  />
                </div>
              ) : null}

              {this.templateSelector(permission)}
            </div>
          )
        })}
      </Fragment>
    )
  }
}
