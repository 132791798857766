// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DirectUpload } from '@rails/activestorage'
import ProgressBar from './ProgressBar'

const ALLOWED_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'image/gif',
  'application/zip',
]

export default class FileDirectUploader extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    fileCounter: PropTypes.number,
    documentId: PropTypes.string,
    fileUploadName: PropTypes.string,
    hidden: PropTypes.bool,
    name: PropTypes.string.isRequired,
    activityIdentifier: PropTypes.string,
    qualificationIdentifier: PropTypes.string,
    sessionDate: PropTypes.string,
    personIdentifier: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onUploadDocument: PropTypes.func.isRequired,
    onInitFileDirectUploader: PropTypes.func,
  }

  componentDidMount() {
    const { onInitFileDirectUploader, documentId } = this.props

    if (onInitFileDirectUploader && documentId) {
      onInitFileDirectUploader(this.fileInput.current, documentId)
    }
  }

  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
    this.state = {
      fileUpload: {
        loaded: 0,
        loading: false,
        total: 1,
      },
    }
  }

  handleChange = () => {
    const file = this.fileInput.current.files[0]
    const file_new = new File([file], this.standarisedFileName(file.name), {
      type: file.type,
    })
    this.uploadFile(file_new)
  }

  uploadFile = file => {
    const { directUploadsUrl, onUploadDocument } = this.props
    const self = this

    const upload = new DirectUpload(file, directUploadsUrl, {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.onprogress = event => {
          const { loaded, total } = event
          self.setState({
            fileUpload: {
              loaded,
              loading: true,
              total,
            },
          })
        }
      },
    })

    upload.create((error, blob) => {
      this.setState({
        fileUpload: {
          loaded: 0,
          loading: false,
          total: 1,
        },
      })

      if (error) {
        //eslint-disable-next-line
        console.log(error)
      } else {
        onUploadDocument(this.fileInput.current, blob.signed_id, blob.filename)
      }
    })
  }

  standarisedFileName = fileName => {
    const {
      activityIdentifier,
      qualificationIdentifier,
      sessionDate,
      personIdentifier,
      fileCounter,
    } = this.props

    if (!activityIdentifier && !qualificationIdentifier) {
      return fileName
    }
    const extensionFile = fileName.split('.').pop()
    const counter = fileCounter ? fileCounter : ''

    return activityIdentifier
      ? [
          activityIdentifier,
          personIdentifier.replace(/[\W_]+/g, '_'),
          sessionDate,
          counter,
        ].join('_') +
          '.' +
          extensionFile
      : [
          qualificationIdentifier,
          personIdentifier.replace(/[\W_]+/g, '_'),
          sessionDate,
          counter,
        ].join('_') +
          '.' +
          extensionFile
  }

  render() {
    const { documentId, fileUploadName, hidden, id, name } = this.props

    const { fileUpload } = this.state
    const { loaded, loading, total } = fileUpload

    const progressBar =
      !hidden && loading ? <ProgressBar loaded={loaded} total={total} /> : null

    const fileUploadRegister =
      !hidden && fileUploadName && fileUploadName.length ? (
        <span className="file-direct-uploader__file-upload-register">
          <span className="file-direct-uploader__file-upload-register-label">
            {fileUploadName}
          </span>
        </span>
      ) : null

    const fileDirectUploaderButton = !hidden ? (
      <div className="file-direct-uploader__upload-btn-wrapper">
        <input
          accept={ALLOWED_TYPES.join(', ')}
          className="file-direct-uploader__input"
          id={id}
          onChange={this.handleChange}
          ref={this.fileInput}
          type="file"
        />
        <label className="file-direct-uploader__btn btn" htmlFor={id}>
          {`Upload ${
            fileUploadName && fileUploadName.length ? 'replacement' : 'a'
          } file`}
        </label>
      </div>
    ) : null

    const hiddenFileUploadInput =
      documentId && documentId.length ? (
        <input name={name} type="hidden" value={documentId} />
      ) : null

    return (
      <div className="file-direct-uploader">
        {fileDirectUploaderButton}

        {progressBar || fileUploadRegister}

        {hiddenFileUploadInput}
      </div>
    )
  }
}
