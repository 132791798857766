// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  open(e) {
    e.preventDefault()
    this.formTarget.classList.add('is-open')
  }

  close(e) {
    e.preventDefault()
    this.formTarget.classList.remove('is-open')
  }

  clear(e) {
    e.preventDefault()
    const inputs = Array.from(this.element.querySelectorAll('input'))
    // Clear all <input>s within this controller (<input>s without a name aren't
    // included when submitting a form).
    inputs
      .filter(input => {
        return input.name && input.type !== 'submit'
      })
      .forEach(input => (input.value = ''))
    // Submit the <form>.
    if (inputs.length > 0) inputs[0].form.requestSubmit()
  }
}
