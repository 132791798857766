// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  syncOfflineTime,
  pinTry,
  updatePin,
  checkLimitOfflineData,
} from '../../src/lib/idb/offlinePin'
import { sha256 } from 'js-sha256'
import Modal from '../Modal'

export default class OfflinePin extends Component {
  static propTypes = {
    translations: PropTypes.object,
    online: PropTypes.bool,
    userPin: PropTypes.string,
    pinEnabled: PropTypes.bool,
    userEmailEncrypted: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      needPin: false,
      pinMessage: null,
      needOnline: false,
      needResetIDB: false,
    }
    this.pinInput = React.createRef()
    this.emailInput = React.createRef()
  }

  componentDidMount = () => {
    const { userPin } = this.props
    let statusInterval = setInterval(this.checkStatus, 1000) //1 sec
    this.setState({ statusInterval })
    if (userPin) updatePin(userPin)
  }

  componentWillUnmount = () => {
    clearInterval(this.state.statusInterval)
  }

  componentDidUpdate = async prevProps => {
    const { online } = this.props
    const { needOnline, needResetIDB } = this.state

    if (prevProps.online != online) {
      if ((needOnline && online) || !needOnline) {
        await syncOfflineTime(true, this.checkOnline)

        if (!needResetIDB) {
          checkLimitOfflineData(this.checkOnline)
        }
      }
    }
  }

  checkPin = () => {
    const { userPin, translations, userEmailEncrypted } = this.props
    const inputPin = sha256(this.pinInput.current.value)
    const inputEmail = userEmailEncrypted
      ? sha256(this.emailInput.current.value.toLowerCase())
      : ''

    this.pinInput.current.value = ''

    const pinVerified = inputPin == userPin
    const emailVerified =
      !userEmailEncrypted || inputEmail == userEmailEncrypted

    if (pinVerified && emailVerified) {
      pinTry(true)
      this.setState({ needPin: false, pinMessage: false })
    } else {
      pinTry(false)
      this.setState({
        pinMessage: translations.activerecord.errors.messages.incorrect_pin,
      })
    }
  }

  checkStatus = async () => {
    const { online } = this.props

    if (!online) {
      await syncOfflineTime(false, this.checkOnline)
      checkLimitOfflineData(this.checkDataIdb)
    }
  }

  checkDataIdb = value => {
    this.setState({ needResetIDB: value })
  }

  checkOnline = (pin, value) => {
    const { pinEnabled, userPin } = this.props

    if (pin) {
      if (pinEnabled && userPin) {
        this.setState({ needPin: value })
      } else if (pinEnabled) {
        this.setState({ needOnline: value })
      } else {
        this.setState({ needPin: false })
      }
    } else {
      if (pinEnabled && userPin) {
        this.setState({ needOnline: value })
      } else if (!pinEnabled) {
        this.setState({ needOnline: value })
      }
    }
  }

  cleanMessage = () => {
    this.setState({ pinMessage: null })
  }

  render = () => {
    const { translations, pinEnabled, userPin, userEmailEncrypted } = this.props

    const { needPin, needOnline, needResetIDB } = this.state

    const { pinMessage } = this.state

    const popUpMessage =
      !pinEnabled || (pinEnabled && userPin)
        ? translations.activerecord.errors.messages.exceeded_offline_time
        : translations.activerecord.errors.messages.go_settings_pin

    return (
      <div>
        <Modal
          title={translations.activerecord.errors.messages.insert_pin}
          visible={needPin}
          buttonSave={true}
          buttonSaveName={translations.ubf.submit}
          hideButtonCancel={true}
          onSaveModal={this.checkPin}
          translations={translations}
        >
          <div className="offline-pin__error-message">
            {pinMessage ? pinMessage : null}
          </div>
          {userEmailEncrypted ? (
            <input
              type="text"
              id="user-email"
              placeholder="Email"
              onChange={this.cleanMessage}
              ref={this.emailInput}
              name="user-email"
            />
          ) : null}
          <input
            type="password"
            id="pin"
            placeholder="PIN"
            onChange={this.cleanMessage}
            ref={this.pinInput}
            name="pin"
          />
        </Modal>

        <Modal
          title={popUpMessage}
          visible={needOnline || needResetIDB}
          buttonSave={false}
          hideButtonCancel={true}
          onSaveModal={null}
          translations={translations}
          center={true}
        >
          <div className="offline-pin__offline-limit-message">
            {!pinEnabled || (pinEnabled && userPin)
              ? needResetIDB
                ? translations.activerecord.errors.messages.idb_going_to_reset
                : translations.activerecord.errors.messages.go_online
              : ''}
          </div>
        </Modal>
      </div>
    )
  }
}
