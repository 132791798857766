// Copyright Northcote Technology Ltd
export default function hasSomeEmpty(object) {
  if (Object.keys(object).length == 0) {
    return true
  }

  const values = Object.values(object)

  return (
    values.includes('') || values.includes(undefined) || values.includes([])
  )
}
