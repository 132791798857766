// Copyright Northcote Technology Ltd
export const getComplianceStore = store => store.compliance
export const getFlexLayoutStore = store => store.flexlayout
export const getBottomButtonsStore = store => store.bottomButtons
export const getPersonStore = store => store.person
export const getPeopleStore = store => store.people
export const getCreationStore = store => store.creation
export const getEditionStore = store => store.edition
export const getLoadingStore = store => store.loading
export const getGradingSessionStore = store => store.gradingSession
export const getMySettingsStore = store => store.mySetting
export const getHeatmapReportsStore = store => store.heatmapReport
export const getGradingsStore = store => store.grading
export const onlineSelector = state => state.online
export const sessionsSelector = state => state.sessions.data

export function makeSessionSelector(sessionId) {
  return state => state.sessions.data[sessionId]
}

export function makeSessionNetworkSelector(sessionId) {
  return state => state.sessions.network[sessionId]
}
