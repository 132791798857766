// Copyright Northcote Technology Ltd
import * as Sentry from '@sentry/browser'

const { dsn, tags, user } = window.UBF?.sentry || {}

if (dsn) {
  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })

  for (const name in tags) {
    Sentry.setTag(name, tags[name])
  }

  if (user) {
    Sentry.setUser({ id: user.id })
  }
}
