// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CachedIcon from '@material-ui/icons/Cached'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

export default class NotificationModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onCloseModal: PropTypes.func,
    notificationType: PropTypes.string.isRequired,
    translations: PropTypes.object,
    model: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  closeModal = () => {
    const { onCloseModal } = this.props

    if (onCloseModal) onCloseModal()
  }

  contentModal() {
    const { model, translations, notificationType } = this.props

    let messageHead = ''
    let messageBody = ''

    let actions = translations ? translations.messages.actions : null

    switch (notificationType) {
      case 'create':
        messageHead = model
        messageBody = actions.create_success
        break
      case 'update':
        messageHead = model
        messageBody = actions.update_success
        break
      case 'delete':
        messageHead = model
        messageBody = actions.delete_success
        break
      case 'loading':
        messageBody = UBF.lang.loading + '...'
        break
      case 'submit_success':
        messageHead = model
        messageBody = actions.submission_success
        break
      case 'submit_failure':
        messageHead = model
        messageBody = actions.submission_failure
        break
      case 'synchronising':
        messageBody = translations.ubf.synchronising
        break
      default:
        messageHead = 'Operation'
        messageBody = 'Succeded'
        break
    }
    return (
      <div className="quantum-layout__notification">
        <h3>{messageHead}</h3>
        <h1>{messageBody}</h1>
        {this.icon()}
      </div>
    )
  }

  icon() {
    const { notificationType } = this.props

    if (notificationType === 'loading' || notificationType === 'synchronising')
      return <CachedIcon className="quantum-layout__notification--loading" />
    else if (notificationType == 'submit_failure')
      return (
        <ReportProblemIcon className="quantum-layout__notification--icon-yellow" />
      )

    return (
      <CheckCircleIcon className="quantum-layout__notification--icon-green" />
    )
  }

  render() {
    const showHideClassName = this.props.visible
      ? 'modal modal-block'
      : 'modal modal-none'

    return (
      <div className={showHideClassName}>
        <div className="modal-notification" onClick={this.closeModal}>
          <div className="modal-content">{this.contentModal()}</div>
        </div>
      </div>
    )
  }
}
