// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const DELAY_UNTIL_TODAY_FOCUS = 300

class TodayLine extends PureComponent {
  static propTypes = {
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.today = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.today.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      })
    }, DELAY_UNTIL_TODAY_FOCUS)
  }

  render() {
    const { scale, t } = this.props

    const now = new Date()

    return (
      <div
        className="today-line"
        style={{
          left: scale(now),
        }}
      >
        <div className="today-line__label" ref={this.today}>
          {t('ubf.today')}
        </div>

        <div className="today-line__line"></div>
      </div>
    )
  }
}

export default TodayLine
