// Copyright Northcote Technology Ltd
import {
  SET_SESSION_ERRORS,
  UPDATE_SESSION_DEVICE_TYPE,
  UPDATE_SESSION_CUSTOM_FIELD,
} from '../../actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_ERRORS: {
      const { errors, sessionId } = action
      const newState = { ...state }

      if (errors) {
        newState[sessionId] = errors
      } else {
        delete newState[sessionId]
      }

      return newState
    }
    case UPDATE_SESSION_DEVICE_TYPE: {
      const { sessionId } = action
      const newState = { ...state }
      delete newState[sessionId]
      return newState
    }
    case UPDATE_SESSION_CUSTOM_FIELD: {
      const { fieldId, sessionId } = action
      const newErrors = { ...state[sessionId] }
      delete newErrors[`customFieldId-${fieldId}`]
      return {
        ...state,
        [sessionId]: newErrors,
      }
    }
    default: {
      return state
    }
  }
}
