// Copyright Northcote Technology Ltd
import React, { Fragment } from 'react'
import { partition, sortBy } from 'lodash'

export default function Inputs({ rows }) {
  const events = []
  let rowIndex = 0

  // Sanitise row/column values by looping over rows/events - skipping deleted
  // events/rows - so that the correct values are submitted for each event.
  rows.forEach(row => {
    const [deletedEvents, otherEvents] = partition(row.events, '_destroy')

    deletedEvents.forEach(event => events.push(event))

    if (otherEvents.length === 0) return

    sortBy(otherEvents, 'column').forEach((event, i) => {
      events.push({
        ...event,
        column: i,
        row: rowIndex,
      })
    })

    rowIndex++
  })

  return (
    <>
      {events.map((event, i) => (
        <Fragment key={i}>
          {event.id ? (
            <input
              name={`grading_session_template[session_template_timelines_attributes][${i}][id]`}
              type="hidden"
              value={event.id}
            />
          ) : null}
          {event._destroy ? (
            <input
              name={`grading_session_template[session_template_timelines_attributes][${i}][_destroy]`}
              type="hidden"
              value="1"
            />
          ) : (
            <>
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][activity_id]`}
                type="hidden"
                value={event.activityId || ''}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][category_ac]`}
                type="hidden"
                value={event.categoryAc}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][category_nav]`}
                type="hidden"
                value={event.categoryNav}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][category_training]`}
                type="hidden"
                value={event.categoryTraining}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][category_weather]`}
                type="hidden"
                value={event.categoryWeather}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][column]`}
                type="hidden"
                value={event.column}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][description]`}
                type="hidden"
                value={event.description}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][estimated_time]`}
                type="hidden"
                value={event.estimatedTime}
              />
              <input
                name={`grading_session_template[session_template_timelines_attributes][${i}][row]`}
                type="hidden"
                value={event.row}
              />
            </>
          )}
        </Fragment>
      ))}
    </>
  )
}
