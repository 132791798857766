// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scaleTime } from 'd3-scale'
import moment from 'moment'

import roleProps from 'proptypes/roleProps'

import RoleQualificationPlot from './RoleQualificationPlot'
import RoleQualificationRowHeadings from './RoleQualificationRowHeadings'
import ScaleZoomer from './ScaleZoomer'

const TODAY = new Date()

const BEGINNING_OF_TIME = moment(TODAY).subtract(10, 'years').toDate()
const END_OF_TIME = moment(TODAY).add(10, 'years').endOf('day').toDate()
const MAX_SCALE_FACTOR = 1000
const MIN_SCALE_FACTOR = 20
const MIN_PLOT_WIDTH = 500
const PIXELS_PER_DAY = 0.02

const NUMBER_OF_DAYS_TO_DISPLAY = moment(END_OF_TIME).diff(
  BEGINNING_OF_TIME,
  'days'
)

class RoleQualificationHistory extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape(roleProps)).isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      scaleFactor: 100,
    }
  }

  handleScaleFactorChange = value => {
    this.setState({ scaleFactor: value })
  }

  render() {
    const { roles, t } = this.props

    const { scaleFactor } = this.state

    const rangeStart = 0
    const rangeEnd = Math.max(
      MIN_PLOT_WIDTH,
      NUMBER_OF_DAYS_TO_DISPLAY * PIXELS_PER_DAY * scaleFactor
    )

    const scale = scaleTime()
      .domain([BEGINNING_OF_TIME, END_OF_TIME])
      .range([rangeStart, rangeEnd])

    return (
      <div className="role-qualification-history">
        <div className="role-qualification-history__controls">
          <ScaleZoomer
            min={MIN_SCALE_FACTOR}
            max={MAX_SCALE_FACTOR}
            onScaleChange={this.handleScaleFactorChange}
            scaleFactor={scaleFactor}
          />
        </div>

        <div className="role-qualification-history__calendar">
          <RoleQualificationRowHeadings roles={roles} />

          <RoleQualificationPlot roles={roles} scale={scale} t={t} />
        </div>
      </div>
    )
  }
}

export default RoleQualificationHistory
