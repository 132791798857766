// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GRADE_CATEGORIES } from '../../src/lib/gradingScaleUtils'
import WarningIcon from '@material-ui/icons/Warning'
import {
  isOverallGradingActive,
  overallPositiveResultFailConditions,
} from '../../src/lib/constantsHelper'
import { getIdb } from '../../src/lib/idb/common'
import { setOverallPositiveResult } from '../../redux/app'

class OverallGradingResult extends Component {
  static propTypes = {
    gradingSessionResult: PropTypes.object.isRequired,
    setOverallPositiveResult: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.conditions = overallPositiveResultFailConditions()
    this.active = isOverallGradingActive()

    this.state = {
      showWarning: false,
      showPleaseChoose: false,
    }
  }

  componentDidMount = async () => {
    const {
      gradingSessionResult: { gradingSessionId, overallPositiveResult },
    } = this.props
    const sessionTriedToSubmit = await getIdb(
      'unsubmitted_sessions',
      gradingSessionId
    )
    if (sessionTriedToSubmit && overallPositiveResult === null)
      this.setState({ showPleaseChoose: true })
  }

  componentDidUpdate = () => {
    if (this.props.gradingSessionResult.overallPositiveResult !== null)
      this.checkResult()
  }

  optionSelected(positive) {
    const {
      gradingSessionResult: { id, gradingSessionId },
      setOverallPositiveResult,
    } = this.props

    setOverallPositiveResult(gradingSessionId, id, positive)

    this.setState({ showPleaseChoose: false })
  }

  checkResult() {
    if (!this.active) return
    const {
      gradingSessionResult: { gradings, id, overallPositiveResult },
    } = this.props

    const { showWarning } = this.state

    const failConditions = JSON.parse(this.conditions)

    let results = {}
    gradings.map(g => {
      if (g.gradingSessionResultId === id)
        results[g.category] = (results[g.category] || 0) + 1
    })

    let positiveResult = true
    GRADE_CATEGORIES.map(category => {
      if (failConditions[category] <= results[category]) positiveResult = false
    })

    if (showWarning != (overallPositiveResult != positiveResult))
      this.setState({ showWarning: overallPositiveResult != positiveResult })
  }

  render() {
    if (!this.active) return null

    const {
      gradingSessionResult: { overallPositiveResult },
      translations,
    } = this.props

    const { showWarning, showPleaseChoose } = this.state

    const optionClass = 'overall-grading-result__option'
    const negativeOption =
      UBF.overallGradingNegativeOption ||
      translations.forms.labels.gradingSessionResult.overallResult.negative
    const positiveOption =
      UBF.overallGradingPositiveOption ||
      translations.forms.labels.gradingSessionResult.overallResult.positive

    return (
      <div className="overall-grading-result">
        <div className="overall-grading-result__label">
          {
            translations.activerecord.attributes.gradingSessionResult
              .overallPositiveResult
          }
        </div>
        {showWarning ? (
          <span className="overall-grading-result__warning">
            <WarningIcon />
            {
              translations.messages.gradingSessionResults
                .overallPositiveResultMismatch
            }
          </span>
        ) : null}
        {showPleaseChoose ? (
          <span className="overall-grading-result__warning">
            <WarningIcon />
            {
              translations.messages.gradingSessionResults
                .overallPositiveResultMissing
            }
          </span>
        ) : null}
        <hr className="grade-input-wrapper-orca--line-tittle" />
        <div
          className={`${optionClass} ${optionClass}--${
            overallPositiveResult === false ? 'selected' : 'inactive'
          } ${optionClass}--negative`}
          onClick={() => this.optionSelected(false)}
        >
          {negativeOption}
        </div>
        <div
          className={`${optionClass} ${optionClass}--${
            overallPositiveResult === true ? 'selected' : 'inactive'
          } ${optionClass}--positive`}
          onClick={() => this.optionSelected(true)}
        >
          {positiveOption}
        </div>
      </div>
    )
  }
}

export default connect(null, { setOverallPositiveResult })(OverallGradingResult)
