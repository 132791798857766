// Copyright Northcote Technology Ltd
import { RESOLVED_GET_PERSON_ITEM } from '../actionTypes'

const person = (
  state = { info: null, showUpdateStatusModal: false },
  action
) => {
  switch (action.type) {
    case RESOLVED_GET_PERSON_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default person
