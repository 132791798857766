// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class QualificationHierarchyForm extends Component {
  static propTypes = {
    authenticity_token: PropTypes.string.isRequired,
    changesNotSavedWarning: PropTypes.string.isRequired,
    onUndo: PropTypes.func.isRequired,
    qualificationChanges: PropTypes.arrayOf(PropTypes.object).isRequired,
    savePath: PropTypes.string.isRequired,
    saveText: PropTypes.string.isRequired,
    undoText: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.startWarningAboutLosingChanges()
  }

  componentWillUnmount() {
    this.stopWarningAboutLosingChanges()
  }

  handleSubmitChanges = () => this.stopWarningAboutLosingChanges()

  render() {
    const {
      authenticity_token,
      onUndo,
      qualificationChanges,
      savePath,
      saveText,
      undoText,
    } = this.props

    const changeInputs = qualificationChanges.map(qualification => {
      const { id, parent_id } = qualification
      return (
        <input
          type="hidden"
          key={id}
          name={`qualifications[${id}][parent_id]`}
          id={`qualifications_${id}_parent_id`}
          value={parent_id || ''}
        />
      )
    })

    return (
      <form
        action={savePath}
        acceptCharset="UTF-8"
        className="qualification-hierarchy-form"
        method="post"
        onSubmit={this.handleSubmitChanges}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value="patch" />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />

        {changeInputs}

        <footer>
          <button className="btn delete-btn" type="button" onClick={onUndo}>
            {undoText}
          </button>

          <input
            type="submit"
            name="commit"
            value={saveText}
            className="btn submit-btn right"
          />
        </footer>
      </form>
    )
  }

  startWarningAboutLosingChanges() {
    window.addEventListener('beforeunload', this.warnBeforeLosingChanges)
  }

  stopWarningAboutLosingChanges() {
    window.removeEventListener('beforeunload', this.warnBeforeLosingChanges)
  }

  warnBeforeLosingChanges = evt => {
    const { changesNotSavedWarning, qualificationChanges } = this.props

    if (qualificationChanges.length > 0) {
      evt.preventDefault()
      const confirmationMessage = changesNotSavedWarning
      evt.returnValue = confirmationMessage
    }
  }
}
