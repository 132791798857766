// Copyright Northcote Technology Ltd
import React from 'react'

import Button from './Button'
import Icon from './Icon'

export default function IconButton({ icon, ...rest }) {
  return (
    <Button noWidth {...rest}>
      <Icon icon={icon} />
    </Button>
  )
}
