// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import gradingScaleProps from '../proptypes/gradingScaleProps'

import GradeOption from './GradeOption'
import GradingCounter from './GradingCounter'

import nameToId from 'src/lib/nameToId'

export default class GradeInput extends Component {
  static propTypes = {
    grade: PropTypes.string,
    repeated: PropTypes.bool,
    deferred: PropTypes.bool,
    deferable: PropTypes.bool,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    gradeName: PropTypes.string.isRequired,
    repeatedName: PropTypes.string.isRequired,
    deferredName: PropTypes.string.isRequired,
    counterName: PropTypes.string.isRequired,
    counter: PropTypes.number,
    onGradeUpdate: PropTypes.func.isRequired,
    onRepeatedUpdate: PropTypes.func,
    onDeferredUpdate: PropTypes.func,
    onCounterUpdate: PropTypes.func.isRequired,
    refApplicableUntilDate: PropTypes.object,
    orca: PropTypes.bool,
    gradingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideRepeated: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.refGradeOption = React.createRef()
  }

  state = {
    categoriesSelectedForHide: ['failing', 'disqualifying', 'other'],
    optionsForGrading: [],
  }

  onCounterUpdate = counterValue => {
    const { onCounterUpdate } = this.props

    if (onCounterUpdate) {
      onCounterUpdate(counterValue)
    }
  }

  renderGrades(values, category) {
    const {
      grade,
      gradeName,
      onGradeUpdate,
      orca,
      gradingId,
      disabled,
      deferable,
    } = this.props

    return values.map(value => {
      const id = `${gradingId}_${nameToId(gradeName)}_${value.replace(
        /\W+/,
        '_'
      )}`
      const checked = value === grade

      this.state.optionsForGrading.push({
        category: category,
        checked: checked,
      })

      return (
        <GradeOption
          key={id}
          category={category}
          id={id}
          isChecked={checked}
          isMultiple={true}
          isUnselectable={deferable}
          name={gradeName}
          onUpdate={onGradeUpdate}
          value={value}
          orca={orca}
          ref={this.refGradeOption}
          refApplicableUntilDate={this.props.refApplicableUntilDate}
          disabled={disabled}
        />
      )
    })
  }

  renderRepeated() {
    const { disabled, onRepeatedUpdate, repeated, repeatedName, orca } =
      this.props

    return (
      <GradeOption
        category="repeat"
        disabled={disabled}
        id={nameToId(repeatedName)}
        isChecked={repeated || false}
        name={repeatedName}
        onUpdate={onRepeatedUpdate}
        value={'R'}
        orca={orca}
        ref={this.refGradeOption}
        refApplicableUntilDate={this.props.refApplicableUntilDate}
      />
    )
  }

  renderDefer() {
    const { onDeferredUpdate, deferred, deferredName, orca } = this.props

    return (
      <GradeOption
        category="defer"
        id={nameToId(deferredName)}
        isChecked={deferred || false}
        name={deferredName}
        onUpdate={onDeferredUpdate}
        value={'Defer'}
        orca={orca}
        ref={this.refGradeOption}
        disabled={false}
      />
    )
  }

  render() {
    const {
      gradingScale: {
        allowRepeats,
        otherGrades,
        disqualifyingGrades,
        failingGrades,
        minimumAcceptableGrades,
        acceptableGrades,
        exemplaryGrades,
        addCounter,
        minCounter,
        maxCounter,
        defaultCounter,
      },
      counterName,
      counter,
      orca,
      hideRepeated,
      deferable,
      gradeName,
      grade,
    } = this.props

    return (
      <div className="grade-input__container">
        {!grade ? ( // Allow nullify a grade
          <input type="hidden" name={gradeName} value="" />
        ) : null}

        <div className={'grade-input'}>
          {this.renderGrades(otherGrades, 'other')}
          {this.renderGrades(disqualifyingGrades, 'disqualifying')}
          {this.renderGrades(failingGrades, 'failing')}
          {this.renderGrades(minimumAcceptableGrades, 'minimum_acceptable')}
          {this.renderGrades(acceptableGrades, 'acceptable')}
          {this.renderGrades(exemplaryGrades, 'exemplary')}
          {allowRepeats && !hideRepeated ? this.renderRepeated() : null}
          {deferable && this.renderDefer()}
        </div>

        {addCounter ? (
          <GradingCounter
            counterName={counterName}
            minCounter={minCounter}
            maxCounter={maxCounter}
            defaultCounter={defaultCounter}
            currentCounter={counter}
            onUpdate={this.onCounterUpdate}
            orca={orca}
          />
        ) : null}
      </div>
    )
  }

  checkApplicableUntilDate() {
    if (this.canHideApplicableUntilDate()) {
      this.refGradeOption.current.hideApplicableUntilDate()
    }
  }

  canHideApplicableUntilDate() {
    const { optionsForGrading, categoriesSelectedForHide } = this.state

    return optionsForGrading.some(
      i => categoriesSelectedForHide.includes(i.category) && i.checked
    )
  }
}
