// Copyright Northcote Technology Ltd
import React, { useContext, useState } from 'react'
import { orderBy } from 'lodash'
import { useDispatch } from 'react-redux'

import TranslationsContext from './contexts/Translations'
import { createRecording } from '../redux/app'

import ApplyRecordingObs from './ApplyRecordingObs'
import Button from './Button'
import IconAction from './IconAction'
import Modal2 from './Modal2'
import RecordingCard from './RecordingCard'
import RecordingForm from './RecordingForm'

export default function Recordings({ session }) {
  const translations = useContext(TranslationsContext)
  const [editMode, setEditMode] = useState(false)
  const [showTransferObs, setShowTransferObs] = useState(false)
  const dispatch = useDispatch()
  const handleCreateRecording = async recording => {
    await dispatch(createRecording(session.id, recording))
    setEditMode(false)
  }
  const sortedRecordings = orderBy(session.recordings, 'createdAt', 'desc')
  const showApplyObsButton =
    UBF.featureApplyRecordingObs && !session.gradingSessionTemplate.gradeAsCrew

  return (
    <div className="recordings">
      {showTransferObs ? (
        <Modal2 full={true}>
          <ApplyRecordingObs
            onCancel={() => setShowTransferObs(false)}
            onSave={() => setShowTransferObs(false)}
            session={session}
          />
        </Modal2>
      ) : null}

      {editMode ? (
        <RecordingForm
          initialState={{
            activityId: null,
            comments: '',
            gradingSessionId: session.id,
            recordingTems: [],
            recordingPersonBehaviours: [],
          }}
          onCancel={() => setEditMode(false)}
          onSave={handleCreateRecording}
          session={session}
        />
      ) : (
        <div className="recordings__buttons">
          {showApplyObsButton ? (
            <Button
              disabled={sortedRecordings.length === 0}
              onClick={() => setShowTransferObs(true)}
            >
              {translations.ubf.apply_to_gradings}
            </Button>
          ) : null}
          <IconAction
            color="light-green"
            icon="plus-circle"
            onClick={() => setEditMode(true)}
            size="30px"
          >
            {translations.forms.placeholders.recordings.add_card}
          </IconAction>
        </div>
      )}

      {sortedRecordings.map(recording => (
        <RecordingCard
          key={recording.id}
          recording={recording}
          session={session}
        />
      ))}
    </div>
  )
}
