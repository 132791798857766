// Copyright Northcote Technology Ltd
import {
  RESOLVED_GET_MY_SETTINGS_ITEM,
  UPDATE_MY_SETTINGS_ITEM,
  UPDATE_MY_SETTINGS_TABLE,
} from '../actionTypes'

const mySetting = (state = { info: null, data: null }, action) => {
  switch (action.type) {
    case RESOLVED_GET_MY_SETTINGS_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case UPDATE_MY_SETTINGS_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case UPDATE_MY_SETTINGS_TABLE: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default mySetting
