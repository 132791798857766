// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import DirectUploadButton from './DirectUploadButton'
import React, { useState } from 'react'

const FileAttachment = ({ file, onRemove, confirmMsg }) => {
  const handleRemove = () => {
    if (confirm(confirmMsg)) {
      onRemove(file)
    }
  }

  return (
    <div className={'grading-file-attachment'}>
      <span>{file.name}</span>{' '}
      <button
        className="icon icon--trash"
        onClick={handleRemove}
        type="button"
      />
    </div>
  )
}

function FileUploader({
  name,
  directUploadsUrl,
  buttonText,
  allowedTypes,
  remove,
  removeConfirm,
  removeName,
  value,
}) {
  const [file, setFile] = useState(remove ? null : value)
  const [destroy, setDestroy] = useState(remove)

  const handleFileUpload = (error, blob) => {
    if (error) {
      console.error(error)
      return
    }

    setFile({
      signedId: blob.signed_id,
      name: blob.filename,
    })
    setDestroy(false)
  }

  const handleRemoveFile = () => {
    setFile(null)
    setDestroy(true)
  }

  return (
    <>
      {file ? (
        <FileAttachment
          file={file}
          onRemove={handleRemoveFile}
          confirmMsg={removeConfirm}
        />
      ) : (
        <>
          <DirectUploadButton
            name={name}
            directUploadsUrl={directUploadsUrl}
            onUpload={handleFileUpload}
            allowedTypes={allowedTypes}
            additionalClassName={'ui-btn'}
          >
            <div className="direct-upload-button--admin">
              <span className="icon icon--picture mr5" />
              {buttonText}
            </div>
          </DirectUploadButton>
        </>
      )}
      {file ? <input name={name} type="hidden" value={file.signedId} /> : null}
      {destroy ? <input name={removeName} type="hidden" value="1" /> : null}
    </>
  )
}

FileUploader.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string.isRequired,
  directUploadsUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.string,
  removeConfirm: PropTypes.string.isRequired,
  removeName: PropTypes.string.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    signedId: PropTypes.string.isRequired,
  }),
}

export default FileUploader
