// Copyright Northcote Technology Ltd
import { isEqual } from 'lodash'

export default function mergeObjects(objects) {
  const merged = {}
  objects.forEach(object => {
    Object.keys(object).forEach(attr => {
      const notSetOrEqual =
        merged[attr] === undefined || isEqual(merged[attr], object[attr])

      const isObjectAttr =
        typeof merged[attr] === 'object' && typeof object[attr] === 'object'

      merged[attr] = notSetOrEqual
        ? object[attr]
        : isObjectAttr
        ? mergeObjects([merged[attr], object[attr]])
        : null
    })
  })
  return merged
}
