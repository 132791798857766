// Copyright Northcote Technology Ltd
import React, { Fragment } from 'react'

export default function simpleFormat(str) {
  return str.split(/\n/).map((line, i) => (
    <Fragment key={i}>
      {i > 0 ? <br /> : null}
      {line}
    </Fragment>
  ))
}
