// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    buttonSave: PropTypes.bool,
    onSaveModal: PropTypes.func,
    children: PropTypes.node,
    onCloseModal: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  saveModal = () => {
    const { onSaveModal } = this.props

    onSaveModal()
  }

  closeModal = () => {
    const { onCloseModal } = this.props

    onCloseModal()
  }

  render() {
    const { translations, visible, children, title } = this.props
    const showHideClassName = visible ? 'modal modal-block' : 'modal modal-none'

    return (
      <div className={showHideClassName}>
        <div className="modal-main">
          <div className="modal-header">
            <h3>{title}</h3>
          </div>
          <div className="modal-content">{children}</div>
          <div className="modal-footer">
            <Button onClick={this.closeModal}>{translations.ubf.cancel}</Button>
            {this.props.buttonSave ? (
              <Button onClick={this.saveModal}>{translations.ubf.save}</Button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
