// Copyright Northcote Technology Ltd
const COLOURS = {
  GRAY: '#969799',
  DARK_GRAY: '#484849',
  SUPER_DARK_GRAY: '#2c2c2c',
  DARKER_GRAY: '#222222',
  DARKEST_GRAY: '#111111',
  BLACK: '#1a1a1a',
}

const FONT = "'Folio-Lig', 'sans-serif'"

export const muiStyles = {
  palette: { type: 'dark' },
  typography: {
    fontFamily: FONT,
    fontStyle: 'normal',
    textTransform: 'none',
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
      },
    },
    MuiTable: {
      root: {
        width: '100%',
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
        border: 0,
      },
    },
    MuiIconButton: {
      root: {
        color: COLOURS.GRAY,
      },
    },
    MUIDataTableSearch: {
      clearIcon: {
        display: 'none',
      },
      searchIcon: {
        display: 'none',
      },
      searchText: {
        borderRadius: '5px',
      },
    },
    MUIDataTable: {
      root: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
      },
      paper: {
        boxShadow: 'none',
        height: '100%',
        padding: '10px',
      },
    },
    MuiGridListTile: {
      root: {
        width: '100% !important',
        padding: '0 15px !important',
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
        fontWeight: 'bold',
      },
    },
    MuiToolbar: {
      regular: {
        borderRadius: '5px 5px 0 0',
      },
      root: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: COLOURS.GRAY,
      },
    },
    MuiSelect: {
      icon: {
        color: COLOURS.GRAY,
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: FONT,
        padding: '15px',
        fontSize: '17px',
      },
      head: {
        color: COLOURS.GRAY,
        fontSize: '20px',
      },
      body: {
        color: COLOURS.GRAY,
      },
    },
    MuiDataTableBodyCell: {
      root: {
        fontFamily: FONT,
        fontSize: '17px !important',
      },
      head: {
        color: COLOURS.GRAY,
        fontSize: '20px',
      },
      body: {
        color: COLOURS.GRAY,
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: COLOURS.SUPER_DARK_GRAY + ' !important',
        color: COLOURS.GRAY,
        '&$hover:hover': {
          backgroundColor: COLOURS.DARK_GRAY + ' !important',
          color: COLOURS.WHITE + ' !important',
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 30px) scale(1)',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 0,
        },
        '&:before': {
          borderBottom: 0,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 0,
        },
      },
    },
    MUIDataTableFilter: {
      title: {
        color: COLOURS.GRAY,
        fontSize: '20px',
        marginLeft: '230px',
      },
      root: {
        fontfamily: FONT,
      },
      resetLink: {
        float: 'left !important',
        marginLeft: '0 !important',
        minWidth: 'unset !important',
        backgroundColor: COLOURS.GRAY + '!important',
        borderRadius: '20px !important',
        padding: '3px !important',
        color: COLOURS.BLACK + '!important',
      },
    },
    MuiTableFooter: {
      root: {
        display: 'block',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: COLOURS.SUPER_DARK_GRAY,
      },
    },
  },
}
