// Copyright Northcote Technology Ltd
import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

import RoleQualificationRow from './RoleQualificationRow'
import TodayLine from './TodayLine'
import DateLine from './DateLine'

const MIN_DATELINE_SEPARATION = 200

class RoleQualificationPlot extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      plotWidth: 1000,
    }

    this.plot = createRef()
    this.scrollArea = createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePlotWidth)
    this.updatePlotWidth
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePlotWidth)
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.scale !== this.props.scale) {
      const plot = this.plot.current
      const plotMidPoint = plot.clientWidth
      const scrollArea = this.scrollArea.current
      const scrollAreaWidth = scrollArea.clientWidth
      return {
        proportionScrolled: (plot.scrollLeft + plotMidPoint) / scrollAreaWidth,
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const plot = this.plot.current
      const plotMidPoint = plot.clientWidth
      const scrollArea = this.scrollArea.current
      const scrollAreaWidth = scrollArea.clientWidth
      plot.scrollLeft =
        snapshot.proportionScrolled * scrollAreaWidth - plotMidPoint
    }
  }

  render() {
    const { roles, scale, t } = this.props

    const { plotWidth } = this.state

    const rangeStart = scale.range()[0]
    const rangeEnd = scale.range()[1]

    const numberOfDateLinesVisible = Math.ceil(
      plotWidth / MIN_DATELINE_SEPARATION
    )

    const numberOfDateLines =
      (numberOfDateLinesVisible * (rangeEnd - rangeStart)) / plotWidth

    const channels = roles.map(role => {
      return (
        <RoleQualificationRow key={role.id} role={role} scale={scale} t={t} />
      )
    })

    const dateLines = scale.ticks(numberOfDateLines).map(date => {
      return <DateLine key={date} date={date} scale={scale} />
    })

    return (
      <div className="role-qualification-plot" ref={this.plot}>
        <div
          className="role-qualification-plot__scrollArea"
          ref={this.scrollArea}
        >
          {dateLines}
          <TodayLine t={t} scale={scale} />
          {channels}
        </div>
      </div>
    )
  }

  updatePlotWidth = () => {
    this.setState({ plotWidth: this.plot.current.clientWidth })
  }
}

export default RoleQualificationPlot
