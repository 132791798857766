// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import snakeCase from 'src/lib/snakeCase'
import FlagLabel from './FlagLabel'

class PeriodBoundary extends Component {
  static propTypes = {
    alignment: PropTypes.oneOf(['bottom', 'top']).isRequired,
    boundaryType: PropTypes.oneOf([
      'earlyInvalidation',
      'period',
      'revalidationPeriod',
      'validPeriod',
    ]).isRequired,
    flagDirection: PropTypes.oneOf(['left', 'right']).isRequired,
    flagPoleLength: PropTypes.number,
    flagTextLines: PropTypes.arrayOf(PropTypes.string).isRequired,
    isDoubleHeaded: PropTypes.bool,
    isFlagVisible: PropTypes.bool,
    protrusionSize: PropTypes.number.isRequired,
  }

  render() {
    const {
      alignment,
      boundaryType,
      flagDirection,
      flagPoleLength,
      flagTextLines,
      isDoubleHeaded,
      isFlagVisible,
      protrusionSize,
    } = this.props

    const classes = classNames({
      'period-boundary': true,
      [`period-boundary--${alignment}`]: true,
      [`period-boundary--${flagDirection}`]: true,
      [`period-boundary--double-headed`]: isDoubleHeaded,
      [`period-boundary--flag-showing`]: isFlagVisible,
      [`period-boundary--${snakeCase(boundaryType)}`]: true,
    })

    const protrusion = isDoubleHeaded ? 2 * protrusionSize : protrusionSize

    const top = alignment === 'top' ? -protrusionSize : null
    const bottom = alignment === 'bottom' ? -protrusionSize : null

    return (
      <div
        className={classes}
        style={{
          bottom,
          height: `calc(100% + ${protrusion}px)`,
          top,
        }}
      >
        <div className="period-boundary__line"></div>
        <FlagLabel
          verticalAlignment={alignment}
          flagPoleLength={flagPoleLength}
          isVisible={isFlagVisible}
          pointing={flagDirection}
          textLines={flagTextLines}
        />
      </div>
    )
  }
}

export default PeriodBoundary
