// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import localizedDate from 'src/lib/localizedDate'
import PeriodBoundary from './PeriodBoundary'
import { AWARD_TIMELINE_HEIGHT, AWARD_TIMELINE_PADDING } from './AwardTimeline'

import awardProps from 'proptypes/awardProps'

const LONG_POLE_LENGTH = 30

class AwardPeriod extends Component {
  static propTypes = {
    award: PropTypes.shape(awardProps).isRequired,
    isGhost: PropTypes.bool.isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ isActive: true })
  }

  handleMouseLeave = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { award, isGhost, scale, t } = this.props

    const { isActive } = this.state

    const {
      awardedDate,
      didInvalidateEarly,
      lapsesDate,
      revalidationPeriodStartsDate,
      validFromDate,
      validUntilDate,
    } = award

    const awardedMoment = moment(awardedDate).startOf('day').toDate()
    const revalidationPeriodStartsMoment = moment(revalidationPeriodStartsDate)
      .startOf('day')
      .toDate()
    const lapsesMoment = moment(lapsesDate).endOf('day').toDate()
    const validFromMoment = moment(validFromDate).startOf('day').toDate()
    const validUntilMoment = moment(validUntilDate).endOf('day').toDate()

    const awardedPosition = scale(awardedMoment)
    const revalidationPeriodStartsPosition = scale(
      revalidationPeriodStartsMoment
    )
    const lapsesPosition = scale(lapsesMoment)
    const validFromPosition = scale(validFromMoment)
    const validUntilPosition = scale(validUntilMoment)

    const localizedAwardedDate = localizedDate(awardedMoment)
    const localizedRevalidationPeriodStartsDate = localizedDate(
      revalidationPeriodStartsMoment
    )
    const localizedLapsesDate = localizedDate(lapsesMoment)
    const localizedValidFromDate = localizedDate(validFromMoment)
    const localizedValidUntilDate = localizedDate(validUntilMoment)

    const hasRevalidationPeriod = lapsesMoment > revalidationPeriodStartsMoment

    const revalidationPeriod = hasRevalidationPeriod ? (
      <div
        className={classNames({
          'award-period__revalidation-period': true,
        })}
        style={{
          left: revalidationPeriodStartsPosition - validFromPosition,
          height: AWARD_TIMELINE_HEIGHT,
          width: lapsesPosition - revalidationPeriodStartsPosition,
        }}
      >
        <PeriodBoundary
          alignment="bottom"
          boundaryType="revalidationPeriod"
          flagDirection="left"
          flagPoleLength={LONG_POLE_LENGTH}
          flagTextLines={[
            t('activerecord.attributes.award.revalidationPeriodStartsDate'),
            localizedRevalidationPeriodStartsDate,
          ]}
          isFlagVisible={isActive}
          isDoubleHeaded={false}
          protrusionSize={AWARD_TIMELINE_PADDING}
        />

        <PeriodBoundary
          alignment="bottom"
          boundaryType="revalidationPeriod"
          flagDirection="right"
          flagPoleLength={LONG_POLE_LENGTH}
          flagTextLines={[
            t('activerecord.attributes.award.lapsesDate'),
            localizedLapsesDate,
          ]}
          isFlagVisible={isActive}
          isDoubleHeaded={true}
          protrusionSize={AWARD_TIMELINE_PADDING}
        />
      </div>
    ) : null

    const validForPeriodHeight = hasRevalidationPeriod
      ? AWARD_TIMELINE_HEIGHT / 2
      : AWARD_TIMELINE_HEIGHT

    const awardedOffset = awardedPosition - validFromPosition
    const validAfterAwarded = awardedOffset < 0

    return (
      <div
        className={classNames({
          'award-period': true,
          'award-period--ghost': isGhost,
        })}
        data-awarded={localizedAwardedDate}
        data-lapses={localizedLapsesDate}
        data-revalidation-period-starts={localizedRevalidationPeriodStartsDate}
        data-valid-from={localizedValidFromDate}
        data-valid-until={localizedValidUntilDate}
        style={{
          height: AWARD_TIMELINE_HEIGHT,
          left: validFromPosition,
          width: lapsesPosition - validFromPosition,
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={classNames({
            'award-period__pre-revalidation-period': true,
          })}
          style={{
            height: AWARD_TIMELINE_HEIGHT,
            width: validUntilPosition - validFromPosition,
          }}
        ></div>

        <div
          className={classNames({
            'award-period__awarded-date': true,
            'award-period__awarded-date--before-valid-for': validAfterAwarded,
          })}
          style={{
            left: awardedOffset,
            height: 1,
            width: validAfterAwarded ? Math.abs(awardedOffset) : 1,
          }}
        >
          <PeriodBoundary
            alignment="bottom"
            boundaryType={'validPeriod'}
            flagDirection="left"
            flagTextLines={[
              t('activerecord.attributes.award.awardedDate'),
              localizedAwardedDate,
            ]}
            isFlagVisible={isActive}
            isDoubleHeaded={false}
            protrusionSize={AWARD_TIMELINE_PADDING}
          />
        </div>

        {revalidationPeriod}

        <div
          className={classNames({
            'award-period__valid-for-period': true,
          })}
          style={{
            height: validForPeriodHeight,
            width: validUntilPosition - validFromPosition,
          }}
        >
          <PeriodBoundary
            alignment="top"
            boundaryType={'validPeriod'}
            flagDirection="left"
            flagTextLines={[
              t('activerecord.attributes.award.validFromDate'),
              localizedValidFromDate,
            ]}
            isFlagVisible={isActive}
            isDoubleHeaded={false}
            protrusionSize={AWARD_TIMELINE_PADDING}
          />

          <PeriodBoundary
            alignment="top"
            boundaryType={
              didInvalidateEarly ? 'earlyInvalidation' : 'validPeriod'
            }
            flagDirection="right"
            flagTextLines={[
              t('activerecord.attributes.award.validUntilDate'),
              localizedValidUntilDate,
            ]}
            isFlagVisible={isActive}
            isDoubleHeaded={false}
            protrusionSize={AWARD_TIMELINE_PADDING}
          />
        </div>
      </div>
    )
  }
}

export default AwardPeriod
