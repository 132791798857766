// Copyright Northcote Technology Ltd
import React from 'react'
import classnames from 'classnames'

export default function Button(props) {
  const { children, cursor, noWidth, w100, transparent, variant, ...rest } =
    props
  const className = classnames({
    'ui-btn': true,
    'ui-btn--dark': variant === 'dark',
    'ui-btn--no-width': !!noWidth,
    'ui-btn--w100': !!w100,
    'ui-btn--transparent': !!transparent,
  })
  const style = { cursor }

  return (
    <button className={className} style={style} type="button" {...rest}>
      {children}
    </button>
  )
}
