// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class DateInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onUpdate: PropTypes.func,
  }

  state = {
    additionalClass: '',
  }

  onChange = e => {
    const { onUpdate } = this.props

    if (onUpdate) {
      const value = e.currentTarget.value
      onUpdate(value)
    }
  }

  render() {
    const { label, name, value } = this.props

    return (
      <div className={'date-input ' + this.state.additionalClass}>
        <label htmlFor={name}>{label}</label>
        <input
          type="date"
          id={name}
          name={name}
          defaultValue={value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}
