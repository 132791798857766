// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { snakeCase, upperFirst } from 'lodash'

import nameToId from 'src/lib/nameToId'
import AutoExpandingTextarea from './AutoExpandingTextarea'

function nameToLabel(name) {
  return upperFirst(snakeCase(name).replace(/_/g, ' '))
}

export default class GradingAdditionalInfoFields extends Component {
  static propTypes = {
    additionalInfo: PropTypes.object.isRequired,
    fieldNames: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      info: props.additionalInfo,
    }
  }

  handleChange(fieldName, value) {
    this.setState({
      info: { ...this.props.additionalInfo, [fieldName]: value },
    })
    this.handleUpdate(fieldName, value)
  }

  handleUpdate = (fieldName, value) => {
    const { onUpdate, additionalInfo } = this.props
    onUpdate({ ...additionalInfo, [fieldName]: value })
  }

  render() {
    const { additionalInfo, fieldNames, hidden } = this.props

    const { info } = this.state

    const additionalInfoFieldNames = Object.keys(info)

    const input = hidden
      ? additionalInfoFieldNames.sort().map(fieldName => {
          const htmlId = nameToId(fieldNames[fieldName] || fieldName)

          return (
            <input
              key={htmlId}
              id={htmlId}
              type="hidden"
              name={fieldNames[fieldName]}
              value={additionalInfo[fieldName] || ''}
            />
          )
        })
      : additionalInfoFieldNames.sort().map(fieldName => {
          const htmlId = nameToId(fieldNames[fieldName])

          return (
            <AutoExpandingTextarea
              className="grading-additional-info-fields__textarea"
              key={htmlId}
              id={htmlId}
              rows="1"
              name={fieldNames[fieldName]}
              onChange={e => this.handleChange(fieldName, e.target.value)}
              placeholder={nameToLabel(fieldName)}
              value={info[fieldName] || ''}
            />
          )
        })

    return <div className="grading-additional-info-fields">{input}</div>
  }
}
