// Copyright Northcote Technology Ltd
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { bottomButtonClicked } from '../redux/actions'

import Button from './Button'
import Logo from '../../assets/images/quantum_logo.png'

export function FooterButton({ children, dark, onClick }) {
  return (
    <Button onClick={onClick} variant={dark ? 'dark' : null}>
      {children}
    </Button>
  )
}

function LogoAndLink() {
  const {
    customLogoUrl,
    customPrivacyLink,
    customPrivacyLinkText,
    customLink,
    customLinkText,
  } = UBF

  return (
    <div>
      <div className="quantum-layout__footer--duo">
        {customLogoUrl ? (
          <div className="quantum-layout__footer--custom_logo-wrapper">
            <img
              className="quantum-layout__footer--custom_logo"
              src={customLogoUrl}
            />
          </div>
        ) : (
          <div className="quantum-layout__footer--custom_logo-wrapper">
            <img className="quantum-layout__footer--logo" src={Logo} />
          </div>
        )}
        <span className="quantum-layout__footer--version">{UBF.version}</span>
      </div>
      <div className="quantum-layout__footer--links">
        {customPrivacyLink && (
          <a
            href={customPrivacyLink}
            className="link"
            rel="noopener noreferrer"
            target="_blank"
          >
            {customPrivacyLinkText}
          </a>
        )}
        {customLink && (
          <a
            href={customLink}
            className="link"
            rel="noopener noreferrer"
            target="_blank"
          >
            {customLinkText}
          </a>
        )}
      </div>
    </div>
  )
}

export default function Footer({ controlledFooterButtons }) {
  const dispatch = useDispatch()
  const bottomButtons = useSelector(store => store.bottomButtons.buttons)

  let buttons

  if (controlledFooterButtons) {
    buttons = controlledFooterButtons
  } else if (bottomButtons) {
    buttons = bottomButtons.map((text, index) => (
      <FooterButton
        dark={index % 2 === 0}
        key={text}
        onClick={() => dispatch(bottomButtonClicked(text))}
      >
        {text}
      </FooterButton>
    ))
  }

  return (
    <div className="quantum-layout__footer">
      <LogoAndLink />
      <div className="quantum-layout__footer--buttons">{buttons}</div>
    </div>
  )
}
