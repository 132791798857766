// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'

import awardProps from 'proptypes/awardProps'
import gradingProps from 'proptypes/gradingProps'

const qualificationProps = {
  awards: PropTypes.arrayOf(PropTypes.shape(awardProps)).isRequired,
  gradings: PropTypes.arrayOf(PropTypes.shape(gradingProps)).isRequired,
  hasFiniteValidity: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  identifier: PropTypes.string.isRequired,
  isGradable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.number,
  subqualifications: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default qualificationProps
