// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MoodOption from './MoodOption'
import { NEGATIVE, NEUTRAL, POSITIVE } from '../src/lib/behaviourUtils'

export default class MoodSelector extends Component {
  static propTypes = {
    availableValues: PropTypes.arrayOf(PropTypes.number),
    editable: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    updateCurrentMoodType: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }

  constructor(props) {
    super(props)

    const { value } = props
    const values = Array.isArray(value) ? value : [value]

    this.state = {
      checked: new Set(values),
    }
  }

  handleChange = (id, bool) => {
    const { multiple, updateCurrentMoodType } = this.props

    let newChecked

    if (multiple) {
      newChecked = new Set(this.state.checked)

      if (bool) {
        newChecked.add(id)
      } else {
        newChecked.delete(id)
      }
    } else {
      newChecked = bool ? new Set([id]) : new Set()
    }

    this.setState({ checked: newChecked })

    if (updateCurrentMoodType) updateCurrentMoodType(bool ? id : null)
  }

  renderMood(mood) {
    const { availableValues, editable, name } = this.props

    if (!(availableValues || []).includes(mood)) return

    const { checked } = this.state

    return (
      <MoodOption
        checked={checked.has(mood)}
        editable={editable}
        name={name}
        onChange={this.handleChange}
        value={mood}
      />
    )
  }

  render() {
    return (
      <div className="mood__selector">
        {this.renderMood(NEGATIVE)}
        {this.renderMood(NEUTRAL)}
        {this.renderMood(POSITIVE)}
      </div>
    )
  }
}
