// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.clickHandler)
  }

  disconnect() {
    this.element.removeEventListener('click', this.clickHandler)
  }

  clickHandler = event => {
    const { target } = event

    // Don't intercept clicks on links.
    if (target.tagName === 'A') return

    const tr = target.closest('tr')

    // No <tr>?!
    if (!tr) return

    const firstLink = tr.querySelector('a')

    // Row doesn't have any links.
    if (!firstLink) return

    firstLink.click()
  }
}
