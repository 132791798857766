// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'

import periodProps from 'proptypes/periodProps'
import qualificationProps from 'proptypes/qualificationProps'

const roleProps = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  qualifiedPeriods: PropTypes.arrayOf(PropTypes.shape(periodProps)).isRequired,
  requiredQualifications: PropTypes.arrayOf(PropTypes.shape(qualificationProps))
    .isRequired,
}

export default roleProps
