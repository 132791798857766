// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import nameToId from 'src/lib/nameToId'
import AsyncPaginate from 'react-select-async-paginate'

import { selectStyles, selectStylesV2, theme } from '../styles/ReactSelect'

export default class AsyncDynamicSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      backEndSearch: true,
      values: [],
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(change) {
    const { name } = this.props

    const payload = { target: { name, change } }
    this.props.onSelect(payload)
  }

  frontEndSearch = search => {
    return {
      results: this.state.values.filter(value =>
        value.label.toLowerCase().includes(search.toLowerCase())
      ),
      hasMore: false,
    }
  }

  backEndCall = async (search, loaded_options_length) => {
    const response = await fetch(
      this.props.apiEndPoint(search, loaded_options_length)
    )
    return await response.json()
  }

  updateValues = (loaded_options, responseJSON) => {
    this.setState({
      values: [...loaded_options, ...responseJSON.results],
      backEndSearch: responseJSON.hasMore,
    })
  }

  loadOptions = async (search, loaded_options) => {
    let responseJSON = {}

    if (this.state.backEndSearch) {
      responseJSON = await this.backEndCall(search, loaded_options.length)
      if (search === '') this.updateValues(loaded_options, responseJSON)
    } else responseJSON = this.frontEndSearch(search)

    return {
      options: responseJSON.results,
      hasMore: responseJSON.hasMore,
    }
  }

  render() {
    const {
      clearable,
      currentValue,
      multi,
      name,
      noResultsText,
      placeholder,
      styleVersion,
      prefixClass,
    } = this.props

    return (
      <div className="dynamic-select" data-label={placeholder}>
        <AsyncPaginate
          loadOptions={this.loadOptions}
          className={prefixClass || 'Select'}
          classNamePrefix={prefixClass || 'Select'}
          isClearable={clearable}
          id={nameToId(name)}
          isMulti={multi}
          name={name}
          noOptionsMessage={() => noResultsText}
          onChange={this.onChange}
          placeholder={placeholder}
          styles={styleVersion === 2 ? selectStylesV2 : selectStyles}
          theme={theme}
          value={currentValue}
        />
      </div>
    )
  }
}

AsyncDynamicSelect.defaultProps = {
  multi: false,
  defaultValues: [],
}

AsyncDynamicSelect.propTypes = {
  apiEndPoint: PropTypes.func.isRequired,
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  multi: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  prefixClass: PropTypes.string,
  styleVersion: PropTypes.number,
}
