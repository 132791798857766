// Copyright Northcote Technology Ltd
import { FLEXLAYOUT_TAB_SELECTED, IS_MAXIMIZED } from '../actionTypes'

const flexlayout = (state = { tabSelected: null, editorOn: false }, action) => {
  switch (action.type) {
    case FLEXLAYOUT_TAB_SELECTED: {
      return Object.assign({}, state, {
        tabSelected: action.payload.tabSelected,
      })
    }
    case IS_MAXIMIZED: {
      return Object.assign({}, state, {
        maximized: action.payload.maximized,
      })
    }
    default: {
      return state
    }
  }
}

export default flexlayout
