// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Link extends PureComponent {
  static propTypes = {
    classes: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
  }

  render() {
    const { url, text, classes } = this.props

    return (
      <a className={classes} href={url}>
        {text}
      </a>
    )
  }
}

export default Link
