// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import AsyncDynamicSelect from './AsyncDynamicSelect'

export default function GraderSelect(props) {
  const { group_id, name, noResultsText, placeholder, url } = props
  const [value, setValue] = useState(props.value)
  const urlGenerator = useCallback(
    function (q, offset) {
      const fullUrl = new URL(url, document.location)
      if (group_id) fullUrl.searchParams.set('group_id', group_id)
      fullUrl.searchParams.set('offset', offset)
      fullUrl.searchParams.set('search', q)
      return fullUrl.toString()
    },
    [url, group_id]
  )

  return (
    <AsyncDynamicSelect
      apiEndPoint={urlGenerator}
      clearable={true}
      currentValue={value}
      name={name}
      noResultsText={noResultsText}
      onSelect={event => setValue(event.target.change)}
      placeholder={placeholder}
      prefixClass="select"
      styleVersion={2}
    />
  )
}

GraderSelect.propTypes = {
  group_id: PropTypes.number,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  placeholder: PropTypes.string,
  url: PropTypes.string.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
}
