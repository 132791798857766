// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Treebeard } from 'react-treebeard'
import { connect } from 'react-redux'
import { selectComplianceItem } from '../../redux/actions'
import translate from 'src/lib/translate'
import { treeStyles } from '../../styles/Treebeard'

class Tree extends PureComponent {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    tree: PropTypes.object.isRequired,
    selectComplianceItem: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = { data: props.tree }
    this.onToggle = this.onToggle.bind(this)
    this.selectComplianceItem = props.selectComplianceItem
    console.log(props)
  }

  onToggle(node, toggled) {
    const { data, cursor } = this.state

    if (cursor) {
      cursor.active = false
    }

    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    this.setState(() => ({ data: Object.assign({}, data), cursor: node }))

    if (node.id) this.selectComplianceItem(node)
  }

  onFilterMouseUp(e) {
    var defaultMatcher = (filterText, node) => {
      return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    }

    var nodeMatchesOrHasMatchingDescendants = (node, filter, matcher) => {
      return (
        matcher(filter, node) ||
        (node.children &&
          node.children.length &&
          !!node.children.find(childNode =>
            nodeMatchesOrHasMatchingDescendants(childNode, filter, matcher)
          ))
      )
    }

    var filterTree = (node, filter, matcher = defaultMatcher) => {
      if (matcher(filter, node)) {
        return node
      } else {
        var filteredChildren

        if (node.children) {
          filteredChildren = node.children.filter(child =>
            nodeMatchesOrHasMatchingDescendants(child, filter, matcher)
          )
        }

        if (filteredChildren && filteredChildren.length) {
          filteredChildren = filteredChildren.map(child =>
            filterTree(child, filter, matcher)
          )
        }

        return Object.assign({}, node, {
          children: filteredChildren,
        })
      }
    }

    var expandNodesWithMatchingDescendants = (
      node,
      filter,
      matcher = defaultMatcher
    ) => {
      var children = node.children
      var shouldExpand = false

      if (children && children.length) {
        var childrenWithMatches = node.children.filter(child =>
          nodeMatchesOrHasMatchingDescendants(child, filter, matcher)
        )
        shouldExpand = !!childrenWithMatches.length

        if (shouldExpand) {
          children = childrenWithMatches.map(child =>
            expandNodesWithMatchingDescendants(child, filter, matcher)
          )
        }
      }

      return Object.assign({}, node, {
        children: children,
        toggled: shouldExpand,
      })
    }

    const filter = e.target.value.trim()

    if (filter) {
      var data = filterTree(this.props.tree, filter)
      data = expandNodesWithMatchingDescendants(data, filter)

      this.setState({ data })
    } else {
      this.setState({ data: this.props.tree })
    }
  }

  render() {
    const { data } = this.state
    const { translations } = this.props

    return (
      <div className="compliance__tree  outer-container-left">
        <div className="compliance__tree--filter">
          <input
            className="m0"
            onKeyUp={this.onFilterMouseUp.bind(this)}
            placeholder={translate('search', translations)}
            type="text"
          />
        </div>

        <div className="compliance__tree--container inner-container">
          <Treebeard data={data} onToggle={this.onToggle} style={treeStyles} />
        </div>
      </div>
    )
  }
}

export default connect(null, { selectComplianceItem })(Tree)
