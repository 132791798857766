// Copyright Northcote Technology Ltd
import { getIdb, updateIdb, clearAll } from './common'

export function pinTry(correct) {
  if (UBF.online) return
  if (correct) {
    updateIdb('common', { key: 'pinTries', data: 0 })
      .then(function () {
        updatePinTime()
      })
      .catch(e => {
        console.log('Error updating pin insert tries')
        console.error(e)
      })
  } else {
    getIdb('common', 'pinTries')
      .then(response => {
        if (response) {
          updateIdb('common', { key: 'pinTries', data: response.data + 1 })
            .then(function () {})
            .catch(e => {
              console.log('Error updating pin insert tries')
              console.error(e)
            })
        } else {
          updateIdb('common', { key: 'pinTries', data: 1 })
            .then(function () {})
            .catch(e => {
              console.log('Error updating pin insert tries')
              console.error(e)
            })
          console.error('Error updating pin insert tries', response.data)
          return null
        }
      })
      .catch(error => {
        console.warn('Error getting pin insert tries')
        console.error(error)
      })
  }
}

function updatePinTime() {
  if (UBF.online) return
  updateIdb('common', { key: 'offline_last_pin', data: new Date() })
    .then(function () {})
    .catch(e => {
      console.log('Error updating last pin insertion date')
      console.error(e)
    })
}

export function updatePin(value) {
  updateIdb('common', { key: 'offline_pin', data: value })
    .then(function () {})
    .catch(e => {
      console.log('Error updating pin')
      console.error(e)
    })
}

export function syncOfflineTime(change = false, callbackFunction = null) {
  if (change) {
    if (!UBF.online) {
      updateIdb('common', { key: 'offline_start', data: new Date() })
        .then(function () {})
        .catch(e => {
          console.log('Error updating offline start time')
          console.error(e)
        })

      var time =
        new Date().getTime() +
        UBF.limitOfflineAtStart * 60 * 1000 -
        UBF.limitOfflinePin * 60 * 1000

      updateIdb('common', { key: 'offline_last_pin', data: new Date(time) })

      updateIdb('common', { key: 'pinTries', data: 0 })
        .then(function () {})
        .catch(e => {
          console.log('Error updating idb sessions')
          console.error(e)
        })
    } else {
      if (callbackFunction) {
        callbackFunction(false, false)
        callbackFunction(true, false)
      }
      updateIdb('common', { key: 'pinTries', data: 0 })
        .then(function () {
          updatePinTime()
        })
        .catch(e => {
          console.log('Error updating idb sessions')
          console.error(e)
        })
    }
  } else if (!UBF.online) {
    getIdb('common', 'pinTries')
      .then(response => {
        if (response && response.data && response.data >= UBF.limitPinTries) {
          if (callbackFunction) {
            callbackFunction(false, true)
            callbackFunction(true, true)
          }
          return
        }
        if (UBF.limitOffline) {
          getIdb('common', 'offline_start')
            .then(response => {
              if (response) {
                var diff = Math.abs(new Date() - response.data)
                var limit = UBF.limitOffline * 60 * 1000
                if (callbackFunction) callbackFunction(false, limit < diff)
              } else {
                console.error('There is no initial date in the database')
                return null
              }
            })
            .catch(error => {
              console.warn('Error getting offline start time')
              console.error(error)
            })
        }
        if (UBF.limitOfflinePin) {
          getIdb('common', 'offline_last_pin')
            .then(response => {
              if (response) {
                var diff = Math.abs(new Date() - response.data)
                var limit = UBF.limitOfflinePin * 60 * 1000
                if (callbackFunction) callbackFunction(true, limit < diff)
              } else {
                console.error('There is no initial date in the database')
                return null
              }
            })
            .catch(error => {
              console.warn('Error getting last pin insertion date')
              console.error(error)
            })
        }
      })
      .catch(error => {
        console.warn('Error synchronizing offline sessions')
        console.error(error)
      })
  }
}

export function checkLimitOfflineData(callbackFunction = null) {
  if (!UBF.online) {
    if (UBF.limitOfflineData) {
      getIdb('common', 'offline_start')
        .then(response => {
          if (response) {
            var diff = Math.abs(new Date() - response.data)
            var limit = UBF.limitOfflineData * 60 * 1000
            if (limit < diff && Math.abs(limit - diff) < 1000) clearAll()
            if (callbackFunction) callbackFunction(limit < diff)
          } else {
            console.error('There is no initial date in the database')
            return null
          }
        })
        .catch(error => {
          console.warn('Error getting offline start time')
          console.error(error)
        })
    }
  }
}
