// Copyright Northcote Technology Ltd
import {
  COMPLIANCE_ITEM_SELECTED,
  RESOLVED_GET_COMPLIANCE_ITEM,
  UPDATE_COMPLIANCE_ITEM,
} from '../actionTypes'

const compliance = (state = { info: null }, action) => {
  switch (action.type) {
    case COMPLIANCE_ITEM_SELECTED: {
      return Object.assign({}, state, {
        info: action.payload.node,
      })
    }
    case RESOLVED_GET_COMPLIANCE_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    case UPDATE_COMPLIANCE_ITEM: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default compliance
