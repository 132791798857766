// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'
import EventModule from './EventModule'

export default class SessionTemplateEventResponse extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    currentValues: PropTypes.object,
    sessionTemplates: PropTypes.array.isRequired,
    sessionTemplatesForQualifications: PropTypes.array.isRequired,
    gradeCategory: PropTypes.array.isRequired,
    logicConditions: PropTypes.array.isRequired,
    comparisonCriterion: PropTypes.array.isRequired,
  }

  constructor(props) {
    super()

    if (props.currentValues.sessionTemplateId) {
      var currentSessionTemplate = props.sessionTemplatesForQualifications.find(
        sessionTemplate =>
          sessionTemplate.id == props.currentValues.sessionTemplateId
      )
      var qualificationsValues = currentSessionTemplate.qualifications.map(
        qualification => {
          var qualifications = {}
          qualifications['label'] = qualification.name
          qualifications['value'] = qualification.id.toString()
          return qualifications
        }
      )
    }

    this.state = {
      currentSessionTemplateValue: props.currentValues.sessionTemplateId
        ? props.currentValues.sessionTemplateId.toString()
        : '',
      currentLogicConditionValue: props.currentValues.logicCondition,
      qualificationsValues: qualificationsValues ? qualificationsValues : [],
      eventModules: this.setEventModules(props),
    }
  }

  setEventModules = props => {
    const { currentValues } = props

    var eventModules = []

    if (currentValues) {
      Object.keys(currentValues).forEach(inputField => {
        const valueField = currentValues[inputField]
        if (Array.isArray(currentValues[inputField])) {
          valueField.forEach((value, index) => {
            if (!eventModules[index]) {
              eventModules[index] = {}
            }
            eventModules[index][inputField] = value
          })
        } else {
          eventModules.map(eventModule => {
            eventModule[inputField] = valueField
          })
        }
      })
    }

    return eventModules
  }

  updateCurrentSessionTemplateValue = event => {
    var currentSessionTemplate =
      this.props.sessionTemplatesForQualifications.find(
        sessionTemplate => sessionTemplate.id == event.target.value
      )
    var qualificationsValues = currentSessionTemplate.qualifications.map(
      qualification => {
        var qualifications = {}
        qualifications['label'] = qualification.name
        qualifications['value'] = qualification.id.toString()
        return qualifications
      }
    )

    this.setState({
      currentSessionTemplateValue: event.target.value,
      qualificationsValues: qualificationsValues,
    })
  }

  onUpdateCurrentValues = (event, index, inputField) => {
    const eventModules = [...this.state.eventModules]
    eventModules[index][inputField] = event.target.value
    this.setState({ eventModules: eventModules })
  }

  classEventResponse = () => {
    return this.state.currentSessionTemplateValue ? 'event-response' : ''
  }

  handleAddingEventModules = () => {
    this.setState({
      eventModules: [
        ...this.state.eventModules,
        {
          qualificationIds: '',
          gradeCategory: '',
          comparisonCriterion: '',
          gradeCount: '',
        },
      ],
    })
  }

  renderEventModules() {
    const { translations, gradeCategory, comparisonCriterion } = this.props

    const { eventModules, qualificationsValues } = this.state

    return eventModules.map((eventModule, index) => {
      return (
        <div key={index} className={'event-module-' + index}>
          <EventModule
            currentQualificationsValue={eventModule.qualificationIds}
            currentGradeCategoryValue={eventModule.gradeCategory}
            currentComparisonCriterionValue={eventModule.comparisonCriterion}
            currentGradeCountValue={eventModule.gradeCount}
            qualifications={qualificationsValues}
            gradeCategory={gradeCategory}
            comparisonCriterion={comparisonCriterion}
            index={index}
            onUpdate={this.onUpdateCurrentValues}
            translations={translations}
          />
        </div>
      )
    })
  }

  render() {
    const { translations, logicConditions, sessionTemplates } = this.props

    return (
      <Fragment>
        <div className={this.classEventResponse()}>
          <label htmlFor="metadata_session_template_id">
            {translations.ubf.metadataSessionTemplateId}
          </label>
          <DynamicSelect
            clearable={true}
            name={'event_attributes[metadata_session_template_id]'}
            currentValue={this.state.currentSessionTemplateValue}
            noResultsText={translations.noResultsText.metadataSessionTemplateId}
            onSelect={this.updateCurrentSessionTemplateValue}
            placeholderText={
              translations.placeholders.metadataSessionTemplateId
            }
            values={sessionTemplates}
          />

          {this.state.currentSessionTemplateValue ? (
            <div className="event-response-tree">
              {logicConditions.map((condition, index) => {
                return (
                  <Fragment key={index}>
                    <input
                      className="hide"
                      type="radio"
                      defaultChecked={
                        condition.value == this.state.currentLogicConditionValue
                      }
                      id={condition.value}
                      name="event_attributes[metadata_logic_condition]"
                      value={condition.value}
                    />
                    <label
                      className="btn neutral btn-group"
                      htmlFor={condition.value}
                    >
                      {condition.label}
                    </label>
                  </Fragment>
                )
              })}

              {this.renderEventModules()}

              <div
                className="btn-add-event-module"
                onClick={this.handleAddingEventModules}
              >
                <span className="icon icon--plus-circle"></span>
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    )
  }
}
