// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const ZOOM_INTERVAL = 50 // lower value for smoother zoom (and faster zoom)
const ZOOM_INCREMENT = 0.1 // higher value for faster zoom

const incrementFor = scaleFactor => {
  return Math.ceil(scaleFactor * ZOOM_INCREMENT)
}

class ScaleZoomer extends PureComponent {
  static propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onScaleChange: PropTypes.func.isRequired,
    scaleFactor: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.repeatedZoomIn = undefined
    this.repeatedZoomOut = undefined
  }

  preventContextMenu(e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  stopZooming = () => {
    clearInterval(this.repeatedZoomIn)
    clearInterval(this.repeatedZoomOut)
  }

  zoomIn = () => {
    const { max, onScaleChange, scaleFactor } = this.props

    let newScaleFactor = scaleFactor

    const zoom = () => {
      newScaleFactor = newScaleFactor + incrementFor(newScaleFactor)

      if (newScaleFactor <= max) {
        onScaleChange(newScaleFactor)
      } else {
        this.stopZooming()
      }
    }

    zoom()
    this.repeatedZoomIn = setInterval(zoom, ZOOM_INTERVAL)
  }

  zoomOut = () => {
    const { min, onScaleChange, scaleFactor } = this.props

    let newScaleFactor = scaleFactor

    const zoom = () => {
      newScaleFactor = newScaleFactor - incrementFor(newScaleFactor)

      if (newScaleFactor >= min) {
        onScaleChange(newScaleFactor)
      } else {
        this.stopZooming()
      }
    }

    zoom()
    this.repeatedZoomOut = setInterval(zoom, ZOOM_INTERVAL)
  }

  render() {
    return (
      <div className="scale-zoomer">
        <button
          className="scale-zoomer__zoom-out"
          onContextMenu={this.preventContextMenu}
          onMouseDown={this.zoomOut}
          onTouchStart={this.zoomOut}
          onMouseLeave={this.stopZooming}
          onMouseUp={this.stopZooming}
          onTouchEnd={this.stopZooming}
        >
          -
        </button>

        <button
          className="scale-zoomer__zoom-in"
          onContextMenu={this.preventContextMenu}
          onMouseDown={this.zoomIn}
          onTouchStart={this.zoomIn}
          onMouseLeave={this.stopZooming}
          onMouseUp={this.stopZooming}
          onTouchEnd={this.stopZooming}
        >
          +
        </button>
      </div>
    )
  }
}

export default ScaleZoomer
