// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIdb } from '../../src/lib/idb/common'
import { setOverallGradingOption } from '../../redux/app'
import {
  getTheoricalOptionId,
  isRuleMet,
  tallyGradingCategories,
  optionHasRule,
} from '../../src/lib/overallGradingHelper'

class OverallGrading extends Component {
  static propTypes = {
    gradingSessionResult: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    translations: PropTypes.object.isRequired,
    setOverallGradingOption: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showPleaseChoose: false,
    }
  }

  componentDidMount = async () => {
    const {
      gradingSessionResult: { gradingSessionId, overallGradingOptionId },
    } = this.props
    const sessionTriedToSubmit = await getIdb(
      'unsubmitted_sessions',
      gradingSessionId
    )
    if (sessionTriedToSubmit && overallGradingOptionId === null)
      this.setState({ showPleaseChoose: true })
  }

  optionSelected(optionId) {
    const {
      gradingSessionResult: { id, gradingSessionId },
      setOverallGradingOption,
    } = this.props

    setOverallGradingOption(gradingSessionId, id, optionId)

    this.setState({ showPleaseChoose: false })
  }

  checkResult() {
    const {
      options,
      gradingSessionResult: { gradings, id, overallGradingOptionId },
    } = this.props

    if (!overallGradingOptionId) return [null, false, false]

    const filteredGradings = gradings.filter(
      g => g.gradingSessionResultId === id
    )
    const gradingCategories = tallyGradingCategories(filteredGradings)
    const theoricalOptionId = getTheoricalOptionId(options, gradingCategories)

    const warnDifferentOption =
      theoricalOptionId != null && overallGradingOptionId != theoricalOptionId

    let warnRuleNotMet = false
    const selectedOption = options.find(o => o.id == overallGradingOptionId)
    if (
      overallGradingOptionId &&
      !warnDifferentOption &&
      optionHasRule(selectedOption)
    ) {
      warnRuleNotMet = !isRuleMet(selectedOption, gradingCategories)
    }

    return [theoricalOptionId, warnDifferentOption, warnRuleNotMet]
  }

  render() {
    const {
      gradingSessionResult: { overallGradingOptionId },
      options,
      translations,
    } = this.props

    const { showPleaseChoose } = this.state

    const [theoricalOptionId, warnDifferentOption, warnRuleNotMet] =
      this.checkResult()

    const optionClass = 'overall-grading-result__option'

    return (
      <div className="overall-grading-result">
        <div className="overall-grading-result__label">
          {
            translations.activerecord.attributes.gradingSessionResult
              .overallPositiveResult
          }
        </div>
        {warnDifferentOption || warnRuleNotMet ? (
          <span className="overall-grading-result__warning">
            <i className="mr5 icon icon--alert-error"></i>
            {warnDifferentOption
              ? translations.messages.gradingSessionResults.overallGradingShallBe.replace(
                  '%{option}',
                  options.find(o => o.id === theoricalOptionId).label
                )
              : translations.messages.gradingSessionResults
                  .overallGradingRuleNotMet}
          </span>
        ) : null}
        {showPleaseChoose ? (
          <span className="overall-grading-result__warning">
            <i className="mr5 icon icon--alert-error"></i>
            {
              translations.messages.gradingSessionResults
                .overallPositiveResultMissing
            }
          </span>
        ) : null}
        <hr className="grade-input-wrapper-orca--line-tittle" />

        {options
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(option => {
            return (
              <div
                key={option.id}
                className={`${optionClass} ${optionClass}--${
                  overallGradingOptionId === option.id ? 'selected' : 'inactive'
                }`}
                style={{ color: option.color }}
                onClick={() => this.optionSelected(option.id)}
              >
                {option.label}
              </div>
            )
          })}
      </div>
    )
  }
}

export default connect(null, { setOverallGradingOption })(OverallGrading)
