// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GradingPoint from './GradingPoint'
import gradingProps from 'proptypes/gradingProps'

export const GRADING_TIMELINE_HEIGHT = 10
export const GRADING_TIMELINE_PADDING = 6

class GradingTimeline extends Component {
  static propTypes = {
    gradings: PropTypes.arrayOf(PropTypes.shape(gradingProps)).isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { gradings, scale, t } = this.props

    const gradingPoints = gradings.map((grading, index) => {
      return <GradingPoint grading={grading} key={index} scale={scale} t={t} />
    })

    return (
      <div
        className="grading-timeline"
        style={{
          height: GRADING_TIMELINE_HEIGHT,
          paddingBottom: GRADING_TIMELINE_PADDING,
          paddingTop: GRADING_TIMELINE_PADDING,
          width: scale.range()[1],
        }}
      >
        {gradingPoints}
      </div>
    )
  }
}

export default GradingTimeline
