// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'
import CustomInput from './CustomInput'

export default class SessionOptions extends Component {
  static propTypes = {
    sessionTemplates: PropTypes.array.isRequired,
    sessionTemplateName: PropTypes.string.isRequired,
    sessionTemplateId: PropTypes.string,
    sessionTemplateNoResultsText: PropTypes.string.isRequired,
    sessionTemplatePlaceholderText: PropTypes.string.isRequired,
    customFieldsByTemplate: PropTypes.array.isRequired,
    devicesByTemplate: PropTypes.array,
    deviceTypeId: PropTypes.string,
    translations: PropTypes.object,
    templateError: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.sessionTemplateId,
      currentDeviceType: props.deviceTypeId,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { currentValue } = newProps

    this.setState({ currentValue: currentValue })
  }

  updateCustomField = event => {
    this.setState({
      currentValue: event.target.value,
      currentDeviceType: '',
    })
  }

  updateDeviceType = event => {
    this.setState({ currentDeviceType: event.target.value })
  }

  render() {
    const {
      sessionTemplates,
      sessionTemplateName,
      sessionTemplatePlaceholderText,
      sessionTemplateNoResultsText,
      translations,
      templateError,
    } = this.props

    const { customFieldsByTemplate, devicesByTemplate } = this.props
    const currentValue = this.state.currentValue
    const customFields = []

    customFieldsByTemplate.forEach(sessionTemplate => {
      const sessionTemplateArray = []
      Object.keys(sessionTemplate).forEach(key =>
        sessionTemplateArray.push({ name: key, value: sessionTemplate[key] })
      )
      sessionTemplateArray.forEach(customField => {
        if (customField.name == currentValue) {
          customFields.push(customField.value)
        }
      })
    })

    const deviceTypes = currentValue
      ? devicesByTemplate.find(elem => elem[currentValue])[currentValue]
      : []
    const deviceTypesDropdown = deviceTypes.map(deviceType => ({
      value: deviceType.id.toString(),
      label: deviceType.name,
    }))

    return (
      <>
        <tr>
          <th>
            {
              translations.activerecord.attributes.gradingSession
                .gradingSessionTemplate
            }{' '}
            *
          </th>

          <td>
            <DynamicSelect
              clearable={true}
              name={sessionTemplateName}
              currentValue={this.state.currentValue}
              noResultsText={sessionTemplateNoResultsText}
              onSelect={this.updateCustomField}
              placeholderText={sessionTemplatePlaceholderText}
              values={sessionTemplates}
              styleVersion={2}
              prefixClass={'select'}
            />
            {templateError.toString() != '' && (
              <span className="error">{templateError}</span>
            )}
          </td>
        </tr>
        {customFields.map(customField =>
          customField.map(field => (
            <tr key={field['name']}>
              <th>{field['name']}</th>
              <td>
                <CustomInput
                  label={field['name']}
                  value={field['list_values'] || field['listValues']}
                  name={`custom_fields[${field['id']}]`}
                  type={field['fieldType']}
                  currentValue={''}
                  styleVersion={2}
                  translations={translations}
                  newUi={false}
                  showLabel={false}
                  customCalendar={false}
                />
              </td>
            </tr>
          ))
        )}
        {deviceTypesDropdown.length > 0 && (
          <tr>
            <th>
              {translations.activerecord.attributes.gradingSession.deviceType}
            </th>
            <td>
              <DynamicSelect
                clearable={true}
                name={'grading_session[device_type_id]'}
                currentValue={this.state.currentDeviceType}
                noResultsText={''}
                onSelect={this.updateDeviceType}
                placeholderText={
                  translations.activerecord.attributes.gradingSession.deviceType
                }
                values={deviceTypesDropdown}
                styleVersion={2}
                prefixClass={'select'}
              />
            </td>
          </tr>
        )}
      </>
    )
  }
}
