// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import RoleQualificationRowHeading from './RoleQualificationRowHeading'
import roleProps from 'proptypes/roleProps'

class RoleQualificationRowHeadings extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape(roleProps)).isRequired,
  }

  render() {
    const { roles } = this.props

    const roleHeadings = roles.map((role, index) => {
      return (
        <RoleQualificationRowHeading
          isFirstRow={index === 0}
          key={role.id}
          role={role}
        />
      )
    })

    return <div className="role-qualification-row-headings">{roleHeadings}</div>
  }
}

export default RoleQualificationRowHeadings
