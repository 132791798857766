// Copyright Northcote Technology Ltd
import { GET_CREATE_STATE } from '../actionTypes'

const creation = (state = { info: null }, action) => {
  switch (action.type) {
    case GET_CREATE_STATE: {
      return Object.assign({}, state, {
        info: action.payload.data,
      })
    }
    default: {
      return state
    }
  }
}

export default creation
