// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'

export default class EventModule extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    qualifications: PropTypes.array.isRequired,
    gradeCategory: PropTypes.array.isRequired,
    comparisonCriterion: PropTypes.array.isRequired,
    currentQualificationsValue: PropTypes.string,
    currentGradeCategoryValue: PropTypes.string,
    currentComparisonCriterionValue: PropTypes.string,
    currentGradeCountValue: PropTypes.string,
    index: PropTypes.number.isRequired,
    onUpdate: PropTypes.func,
  }

  constructor(props) {
    super()
    this.onUpdate = props.onUpdate
  }

  render() {
    const {
      translations,
      qualifications,
      gradeCategory,
      comparisonCriterion,
      index,
      currentQualificationsValue,
      currentGradeCategoryValue,
      currentComparisonCriterionValue,
      currentGradeCountValue,
    } = this.props

    return (
      <div key={index}>
        <label htmlFor="metadata_qualification_ids">
          {translations.ubf.metadataQualificationIds}
        </label>
        <DynamicSelect
          clearable={true}
          name={'event_attributes[metadata_qualification_ids][]'}
          currentValue={currentQualificationsValue}
          noResultsText={translations.noResultsText.metadataQualificationIds}
          onSelect={event => {
            this.onUpdate(event, index, 'qualificationIds')
          }}
          placeholderText={translations.placeholders.metadataQualificationIds}
          values={qualifications}
        />

        <label htmlFor="metadata_grade_category">
          {translations.ubf.metadataGradeCategory}
        </label>
        <DynamicSelect
          clearable={true}
          name={'event_attributes[metadata_grade_category][]'}
          currentValue={currentGradeCategoryValue}
          noResultsText={translations.noResultsText.metadataGradeCategory}
          onSelect={event => {
            this.onUpdate(event, index, 'gradeCategory')
          }}
          placeholderText={translations.placeholders.metadataGradeCategory}
          values={gradeCategory}
        />

        <label htmlFor="metadata_comparison_criterion">
          {translations.ubf.metadataComparisonCriterion}
        </label>
        <DynamicSelect
          clearable={true}
          name={'event_attributes[metadata_comparison_criterion][]'}
          currentValue={currentComparisonCriterionValue}
          noResultsText={translations.noResultsText.metadataComparisonCriterion}
          onSelect={event => {
            this.onUpdate(event, index, 'comparisonCriterion')
          }}
          placeholderText={
            translations.placeholders.metadataComparisonCriterion
          }
          values={comparisonCriterion}
        />

        <label htmlFor="metadata_grade_count">
          {translations.ubf.metadataGradeCount}
        </label>
        <input
          type="text"
          name={'event_attributes[metadata_grade_count][]'}
          value={currentGradeCountValue}
          placeholder={translations.placeholders.metadataGradeCount}
          onChange={event => {
            this.onUpdate(event, index, 'gradeCount')
          }}
        />
      </div>
    )
  }
}
