// Copyright Northcote Technology Ltd
import React, { useContext } from 'react'

import TranslationsContext from '../contexts/Translations'

import DynamicSelect from '../DynamicSelect'

export default function FleetPicker({ fleets, onChange, value }) {
  const translations = useContext(TranslationsContext)
  const handleChange = event => {
    onChange(event.target.value.map(value => parseInt(value, 10)))
  }
  const options = fleets.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }))
  const placeholder =
    translations.forms.placeholders.grading_session_template.fleet_ids

  return (
    <DynamicSelect
      clearable={false}
      currentValue={value.map(id => id.toString())}
      multi={true}
      name=""
      noResultsText={placeholder}
      onSelect={handleChange}
      placeholderText={placeholder}
      prefixClass="select"
      values={options}
    />
  )
}
