// Copyright Northcote Technology Ltd
import {
  createRecording as actionCreateRecording,
  deleteRecording as actionDeleteRecording,
  deleteSessionSuccess,
  fetchSession,
  fetchSessionSuccess,
  saveSession as actionSaveSession,
  saveSessionSuccess,
  setOverallPositiveResult as actionSetOverallPositiveResult,
  setOverallGradingOption as actionSetOverallGradingOption,
  updateGradings as actionUpdateGradings,
  updateRecording as actionUpdateRecording,
  updateSession,
  updateSessionCustomField as actionUpdateSessionCustomField,
  updateSessionDeviceType as actionUpdateSessionDeviceType,
  updateSessionResultFleetIds as actionUpdateSessionResultFleetIds,
  updateSessionResultPosition as actionUpdateSessionResultPosition,
  updateSessionResultSignatureData as actionUpdateSessionResultSignatureData,
  updateSessionResultAbstract as actionUpdateSessionResultAbstract,
  offlineSessionsSynced as actionOfflineSessionsSynced,
} from './actions'
import { fetchSession as apiFetchSession } from './api'
import { makeSessionSelector, onlineSelector } from './selectors'
import {
  syncEntities,
  syncOfflineSession,
  syncOfflineSessions,
} from '../src/init/syncDataServer'
import {
  deleteGrading,
  deleteResult,
  deleteSession as deleteIdbSession,
  getGradings,
  getSession,
  getSessionChanges,
  getSessionsDisplay,
  getRecordings,
  getResult,
  setResult,
  setSession,
  setSessionChanges,
  setSessionsDisplay,
} from '../src/lib/idb/common'
import {
  deleteIdbRecording,
  getNewRecordingKey,
  sendGrading,
  updateFieldGradingSessionResult,
  updateIdbRecording,
} from '../src/lib/idb/sessions'
import mergeCustomFieldChanges from '../src/lib/mergeCustomFieldChanges'
import mergeResultChanges from '../src/lib/mergeResultChanges'

// Write the new recording to IndexedDB to make it available to sync, dispatch
// a createRecording Redux action, and write the newly-updated session to
// IndexedDB.
export function createRecording(sessionId, recording) {
  return async (dispatch, getState) => {
    recording.createdAt = new Date().toISOString()
    recording.id = await getNewRecordingKey()

    await updateIdbRecording(recording, 'save')

    dispatch(actionCreateRecording(sessionId, recording))

    const state = getState()
    const newlyUpdatedSession = makeSessionSelector(sessionId)(state)

    await setSession(newlyUpdatedSession)
  }
}

// If the recording hasn't yet been persisted then it can be deleted from
// IndexedDB, otherwise it's marked as deleted in IndexedDB so that it can be
// deleted via the API. Then dispatch a deleteRecording Redux action and write
// the newly-updated session to IndexedDB.
export function deleteRecording(sessionId, recording) {
  const { id } = recording

  return async (dispatch, getState) => {
    if (id > 0) {
      await updateIdbRecording({ id }, 'delete')
    } else {
      await deleteIdbRecording(id)
    }

    dispatch(actionDeleteRecording(sessionId, recording))

    const state = getState()
    const newlyUpdatedSession = makeSessionSelector(sessionId)(state)

    await setSession(newlyUpdatedSession)
  }
}

// Remove gradings/recordings/results and the session from IndexedDB then
// dispatch an action to delete the data from Redux.
export function deleteSession(id) {
  return async (dispatch, getState) => {
    const state = getState()
    const session = makeSessionSelector(id)(state)
    const resultIds = new Set(session.gradingSessionResults.map(({ id }) => id))

    const gradings = await getGradings()
    for (const grading of gradings) {
      if (resultIds.has(grading.grading_session_result_id)) {
        await deleteGrading(grading.id)
      }
    }

    const recordings = await getRecordings()
    for (const recording of recordings) {
      if (recording.grading_session_id === id) {
        await deleteIdbRecording(recording.id)
      }
    }

    for (const id of resultIds) {
      await deleteResult(id)
    }

    await deleteIdbSession(id)

    dispatch(deleteSessionSuccess(id))
  }
}

export function loadSession(sessionId) {
  return (dispatch, getState) => {
    const state = getState()
    const online = onlineSelector(state)
    const existingSession = makeSessionSelector(sessionId)(state)

    // No-op: the Session is already present in Redux.
    if (existingSession) return

    dispatch(fetchSession(sessionId))

    if (online) {
      // Fetch from API and update the Session in IndexedDB.
      apiFetchSession(sessionId).then(data => {
        dispatch(fetchSessionSuccess(data))
        setSession(data)
      })
    } else {
      // Load directly from IndexedDB.
      getSession(sessionId).then(data => {
        dispatch(fetchSessionSuccess(data))
      })
    }
  }
}

export function loadSessionsForOffline(sessionIds, callback) {
  return async (dispatch, getState) => {
    const state = getState()

    for (const id of sessionIds) {
      const existingSession = makeSessionSelector(id)(state)

      if (existingSession) {
        // No-op: the Session is already present in Redux.
        callback()
        continue
      }

      dispatch(fetchSession(id))

      await apiFetchSession(id).then(data => {
        dispatch(fetchSessionSuccess(data))
        setSession(data)
        callback()
      })
    }
  }
}

// Update the recording in IndexedDB which makes it available to sync, dispatch
// an updateRecording Redux action, and write the newly-updated session to
// IndexedDB.
export function updateRecording(sessionId, recording) {
  return async (dispatch, getState) => {
    dispatch(actionUpdateRecording(sessionId, recording))

    await updateIdbRecording(recording, 'update')

    const state = getState()
    const newlyUpdatedSession = makeSessionSelector(sessionId)(state)

    await setSession(newlyUpdatedSession)
  }
}

export function saveSession(id) {
  return async (dispatch, getState) => {
    const state = getState()
    const session = makeSessionSelector(id)(state)

    dispatch(actionSaveSession(id))

    if (session.local) {
      const online = onlineSelector(state)

      if (online) {
        // This is an offline-created session, save it to the server.
        const serverSession = await syncOfflineSession(session)

        // Store the newly-created session in Redux and IndexedDB.
        dispatch(fetchSessionSuccess(serverSession))
        await setSession(serverSession)

        // Remove the offline-created session and its dependents from Redux and
        // IndexedDB.
        await dispatch(deleteSession(id))

        // The newly-created session's database id can be useful to the
        // consumer of this action.
        return serverSession.id
      } else {
        // We're offline so there's nothing to do as at this point everything
        // has already been saved to IndexedDB.
        dispatch(saveSessionSuccess(id))
      }
    } else {
      // Sync individual gradings/recordings/results.
      const shouldRetrieveSession = await syncEntities()

      dispatch(saveSessionSuccess(id))

      if (shouldRetrieveSession) {
        // Something's changed so get the latest data from the server.
        dispatch(fetchSession(id))

        await apiFetchSession(id).then(data => {
          dispatch(fetchSessionSuccess(data))
          setSession(data)
        })
      }
    }
  }
}

export function setOverallPositiveResult(sessionId, sessionResultId, value) {
  return async dispatch => {
    dispatch(actionSetOverallPositiveResult(sessionId, sessionResultId, value))

    await updateFieldGradingSessionResult(
      sessionId,
      sessionResultId,
      'overall_positive_result',
      value
    )
  }
}
export function setOverallGradingOption(sessionId, sessionResultId, value) {
  return async dispatch => {
    dispatch(actionSetOverallGradingOption(sessionId, sessionResultId, value))

    await updateFieldGradingSessionResult(
      sessionId,
      sessionResultId,
      'overall_grading_option_id',
      value
    )
  }
}

export function submitLocalSession(sessionId) {
  return async (dispatch, getState) => {
    const params = {
      submitted_at: new Date().toISOString(),
      status: 'submitted',
    }

    dispatch(updateSession(sessionId, params))

    const state = getState()
    const updatedSession = makeSessionSelector(sessionId)(state)

    // Persist the updated Session data to IndexedDB.
    await setSession(updatedSession)

    const sessionsDisplay = await getSessionsDisplay()
    sessionsDisplay.forEach(session => {
      if (session.id === sessionId) {
        session.submitted_at = params.submitted_at
      }
    })
    await setSessionsDisplay(sessionsDisplay)

    await syncOfflineSessions()
  }
}

export function updateGradings(sessionId, gradings) {
  return async (dispatch, getState) => {
    dispatch(actionUpdateGradings(sessionId, gradings))

    const state = getState()
    const updatedSession = makeSessionSelector(sessionId)(state)

    // Persist the updated Session data to IndexedDB.
    await setSession(updatedSession)

    // Update gradings in IndexedDB. Assume it works, no error checking.
    for (const grading of gradings) {
      await sendGrading(grading, updatedSession)
    }
  }
}

export function updateSessionCustomField(sessionId, fieldId, value) {
  const isPersisted = sessionId > 0

  return async (dispatch, getState) => {
    dispatch(actionUpdateSessionCustomField(sessionId, fieldId, value))

    const state = getState()
    const updatedSession = makeSessionSelector(sessionId)(state)

    // Update the full session in IndexedDB.
    await setSession(updatedSession)

    // Update the session changes in IndexedDB.
    if (isPersisted) {
      const gscf = updatedSession.gradingSessionCustomFields.find(
        ({ customFieldId }) => customFieldId === fieldId
      )
      if (!gscf) return
      const idbSessionChange = await getSessionChanges(sessionId)
      const customFields = mergeCustomFieldChanges(
        idbSessionChange?.grading_session_custom_fields_attributes || [],
        { id: gscf.id, value }
      )

      await setSessionChanges({
        ...idbSessionChange,
        id: sessionId,
        grading_session_custom_fields_attributes: customFields,
        action_server: 'update',
        saved_on_server: false,
      })

      await syncEntities()
    }
  }
}

export function updateSessionDeviceType(sessionId, deviceTypeId) {
  const isPersisted = sessionId > 0

  return async (dispatch, getState) => {
    dispatch(actionUpdateSessionDeviceType(sessionId, deviceTypeId))

    const state = getState()
    const updatedSession = makeSessionSelector(sessionId)(state)

    // Update the full session in IndexedDB.
    await setSession(updatedSession)

    // Update the session changes in IndexedDB.
    if (isPersisted) {
      const existingSessionChange = (await getSessionChanges(sessionId)) || {}

      await setSessionChanges({
        ...existingSessionChange,

        id: sessionId,
        action_server: 'update',
        saved_on_server: false,

        device_type_id: deviceTypeId,

        // Changing the session's device type necessitates changing results'
        // existing position values.
        grading_session_results_attributes: mergeResultChanges(
          existingSessionChange.grading_session_results_attributes || [],
          updatedSession.gradingSessionResults.map(({ id, position }) => ({
            id,
            position,
          }))
        ),
      })

      await syncEntities()
    }
  }
}

export function updateSessionResultFleetIds(sessionId, resultId, fleetIds) {
  const isPersisted = sessionId > 0

  return async (dispatch, getState) => {
    dispatch(actionUpdateSessionResultFleetIds(sessionId, resultId, fleetIds))

    const state = getState()
    const updatedSession = makeSessionSelector(sessionId)(state)

    // Update the full session in IndexedDB.
    await setSession(updatedSession)

    if (isPersisted) {
      const existingSessionChange = (await getSessionChanges(sessionId)) || {}

      await setSessionChanges({
        ...existingSessionChange,

        id: sessionId,
        action_server: 'update',
        saved_on_server: false,

        grading_session_results_attributes: mergeResultChanges(
          existingSessionChange.grading_session_results_attributes || [],
          [
            {
              id: resultId,
              fleet_ids: fleetIds,
            },
          ]
        ),
      })

      await syncEntities()
    }
  }
}

export function updateSessionResultPosition(sessionId, resultId, position) {
  return async dispatch => {
    dispatch(actionUpdateSessionResultPosition(sessionId, resultId, position))

    await updateFieldGradingSessionResult(
      sessionId,
      resultId,
      'position',
      position
    )

    const idbResult = await getResult(resultId)

    if (typeof resultId !== 'string') {
      // Existing result.
      setResult({
        ...idbResult,
        id: resultId,
        position,
        action_server: 'update',
        saved_on_server: false,
      }).then(() => syncEntities())
    }
  }
}

export function updateSessionResultSignatureData(
  sessionId,
  resultId,
  signatureData
) {
  return async dispatch => {
    dispatch(
      actionUpdateSessionResultSignatureData(sessionId, resultId, signatureData)
    )

    await updateFieldGradingSessionResult(
      sessionId,
      resultId,
      'signature_data',
      signatureData
    )

    const idbResult = await getResult(resultId)

    if (typeof resultId !== 'string') {
      // Existing result.
      setResult({
        ...idbResult,
        id: resultId,
        signature_data: signatureData,
        action_server: 'update',
        saved_on_server: false,
      }).then(() => syncEntities())
    }
  }
}

export function updateSessionResultAbstract(sessionId, resultId, abstract) {
  return async dispatch => {
    dispatch(actionUpdateSessionResultAbstract(sessionId, resultId, abstract))

    await updateFieldGradingSessionResult(
      sessionId,
      resultId,
      'abstract',
      abstract
    )

    const idbResult = await getResult(resultId)

    if (typeof resultId !== 'string') {
      // Existing result.
      setResult({
        ...idbResult,
        id: resultId,
        abstract,
        action_server: 'update',
        saved_on_server: false,
      }).then(() => syncEntities())
    }
  }
}

export function offlineSessionsSynced() {
  return async dispatch => {
    dispatch(actionOfflineSessionsSynced())
  }
}
