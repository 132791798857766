// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { lightColors } from '../src/lib/overallGradingHelper'

export default class GradeOption extends PureComponent {
  static propTypes = {
    category: PropTypes.string.isRequired,
    id: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    isMultiple: PropTypes.bool,
    isUnselectable: PropTypes.bool,
    name: PropTypes.string,
    onUpdate: PropTypes.func,
    value: PropTypes.string.isRequired,
    refApplicableUntilDate: PropTypes.object,
    orca: PropTypes.bool,
    disabled: PropTypes.bool,
    bgColor: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      categoriesSelectedForHide: ['failing', 'disqualifying', 'other'],
    }
  }

  hideApplicableUntilDate() {
    const { refApplicableUntilDate } = this.props

    if (
      refApplicableUntilDate != undefined &&
      refApplicableUntilDate.current != null
    ) {
      refApplicableUntilDate.current.setState({ additionalClass: 'hidden' })
    }
  }

  onChange = e => {
    const {
      isMultiple,
      isUnselectable,
      onUpdate,
      category,
      refApplicableUntilDate,
      disabled,
    } = this.props

    if (
      refApplicableUntilDate != undefined &&
      refApplicableUntilDate.current != null
    ) {
      this.state.categoriesSelectedForHide.includes(category)
        ? refApplicableUntilDate.current.setState({ additionalClass: 'hidden' })
        : refApplicableUntilDate.current.setState({ additionalClass: '' })
    }

    if (onUpdate && (!disabled || category == 'repeat')) {
      let value = e.currentTarget.value
      if (isMultiple && !!isUnselectable && !e.currentTarget.checked)
        value = null
      else if (!isMultiple) value = e.currentTarget.checked
      onUpdate(value, category)
    }
  }

  render() {
    const {
      category,
      id,
      isChecked,
      isMultiple,
      name,
      onUpdate,
      value,
      orca,
      disabled,
      bgColor,
    } = this.props

    const checkboxUncheckedInput = !isMultiple ? (
      <input type="hidden" value="0" name={name} />
    ) : null

    const optionClass = orca ? 'grade-option-orca' : 'grade-option'

    return (
      <div className={optionClass}>
        {checkboxUncheckedInput}
        <input
          className={classNames({
            [optionClass + '__input']: true,
            [optionClass + '__input--disabled']: !onUpdate || disabled,
            checked: isChecked,
          })}
          checked={isChecked}
          id={id}
          name={name}
          onChange={this.onChange}
          type={'checkbox'}
          value={isMultiple ? value : '1'}
        />{' '}
        <label
          className={`${optionClass}__label ${optionClass}__label--${category}`}
          htmlFor={id}
          style={{
            backgroundColor: bgColor,
            borderColor: bgColor,
            color: bgColor && !lightColors.includes(bgColor) ? 'white' : 'none',
          }}
        >
          {value}
        </label>
      </div>
    )
  }
}
