// Copyright Northcote Technology Ltd
import React, { useContext } from 'react'

import DynamicSelect from '../DynamicSelect'

import TranslationsContext from '../contexts/Translations'

export default function AircraftTypeSelect({ data, name, onChange, value }) {
  const translations = useContext(TranslationsContext)
  const label =
    translations.activerecord.attributes.grading_session_template.aircraft_type
  const handleChange = ({ target: { value } }) =>
    onChange(value ? parseInt(value) : null)

  return (
    <tr>
      <th>
        <label>{label}</label>
      </th>
      <td>
        <DynamicSelect
          clearable={true}
          currentValue={value?.toString()}
          multi={false}
          name={name}
          noResultsText={translations.messages.aircraft_types.none_found}
          onSelect={handleChange}
          placeholderText={label}
          values={data.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          }))}
        />
      </td>
    </tr>
  )
}
