// Copyright Northcote Technology Ltd
import React from 'react'
import { isEmpty } from 'lodash'

import simpleFormat from '../../src/lib/simpleFormat'

export default function Category({ children, type }) {
  if (isEmpty(children)) return null

  return (
    <div className={`category category--${type}`}>{simpleFormat(children)}</div>
  )
}
