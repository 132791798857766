// Copyright Northcote Technology Ltd
import React, { Component } from 'react'

import PropTypes from 'prop-types'

export default class Tag extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    removable: PropTypes.bool.isRequired,
    identifier: PropTypes.string,
    onRemove: PropTypes.func,
  }

  constructor(props) {
    super(props)
  }

  removeTag = () => {
    const { onRemove, identifier } = this.props
    if (onRemove) onRemove(identifier)
  }

  render() {
    const { text, removable } = this.props

    return (
      <div className={'quantum-layout__tag'}>
        {text}
        {removable ? (
          <div
            className={'quantum-layout__tag--remove'}
            onClick={this.removeTag}
          />
        ) : null}
      </div>
    )
  }
}
