// Copyright Northcote Technology Ltd
import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import TranslationsContext from './contexts/Translations'
import { deleteRecording, updateRecording } from '../redux/app'
import { moodsLabels } from '../src/lib/behaviourUtils.js'
import { personName } from '../src/lib/peopleHelper'

import RecordingForm from './RecordingForm'

function getPersonName(id, session, translations) {
  const { gradingSessionResults, gradingSessionTemplate } = session

  if (gradingSessionTemplate.gradeAsCrew) {
    return translations.ubf.crew
  } else {
    const result = gradingSessionResults.find(r => r.personId == id)
    if (!result) return null
    return personName(result.person)
  }
}

function RecordingTemCategory(props) {
  const { category, recordingTems } = props
  const hasCategory = recordingTems.find(recordingTem =>
    recordingTem.temCategoriesId.includes(category.id)
  )
  const className = classnames({
    ['recording-card__tem-' + category.name.toLowerCase()]: hasCategory,
  })

  return <span className={className}>{category.name}</span>
}

function ShowRecording(props) {
  const { onDelete, onEdit, recording, session } = props
  const { activitiesData, behavioursData, temsData } = session
  const {
    gradingSessionTemplate: { gradeAsCrew },
  } = session

  const translations = useContext(TranslationsContext)

  const activityName =
    activitiesData[recording.activityId]?.name ||
    translations.activerecord.errors.models.recording.attributes.activity

  const personObservations = gradeAsCrew
    ? recording.recordingPersonBehaviours.slice(0, 1)
    : recording.recordingPersonBehaviours

  return (
    <div className="recording-card">
      <div className="recording-card__header">
        {activityName}
        <div
          className="right ml20 recording-card__icon pointer"
          onClick={onEdit}
        >
          <a className="icon icon--medium icon--pencil icon--black"></a>
        </div>
        <div className="right recording-card__icon pointer" onClick={onDelete}>
          <a className="icon icon--medium icon--trash icon--black"></a>
        </div>
      </div>
      <div className="recording-card__tem">
        {temsData.temCategories.map(category => (
          <RecordingTemCategory
            category={category}
            key={category.id}
            recordingTems={recording.recordingTems}
          />
        ))}
      </div>
      <hr />
      <div className="recording-card__comment">{recording.comments}</div>
      <div className="recording-card__list">
        {personObservations.map(({ observations, personId }) => (
          <div key={personId}>
            <div className="recording-card__person">
              {getPersonName(personId, session, translations)}
            </div>
            {observations.map(({ behaviourId, mood }) => (
              <div
                key={behaviourId}
                className={`recording-card__behaviour label-tag label-tag__mood label-tag__mood--${moodsLabels[mood]}`}
              >
                {behavioursData[behaviourId]?.name}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default function RecordingCard(props) {
  const { recording, session } = props
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useDispatch()
  const handleSave = async state => {
    await dispatch(updateRecording(session.id, state))
    setIsEditing(false)
  }
  const handleDelete = () => dispatch(deleteRecording(session.id, recording))

  return isEditing ? (
    <RecordingForm
      initialState={recording}
      onCancel={() => setIsEditing(false)}
      onSave={handleSave}
      session={session}
    />
  ) : (
    <ShowRecording
      onDelete={handleDelete}
      onEdit={() => setIsEditing(true)}
      recording={recording}
      session={session}
    />
  )
}
