// Copyright Northcote Technology Ltd
import { SET_ONLINE } from '../actionTypes'

const initialState = window.navigator.onLine

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE:
      return action.online
    default:
      return state
  }
}
