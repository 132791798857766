// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getComplianceStore,
  getFlexLayoutStore,
  getBottomButtonsStore,
} from '../../redux/selectors'
import {
  setBottomButtons,
  bottomButtonClicked,
  updateComplianceItem,
} from '../../redux/actions'
import PropTypes from 'prop-types'
import { EntitiesSelector } from '../common/EntitiesSelector'
import { update_request } from '../../src/lib/requestToServer'
import { Checkbox } from '../common/Checkbox'
import { camelCase } from 'lodash'
import translate from 'src/lib/translate'
import DynamicSelect from '../DynamicSelect'
import {
  get_request,
  save_request,
  delete_request,
} from 'src/lib/requestToServer'
import moment from 'moment'
import { selectComplianceItem } from '../../redux/actions'

class Data extends Component {
  static propTypes = {
    comData: PropTypes.object,
    options: PropTypes.shape({
      templates: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      qualifications: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      activities: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      gradingScales: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      behaviours: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      deviceTypes: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      documentChapters: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      reasons: PropTypes.shape({
        values: PropTypes.array.isRequired,
        currentValues: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        placeholderText: PropTypes.string.isRequired,
        currentValue: PropTypes.array,
        sessionTemplateLabel: PropTypes.string,
      }),
      prefixClass: PropTypes.string,
    }),
    translations: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    setBottomButtons: PropTypes.func,
    bottomButtonClicked: PropTypes.func,
    updateComplianceItem: PropTypes.func,
    selectComplianceItem: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      info: props.comData.info,
      editMode: false,
      tabSelected: null,
      submitting: false,
    }

    if (props.comData.info) this.setButtons()

    this.selectComplianceItem = props.selectComplianceItem
    this.updateComplianceItem = props.updateComplianceItem
  }

  setButtons = (editMode = false) => {
    let buttons = []
    if (!editMode && this.props.editable) {
      buttons = [translate('edit', this.props.translations)]
    } else if (editMode) {
      buttons = [
        translate('save', this.props.translations),
        //translate('delete', this.props.translations)  //TO BE ENABLED IN FUTURE
      ]
    }
    this.props.setBottomButtons(buttons)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { info, tabSelected, submitting } = this.state

    const { flexlayout, bottomButtons, comData } = newProps

    if (flexlayout && flexlayout.tabSelected != tabSelected) {
      if (flexlayout.tabSelected == 'data') {
        this.setState({ editMode: false })
        this.setButtons()
      }
      this.setState({ tabSelected: flexlayout.tabSelected })
    }

    if (!submitting && bottomButtons && bottomButtons.clicked) {
      if (bottomButtons.clicked == translate('edit', this.props.translations)) {
        this.props.bottomButtonClicked(null)
        this.onEdit()
      } else if (
        bottomButtons.clicked == translate('save', this.props.translations)
      ) {
        this.setState({ submitting: true })
        this.props.bottomButtonClicked(null)
        this.onSubmit()
      }
      return
    }

    if (!submitting && comData && comData.info != info) {
      this.setState({ info: comData.info, editMode: false })
      this.setButtons(false)
    }
  }

  textFieldNames = () => {
    return [
      'text',
      'remarks',
      'category',
      'amending_regulation',
      'revision',
      'document_reference',
      'version',
    ]
  }

  dateFieldNames = () => {
    return ['introduction_date', 'obsolescence_date']
  }

  onSubmit = () => {
    const { info } = this.state

    let context = this

    if (info.id) {
      update_request('/admin/compliance/', info, info.id).then(response => {
        if (response.id) {
          context.updateComplianceItem(response)
          context.setState({
            info: response,
            editMode: false,
            submitting: false,
          })
          context.setButtons()
        } else {
          alert(response.join('. '))
        }
      })
    } else {
      save_request('/admin/compliance/', info).then(response => {
        console.log(response)
        if (response.id) {
          context.setState({ info: response, editMode: false })
        } else {
          alert(response.join('. '))
        }
      })
    }
  }

  onEdit = () => {
    this.setState({ editMode: true })
    this.setButtons(true)
  }

  changeVersion = event => {
    const context = this

    get_request('/admin/compliance', { id: event.target.value }).then(function (
      response
    ) {
      context.setState({ info: response })
      context.updateComplianceItem(response)
    })
  }

  isHighestVersion = () => {
    return !this.state.info.next_sibling_versions
  }

  newVersion = () => {
    const { info } = this.state
    const context = this
    const elementWithHighestVersion = [
      ...info.self_and_sibling_versions,
    ].reduce(function (prev, current) {
      return prev.version > current.version ? prev : current
    })

    if (info.obsolescence_date) {
      get_request('/admin/compliance', {
        id: elementWithHighestVersion.value,
      }).then(function (response) {
        response.id = undefined
        response.version = response.version + 1
        response.introduction_date = moment(response.obsolescence_date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
        response.obsolescence_date = moment(response.obsolescence_date)
          .add(2, 'days')
          .format('YYYY-MM-DD')

        context.updateComplianceItem(response)
        context.setState({ info: response, editMode: true })
        context.setButtons(true)
      })
    } else {
      alert(translate('errorNoObsolenceDate', this.props.translations))
    }
  }

  removeVersion = () => {
    const { info } = this.state
    const context = this

    if (confirm(translate('confirmDelete', this.props.translations))) {
      delete_request('/admin/compliance/', info.id).then(function () {
        context.selectComplianceItem({
          id: info.prev_sibling_versions
            ? info.prev_sibling_versions
            : info.parent_id,
        })
      })
    }
  }

  handleInputChange = event => {
    const { info } = this.state

    const target = event.target
    const value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      info: {
        ...info,
        [name]: value,
      },
    })
  }

  inputFields = options => {
    const { prefixClass } = this.props.options
    const { info } = this.state

    const mainClass = 'compliance__data--field'

    return (
      <div>
        {this.textFieldNames().map(field => {
          return this.inputField(field, 'text')
        })}

        <Checkbox
          name={'not_applicable'}
          label={this.translateField('not_applicable')}
          onUpdate={this.handleInputChange}
          checked={info.not_applicable == true}
          divClass={mainClass}
          labelClass={'ui-btn ui-btn--toggle mb20'}
        />

        {info.not_applicable ? (
          <EntitiesSelector
            key={info.id + 'rea'}
            mainClass={mainClass}
            prefixClass={prefixClass}
            onUpdate={this.handleInputChange}
            currentValues={info.reason_id}
            info={options.reasons}
          />
        ) : (
          ''
        )}

        {this.dateFieldNames().map(field => {
          return this.inputField(field, 'date')
        })}

        <EntitiesSelector
          key={info.id + 'doc'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.document_chapter_ids}
          info={options.documentChapters}
        />
        <EntitiesSelector
          key={info.id + 'tem'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.grading_session_template_ids}
          info={options.templates}
        />
        <EntitiesSelector
          key={info.id + 'qua'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.qualification_ids}
          info={options.qualifications}
        />
        <EntitiesSelector
          key={info.id + 'act'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.activity_ids}
          info={options.activities}
        />
        <EntitiesSelector
          key={info.id + 'gra'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.grading_scale_ids}
          info={options.gradingScales}
        />
        <EntitiesSelector
          key={info.id + 'beh'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.behaviour_ids}
          info={options.behaviours}
        />
        <EntitiesSelector
          key={info.id + 'dev'}
          mainClass={mainClass}
          prefixClass={prefixClass}
          onUpdate={this.handleInputChange}
          currentValues={info.device_type_ids}
          info={options.deviceTypes}
        />
      </div>
    )
  }

  inputField = (field, type) => {
    const { info } = this.state

    const Tag = field == 'text' ? 'textarea' : 'input'
    const inputClass = field == 'text' ? 'full-width-container' : ''

    return (
      <div className="compliance__data--field">
        <span className="compliance__data--label p0">
          {this.translateField(field)}
        </span>
        <Tag
          className={inputClass}
          disabled={field == 'version'}
          key={info.id + field}
          type={type}
          name={field}
          placeholder={this.translateField(field)}
          value={info[field] || ''}
          onChange={this.handleInputChange}
        />
      </div>
    )
  }

  translateField = field => {
    return translate('attributes.' + camelCase(field), this.props.translations)
  }

  viewFields = () => {
    const { info, editable } = this.state

    return (
      <div>
        <div className="compliance__data--title">{info.name_to_show}</div>
        <DynamicSelect
          additionalClass={'compliance__dropdown-version'}
          clearable={false}
          name={translate('versionDropdown.name', this.props.translations)}
          currentValue={info.id ? info.id.toString() : ''}
          noResultsText={translate(
            'versionDropdown.noResultsText',
            this.props.translations
          )}
          onSelect={this.changeVersion}
          placeholderText={translate(
            'versionDropdown.placeholderText',
            this.props.translations
          )}
          values={info.self_and_sibling_versions}
          multi={false}
          styleVersion={2}
        />

        {[...this.textFieldNames(), ...this.dateFieldNames()].map(field => {
          return this.viewField(field)
        })}

        {info.not_applicable ? this.viewValues('reason') : ''}

        {this.viewValues('document_chapter')}
        {this.viewValues('grading_session_template', true)}
        {this.viewValues('qualification', true)}
        {this.viewValues('activity', true)}
        {this.viewValues('grading_scale', true)}
        {this.viewValues('behaviour', true)}
        {this.viewValues('device_type', true)}

        {this.isHighestVersion() ? (
          <a
            className="compliance__buttons-version compliance__buttons-version--remove"
            onClick={this.removeVersion}
          >
            {translate('removeVersion', this.props.translations)}
          </a>
        ) : null}
        {editable ? (
          <a
            className="compliance__buttons-version compliance__buttons-version--edit"
            onClick={this.onEdit}
          >
            {translate('edit', this.props.translations)}
          </a>
        ) : (
          ''
        )}
        <a
          className="compliance__buttons-version compliance__buttons-version--add"
          onClick={this.newVersion}
        >
          {translate('addNewVersion', this.props.translations)}
        </a>
      </div>
    )
  }

  viewField = field => {
    const { info } = this.state

    return (
      <div key={info.id + field} className="compliance__data--field">
        <span
          key={info.id + field + 'lab'}
          className={'compliance__data--label p0'}
        >
          {this.translateField(field)}
        </span>
        <span
          key={info.id + field + 'val'}
          className={'compliance__data--value field-' + field}
        >
          {info[field]}
        </span>
      </div>
    )
  }

  viewValues = (type, big = false) => {
    const { info } = this.state
    const { value_names } = this.state.info

    let values = value_names[type]

    if (!values) return

    return (
      <div className="compliance__data--field">
        <span className={'compliance__data--label p0'}>
          {this.translateField(type)}
        </span>
        <div
          className={
            'compliance__data--value' + (big ? ' full-width-container' : '')
          }
        >
          {values.map((value, index) => {
            return (
              <span
                key={info.id + type + value + index}
                className="compliance__data--multi-value"
              >
                {value}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  showData = () => {
    const { comData, options } = this.props

    const { editMode } = this.state

    if (!comData || !comData.info)
      return translate('nothingSelected', this.props.translations)

    return <div>{editMode ? this.inputFields(options) : this.viewFields()}</div>
  }

  render() {
    return (
      <div className="compliance__data outer-container-right">
        <div className="compliance__data--contents inner-container">
          {this.showData()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const comData = getComplianceStore(state)
  const flexlayout = getFlexLayoutStore(state)
  const bottomButtons = getBottomButtonsStore(state)
  return { comData, flexlayout, bottomButtons }
}

export default connect(mapStateToProps, {
  setBottomButtons,
  bottomButtonClicked,
  updateComplianceItem,
  selectComplianceItem,
})(Data)
