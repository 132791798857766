// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getFlexLayoutStore,
  getGradingSessionStore,
} from '../../redux/selectors'
import { get_request } from '../../src/lib/requestToServer'
import Table from '../common/Table'
import moment from 'moment'

class GradingSessionTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      maximized: false,
    }

    if (window.navigator.onLine) {
      get_request('/unsubmitted_sessions/', {
        ui: true,
        count: true,
        for_dashboard: true,
      }).then(totalElements => {
        this.getSubsetSessions(0, totalElements)
      })
    }
  }

  getSubsetSessions = (step, totalElements) => {
    const { data } = this.state

    let offset = step * UBF.tableBatchRetrievalElements
    get_request('/unsubmitted_sessions/', {
      ui: true,
      limit: UBF.tableBatchRetrievalElements,
      offset: offset,
      for_dashboard: true,
    }).then(response => {
      let currentData = data || []
      this.setState({
        data: currentData.concat(this.prepareResponse(response)),
      })

      if (offset < totalElements)
        this.getSubsetSessions(step + 1, totalElements)
    })
  }

  prepareResponse = response => {
    let data = []
    response.map(row => {
      data.push(this.prepareRow(row))
    })
    return data
  }

  prepareRow = row => {
    let r = []
    r.push(row.id)
    r.push(row.graded_date)
    r.push(row.grading_session_template)
    r.push(row.grader)
    r.push(row.status)
    return r
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { flexlayout, gradingSessionData } = newProps
    const { maximized, data } = this.state

    if (
      flexlayout &&
      flexlayout.maximized != null &&
      flexlayout.maximized != maximized
    ) {
      this.setState({ maximized: flexlayout.maximized })
    }

    if (
      gradingSessionData &&
      gradingSessionData.info != null &&
      gradingSessionData.info != data &&
      Array.isArray(gradingSessionData.info)
    ) {
      this.setState({ data: gradingSessionData.info })
    }
  }

  userTypeByIdentifier = identifier => {
    const { userTypeDropdownOptions } = this.props

    return userTypeDropdownOptions.filter(x => x.value == identifier)[0].label
  }

  datatable = () => {
    const { data } = this.state

    const { translations } = this.props

    const columns = [
      {
        name: 'Id',
        options: {
          filter: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.grading_session.graded_date,
        options: {
          filter: false,
          filterType: 'date',
          customBodyRender: value => {
            return (
              <div className="">
                {moment(value).format(UBF.globalDateFormat)}
              </div>
            )
          },
        },
      },
      {
        name: translations.activerecord.attributes.grading_session
          .grading_session_template,
        options: {
          filter: false,
          customBodyRender: value => {
            let session_template = value ? value.name : ''
            return (
              <div>
                <span key={session_template}>{session_template}</span>
              </div>
            )
          },
        },
      },
      {
        name: translations.activerecord.attributes.grading_session.grader,
        options: {
          filter: false,
          display: false,
          customBodyRender: value => {
            let grader = value ? value.name : ''
            return (
              <div>
                <span
                  className="quantum-layout-options__multi-value__label"
                  key={grader}
                >
                  {grader}
                </span>
              </div>
            )
          },
        },
      },
      {
        name: translations.activerecord.attributes.grading_session.status,
        options: {
          filter: false,
          display: true,
          customBodyRender: value => {
            return (
              <div>
                {
                  <span
                    className={'label-tag label-tag--large label-tag--' + value}
                  >
                    {translations.ubf.grading_session_statuses[value]}
                  </span>
                }
              </div>
            )
          },
        },
      },
    ]

    return {
      columns: columns,
      data: data,
    }
  }

  render() {
    const datatable = this.datatable()
    if (this.state.data)
      return (
        <div className="dashboard__table">
          <Table
            data={datatable.data}
            columns={datatable.columns}
            translations={this.props.translations}
            reduceHeightPx={225}
            showToolbar={false}
          />
        </div>
      )
    else return ''
  }
}

const mapStateToProps = state => {
  const flexlayout = getFlexLayoutStore(state)
  const gradingSessionData = getGradingSessionStore(state)
  return { flexlayout, gradingSessionData }
}

export default connect(mapStateToProps, {})(GradingSessionTable)
