// Copyright Northcote Technology Ltd
import React, { useContext } from 'react'
import { groupBy, sortBy } from 'lodash'

import TranslationsContext from '../contexts/Translations'

import Row from './Row'

export default function Timeline({ activitiesData, events }) {
  const translations = useContext(TranslationsContext)

  if (events.length === 0) {
    return (
      <p className="centred-message">
        {translations.messages.orca.no_timeline}
      </p>
    )
  }

  const rows = Object.values(groupBy(sortBy(events, 'row'), 'row'))

  return (
    <div className="timeline">
      {rows.map((row, i) => (
        <Row activitiesData={activitiesData} events={row} key={i} />
      ))}
    </div>
  )
}
