// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'

const gradingProps = {
  behaviourIds: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  qualificationId: PropTypes.number.isRequired,
  graded: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  sessionName: PropTypes.string.isRequired,
  sessionLink: PropTypes.string,
}

export default gradingProps
