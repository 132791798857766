// Copyright Northcote Technology Ltd
import { groupBy, partition, sortBy, uniq } from 'lodash'

function groupAndSortGradeByActivityGradings(
  gradings,
  timelineItems,
  activitiesData
) {
  const remainingActivityIds = new Set(
    gradings.map(({ activityId }) => activityId)
  )
  const activityGradingGroups = groupBy(
    gradings,
    ({ activityId }) => activityId
  )

  // Grading groups are first sorted by the order that their activity first
  // appears in the timeline.
  const sortedGradingGroups = uniq(
    sortBy(timelineItems, ['row', 'column'])
      .map(({ activityId }) => activityId)
      .filter(activityId => activityId)
  ).map(activityId => {
    remainingActivityIds.delete(activityId)

    // Note that the timeline may include activities that aren't present in the
    // list of grade-by-actvity gradings - so the following may be `undefined`.
    return activityGradingGroups[activityId]
  })

  // The remaining activities are sorted alphabetically by name and appended to
  // the list of grading groups.
  sortBy(
    Array.from(remainingActivityIds),
    activityId => activitiesData[activityId].name
  ).forEach(activityId => {
    sortedGradingGroups.push(activityGradingGroups[activityId])
  })

  // Remove any `undefined` timeline activity grading groups.
  return sortedGradingGroups.filter(group => group)
}

function sortGradeBySessionGradings(gradings, qualificationsData) {
  return sortBy(
    gradings,
    ({ qualificationId }) => qualificationsData[qualificationId].name
  )
}

// The passed gradings are partitioned into grade-by-activity and
// grade-by-session groups. The grade-by-activity group is itself grouped by
// activity and sorted first by the activity's first appearance in the timeline
// and then by the activity's name. The grade-by-session group is sorted by
// qualification name.
export default function (gradings, session) {
  const {
    activitiesData,
    gradingSessionTemplate: { sessionTemplateTimelines },
    qualificationsData,
  } = session
  const [gradeByActivityGradings, gradeBySessionGradings] = partition(
    gradings,
    ({ activityId }) => activityId
  )
  const sortedGradingGroupsByActivity = groupAndSortGradeByActivityGradings(
    gradeByActivityGradings,
    sessionTemplateTimelines,
    activitiesData
  )
  const sortedGradingsBySession = sortGradeBySessionGradings(
    gradeBySessionGradings,
    qualificationsData
  )
  return [sortedGradingGroupsByActivity, sortedGradingsBySession]
}
