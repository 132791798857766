// Copyright Northcote Technology Ltd
import React, { useContext, useReducer } from 'react'

import TranslationsContext from './contexts/Translations'

import Button from './Button'
import DynamicSelect from './DynamicSelect'
import Modal from './Modal2'

function reducer(state, action) {
  const { categoryId } = action
  const temIds = new Set(action.values.map(id => parseInt(id)))

  // Remove TEMs that aren't present in the new list of ids.
  const newState = state.filter(tem => {
    const { temCategoriesId, temId } = tem

    if (!temCategoriesId.includes(categoryId)) return true

    if (temIds.has(temId)) {
      temIds.delete(temId)
      return true
    } else {
      return false
    }
  })

  // Add new TEMs.
  temIds.forEach(temId =>
    newState.push({ temCategoriesId: [categoryId], temId })
  )

  return newState
}

export default function RecordingFormTemsModal(props) {
  const translations = useContext(TranslationsContext)
  const {
    onCancel,
    onSave,
    recording,
    session: {
      temsData: { tems, temCategories },
    },
  } = props
  const [recordingTems, dispatch] = useReducer(reducer, recording.recordingTems)
  const categoryValues = temCategories
    .map(category => ({ category, values: tems[category.id] }))
    .filter(({ values }) => values)

  return (
    <Modal className="recording-form-tems-modal">
      <h1>{translations.ubf.modal.tem}</h1>

      <table>
        <tbody>
          {categoryValues.map(({ category, values }) => {
            const identifier = category.name.toLowerCase()
            const selected = recordingTems
              .filter(({ temCategoriesId }) =>
                temCategoriesId.includes(category.id)
              )
              .map(({ temId }) => temId.toString())

            return (
              <tr key={category.id}>
                <th className={`recording-form-tems-modal__th-${identifier}`}>
                  {
                    translations.activerecord.attributes.recording.tem[
                      identifier
                    ]
                  }
                </th>
                <td>
                  <DynamicSelect
                    additionalClass={`recording-form-tems-modal__select-${identifier}`}
                    clearable={true}
                    currentValue={selected}
                    multi={true}
                    name={`tem_${identifier}`}
                    noResultsText=""
                    onSelect={({ target: { value } }) =>
                      dispatch({ categoryId: category.id, values: value })
                    }
                    placeholderText={category.name}
                    values={values}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="recording-form-tems-modal__actions">
        <Button onClick={onCancel}>{translations.ubf.cancel}</Button>
        <Button onClick={() => onSave(recordingTems)}>
          {translations.ubf.save}
        </Button>
      </div>
    </Modal>
  )
}
