// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['connected', 'disconnected']

  connect() {
    this.checkStatus()
    this.interval = setInterval(this.checkStatus, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  checkStatus = () => {
    const onLine = window.navigator.onLine

    this.connectedTarget.classList.toggle('is-active', onLine)
    this.disconnectedTarget.classList.toggle('is-active', !onLine)
  }
}
