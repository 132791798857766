// Copyright Northcote Technology Ltd
import React from 'react'
import FlexLayout from 'flexlayout-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Footer from './Footer'
import Header from './common/Header'

import { flexlayoutTabSelected, isMaximized } from '../redux/actions'

document.addEventListener('touchend', function (e) {
  var currentNode = e.target

  if (
    currentNode.classList.contains('flexlayout__tab_toolbar_button-min') ||
    currentNode.classList.contains('flexlayout__tab_toolbar_button-max')
  ) {
    e.preventDefault()
    currentNode.click()
  }
})

class QuantumLayout extends React.Component {
  static propTypes = {
    // Not actually used in this file but by receiving this prop we can force a
    // re-render when it changes.
    online: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types

    backAction: PropTypes.func.isRequired,
    controlledFooterButtons: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    leftPaneComponents: PropTypes.array.isRequired,
    rightPaneComponents: PropTypes.array,
    customFactory: PropTypes.func.isRequired,
    customIconFactory: PropTypes.func,
    flexlayoutTabSelected: PropTypes.func,
    customClassMapper: PropTypes.func,
    leftMaximized: PropTypes.bool,
    isMaximized: PropTypes.func,
    disableHome: PropTypes.bool,
    disableBack: PropTypes.bool,
    multipleTab: PropTypes.bool,
    enableMaximizeRight: PropTypes.bool,
    leftActiveComponentIndex: PropTypes.number,
    rightActiveComponentIndex: PropTypes.number,
  }

  constructor(props) {
    super(props)

    let jsonModel = this.jsonModel()
    let model = FlexLayout.Model.fromJson(jsonModel)

    this.state = {
      maximized: props.leftMaximized ? props.leftMaximized : false,
      jsonModel: jsonModel,
      model: model,
    }

    this.props.isMaximized(this.state.maximized)
  }

  jsonModel = (newProps = false) => {
    let props = this.props
    if (newProps) props = newProps

    const {
      leftPaneComponents,
      rightPaneComponents,
      leftMaximized,
      multipleTab,
      enableMaximizeRight,
      leftActiveComponentIndex,
      rightActiveComponentIndex,
    } = props

    if (
      this.state &&
      leftMaximized != undefined &&
      leftMaximized != this.state.maximized
    )
      this.setState({ maximized: leftMaximized })

    let singleTab = !rightPaneComponents

    let paneChildren = [
      {
        id: 'left-pane',
        type: 'tabset',
        classNameTabStrip: 'left-pane',
        enableMaximize: !singleTab,
        enableTabStrip: multipleTab,
        weight: 50,
        selected: leftActiveComponentIndex || 0,
        children: leftPaneComponents,
      },
    ]

    if (!singleTab) {
      paneChildren.push({
        id: 'right-pane',
        type: 'tabset',
        classNameTabStrip: 'right-pane',
        enableMaximize: !!enableMaximizeRight,
        weight: leftMaximized ? 0 : 50,
        selected: rightActiveComponentIndex || 0,
        children: rightPaneComponents,
      })
    }

    return {
      global: {
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetHeaderHeight: 50,
        tabSetTabStripHeight: 50,
        tabEnableRename: false,
        splitterSize: 0,
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            children: [
              {
                type: 'tabset',
                enableMaximize: false,
                enableTabStrip: false,
                selected: 0,
                height: 80,
                children: [
                  {
                    type: 'tab',
                    name: 'topbar',
                    component: 'Header',
                  },
                ],
              },
              {
                type: 'row',
                children: paneChildren,
              },
              {
                type: 'tabset',
                enableMaximize: false,
                enableTabStrip: false,
                selected: 0,
                height: 90,
                children: [
                  {
                    type: 'tab',
                    component: 'Footer',
                  },
                ],
              },
            ],
          },
        ],
      },
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let jsonModel = this.jsonModel(newProps)
    if (JSON.stringify(this.state.jsonModel) !== JSON.stringify(jsonModel)) {
      this.setState({
        jsonModel: jsonModel,
        model: FlexLayout.Model.fromJson(jsonModel),
      })
    }
  }

  factory = node => {
    const {
      backAction,
      controlledFooterButtons,
      customFactory,
      subtitle,
      disableHome,
      disableBack,
      title,
    } = this.props

    var component = node.getComponent()

    if (component === 'Footer') {
      return <Footer controlledFooterButtons={controlledFooterButtons} />
    } else if (component === 'Header') {
      return (
        <Header
          backAction={backAction}
          title={title}
          subtitle={subtitle}
          greeting={UBF.lang.greeting}
          userName={UBF.user.name}
          userType={UBF.user.type}
          menuElements={UBF.menu}
          adminMenuElements={UBF.adminMenu}
          disableHome={disableHome}
          disableBack={disableBack}
        />
      )
    } else {
      return customFactory(node)
    }
  }

  classMapper = className => {
    const { customClassMapper } = this.props

    var additionalClass = ''
    var suffix = 'quantum-layout__'
    var replaceableClasses = [
      'flexlayout__layout',
      'flexlayout__tab',
      'flexlayout__tabset',
      'flexlayout__tab_button_content',
    ]
    if (replaceableClasses.includes(className))
      additionalClass = className.replace('flexlayout__', suffix)

    if (className === 'flexlayout__tab_button_leading') {
      return suffix + 'tab_leading'
    }
    if (className === 'flexlayout__tab_toolbar') {
      additionalClass =
        suffix +
        'tab_toolbar ' +
        (this.state.maximized ? suffix + 'tab_toolbar--max' : '')
    }
    if (className === 'flexlayout__tab_toolbar_button-min') {
      return this.state.maximized
        ? ' flexlayout__tab_toolbar_button-max'
        : ' flexlayout__tab_toolbar_button-min'
    }

    if (customClassMapper) additionalClass += ' ' + customClassMapper(className)

    return className + ' ' + additionalClass
  }

  iconFactory = node => {
    const { customIconFactory } = this.props

    if (customIconFactory) return customIconFactory(node)
  }

  updateMaximizedView = action => {
    const { model, maximized } = this.state
    if (
      this.props.rightPaneComponents == {} ||
      this.props.rightPaneComponents == null
    )
      return

    let leftWeight = 50
    let rightWeight = 50

    if (!maximized) {
      const side = action.data.node
      leftWeight = side == 'left-pane' ? 100 : 0
      rightWeight = side == 'right-pane' ? 100 : 0
    }

    this.setState({ maximized: !maximized })
    this.props.isMaximized(!maximized)
    model.doAction(
      FlexLayout.Actions.updateNodeAttributes('left-pane', {
        weight: leftWeight,
      })
    )
    model.doAction(
      FlexLayout.Actions.updateNodeAttributes('right-pane', {
        weight: rightWeight,
      })
    )
  }

  onAction = action => {
    if (action.type === FlexLayout.Actions.MAXIMIZE_TOGGLE) {
      this.updateMaximizedView(action)
      return
    }

    if (action.type == FlexLayout.Actions.SELECT_TAB) {
      this.props.flexlayoutTabSelected(action.data.tabNode)
    }

    return action
  }

  render() {
    const { model } = this.state

    return (
      <FlexLayout.Layout
        model={model}
        factory={this.factory}
        classNameMapper={this.classMapper}
        onAction={this.onAction}
        iconFactory={this.iconFactory}
      />
    )
  }
}

export default connect(null, { flexlayoutTabSelected, isMaximized })(
  QuantumLayout
)
