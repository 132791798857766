// Copyright Northcote Technology Ltd
import { intersection } from 'lodash'

export const personName = person => `${person.firstNames} ${person.lastNames}`

export function personDisplay(person) {
  return (
    (person.firstNames || person.firstName) +
    ' ' +
    (person.lastNames || person.lastName) +
    (person.identifier ? ' (#' + person.identifier + ')' : '')
  )
}

export function isPersonInGroups(person, groupIds) {
  return (
    intersection(
      person.groups.map(id => id.toString()),
      groupIds
    ).length > 0
  )
}
