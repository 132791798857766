// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import localizedDate from 'src/lib/localizedDate'
import PeriodBoundary from './PeriodBoundary'
import { PERIOD_TIMELINE_HEIGHT } from './PeriodTimeline'
import periodProps from 'proptypes/periodProps'

class Period extends PureComponent {
  static propTypes = {
    ...periodProps,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ isActive: true })
  }

  handleMouseLeave = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { finish, scale, start, t } = this.props

    const { isActive } = this.state

    const startDate = moment(start).toDate()
    const finishDate = moment(finish).endOf('day').toDate()

    const startPosition = scale(startDate)
    const finishPosition = scale(finishDate)

    const localizedStartDate = localizedDate(startDate)
    const localizedFinishDate = localizedDate(finishDate)

    return (
      <div
        className="period"
        data-start={localizedStartDate}
        data-finish={localizedFinishDate}
        style={{ left: startPosition }}
      >
        <div
          className="period__block"
          style={{
            height: PERIOD_TIMELINE_HEIGHT,
            width: finishPosition - startPosition,
          }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <PeriodBoundary
            alignment="top"
            boundaryType="period"
            flagDirection="left"
            flagTextLines={[t('ubf.start'), localizedStartDate]}
            isFlagVisible={isActive}
            isDoubleHeaded={true}
            protrusionSize={0}
          />

          <PeriodBoundary
            alignment="top"
            boundaryType="period"
            flagDirection="right"
            flagTextLines={[t('ubf.finish'), localizedFinishDate]}
            isFlagVisible={isActive}
            isDoubleHeaded={true}
            protrusionSize={0}
          />
        </div>
      </div>
    )
  }
}

export default Period
