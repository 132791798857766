// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  PERIOD_TIMELINE_HEIGHT,
  PERIOD_TIMELINE_PADDING,
} from './PeriodTimeline'

import PeriodTimeline from './PeriodTimeline'
import roleProps from 'proptypes/roleProps'

import AwardRow from './AwardRow'

const ROW_PADDING = 30
export const ROW_QUALIFICATION_ROW_HEIGHT =
  2 * ROW_PADDING + 2 * PERIOD_TIMELINE_PADDING + PERIOD_TIMELINE_HEIGHT

class RoleQualificationRow extends Component {
  static propTypes = {
    role: PropTypes.shape(roleProps).isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { role, scale, t } = this.props

    const { qualifiedPeriods, requiredQualifications } = role

    const awardRows = requiredQualifications.map(qualification => {
      return (
        <AwardRow
          key={qualification.id}
          qualification={qualification}
          scale={scale}
          t={t}
        />
      )
    })

    return (
      <div className="role-qualification-row">
        <div
          className="role-qualification-row__content"
          style={{
            paddingBottom: ROW_PADDING,
            paddingTop: ROW_PADDING,
            width: scale.range()[1],
          }}
        >
          <PeriodTimeline
            qualifiedPeriods={qualifiedPeriods}
            scale={scale}
            t={t}
          />
        </div>

        {awardRows}
      </div>
    )
  }
}

export default RoleQualificationRow
