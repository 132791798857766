// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { delete_request } from '../../src/lib/requestToServer'

export default class DeleteSessionButton extends PureComponent {
  static propTypes = {
    sessionId: PropTypes.number.isRequired,
    reloadSessions: PropTypes.func.isRequired,
    confirmMessage: PropTypes.string.isRequired,
  }

  deleteSession = () => {
    if (confirm(this.props.confirmMessage)) {
      delete_request('unsubmitted_sessions/', this.props.sessionId).then(
        async () => {
          await this.props.reloadSessions()
        }
      )
    }
  }

  render() {
    return (
      <IconButton
        className="right"
        aria-label="delete"
        onClick={this.deleteSession}
      >
        <span className="icon icon--trash icon--black sessions__item--delete" />
      </IconButton>
    )
  }
}
