// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import gradingScaleProps from '../proptypes/gradingScaleProps'
import updateGradingObservations from '../src/lib/updateGradingObservations'

import GradeInputWrapper from './GradeInputWrapperClassic'

export default class StandaloneGradeInput extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    gradedDate: PropTypes.string.isRequired,
    grading: PropTypes.shape({
      activityId: PropTypes.number,
      additionalInfo: PropTypes.object,
      allowUploads: PropTypes.bool,
      documentId: PropTypes.string,
      fileUploadName: PropTypes.string,
      grade: PropTypes.string,
      id: PropTypes.number,
      qualificationId: PropTypes.number,
      repeated: PropTypes.bool,
      hasApplicableFromDate: PropTypes.bool,
      hasApplicableUntilDate: PropTypes.bool,
      counter: PropTypes.number,
    }).isRequired,
    gradingNames: PropTypes.shape({
      activityId: PropTypes.string,
      additionalInfo: PropTypes.object.isRequired,
      documentId: PropTypes.string,
      counter: PropTypes.string.isRequired,
      grade: PropTypes.string.isRequired,
      id: PropTypes.string,
      qualificationId: PropTypes.string,
      repeated: PropTypes.string.isRequired,
    }).isRequired,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    label: PropTypes.string,
    translations: PropTypes.object.isRequired,
    errorsByQualification: PropTypes.array,
    deferable: PropTypes.bool.isRequired,
  }

  state = {
    grading: this.props.grading,
  }

  componentDidMount() {
    if (this.props.grading.id) {
      this.onUpdate(this.props.grading)
    }
  }

  handleObservationsChange = newObservations => {
    this.setState({
      grading: updateGradingObservations(this.state.grading, newObservations),
    })
  }

  /* eslint react/no-direct-mutation-state: "off" */
  onUpdate = grading => {
    if (grading.id && !grading.server_id) {
      grading.server_id = grading.id
    }

    if (grading.files.length != this.state.grading.files.length) {
      var lastFileAdded = grading.files[grading.files.length - 1]
      var findReplace = this.state.grading.files.find(element => {
        return element.ref.id == lastFileAdded.ref.id
      })

      if (findReplace && findReplace.ref) {
        findReplace.id = lastFileAdded.id
        findReplace.name = lastFileAdded.name
      }
    }
    this.setState({ grading: findReplace ? this.state.grading : grading })
  }

  render() {
    const {
      directUploadsUrl,
      gradedDate,
      gradingNames,
      gradingScale,
      label,
      translations,
      errorsByQualification,
      deferable,
    } = this.props

    return Object.keys(this.state.grading).length > 0 ? (
      <GradeInputWrapper
        directUploadsUrl={directUploadsUrl}
        gradedDate={gradedDate}
        grading={this.state.grading}
        gradingNames={gradingNames}
        gradingScale={gradingScale}
        includeObservationInputs={true}
        label={label}
        deferable={deferable}
        onObservationsChange={this.handleObservationsChange}
        onUpdate={this.onUpdate}
        translations={translations}
        errorsByQualification={errorsByQualification}
        errorsByGradings={this.state.errors}
      />
    ) : null
  }
}
