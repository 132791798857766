// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreatableListInput from './CreatableListInput'
import ColorPicker from './common/ColorPicker'

export default class OverallGradingEditor extends Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    nameError: PropTypes.array,
    translations: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      name: props.name,
      options: props.options.sort(this.sortingRule),
    }
  }

  sortingRule = (a, b) => a.label.localeCompare(b.label)

  setOption = option => {
    const { options } = this.state

    this.setState({
      options: [...options.filter(o => o.label != option.label), option].sort(
        this.sortingRule
      ),
    })
  }

  changeRule = (value, label, field) => {
    const { options } = this.state

    if (field != 'color' && !Number.isInteger(Number(value))) value = ''

    let option = options.find(o => o.label === label)
    option[field] = value

    this.setOption(option)
  }

  numberInput = (option, field) => (
    <td>
      <input
        type="text"
        className="text-center w50p m-auto"
        name={`overall_grading[overall_grading_options_attributes][][${field}]`}
        onChange={event =>
          this.changeRule(event.target.value, option.label, field)
        }
        value={option[field]}
      />
    </td>
  )

  availableColors = () => [
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-other-color'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-disqualifying-color'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-failing-color'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-minimum_acceptable-color'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-acceptable-color'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--grade-exemplary-color'
    ),
  ]

  optionRule = option => (
    <tr key={option.label} data-label={option.label}>
      <th>
        <div>{option.label}</div>
        <input
          type="hidden"
          name={`overall_grading[overall_grading_options_attributes][][id]`}
          value={option.id || ''}
        />
        <input
          type="hidden"
          name={`overall_grading[overall_grading_options_attributes][][label]`}
          value={option.label}
        />
        <input
          type="hidden"
          name={`overall_grading[overall_grading_options_attributes][][color]`}
          value={option.color}
        />
      </th>
      {this.numberInput(option, 'min_disqualifying_grades')}
      {this.numberInput(option, 'min_failing_grades')}
      {this.numberInput(option, 'min_minimum_acceptable_grades')}
      <td data-label={option.label}>
        <ColorPicker
          currentColor={option.color}
          colors={this.availableColors()}
          onUpdate={color => this.changeRule(color, option.label, 'color')}
        />
      </td>
    </tr>
  )

  changeOptions = event => {
    const { options } = this.state

    const eventValues = event.target.value.map(v => v.label)
    const currentOptions = options.map(v => v.label)
    const oldOptions = options.filter(o => eventValues.includes(o.label))
    const newOptions = eventValues.filter(v => !currentOptions.includes(v))

    this.setState({
      options: [
        ...oldOptions,
        ...newOptions.map(o => {
          return {
            id: this.props.options.find(opt => opt.label === o)?.id,
            label: o,
            min_disqualifying_grades: '',
            min_failing_grades: '',
            min_minimum_acceptable_grades: '',
            color: this.availableColors()[0],
          }
        }),
      ].sort(this.sortingRule),
    })
  }

  removedOptions = () => {
    const removedOptions = this.props.options
      .filter(o => !this.state.options.map(oo => oo.label).includes(o.label))
      .filter(o => o.id != null)

    return removedOptions.map(o => (
      <>
        <input
          type="hidden"
          name={`overall_grading[overall_grading_options_attributes][][id]`}
          value={o.id}
        />
        <input
          type="hidden"
          name={`overall_grading[overall_grading_options_attributes][][_destroy]`}
          value="1"
        />
      </>
    ))
  }

  render() {
    const { translations, nameError } = this.props

    const { name, options } = this.state

    const currentOptions = options.map(o => o.label)

    return (
      <div>
        {this.removedOptions()}
        <table className="table table-compact table-nowrapHeaders">
          <tbody>
            <tr>
              <th>
                {translations.activerecord.attributes.overallGrading.name}
              </th>
              <td>
                <input
                  type="text"
                  className="required-field"
                  name="overall_grading[name]"
                  placeholder={
                    translations.activerecord.attributes.overallGrading.name
                  }
                  value={name || ''}
                  onChange={event =>
                    this.setState({ name: event.target.value })
                  }
                />
                {nameError.toString() !== '' && (
                  <span className="error">{nameError}</span>
                )}
              </td>
            </tr>
            <tr>
              <th>
                {translations.activerecord.attributes.overallGrading.options}
              </th>
              <td>
                <CreatableListInput
                  currentValues={currentOptions}
                  name={'options[]'}
                  placeholderText={
                    translations.activerecord.attributes.overallGrading.options
                  }
                  onChange={event => this.changeOptions(event)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {translations.activerecord.attributes.overallGrading.rules}
              </th>
              <td>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          translations.activerecord.attributes
                            .overallGradingOption.option
                        }
                      </th>
                      <th className="text-center">
                        {
                          translations.activerecord.attributes
                            .overallGradingOption.disqualifying
                        }
                      </th>
                      <th className="text-center">
                        {
                          translations.activerecord.attributes
                            .overallGradingOption.failing
                        }
                      </th>
                      <th className="text-center">
                        {
                          translations.activerecord.attributes
                            .overallGradingOption.minAcceptable
                        }
                      </th>
                      <th className="text-center">
                        {
                          translations.activerecord.attributes
                            .overallGradingOption.color
                        }
                      </th>
                    </tr>
                    {options.map(o => this.optionRule(o))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
