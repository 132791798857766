// Copyright Northcote Technology Ltd
import React, { useState } from 'react'
import AsyncDynamicSelect from './AsyncDynamicSelect'

export default function RemoteSelect(props) {
  const { url, onUpdate, ...rest } = props
  const [value, setValue] = useState(props.value)

  function apiEndPoint(search, offset) {
    const searchParams = new URLSearchParams()
    searchParams.set('search', search)
    searchParams.set('offset', offset)
    return url + '?' + searchParams.toString()
  }

  function handleSelect(event) {
    setValue(event.target.value)
    if (onUpdate) onUpdate(event)
  }

  return (
    <AsyncDynamicSelect
      {...rest}
      apiEndPoint={apiEndPoint}
      currentValue={value}
      onSelect={handleSelect}
    />
  )
}
