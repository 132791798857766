// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import localizedDate from 'src/lib/localizedDate'

class DateLine extends PureComponent {
  static propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    scale: PropTypes.func.isRequired,
  }

  render() {
    const { date, scale } = this.props

    return (
      <div
        className="date-line"
        style={{
          left: scale(date),
        }}
      >
        <div className="date-line__label">{localizedDate(date)}</div>

        <div className="date-line__line"></div>
      </div>
    )
  }
}

export default DateLine
