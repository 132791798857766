// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import CreatableListInput from './CreatableListInput'
import DynamicSelect from './DynamicSelect'

function Errors({ messages }) {
  if (messages.length === 0) return null

  return <span className="error">{messages.join(', ')}</span>
}

export default class AnswerPicker extends Component {
  static propTypes = {
    creatableListInputName: PropTypes.string.isRequired,
    creatableListInputCurrentValues: PropTypes.array,
    creatableListInputPlaceholder: PropTypes.string.isRequired,
    creatableListInputLabel: PropTypes.string.isRequired,
    creatableListValidationErrors: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    creatableListInputRequired: PropTypes.bool,
    correctAnswers: PropTypes.array.isRequired,
    correctAnswersLabel: PropTypes.string.isRequired,
    correctAnswersName: PropTypes.string.isRequired,
    correctAnswersNoResultsText: PropTypes.string.isRequired,
    correctAnswersPlaceholderText: PropTypes.string.isRequired,
    correctAnswersValidationErrors: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    correctAnswersRequired: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      correctAnswersValues: props.correctAnswers,
      correctAnswerOptions: props.creatableListInputCurrentValues.map(
        v => [{ label: v, value: v }][0]
      ),
      answerOptionsValues: props.creatableListInputCurrentValues,
    }
  }

  updateAnswerOptions = event => {
    this.setState({
      answerOptionsValues: event.target.value.map(v => v.value),
      correctAnswerOptions: event.target.value,
    })
  }

  updateCorrectAnswers = event => {
    this.setState({ correctAnswersValues: event.target.value })
  }

  render() {
    const {
      correctAnswersLabel,
      correctAnswersName,
      correctAnswersPlaceholderText,
      correctAnswersNoResultsText,
      correctAnswersValidationErrors,
      correctAnswersRequired,
      creatableListInputName,
      creatableListInputLabel,
      creatableListInputPlaceholder,
      creatableListValidationErrors,
      creatableListInputRequired,
    } = this.props

    return (
      <Fragment>
        <tr>
          <th>
            <label
              className={creatableListInputRequired && 'required-field'}
              htmlFor=""
            >
              {creatableListInputLabel}
            </label>
          </th>
          <td>
            <div>
              <CreatableListInput
                currentValues={this.state.answerOptionsValues}
                name={creatableListInputName}
                placeholderText={creatableListInputPlaceholder}
                onChange={this.updateAnswerOptions}
              />
              <Errors messages={creatableListValidationErrors} />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <label
              className={correctAnswersRequired && 'required-field'}
              htmlFor=""
            >
              {correctAnswersLabel}
            </label>
          </th>
          <td>
            <DynamicSelect
              clearable={true}
              multi={true}
              name={correctAnswersName}
              currentValue={this.state.correctAnswersValues}
              noResultsText={correctAnswersNoResultsText}
              onSelect={this.updateCorrectAnswers}
              placeholderText={correctAnswersPlaceholderText}
              values={this.state.correctAnswerOptions}
            />
            <Errors messages={correctAnswersValidationErrors} />
          </td>
        </tr>
      </Fragment>
    )
  }
}
