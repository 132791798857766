// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { createMuiTheme } from '@material-ui/core/styles'
import { bottomButtonClicked, setBottomButtons } from '../../../redux/actions'
import {
  getBottomButtonsStore,
  getFlexLayoutStore,
} from '../../../redux/selectors'
import { muiStyles } from '../../../styles/MuiDataTable'
import HeatmapTable from '../../HeatmapTable'
import NotificationModal from '../../common/NotificationModal'
import { get_request } from '../../../src/lib/requestToServer'

const HEATMAP_LOADING = {}

class HeatmapReportsVisualisation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      heatmapInfoCache: {},
      loading: false,
      visualisationCache: {},
    }

    this.setButtons()
  }

  componentDidMount() {
    this.fetchHeatmapVisualisation(this.props.heatmapInfo)
  }

  shouldComponentUpdate(nextProps) {
    // Don't re-render when the tab isn't selected.
    if (nextProps.flexlayout.tabSelected !== 'visualisation') return false

    return true
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { info } = this.state
    const { heatmapReportsData, heatmapInfo, bottomButtons, flexlayout } =
      newProps

    if (flexlayout && flexlayout.tabSelected !== this.state.tabSelected) {
      if (flexlayout.tabSelected === 'visualisation') {
        this.setButtons()
      }
      this.setState({ tabSelected: flexlayout.tabSelected })
    }

    // Only update when the tab is selected.
    if (flexlayout.tabSelected !== 'visualisation') return

    if (heatmapReportsData && heatmapReportsData.info) {
      if (
        !info ||
        (heatmapReportsData.info['id'] &&
          info['id'] !== heatmapReportsData.info['id'])
      )
        this.setState({ info: heatmapReportsData.info }, () =>
          this.setButtons()
        )
    }

    if (bottomButtons && bottomButtons.clicked) {
      if (bottomButtons.clicked === this.props.translations.ubf.csv_export) {
        this.props.bottomButtonClicked(null)
        this.exportCsv(heatmapInfo)
      }
    }
    this.fetchHeatmapVisualisation(heatmapInfo)
  }

  setButtons = () => {
    const { visualisationCache } = this.state
    const { heatmapInfo } = this.props

    if (
      heatmapInfo &&
      visualisationCache[heatmapInfo.id] &&
      visualisationCache[heatmapInfo.id].reports.length === 1
    ) {
      this.props.setBottomButtons([this.props.translations.ubf.csv_export])
    } else {
      this.props.setBottomButtons([])
    }
  }

  exportCsv = heatmapInfo => {
    const { id } = heatmapInfo

    window.location = `heatmap_reports/${id}.csv`
  }

  fetchHeatmapVisualisation = async heatmapInfo => {
    // No heatmap selected.
    if (!heatmapInfo) return

    const { id } = heatmapInfo

    if (HEATMAP_LOADING[id]) return

    const { heatmapInfoCache, visualisationCache } = this.state
    const existingHeatmapInfo = heatmapInfoCache[id]
    const existingVisualisation = visualisationCache[id]
    const heatmapInfoHasChanged = !isEqual(existingHeatmapInfo, heatmapInfo)

    if (!existingVisualisation || heatmapInfoHasChanged) {
      HEATMAP_LOADING[id] = true

      this.setState({
        heatmapInfoCache: {
          ...heatmapInfoCache,
          [id]: heatmapInfo,
        },
      })

      this.setState({ loading: true })

      const keys = await get_request('/heatmap_reports', {
        id,
        keys: true,
      })

      let reports = []
      for (const key of Object.entries(keys)) {
        const gradingScaleIds = key[1]
        const data = await get_request('/heatmap_reports', {
          id,
          visualisation: true,
          grading_scale_ids: gradingScaleIds,
        })

        reports.push(data.reports[0])

        this.setState(
          {
            visualisationCache: {
              ...visualisationCache,
              [id]: { reports, session_count: data.session_count },
            },
          },
          () => this.setButtons()
        )
      }

      this.setState({ loading: false })

      delete HEATMAP_LOADING[id]
    }
  }

  theme = () => createMuiTheme(muiStyles)

  render() {
    const { heatmapInfo, referenceGraderName, translations } = this.props
    const { loading, visualisationCache } = this.state

    if (!heatmapInfo) {
      return (
        <div className="heatmap__margin_top">
          <div className="quantum-layout__container--middle-message">
            <h3>{this.props.translations.messages.heatmaps.select_heatmap}</h3>
          </div>
        </div>
      )
    }

    const { id } = heatmapInfo
    const visualisation = visualisationCache[id]
    const sessionCount = visualisation ? visualisation.session_count : 0
    const reports = visualisation ? visualisation.reports : []

    return (
      <div className="heatmap__margin_top">
        <NotificationModal notificationType="loading" visible={loading} />

        <div className="heatmap__flex_distribution--extra_information">
          <div className="heatmap__flex_distribution--column_information outer-container-right">
            <label className="heatmap__text--normal_text heatmap__text--not_bold heatmap__text--gray_text">
              {translations.activerecord.attributes.heatmap.reference_grader_id}
              :{' '}
            </label>
            <span>{referenceGraderName}</span>
          </div>
          <div className="heatmap__flex_distribution--column_information outer-container-left">
            <label className="heatmap__text--normal_text">
              <span className="heatmap__text--not_bold heatmap__text--gray_text">
                {translations.activerecord.attributes.heatmap.start_date}:{' '}
              </span>
              {heatmapInfo.start_date}
            </label>
            <label className="heatmap__text--normal_text">
              <span className="heatmap__text--not_bold heatmap__text--gray_text">
                {translations.activerecord.attributes.heatmap.end_date}:{' '}
              </span>
              {heatmapInfo.end_date}
            </label>
          </div>
          <div className="heatmap__flex_distribution--column_information outer-container-left">
            <span className="heatmap__number_of_sessions heatmap__text--normal_text">
              {sessionCount}
            </span>
            <span className="heatmap__text--not_bold heatmap__text--gray_text">
              {translations.activerecord.attributes.heatmap.sessions}
            </span>
          </div>
        </div>
        {reports.map((element, index) =>
          element ? (
            <HeatmapTable
              heatmapId={id}
              header={element.header}
              key={index}
              rows={element.rows}
              translations={this.props.translations}
            />
          ) : null
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const flexlayout = getFlexLayoutStore(state)
  const bottomButtons = getBottomButtonsStore(state)
  return { flexlayout, bottomButtons }
}

export default connect(mapStateToProps, {
  setBottomButtons,
  bottomButtonClicked,
})(HeatmapReportsVisualisation)
