// Copyright Northcote Technology Ltd
import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import QuantumLayout from './QuantumLayout'
import InfoIcon from '@material-ui/icons/Info'
import MySettingsFields from './my_settings/MySettingsFields'
import MySettingsTable from './my_settings/MySettingsTable'

export default class MySettingsModule extends React.Component {
  constructor(props) {
    super(props)
  }

  iconFactory = node => {
    if (node.getId() === 'info') {
      return <InfoIcon />
    }
  }

  factory = node => {
    const {
      translations,
      userTypeOptions,
      userTypeDropdownOptions,
      canEditMyOfflinePin,
      currentUserId,
    } = this.props

    let component = node.getComponent()

    if (component === 'Table') {
      return (
        <MySettingsTable
          translations={translations}
          userTypeOptions={userTypeOptions}
          userTypeDropdownOptions={userTypeDropdownOptions}
        />
      )
    } else if (component === 'MySettingsFields') {
      return (
        <div className="outer-container-right">
          <MySettingsFields
            translations={translations}
            currentUserId={currentUserId}
            canEditMyOfflinePin={canEditMyOfflinePin}
          />
        </div>
      )
    } else {
      return (
        <div className="outer-container-right">
          <div className="inner-container">{node.getName()}</div>
        </div>
      )
    }
  }

  render() {
    const { backUrl, translations } = this.props

    let leftPaneComponents = [
      {
        id: 'table',
        type: 'tab',
        name: '',
        component: 'Table',
      },
    ]

    let rightPaneComponents = [
      {
        id: 'info',
        name: translations.titles.admin.my_settings.my_setting_info,
        component: 'MySettingsFields',
      },
    ]

    return (
      <Provider store={store}>
        <QuantumLayout
          backAction={() => (window.location = backUrl)}
          title={translations.titles.admin.my_settings.main}
          leftPaneComponents={leftPaneComponents}
          rightPaneComponents={rightPaneComponents}
          customFactory={this.factory}
          customIconFactory={this.iconFactory}
        />
      </Provider>
    )
  }
}
