// Copyright Northcote Technology Ltd
import { keyBy, uniqueId } from 'lodash'

// Move the row object and its events.
const moveObjToRow = (obj, row) => ({
  ...obj,
  events: obj.events.map(event => ({ ...event, row })),
  row,
})

// Transform the list of timeline events into a data structure suitable for use
// with dnd-kit. The main thing here is that because we're grouping events into
// rows each row requires a consistent identifier that lives for the duration
// of the component and is unrelated to its row number.
export function prepareEvents(events) {
  const rowKeyLookup = {}

  return events.reduce((acc, event) => {
    const { row } = event

    // Get the row's id or define one.
    let cid = rowKeyLookup[row]
    if (!cid) cid = rowKeyLookup[row] = uniqueId('r')

    if (!acc[cid]) acc[cid] = { cid, events: [], row }
    acc[cid].events.push({ ...event, cid: uniqueId('c') })

    return acc
  }, {})
}

export default function reducer(state, action) {
  switch (action.type) {
    case 'addColumn': {
      const { event, row } = action
      const newEvent = {
        ...event,
        cid: uniqueId('c'),
        column: row.events.length,
        row: row.row,
      }
      const newRow = {
        ...row,
        events: [...row.events, newEvent],
      }

      return {
        ...state,
        [row.cid]: newRow,
      }
    }
    case 'addRow': {
      const { event } = action
      const rowIndexes = Object.values(state).map(({ row }) => row)
      const row = rowIndexes.length === 0 ? 0 : Math.max(...rowIndexes) + 1
      const rowCid = uniqueId('r')
      const newEvent = {
        ...event,
        cid: uniqueId('c'),
        column: 0,
        row,
      }
      const newRow = {
        cid: rowCid,
        events: [newEvent],
        row,
      }

      return {
        ...state,
        [rowCid]: newRow,
      }
    }
    case 'deleteEvent': {
      const { cid, row } = action
      const eventToDelete = row.events.find(event => event.cid === cid)
      let newEvents
      let newState

      if (eventToDelete.id) {
        // The event has already been persisted on the server, flag it as
        // deleted so that it can be included in the form inputs whilst being
        // hidden from view.
        const newEvent = { ...eventToDelete, _destroy: true }
        newEvents = row.events.map(event =>
          event.cid === cid ? newEvent : event
        )
      } else {
        // The event only exists in the client so can be immediately deleted.
        newEvents = row.events.filter(event => event.cid !== cid)
      }

      if (newEvents.length === 0) {
        // The event only existed on the client so was removed, the row is now
        // empty and can also be deleted.
        newState = { ...state }
        delete newState[row.cid]
      } else {
        const newRow = {
          ...row,
          events: newEvents,
        }
        newState = {
          ...state,
          [row.cid]: newRow,
        }
      }

      return newState
    }
    case 'moveColumn': {
      const { activeId, overId, row } = action

      if (activeId === overId) return state

      const eventsLookup = keyBy(row.events, 'cid')
      const activeColumn = eventsLookup[activeId].column
      const overColumn = eventsLookup[overId].column

      const newEvents = row.events.map(event => {
        const { column } = event
        let newColumn = null

        if (event.cid === activeId) {
          newColumn = overColumn
        } else if (activeColumn > overColumn) {
          // Moving visually left, shift in-between events right.
          if (column >= overColumn && column < activeColumn) {
            newColumn = column + 1
          }
        } else if (activeColumn < overColumn) {
          // Moving visually right, shift in-between events left.
          if (column > activeColumn && column <= overColumn) {
            newColumn = column - 1
          }
        }

        return newColumn === null ? event : { ...event, column: newColumn }
      })

      const newRow = {
        ...row,
        events: newEvents,
      }

      return {
        ...state,
        [row.cid]: newRow,
      }
    }
    case 'moveRow': {
      const { activeId, overId } = action

      if (activeId === overId) return state

      const activeRow = state[activeId].row
      const overRow = state[overId].row
      const newState = {}

      for (const cid in state) {
        const obj = state[cid]
        const { row } = obj
        let newObj

        if (cid === activeId) {
          newObj = moveObjToRow(obj, overRow)
        } else if (activeRow > overRow) {
          // Moving visually up the list, shift in-between rows down.
          if (row >= overRow && row < activeRow) {
            newObj = moveObjToRow(obj, row + 1)
          }
        } else if (activeRow < overRow) {
          // Moving visually down the list, shift in-between rows up.
          if (row > activeRow && row <= overRow) {
            newObj = moveObjToRow(obj, row - 1)
          }
        }

        newState[cid] = newObj ? newObj : obj
      }

      return newState
    }
    case 'updateEvent': {
      const { event, row } = action
      const newState = {}

      for (const rowId in state) {
        const currentRow = state[rowId]

        if (rowId === row.cid) {
          newState[rowId] = {
            ...currentRow,
            events: currentRow.events.map(currentEvent =>
              currentEvent.cid === event.cid ? event : currentEvent
            ),
          }
        } else {
          newState[rowId] = currentRow
        }
      }

      return newState
    }
    default:
      return state
  }
}
