// Copyright Northcote Technology Ltd
import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compact, keyBy } from 'lodash'

import DynamicSelect from './DynamicSelect'

import TranslationsContext from './contexts/Translations'

const toOption = ({ id, name }) => ({
  label: name,
  value: id.toString(),
})

export default function SessionTemplatePicker(props) {
  const translations = useContext(TranslationsContext)
  const {
    children,
    date,
    name,
    onChange,
    templateOptions: { collections, templates },
    value,
  } = props

  const [collectionId, setCollectionId] = useState(null)
  const handleChangeCollection = useCallback(
    event => {
      setCollectionId(event.target.value)
      onChange({
        target: { name, value: null },
      })
    },
    [name]
  )

  const options = useMemo(() => {
    let filteredTemplates

    if (collectionId) {
      const collection = collections.find(({ id }) => id == collectionId)
      const templatesData = keyBy(templates, 'id')
      filteredTemplates = compact(
        collection.template_ids.map(id => templatesData[id])
      )
    } else {
      filteredTemplates = templates.filter(
        ({ template_collection_id }) => !template_collection_id
      )
    }

    return filteredTemplates
      .filter(({ obsolescence_date }) =>
        date ? !obsolescence_date || obsolescence_date >= date : true
      )
      .map(toOption)
  }, [templates, collections, collectionId, date])

  return (
    <>
      {collections.length > 0 ? (
        <div className="quantum-layout__field">
          <DynamicSelect
            clearable={true}
            currentValue={collectionId}
            name={`${name}-collection`}
            noResultsText={translations.ubf.nothing_found}
            onSelect={handleChangeCollection}
            placeholderText={
              translations.forms.placeholders.grading_session
                .template_collection_id
            }
            prefixClass="select"
            styleVersion={2}
            values={collections.map(toOption)}
          />
        </div>
      ) : null}

      <div className="quantum-layout__field">
        <DynamicSelect
          clearable={true}
          currentValue={value}
          name={name}
          noResultsText={translations.ubf.nothing_found}
          onSelect={onChange}
          placeholderText={
            translations.forms.placeholders.grading_session
              .grading_session_template_id
          }
          prefixClass="select"
          styleVersion={2}
          values={options}
        />
        {children}
      </div>
    </>
  )
}

SessionTemplatePicker.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  templateOptions: PropTypes.shape({
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        obsolescence_date: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
  value: PropTypes.string,
}
