// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFlexLayoutStore, getMySettingsStore } from '../../redux/selectors'
import { getMySettingsItem, getMySettingsUpdate } from '../../redux/actions'
import { get_request } from '../../src/lib/requestToServer'
import Table from '../common/Table'

class MySettingsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      maximized: false,
    }

    get_request('/my_settings/', null).then(response => {
      this.setState({
        data: response,
      })
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { flexlayout, mySettings } = newProps
    const { maximized, data } = this.state

    if (
      flexlayout &&
      flexlayout.maximized != null &&
      flexlayout.maximized != maximized
    ) {
      this.setState({ maximized: flexlayout.maximized })
    }
    if (mySettings && mySettings.data != null && mySettings.data != data) {
      this.setState({ data: mySettings.data })
    }
  }

  datatable = () => {
    const { data } = this.state
    const { translations } = this.props
    const columns = [
      {
        name: translations.ubf.my_settings,
        options: {
          filter: true,
          filterType: 'select',
        },
      },
    ]

    return {
      columns: columns,
      data: data
        ? Object.keys(data).map(field => {
            return {
              [field]: translations.activerecord.attributes.my_setting[field],
            }
          })
        : [],
    }
  }

  actionOnRowClick = param => {
    this.props.getMySettingsItem(param)
    this.props.getMySettingsUpdate()
  }

  render() {
    const datatable = this.datatable()
    if (this.state.data)
      return (
        <Table
          data={datatable.data}
          columns={datatable.columns}
          translations={this.props.translations}
          actionOnRowClick={this.actionOnRowClick}
        />
      )
    else return ''
  }
}

const mapStateToProps = state => {
  const flexlayout = getFlexLayoutStore(state)
  const mySettingsData = getMySettingsStore(state)
  return { flexlayout, mySettingsData }
}

export default connect(mapStateToProps, {
  getMySettingsItem,
  getMySettingsUpdate,
})(MySettingsTable)
