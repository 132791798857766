// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import { connect } from 'react-redux'
import NotificationModal from '../people/NotificationModal'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  setBottomButtons,
  bottomButtonClicked,
  getMySettingsUpdate,
  setEditStateFalse,
  setEditStateTrue,
  setCreateStateFalse,
  setCreateStateTrue,
  setMySettingsItem,
  getMySettingsItem,
  flexlayoutTabSelected,
} from '../../redux/actions'
import {
  getMySettingsStore,
  getFlexLayoutStore,
  getBottomButtonsStore,
  getEditionStore,
} from '../../redux/selectors'
import { update_request } from '../../src/lib/requestToServer'
import MUIDataTable from 'mui-datatables'
import { muiStyles } from '../../styles/MuiDataTable'
import translate from 'src/lib/translate'
import { camelCase } from 'lodash'
import { sha256 } from 'js-sha256'

class MySettingsFields extends Component {
  constructor(props) {
    super(props)

    const { mySettingsData, editState } = props

    let editValue = false
    if (editState && editState.info) {
      editValue = editState.info
    }

    if (
      mySettingsData &&
      mySettingsData.info &&
      !Array.isArray(mySettingsData.info)
    ) {
      this.state = {
        info: mySettingsData.info,
        editMode: editValue,
        notificationType: '',
        showModal: false,
        tabSelected: 'info',
        submitting: false,
        asterisks: '******',
      }
    } else {
      this.state = {
        info: null,
        editMode: editValue,
        notificationType: '',
        showModal: false,
        tabSelected: 'info',
        submitting: false,
        asterisks: '******',
      }
    }
    this.props.flexlayoutTabSelected('info')
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { tabSelected, submitting, editMode, info } = this.state

    const { flexlayout, mySettingsData, bottomButtons, editState } = newProps

    if (editState && editState.info != null && editState.info != editMode) {
      this.setState({ editMode: editState.info })
    }

    if (flexlayout && flexlayout.tabSelected != tabSelected) {
      if (flexlayout.tabSelected == 'info') {
        this.setButtons()
      }
      this.setState({ tabSelected: flexlayout.tabSelected })
    }
    if (
      !submitting &&
      mySettingsData &&
      mySettingsData.info &&
      mySettingsData.info != info
    ) {
      if (info) {
        this.setState({ info: mySettingsData.info })
      } else {
        this.setState({ info: mySettingsData.info }, () =>
          this.setButtons(false)
        )
      }
    } else if (
      (!submitting && !mySettingsData) ||
      (mySettingsData && !mySettingsData.info)
    ) {
      this.setState({ info: null })
    }

    if (
      !submitting &&
      bottomButtons &&
      bottomButtons.clicked &&
      flexlayout.tabSelected == 'info'
    ) {
      let translation = this.props.translations.ubf

      if (bottomButtons.clicked == translation.edit) {
        this.props.bottomButtonClicked(null)
        this.onEdit()
      } else if (!submitting && bottomButtons.clicked == translation.save) {
        this.props.bottomButtonClicked(null)
        this.onSubmit()
      } else if (!submitting && bottomButtons.clicked == translation.cancel) {
        this.props.bottomButtonClicked(null)
        this.props.setEditStateFalse()
        this.setState({ editMode: false, info: null }, () =>
          this.setButtons(false)
        )
        if (editMode) this.props.getMySettingsItem(this.props.currentUserId)
        else this.props.setMySettingsItem(null)
      }
      return
    }
  }

  onSubmit = () => {
    if (this.state.submitting) return
    const { info } = this.state
    const { translations, currentUserId } = this.props

    this.setState({ submitting: true })

    if (info.offline_pin || info.repeat_pin) {
      if (!isNaN(info.offline_pin)) {
        if (info.offline_pin == info.repeat_pin) {
          delete info.repeat_pin
          info.offline_pin = sha256(info.offline_pin)
          update_request('/my_settings/', info, currentUserId)
            .then(response => {
              if (!response) {
                // failure
                info.errors = response
                this.setState({ info: info, submitting: false })
                this.props.setMySettingsItem(info)
              } else {
                info.errors = false
                info.id = currentUserId
                this.setState(
                  {
                    showModal: true,
                    notificationType: 'update',
                    editMode: false,
                    submitting: false,
                  },
                  () => {
                    setTimeout(this.handleCloseModal, 2000)
                  }
                )
                this.props.getMySettingsUpdate()
                this.props.setMySettingsItem(info)
                this.props.setEditStateFalse()
                this.setButtons(false)
              }
            })
            .catch(error => {
              console.error(error)
              this.setState({ info: null, editMode: false, submitting: false })
            })
        } else {
          info.errors = {
            offline_pin:
              translations.activerecord.errors.messages
                .offline_pin_equal_repeat_pin,
            repeat_pin:
              translations.activerecord.errors.messages
                .repeat_pin_equal_offline_pin,
          }
          this.setState({ info: info, submitting: false })
          this.props.setMySettingsItem(info)
        }
      } else {
        info.errors = {
          offline_pin:
            translations.activerecord.errors.messages.pin_should_be_numeric,
        }
        this.setState({ info: info, submitting: false })
        this.props.setMySettingsItem(info)
      }
    } else {
      update_request('/my_settings/', info, currentUserId)
        .then(response => {
          if (!response.id) {
            // failure
            info.errors = response
            this.setState({ info: info, submitting: false })
            this.props.setMySettingsItem(info)
          } else {
            info.errors = false
            this.setState(
              {
                showModal: true,
                notificationType: 'update',
                editMode: false,
                submitting: false,
              },
              () => {
                setTimeout(this.handleCloseModal, 2000)
              }
            )
            this.props.getMySettingsUpdate()
            this.props.setMySettingsItem(info)
            this.props.setEditStateFalse()
            this.setButtons(false)
          }
        })
        .catch(error => {
          console.error(error)
          this.setState({ info: null, editMode: false, submitting: false })
        })
    }
  }

  setButtons = (editMode = false) => {
    const { translations } = this.props

    let translation = translations.ubf
    let buttons = []

    // edit
    if (editMode) {
      buttons.push(translation.save)
      buttons.push(translation.cancel)
    }
    // show
    else {
      buttons.push(translation.edit)
    }

    this.props.setBottomButtons(buttons)
  }

  textFieldNames = () => {
    let attr = this.props.translations.activerecord.attributes.my_setting
    return [
      [attr.offline_pin, 'offline_pin'],
      [attr.repeat_pin, 'repeat_pin'],
    ]
  }

  handleInputChange = event => {
    const { info } = this.state
    const target = event.target
    const value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    this.props.setMySettingsItem({
      ...info,
      [name]: value,
    })
  }

  inputField = (name, field, type) => {
    const { info } = this.state
    const Tag = field == 'text' ? 'textarea' : 'input'
    const inputClass = field == 'text' ? 'full-width-container' : ''
    const { currentUserId } = this.props

    let mandatory = ['offline_pin']
    let error = info.errors ? info.errors[field] : false

    return (
      <div className="quantum-layout__field-50" key={currentUserId + field}>
        <span className="quantum-layout__label p0">
          {name + (mandatory.includes(field) ? ' *' : '')}
        </span>
        {error ? (
          <div className="quantum-layout__field--error">{error}</div>
        ) : (
          ''
        )}
        <Tag
          className={inputClass}
          key={currentUserId + field}
          type={type}
          name={field}
          placeholder={name}
          value={info[field] || ''}
          onChange={this.handleInputChange}
          autoComplete="off"
        />
      </div>
    )
  }

  translateField = field => {
    return translate('attributes.' + camelCase(field), this.props.translations)
  }

  onEdit = () => {
    this.setState({ editMode: true })
    this.props.setEditStateTrue()
    this.setButtons(true)
  }

  theme = () => createMuiTheme(muiStyles)

  inputFields = () => {
    const { translations } = this.props
    return (
      <div>
        <div className="quantum-layout__container--input-fields">
          {this.state.info.errors ? (
            <div className="quantum-layout__field--error">
              {translations.messages.people.some_errors}
            </div>
          ) : (
            ''
          )}
          {this.textFieldNames().map(field => {
            if (field[1] == 'offline_pin' || field[1] == 'repeat_pin') {
              return this.inputField(field[0], field[1], 'password')
            } else {
              return this.inputField(field[0], field[1], 'text')
            }
          })}
        </div>
      </div>
    )
  }

  viewFields = () => {
    const columns = [
      {
        name: '',
        options: {
          filter: false,
        },
      },
      {
        name: '',
        options: {
          filter: false,
          sort: false,
        },
      },
    ]

    const options = {
      filterType: 'dropdown',
      print: false,
      download: false,
      fixedHeader: true,
      fixedSelectColumn: true,
      selectableRows: 'none',
      rowsPerPage: 25,
      responsive: 'standard',
      tableBodyHeight: window.innerHeight - 343 + 'px',
      search: false,
      selectableRowsHeader: false,
      viewColumns: false,
      pagination: false,
      filter: false,
    }
    var res = []
    res.push(this.fieldRow('offline_pin'))

    return (
      <MuiThemeProvider theme={this.theme()}>
        <div className="my_setting__info">
          <MUIDataTable
            title={''}
            data={res}
            columns={columns}
            options={options}
          />
        </div>
      </MuiThemeProvider>
    )
  }

  fieldRow = field => {
    const { info } = this.state
    let attr = this.props.translations.activerecord.attributes.my_setting
    if (info[field] == info['offline_pin']) info[field] = this.state.asterisks
    return [attr[field], info[field] ? info[field] : 'N/A']
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  handleSaveModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { translations } = this.props
    const { info, editMode } = this.state
    return (
      <div>
        <NotificationModal
          visible={this.state.showModal}
          onSaveModal={this.handleSaveModal}
          onCloseModal={this.handleCloseModal}
          translations={this.props.translations}
          notificationType={this.state.notificationType}
          model={translations.activerecord.models.my_setting}
        />

        {info ? (
          editMode ? (
            this.inputFields()
          ) : (
            this.viewFields()
          )
        ) : (
          <div className="quantum-layout__container--middle-message">
            <h3>{translations.messages.my_settings.select_my_setting}</h3>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const mySettingsData = getMySettingsStore(state)
  const flexlayout = getFlexLayoutStore(state)
  const bottomButtons = getBottomButtonsStore(state)
  const editState = getEditionStore(state)
  return { mySettingsData, flexlayout, bottomButtons, editState }
}

export default connect(mapStateToProps, {
  setBottomButtons,
  bottomButtonClicked,
  getMySettingsUpdate,
  setEditStateFalse,
  setEditStateTrue,
  setCreateStateFalse,
  setCreateStateTrue,
  setMySettingsItem,
  getMySettingsItem,
  flexlayoutTabSelected,
})(MySettingsFields)
