// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InitialsAvatar from '../common/InitialsAvatar'
import { personName } from '../../src/lib/peopleHelper'
import classNames from 'classnames'

export default class PersonSelector extends Component {
  static propTypes = {
    people: PropTypes.array.isRequired,
    gradeAsCrew: PropTypes.bool.isRequired,
    selectedId: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired,
    crewName: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { people, gradeAsCrew, selectedId, onUpdate, crewName } = this.props

    const selectorClasses = classNames({
      'person-selector': true,
      'person-selector--big': people.length > 4,
    })

    if (gradeAsCrew)
      return (
        <div className="person-selector--person person-selector--selected">
          <InitialsAvatar
            classes={'icon icon--users'}
            isCrew={gradeAsCrew}
            name={crewName}
          />
          <div>{crewName}</div>
        </div>
      )

    return (
      <div className={selectorClasses}>
        {people.map(item => {
          const person = item.person || item // Could be a GradingSessionResult or Person item
          const itemClasses = classNames({
            'person-selector--person': true,
            'person-selector--selected': selectedId == item.id,
          })
          return (
            <div
              key={item.id}
              className={itemClasses}
              onClick={() => {
                onUpdate(item.id)
              }}
            >
              <InitialsAvatar
                classes={gradeAsCrew ? 'icon icon--users' : ''}
                isCrew={gradeAsCrew}
                name={personName(person)}
              />
              <span>{personName(person)}</span>
            </div>
          )
        })}
      </div>
    )
  }
}
