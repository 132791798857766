// Copyright Northcote Technology Ltd
import React from 'react'
import PropTypes from 'prop-types'

import ObservationSelector from './ObservationSelector'

export default function ObservationSelectorCompat(props) {
  const { behaviours, value, ...rest } = props
  const behavioursData = behaviours.reduce((memo, behaviour) => {
    memo[behaviour.id] = behaviour
    return memo
  }, {})

  return (
    <ObservationSelector
      availableBehaviourIds={behaviours.map(({ id }) => id)}
      behavioursData={behavioursData}
      value={value.filter(({ _destroy }) => !_destroy)}
      {...rest}
    />
  )
}

ObservationSelectorCompat.propTypes = {
  behaviours: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
}
