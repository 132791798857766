// Copyright Northcote Technology Ltd
import { keyBy } from 'lodash'

function addExistingGradingObConflicts(gradingObs, gradings) {
  const gradingsLookup = keyBy(gradings, 'id')

  // Loop through all the gradings that will receive recording OBs and add any
  // behaviour/mood conflicts from existing OBs.
  gradingObs.forEach((obs, gradingId) => {
    const grading = gradingsLookup[gradingId]
    grading.observations.forEach(({ behaviourId, mood }) => {
      if (obs.has(behaviourId)) obs.get(behaviourId).add(mood)
    })
  })
}

function mergeGradingOb(gradingObs, gradingId, ob) {
  const { behaviourId, mood } = ob

  if (gradingObs.has(gradingId)) {
    const existingGradingObs = gradingObs.get(gradingId)

    if (existingGradingObs.has(behaviourId)) {
      existingGradingObs.get(behaviourId).add(mood)
    } else {
      existingGradingObs.set(behaviourId, new Set([mood]))
    }
  } else {
    const obMap = new Map([[behaviourId, new Set([mood])]])
    gradingObs.set(gradingId, obMap)
  }
}

function gradingObsForResult(session, result, behaviourGradingScales) {
  // All recording OBs for the result's person.
  const recordingObs = session.recordings.reduce((acc, recording) => {
    const obs = recording.recordingPersonBehaviours.find(
      obj => obj.personId === result.personId
    )

    if (obs) {
      acc.push({
        activityId: recording.activityId,
        observations: obs.observations,
      })
    }

    return acc
  }, [])

  // No need to continue if the result's person doesn't have any recording OBs.
  if (recordingObs.length === 0) return []

  // Construct a lookup of gradingScaleId->gradings.
  const gradingsByGradingScaleId = new Map()
  result.gradings.forEach(grading => {
    const qualification = session.qualificationsData[grading.qualificationId]

    // Can't find the qualification.
    if (!qualification) return

    const { gradingScaleId } = qualification
    if (!gradingsByGradingScaleId.has(gradingScaleId)) {
      gradingsByGradingScaleId.set(gradingScaleId, new Set())
    }
    gradingsByGradingScaleId.get(gradingScaleId).add(grading)
  })

  // The receptacle to link gradings to their recording OBs.
  //
  // type GradingObs = {
  //   [gradingId: number]: {
  //     [behaviourId: number]: Set<mood: number>,
  //   },
  // }
  const gradingObs = new Map()

  // Each individual recording OB can match to zero or more gradings where the
  // grading's qualification's grading scale includes the OB's behaviour - plus
  // an additional filter:
  // - if the recording has an activityId then that can include all
  //   grade-by-session gradings but it only includes grade-by-activity gradings
  //   with the same activityId.
  // - if the recording does not have an activityId it can include all
  //   grade-by-session gradings but no grade-by-activity gradings.
  recordingObs.forEach(recordingOb => {
    recordingOb.observations.forEach(ob => {
      const { behaviourId } = ob

      // No grading scale for this behaviour.
      if (!behaviourGradingScales.has(behaviourId)) return

      // Loop through all the grading scales for this behaviour then loop
      // through all their gradings adding the recording OB to each one.
      behaviourGradingScales.get(behaviourId).forEach(gradingScaleId => {
        // No gradings for this grading scale.
        if (!gradingsByGradingScaleId.has(gradingScaleId)) return

        gradingsByGradingScaleId.get(gradingScaleId).forEach(grading => {
          if (recordingOb.activityId) {
            // The recording has an activity so if the grading is
            // grade-by-activity but the activities don't match then skip this
            // grading.
            if (
              grading.activityId &&
              recordingOb.activityId !== grading.activityId
            ) {
              return
            }
          } else if (grading.activityId) {
            // The recording has no activity and so should also only match
            // gradings without an activity.
            return
          }

          mergeGradingOb(gradingObs, grading.id, ob)
        })
      })
    })
  })

  addExistingGradingObConflicts(gradingObs, result.gradings)

  // Construct the final data for the grading OBs to be copied from the
  // result's recordings.
  return Array.from(gradingObs.entries()).map(([gradingId, obs]) => {
    const observations = Array.from(obs.entries()).map(
      ([behaviourId, moods]) => {
        const moodPicks = Array.from(moods)

        return {
          behaviourId,
          moodPicks,
          moods: moodPicks,
        }
      }
    )

    return {
      gradingId,
      observations,
    }
  })
}

// This looks through recordings and determines which gradings should receive
// their OBs (if any). In the best case the OBs can be copied to gradings
// without issue but there are a couple of cases where a behaviour/mood
// conflict can occur:
//
// - The grading has an existing OB with the same behaviour but a different mood
// - Multiple recordings link to the same grading and have OBs with the same
//   behaviour but a different mood (a much more likely scenario when the
//   recording doesn't reference an activity).
//
// In either case the multiple moods for the behaviour are included in the
// output to present to the user so they can choose a final appropriate mood.
export function gradingObsFromRecordings(session) {
  // Generate a lookup to map a behaviourId to its many gradingScaleIds.
  //
  // {
  //   [behaviourId: number]: Set<gradingScaleId: number>,
  // }
  const behaviourGradingScales = new Map()
  Object.values(session.gradingScalesData).forEach(({ behaviourIds, id }) => {
    behaviourIds.forEach(behaviourId => {
      if (behaviourGradingScales.has(behaviourId)) {
        behaviourGradingScales.get(behaviourId).add(id)
      } else {
        behaviourGradingScales.set(behaviourId, new Set([id]))
      }
    })
  })

  const lookup = {}
  session.gradingSessionResults.forEach(result => {
    lookup[result.id] = gradingObsForResult(
      session,
      result,
      behaviourGradingScales
    )
  })

  // type Observation = {
  //   behaviourId: number,
  //   moodPicks: Array<number>,
  //   moods: Array<number>,
  // }
  // type GradingObs = {
  //   gradingId: number,
  //   observations: Array<Observation>,
  // }
  // {
  //   [resultId: number]: Array<GradingObs>,
  // }
  return lookup
}

export function mergeObsWithGradings(session, gradingObsByResultId) {
  const gradings = []

  for (const resultId in gradingObsByResultId) {
    const result = session.gradingSessionResults.find(
      ({ id }) => id == resultId
    )
    if (!result) continue

    const gradingsById = keyBy(result.gradings, 'id')
    const gradingObs = gradingObsByResultId[resultId]

    gradingObs.forEach(({ gradingId, observations }) => {
      const grading = gradingsById[gradingId]
      if (!grading) return
      const obsByBehaviourId = keyBy(observations, 'behaviourId')

      // Construct a new array of observations consisting of all the existing
      // OBs but with changes to moods.
      const newObservations = grading.observations.map(ob => {
        const { behaviourId } = ob
        const newOb = obsByBehaviourId[behaviourId]

        if (newOb) {
          delete obsByBehaviourId[behaviourId]

          return {
            ...ob,
            mood: newOb.moods[0],
          }
        } else {
          return ob
        }
      })

      // Now add the remaining recordings OBs.
      Object.values(obsByBehaviourId).forEach(({ behaviourId, moods }) => {
        newObservations.push({ behaviourId, mood: moods[0] })
      })

      const newGrading = {
        ...grading,
        observations: newObservations,
      }

      gradings.push(newGrading)
    })
  }

  return gradings
}

// Return a a clone of the data structure with only the necessary deeply-nested
// branch changed. To be used by useReducer.
export function selectMoodsForOb(state, action) {
  const { behaviourId, gradingId, moods, resultId } = action
  const newState = {}

  for (const key in state) {
    const gradingObs = state[key]

    // Comparing string to number.
    if (key == resultId) {
      newState[key] = gradingObs.map(gradingOb => {
        if (gradingOb.gradingId === gradingId) {
          const observations = gradingOb.observations.map(ob =>
            ob.behaviourId === behaviourId ? { ...ob, moods } : ob
          )
          return { ...gradingOb, observations }
        } else {
          return gradingOb
        }
      })
    } else {
      newState[key] = gradingObs
    }
  }

  return newState
}
