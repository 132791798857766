// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TemSelector extends Component {
  static propTypes = {
    temCategories: PropTypes.array.isRequired,
    temCategoryOptionsDropdown: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      categories: props.temCategories,
    }
  }

  findCategory(elem) {
    return this.state.categories.find(e => e.id.toString() === elem.value)
  }

  updateTemCategory(elem) {
    let newCategories

    if (this.findCategory(elem)) {
      newCategories = this.state.categories.filter(
        e => e.id.toString() !== elem.value
      )
    } else {
      newCategories = this.state.categories.slice()
      newCategories.push({ id: elem.value, name: elem.label })
    }

    this.setState({ categories: newCategories })
  }

  render() {
    const { temCategoryOptionsDropdown } = this.props

    return (
      <div className="recording-form__tems">
        {temCategoryOptionsDropdown.map((elem, index) => {
          const category = this.findCategory(elem)
          const className = category
            ? 'pointer recording-form__tems-' + category.name.toLowerCase()
            : 'pointer'

          return (
            <div
              key={index}
              className={className}
              onClick={() => this.updateTemCategory(elem)}
            >
              <input
                type="hidden"
                name="tem[tem_category_ids][]"
                value={elem.value}
                disabled={!category}
              />
              {elem.label}
            </div>
          )
        })}
      </div>
    )
  }
}
