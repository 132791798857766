// Copyright Northcote Technology Ltd
import thunkMiddleware from 'redux-thunk'
import { compose, createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'

let composeWithDevtools = compose
const middleware = [thunkMiddleware]

if (process.env.NODE_ENV === 'development') {
  composeWithDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const { logger } = require('redux-logger')
  middleware.push(logger)
}

export default createStore(
  rootReducer,
  composeWithDevtools(applyMiddleware(...middleware))
)
