// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gradingScaleProps from '../../proptypes/gradingScaleProps'
import GradeInputWrapper from './GradeInputWrapper'
import { getIdb } from '../../src/lib/idb/common'
import { gradingErrors } from 'src/lib/validations/gradingValidations'

export default class StandaloneGradeInput extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    grading: PropTypes.shape({
      activityId: PropTypes.number,
      additionalInfo: PropTypes.object,
      behaviourIds: PropTypes.array,
      documentId: PropTypes.string,
      fileUploadName: PropTypes.string,
      grade: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      qualificationId: PropTypes.number,
      repeated: PropTypes.bool,
      counter: PropTypes.number,
    }).isRequired,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    incompleteMode: PropTypes.bool.isRequired,
    label: PropTypes.string,
    onGrading: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    gradingSession: PropTypes.object.isRequired,
    deferable: PropTypes.bool.isRequired,
  }

  state = {
    errors: null,
  }

  componentDidMount() {
    const { grading, gradingScale, gradingSession, translations } = this.props

    const context = this

    getIdb('unsubmitted_sessions', gradingSession.id).then(function (result) {
      if (result) {
        context.setState({
          errors: gradingErrors(translations, gradingScale, grading),
        })
      }
    })
  }

  onUpdate = grading => {
    const { translations, gradingSession, gradingScale, onGrading } = this.props

    const context = this

    if (!grading.applicableFromDate && gradingScale.hasApplicableFromDate) {
      grading.applicableFromDate = gradingSession.gradedDate
    }

    getIdb('unsubmitted_sessions', gradingSession.id).then(function (result) {
      if (result) {
        context.setState({
          errors: gradingErrors(translations, gradingScale, grading),
        })
      }
    })

    onGrading(grading)
  }

  render() {
    const {
      directUploadsUrl,
      grading,
      gradingScale,
      incompleteMode,
      label,
      online,
      translations,
      gradingSession,
      deferable,
    } = this.props

    return Object.keys(grading).length > 0 ? (
      <GradeInputWrapper
        directUploadsUrl={directUploadsUrl}
        grading={grading}
        deferable={deferable}
        gradingScale={gradingScale}
        incompleteMode={incompleteMode}
        label={label}
        onUpdate={this.onUpdate}
        online={online}
        translations={translations}
        gradingSession={gradingSession}
        errorsByGradings={this.state.errors}
      />
    ) : null
  }
}
