// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'
import DynamicSelectAsync from './DynamicSelectAsync'

export default class DynamicSelectWithState extends Component {
  constructor(props) {
    super(props)
    this.state = { currentValue: props.currentValue }
    this.setCurrentValue = this.setCurrentValue.bind(this)
  }

  render() {
    const {
      async,
      clearable,
      multi,
      name,
      noResultsText,
      placeholderText,
      prefixClass,
      styleVersion,
      values,
      preserveSelectionOrder,
    } = this.props

    const Component = async ? DynamicSelectAsync : DynamicSelect

    return (
      <Component
        clearable={clearable}
        currentValue={this.state.currentValue}
        multi={multi}
        name={name}
        noResultsText={noResultsText}
        onSelect={this.setCurrentValue}
        placeholderText={placeholderText}
        prefixClass={prefixClass}
        values={values}
        styleVersion={styleVersion}
        preserveSelectionOrder={preserveSelectionOrder}
      />
    )
  }

  setCurrentValue(event) {
    const { onUpdate } = this.props

    this.setState({ currentValue: event.target.value })

    if (onUpdate) onUpdate(event)
  }
}

DynamicSelectWithState.propTypes = {
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  placeholderText: PropTypes.string,
  prefixClass: PropTypes.string,
  async: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  styleVersion: PropTypes.number,
  onUpdate: PropTypes.func,
  preserveSelectionOrder: PropTypes.bool,
}
