// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AwardPeriod from './AwardPeriod'
import awardProps from 'proptypes/awardProps'

export const AWARD_TIMELINE_HEIGHT = 10
export const AWARD_TIMELINE_PADDING = 4

class AwardTimeline extends Component {
  static propTypes = {
    awards: PropTypes.arrayOf(PropTypes.shape(awardProps)).isRequired,
    qualificationId: PropTypes.number.isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { awards, qualificationId, scale, t } = this.props

    const periods = awards.map((award, index) => {
      const isGhost = qualificationId !== award.qualificationId
      return (
        <AwardPeriod
          award={award}
          isGhost={isGhost}
          key={index}
          scale={scale}
          t={t}
        />
      )
    })

    return (
      <div
        className="award-timeline"
        style={{
          height: AWARD_TIMELINE_HEIGHT,
          paddingBottom: AWARD_TIMELINE_PADDING,
          paddingTop: AWARD_TIMELINE_PADDING,
          width: scale.range()[1],
        }}
      >
        {periods}
      </div>
    )
  }
}

export default AwardTimeline
