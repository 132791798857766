// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import nameToId from 'src/lib/nameToId'
import DynamicSelect from './DynamicSelect'

export default class QuestionsPicker extends Component {
  static propTypes = {
    questionsListName: PropTypes.string.isRequired,
    questionsListLabel: PropTypes.string.isRequired,
    questionsListValues: PropTypes.array.isRequired,
    questionsListCurrentValues: PropTypes.array,
    questionsListPlaceholder: PropTypes.string.isRequired,
    minCorrectAnswersName: PropTypes.string.isRequired,
    minCorrectAnswersLabel: PropTypes.string.isRequired,
    minCorrectAnswersCurrentValue: PropTypes.number,
    minCorrectAnswersError: PropTypes.string,
    questionIdsError: PropTypes.string,
    noResultsText: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      questionsListCurrentValues: props.questionsListCurrentValues,
      minCorrectAnswersCurrentValue: props.minCorrectAnswersCurrentValue,
    }
  }

  updateQuestions = event => {
    this.setState({
      questionsListCurrentValues: event.target.value,
    })
  }

  updateMinCorrectAnswers = event => {
    this.setState({
      minCorrectAnswersCurrentValue: event.target.value,
    })
  }

  render() {
    const {
      questionsListName,
      questionsListPlaceholder,
      questionsListLabel,
      questionsListValues,
      minCorrectAnswersName,
      minCorrectAnswersLabel,
      minCorrectAnswersError,
      questionIdsError,
      noResultsText,
    } = this.props

    const { questionsListCurrentValues, minCorrectAnswersCurrentValue } =
      this.state

    return (
      <Fragment>
        <tr>
          <th>
            <label
              htmlFor={nameToId(questionsListName)}
              className="required-field"
            >
              {questionsListLabel}
            </label>
          </th>
          <td>
            <DynamicSelect
              clearable={true}
              name={questionsListName}
              currentValue={questionsListCurrentValues}
              noResultsText={noResultsText}
              placeholderText={questionsListPlaceholder}
              values={questionsListValues}
              multi={true}
              onSelect={this.updateQuestions}
            />
            {questionIdsError ? (
              <span className="error">{questionIdsError}</span>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>
            <label
              htmlFor={nameToId(minCorrectAnswersName)}
              className="required-field"
            >
              {minCorrectAnswersLabel}
            </label>
          </th>
          <td>
            <MinCorrectAnswersInput
              error={minCorrectAnswersError}
              id={nameToId(minCorrectAnswersName)}
              name={minCorrectAnswersName}
              numberOfQuestions={questionsListCurrentValues.length}
              onChange={this.updateMinCorrectAnswers}
              value={minCorrectAnswersCurrentValue || ''}
            />
          </td>
        </tr>
      </Fragment>
    )
  }
}

function MinCorrectAnswersInput({
  error,
  id,
  name,
  numberOfQuestions,
  onChange,
  value,
}) {
  return (
    <Fragment>
      <div className="min-correct-answers-input">
        <input
          id={id}
          name={name}
          value={value}
          type={'number'}
          onChange={onChange}
        />
        <span className="additional-info">/ {numberOfQuestions}</span>
      </div>
      {error ? <span className="error">{error}</span> : null}
    </Fragment>
  )
}
