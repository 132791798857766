// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelectWithState from './DynamicSelectWithState'
import Calendar from './common/Calendar'
import TimeInput from './TimeInput'

export default class CustomInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.array,
    currentValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    styleVersion: PropTypes.number,
    translations: PropTypes.object,
    newUi: PropTypes.bool,
    labelClass: PropTypes.string,
    mainClass: PropTypes.string,
    onUpdate: PropTypes.func,
    showLabel: PropTypes.bool,
    customCalendar: PropTypes.bool,
  }

  onChange = event => {
    const { onUpdate } = this.props

    if (onUpdate) {
      onUpdate(event)
    }
  }

  render() {
    const {
      label,
      name,
      type,
      value,
      currentValue,
      styleVersion,
      newUi,
      labelClass,
      mainClass,
      translations,
      showLabel,
    } = this.props

    const customCalendar = !!this.props.customCalendar
    let Tag = styleVersion == 2 ? 'div' : 'label'

    switch (type) {
      case 'text':
      case 'number':
        return (
          <div className={mainClass}>
            {!!showLabel && (
              <Tag className={labelClass} htmlFor={name}>
                {label}
              </Tag>
            )}
            <input
              type={type}
              id={name}
              name={name}
              defaultValue={currentValue}
              placeholder={label}
              onChange={this.onChange}
            />
          </div>
        )
      case 'date':
        return (
          <div className={mainClass}>
            {!!showLabel && (
              <Tag className={labelClass} htmlFor={name}>
                {label}
              </Tag>
            )}
            {customCalendar ? (
              <Calendar
                translations={translations}
                name={name}
                selected={currentValue}
                onChange={this.onChange}
              />
            ) : (
              <input
                type="date"
                name={name}
                defaultValue={currentValue}
                onChange={this.onChange}
              />
            )}
          </div>
        )
      case 'time':
        return (
          <TimeInput
            label={label}
            name={name}
            value={currentValue}
            onChange={this.onChange}
          />
        )
      case 'select':
        return (
          <div className={mainClass}>
            {!!showLabel && (
              <Tag className={labelClass} htmlFor={name}>
                {label}
              </Tag>
            )}
            <DynamicSelectWithState
              clearable={true}
              multi={false}
              name={name}
              noResultsText={name}
              placeholderText={label}
              values={value}
              currentValue={currentValue || ''}
              styleVersion={styleVersion}
              onUpdate={this.onChange}
              prefixClass="select"
            />
          </div>
        )
      case 'multiselect':
        return (
          <div className={mainClass}>
            {!!showLabel && (
              <Tag className={labelClass} htmlFor={name}>
                {label}
              </Tag>
            )}
            <DynamicSelectWithState
              clearable={true}
              multi={true}
              name={newUi ? name : name + '[]'}
              noResultsText={name}
              placeholderText={label}
              values={value}
              currentValue={currentValue || ''}
              styleVersion={styleVersion}
              onUpdate={this.onChange}
              prefixClass="select"
            />
          </div>
        )
      default:
        console.log('No custom field detected')
        return null
    }
  }
}
