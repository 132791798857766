// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', this.refreshList)
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.refreshList)
  }

  refreshList = event => {
    if (!event.detail.success) return

    const elem = document.querySelector('turbo-frame[id="list"]')
    if (!elem) return

    if (elem.src) {
      elem.reload()
    } else {
      // Reloading a frame requires it to have a src but one won't normally be
      // present on an index page's list frame unless a link has already been
      // followed (i.e. a pagination link or search options form submission),
      // in this case fallback to the manually-added data attribute (assigning
      // the src also causes the frame to load the URL).
      elem.src = elem.getAttribute('data-default-refresh-url')
    }
  }
}
