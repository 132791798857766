// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QualificationTree from './QualificationTree'

export default class SearchableQualificationTree extends Component {
  static propTypes = {
    changes: PropTypes.object.isRequired,
    isRearrangeable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    searchPlaceholderText: PropTypes.string.isRequired,
    treeData: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      searchString: '',
      searchFocusIndex: 0,
    }
  }

  handleMoveNode = () => {
    // When a node in the search results is moved, it breaks the search
    // (i.e. the wrong node is highlighted), so we refresh the search whenever
    // a node is moved
    this.refreshSearch()
  }

  handleSearchChange = event => {
    this.setState({ searchString: event.target.value })
  }

  handleSearchFinished = matches => {
    const resultCount = matches.length
    const { searchFocusIndex } = this.state
    this.setState({
      searchFocusIndex: resultCount > 0 ? searchFocusIndex % resultCount : 0,
    })
  }

  refreshSearch = () => {
    const { searchString } = this.state
    // Need to clear the search and redo it for the search to update
    this.setState({ searchString: '' })
    this.setState({ searchString: searchString })
  }

  searchMethod = ({ node, searchQuery }) => {
    return (
      searchQuery &&
      (node.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.identifier.toLowerCase().includes(searchQuery.toLowerCase()))
    )
  }

  render() {
    const {
      changes,
      isRearrangeable,
      onChange,
      searchPlaceholderText,
      treeData,
    } = this.props

    const { searchString, searchFocusIndex } = this.state

    return (
      <div className="searchable-qualification-tree">
        <div className="searchable-qualification-tree__search-field">
          <input
            type="text"
            placeholder={searchPlaceholderText}
            value={searchString}
            onChange={this.handleSearchChange}
          />
        </div>

        <QualificationTree
          changes={changes}
          isRearrangeable={isRearrangeable}
          onChange={onChange}
          onMoveNode={this.handleMoveNode}
          onlyExpandSearchedNodes={true}
          onSearchFinish={this.handleSearchFinished}
          searchFocusIndex={searchFocusIndex}
          searchMethod={this.searchMethod}
          searchString={searchString}
          treeData={treeData}
        />
      </div>
    )
  }
}
