// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'

const awardProps = {
  awardedDate: PropTypes.string.isRequired,
  didInvalidateEarly: PropTypes.bool.isRequired,
  lapsesDate: PropTypes.string.isRequired,
  qualificationId: PropTypes.number.isRequired,
  qualificationIdentifier: PropTypes.string.isRequired,
  revalidationPeriodStartsDate: PropTypes.string.isRequired,
  validFromDate: PropTypes.string.isRequired,
  validUntilDate: PropTypes.string.isRequired,
}

export default awardProps
