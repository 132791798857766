// Copyright Northcote Technology Ltd
import {
  FETCH_SESSION_SUCCESS,
  SET_RESULTS_ERRORS,
  UPDATE_SESSION_RESULT_SIGNATURE_DATA,
} from '../actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION_SUCCESS: {
      const newState = { ...state }

      action.payload.gradingSessionResults.forEach(({ id }) => {
        delete newState[id]
      })

      return newState
    }
    case SET_RESULTS_ERRORS: {
      const { errors } = action
      const newState = { ...state }

      for (const id in errors) {
        newState[id] = errors[id]
      }

      return newState
    }
    case UPDATE_SESSION_RESULT_SIGNATURE_DATA: {
      const newState = { ...state }
      delete newState[action.resultId]
      return newState
    }
    default: {
      return state
    }
  }
}
