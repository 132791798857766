// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NotificationModal from './NotificationModal'
import { EntitiesSelector } from './EntitiesSelector'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  setBottomButtons,
  bottomButtonClicked,
  setEditStateFalse,
  setEditStateTrue,
  setCreateStateFalse,
  setCreateStateTrue,
  isMaximized,
  flexlayoutTabSelected,
} from '../../redux/actions'
import {
  getFlexLayoutStore,
  getBottomButtonsStore,
  getEditionStore,
  getCreationStore,
} from '../../redux/selectors'
import {
  update_request,
  delete_request,
  save_request,
} from '../../src/lib/requestToServer'
import MUIDataTable from 'mui-datatables'
import { muiStyles } from '../../styles/MuiDataTable'
import translate from 'src/lib/translate'
import { camelCase } from 'lodash'
import Calendar from './Calendar'
import moment from 'moment'
import CreatableListInput from '../CreatableListInput'

class EntityFields extends Component {
  static propTypes = {
    entityData: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    modelName: PropTypes.string.isRequired,
    moduleName: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    canEdit: PropTypes.bool.isRequired,
    canCreate: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    setEntityItem: PropTypes.func.isRequired,
    getEntityItem: PropTypes.func.isRequired,
    getEntityUpdate: PropTypes.func.isRequired,
    controllerUrl: PropTypes.string.isRequired,
    header: PropTypes.func,
    extraPadding: PropTypes.bool,
    styleType: PropTypes.number,
    tabName: PropTypes.string,

    // overrides
    showRows: PropTypes.func,
    inputFields: PropTypes.func,
    setButtons: PropTypes.func,
    additionalButtonsBehaviours: PropTypes.func,
    detailsPermission: PropTypes.func,
    handleInputChange: PropTypes.func,

    // redux props
    editState: PropTypes.object,
    createState: PropTypes.object,
    flexlayoutTabSelected: PropTypes.func,
    setEditStateTrue: PropTypes.func,
    setCreateStateTrue: PropTypes.func,
    setEditStateFalse: PropTypes.func,
    setCreateStateFalse: PropTypes.func,
    bottomButtonClicked: PropTypes.func,
    setBottomButtons: PropTypes.func,
    isMaximized: PropTypes.func,
  }

  constructor(props) {
    super(props)

    const { editState, createState, entityData } = props

    let editValue = false
    if (editState && editState.info) {
      editValue = editState.info
    }

    let createValue = false
    if (createState && createState.info) {
      createValue = createState.info
    }
    let tab = props.tabName ? props.tabName : 'info'
    if (entityData && entityData.info && !Array.isArray(entityData.info)) {
      this.state = {
        info: entityData.info,
        editMode: editValue,
        createMode: createValue,
        notificationType: '',
        showModal: false,
        tabSelected: tab,
        tabName: tab,
        submitting: false,
      }
    } else {
      this.state = {
        info: null,
        editMode: editValue,
        createMode: createValue,
        notificationType: '',
        showModal: false,
        tabSelected: tab,
        tabName: tab,
        submitting: false,
      }
    }
    this.setButtons(editValue || createValue)
    this.props.flexlayoutTabSelected(tab)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { tabSelected, submitting, editMode, createMode, info, tabName } =
      this.state

    const {
      flexlayout,
      entityData,
      bottomButtons,
      editState,
      createState,
      modelName,
    } = newProps

    if (editState && editState.info != null && editState.info != editMode) {
      this.setState({
        editMode: editState.info,
        createMode: editState.info ? false : createMode,
      })
    }

    if (
      createState &&
      createState.info != null &&
      createState.info != createMode
    ) {
      this.setState({
        createMode: createState.info,
        editMode: createState.info ? false : editMode,
      })
    }

    if (flexlayout && flexlayout.tabSelected != tabSelected) {
      if (flexlayout.tabSelected == tabName) {
        this.setButtons(editMode || createMode)
      }
      this.setState({ tabSelected: flexlayout.tabSelected })
    }

    if (
      !submitting &&
      entityData &&
      entityData.info &&
      !Array.isArray(entityData.info)
    ) {
      if (entityData.info != info) this.setState({ info: entityData.info })
      if (info) {
        if (entityData.info['id'] && info['id'] != entityData.info['id']) {
          if (editMode) this.props.setEditStateFalse()
          if (createMode) this.props.setCreateStateFalse()
          this.setState({ info: entityData.info, editMode: false }, () =>
            this.setButtons(false)
          )
        } else if (editMode || createMode) {
          this.setState({ info: entityData.info })
        }
      } else {
        this.setState({ info: entityData.info }, () => this.setButtons(false))
      }
    } else if (
      (!submitting && !entityData) ||
      (entityData && !entityData.info)
    ) {
      this.setState({ info: null })
    }

    if (
      !submitting &&
      bottomButtons &&
      bottomButtons.clicked &&
      flexlayout.tabSelected == tabName
    ) {
      let translation = this.props.translations.ubf

      if (this.props.additionalButtonsBehaviours) {
        this.props.additionalButtonsBehaviours(bottomButtons.clicked, this)
      }
      if (bottomButtons.clicked == translation.edit) {
        this.props.bottomButtonClicked(null)
        this.onEdit()
      } else if (!submitting && bottomButtons.clicked == translation.save) {
        this.props.bottomButtonClicked(null)
        this.onSubmit()
      } else if (!submitting && bottomButtons.clicked == translation.delete) {
        this.props.bottomButtonClicked(null)
        this.onDelete()
      } else if (
        !submitting &&
        bottomButtons.clicked == translation.new_model[modelName]
      ) {
        this.props.bottomButtonClicked(null)
        this.onCreate()
      } else if (!submitting && bottomButtons.clicked == translation.cancel) {
        this.props.bottomButtonClicked(null)
        this.props.setEditStateFalse()
        this.props.setCreateStateFalse()
        this.setState({ editMode: false, createMode: false, info: null }, () =>
          this.setButtons(false)
        )
        if (editMode) this.props.getEntityItem(info.id)
        else this.props.setEntityItem(null)
      }
      return
    }
  }

  onCreate = () => {
    const { isMaximized, setEntityItem, setCreateStateTrue } = this.props

    let fields = {
      first_names: null,
      last_names: null,
      identifier: null,
      notification_email: null,
      default_user_type_identifier: null,
      created_at: null,
      updated_at: null,
      roles: null,
    }

    isMaximized(false)
    this.setState({ createMode: true })
    setEntityItem({ fields })
    setCreateStateTrue()
    this.setButtons(true)
  }

  onSubmit = () => {
    if (this.state.submitting) return
    const { info } = this.state
    const { controllerUrl } = this.props

    this.setState({ submitting: true })

    if (this.state.editMode) {
      update_request(controllerUrl, info, info['id'])
        .then(response => {
          if (!response.id) {
            // failure
            info.errors = response
            this.setState({
              info: info,
              submitting: false,
            })
            this.props.setEntityItem(info)
          } else {
            info.errors = false
            this.setState(
              {
                showModal: true,
                notificationType: 'update',
                editMode: false,
                submitting: false,
              },
              () => {
                setTimeout(this.handleCloseModal, 2000)
              }
            )
            this.props.setEntityItem({
              ...info,
              ...response,
            })
            this.props.setEditStateFalse()
            this.props.getEntityUpdate()
            this.setButtons(false)
          }
        })
        .catch(error => {
          console.error(error)
          this.setState({ info: null, editMode: false, submitting: false })
        })
    } else if (this.state.createMode) {
      save_request(controllerUrl, info)
        .then(response => {
          if (!response.id) {
            // failure
            info.errors = response
            this.setState({
              info: info,
              submitting: false,
            })
          } else {
            info.errors = false
            this.setState(
              {
                showModal: true,
                notificationType: 'create',
                createMode: false,
                submitting: false,
              },
              () => {
                setTimeout(this.handleCloseModal, 2000)
              }
            )
            this.props.setEntityItem(response)
            this.props.setCreateStateFalse()
            this.props.getEntityUpdate()
            this.setButtons(false)
          }
        })
        .catch(error => {
          console.error(error)
          this.setState({ info: null, createMode: false, submitting: false })
          this.setButtons(false)
        })
    }
  }

  onDelete = () => {
    const { info } = this.state
    const { controllerUrl } = this.props

    delete_request(controllerUrl, info['id'])
      .then(() => {
        this.setState({ showModal: true, notificationType: 'delete' }, () => {
          setTimeout(this.handleCloseModal, 2000)
        })
        this.props.setEntityItem(null)
        this.props.setEditStateFalse()
        this.setButtons(false)
        this.props.getEntityUpdate()
        this.setState({ editMode: false, info: null })
      })
      .catch(error => {
        console.error(error)
        this.setState({ info: null, editMode: false, submitting: false })
      })
  }

  setButtons = (editMode = false) => {
    const {
      translations,
      canEdit,
      canCreate,
      canDelete,
      modelName,
      setButtons,
    } = this.props
    const { createMode, info } = this.state

    if (setButtons) return setButtons(editMode, this)

    let translation = translations.ubf
    let buttons = []

    // nothing selected
    if (!editMode && !createMode && !info && canCreate) {
      buttons.push(translation.new_model[modelName])
    }
    // edit/create
    else if (createMode || editMode) {
      buttons.push(translation.cancel)
      buttons.push(translation.save)
    }
    // show
    else {
      if (canCreate) buttons.push(translation.new_model[modelName])
      if (canDelete) buttons.push(translation.delete)
      if (canEdit) buttons.push(translation.edit)
    }

    this.props.setBottomButtons(buttons)
  }

  handleInputChange = event => {
    const { info } = this.state
    const { handleInputChange } = this.props

    if (handleInputChange != null) {
      const stop = handleInputChange(this, event)
      if (stop) return
    }

    const target = event.target
    const value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    this.props.setEntityItem({
      ...info,
      [name]: value,
    })
  }

  translateField = field => {
    return translate('attributes.' + camelCase(field), this.props.translations)
  }

  onEdit = () => {
    this.setState({ editMode: true })
    this.props.setEditStateTrue()
    this.setButtons(true)
  }

  theme = () => createMuiTheme(muiStyles)

  showHeader = () => {
    const { header } = this.props

    return header ? header(this.state, this.props) : null
  }

  inputFields = () => {
    const { translations, moduleName, fields, inputFields, extraPadding } =
      this.props

    if (inputFields != null) return inputFields(this)

    return (
      <div>
        {this.showHeader()}

        <div
          className={
            'quantum-layout__container--input-fields' +
            (extraPadding
              ? ' quantum-layout__container--input-fields-extra-padding'
              : '')
          }
        >
          {this.state.info.errors ? (
            <div className="quantum-layout__field--error">
              {translations.messages[moduleName].some_errors}
            </div>
          ) : (
            ''
          )}

          {Object.keys(fields).map(key => {
            return this.inputField(
              this.fieldLabel(key),
              key,
              fields[key].type,
              fields[key].mandatory,
              fields[key].options
            )
          })}
        </div>
      </div>
    )
  }

  inputField = (name, field, type, mandatory, options) => {
    const { info } = this.state
    const { translations, styleType } = this.props

    const inputClass = field == 'text' ? 'full-width-container' : ''
    const error = info.errors
      ? info.errors[field] ||
        info.errors[field.replace('_ids', '').replace('_id', '')]
      : false
    let input = null
    const divClass =
      styleType == 2 ? 'quantum-layout__field' : 'quantum-layout__field-50'

    switch (type) {
      case 'text':
        input = (
          <input
            className={inputClass}
            key={info['id'] + field}
            type={type}
            name={field}
            placeholder={name}
            value={info[field] || ''}
            onChange={this.handleInputChange}
          />
        )
        break
      case 'date':
        input = (
          <Calendar
            className={inputClass}
            name={field}
            selected={info[field]}
            onChange={this.handleInputChange}
            translations={translations}
          />
        )
        break
      case 'creatable_list': {
        let value = info[field] ? info[field] : []

        let opts = { ...options }
        return (
          <div className={divClass}>
            <div className={'quantum-layout__label p0 mt5'}>{opts.label}</div>
            {error ? (
              <div className="quantum-layout__field--error">
                {error.join(', ')}
              </div>
            ) : (
              ''
            )}
            <div className={'quantum-layout__select'}>
              <CreatableListInput
                currentValues={value}
                name={opts.name}
                placeholderText={opts.placeholderText}
                onChange={this.handleInputChange}
                styleVersion={2}
                retrieveOption={true}
              />
            </div>
          </div>
        )
      }
      case 'selector': {
        let value = info[field] ? info[field] : []
        if (typeof value == 'object') {
          let newValue = []
          value.map(v => newValue.push(v.toString()))
          value = newValue
        } else {
          value = value.toString()
        }
        let opts = { ...options }
        if (mandatory) opts.label += ' *'
        return (
          <EntitiesSelector
            key={info['id'] + field}
            mainClass={divClass}
            labelClass={'quantum-layout__label p0 mt5'}
            selectClass={'quantum-layout__select'}
            prefixClass="select"
            onUpdate={this.handleInputChange}
            currentValues={value}
            info={opts}
            multi={opts.multi}
            error={error || null}
          />
        )
      }
    }

    return (
      <div className={divClass} key={info['id'] + field}>
        <span className="quantum-layout__label p0">
          {name + (mandatory ? ' *' : '')}
        </span>
        {error
          ? error.map(e => {
              return (
                <div key={e} className="quantum-layout__field--error">
                  {e}
                </div>
              )
            })
          : ''}
        {input}
      </div>
    )
  }

  viewFields = () => {
    const { moduleName } = this.props

    const columns = [
      {
        name: '',
        options: {
          filter: false,
        },
      },
      {
        name: '',
        options: {
          filter: false,
          sort: false,
        },
      },
    ]

    const options = {
      filterType: 'dropdown',
      print: false,
      download: false,
      fixedHeader: true,
      fixedSelectColumn: true,
      selectableRows: 'none',
      rowsPerPage: 25,
      responsive: 'standard',
      tableBodyHeight: window.innerHeight - 343 + 'px',
      search: false,
      selectableRowsHeader: false,
      viewColumns: false,
      pagination: false,
      filter: false,
    }

    return (
      <MuiThemeProvider theme={this.theme()}>
        {this.showHeader()}
        <div className={`${moduleName}__info`}>
          <MUIDataTable
            title={''}
            data={this.showRows()}
            columns={columns}
            options={options}
          />
        </div>
      </MuiThemeProvider>
    )
  }

  showRows = () => {
    const { fields, showRows } = this.props

    if (showRows) return showRows(this)

    let rows = []
    Object.keys(fields).map(key => {
      rows.push(this.fieldRow(key, fields[key].type, fields[key].mandatory))
    })
    return rows
  }

  fieldRow = (field, type, mandatory) => {
    const { info } = this.state
    const { fields, translations } = this.props

    let value = info[field] ? info[field] : translations.ubf.na
    if (value != translations.ubf.na) {
      if (type == 'date') {
        value = moment(info[field]).format(UBF.globalDateFormat)
      }
      if (type == 'selector') {
        let values = []
        if (fields[field].options.multi) {
          value.map(id => {
            values.push(
              fields[field].options.values.find(
                x => x.value.toString() == id.toString()
              )?.label
            )
          })
        } else {
          values.push(
            fields[field].options.values.find(
              x => x.value.toString() == value.toString()
            )?.label
          )
        }
        value = values.map((v, index) => (
          <span
            key={index}
            className="quantum-layout-options__multi-value__label"
          >
            {v}
          </span>
        ))
      }
      if (type == 'creatable_list') {
        value = info[field].map((v, index) => (
          <span
            key={index}
            className="quantum-layout-options__multi-value__label"
          >
            {v}
          </span>
        ))
      }
    }
    return [this.fieldLabel(field) + (mandatory ? ' *' : ''), value]
  }

  fieldLabel = field => {
    const { modelName, translations } = this.props
    let attr = translations.activerecord.attributes[modelName]
    return attr[field]
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  selectMessage = () => {
    const { translations, modelName, moduleName } = this.props
    return translations.messages[moduleName][`select_${modelName}`]
  }

  permissionMessage = () => {
    const { translations, modelName, moduleName } = this.props
    return translations.messages[moduleName][`denied_permission_${modelName}`]
  }

  render() {
    const { translations, modelName, detailsPermission } = this.props
    const { info, createMode, editMode } = this.state

    return (
      <div>
        <NotificationModal
          visible={this.state.showModal}
          onCloseModal={this.handleCloseModal}
          translations={this.props.translations}
          notificationType={this.state.notificationType}
          model={translations.activerecord.models[modelName]}
        />

        {info ? (
          !detailsPermission ||
          (detailsPermission && detailsPermission(this)) ? (
            editMode || createMode ? (
              this.inputFields()
            ) : (
              this.viewFields()
            )
          ) : (
            <div className="quantum-layout__container--middle-message">
              <h3>{this.permissionMessage()}</h3>
            </div>
          )
        ) : (
          <div className="quantum-layout__container--middle-message">
            <h3>{this.selectMessage()}</h3>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const flexlayout = getFlexLayoutStore(state)
  const bottomButtons = getBottomButtonsStore(state)
  const editState = getEditionStore(state)
  const createState = getCreationStore(state)
  return { flexlayout, bottomButtons, editState, createState }
}

export default connect(mapStateToProps, {
  setBottomButtons,
  bottomButtonClicked,
  setEditStateFalse,
  setEditStateTrue,
  setCreateStateFalse,
  setCreateStateTrue,
  isMaximized,
  flexlayoutTabSelected,
})(EntityFields)
