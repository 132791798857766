// Copyright Northcote Technology Ltd
import React, { Component } from 'react'

import PropTypes from 'prop-types'

export class Checkbox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    divClass: PropTypes.string,
    labelClass: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      checked: this.props.checked,
    }
  }

  onChange = event => {
    const { onUpdate } = this.props
    onUpdate(event)
    this.setState({ checked: event.target.value })
  }

  render() {
    const { checked } = this.state

    const { name, label, divClass, labelClass } = this.props

    return (
      <div className={divClass}>
        <input type="hidden" name={name} value="0" />
        <input
          type="checkbox"
          name={name}
          id={name}
          value="1"
          className="hide"
          defaultChecked={checked}
          onChange={this.onChange}
        />
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }
}
