// Copyright Northcote Technology Ltd
import React, { useContext, useReducer, useState } from 'react'
import { isEmpty } from 'lodash'

import TranslationsContext from '../contexts/Translations'

import { activitiesDataToSelectValues } from '../../src/lib/activitiesHelper'

import Button from '../Button'
import Category from './Category'
import DynamicSelect from '../DynamicSelect'
import Modal from '../Modal2'

const InputRow = ({ category, multiline, name, onChange, value }) => {
  const translations = useContext(TranslationsContext)
  const id = `form_modal_${name}`
  const placeholder =
    translations.activerecord.attributes.session_template_timeline[name]
  const InputTag = multiline ? 'textarea' : 'input'
  const inputProps = multiline ? { rows: 2 } : { type: 'text' }

  return (
    <div className="timeline-editor__modal-input">
      <label htmlFor={id}>
        {category ? (
          <Category type={category}>{placeholder}</Category>
        ) : (
          placeholder
        )}
      </label>
      <InputTag
        id={id}
        name={`form_modal[${name}]`}
        onChange={({ target: { value } }) => onChange(value)}
        placeholder={placeholder}
        value={value || ''}
        {...inputProps}
      />
    </div>
  )
}

export default function FormModal({ activitiesData, event, onCancel, onSave }) {
  const translations = useContext(TranslationsContext)
  const [state, updateState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    event
  )
  const [errors, setErrors] = useState({})

  const handleSubmit = event => {
    event.preventDefault()

    const errors = {}

    if (!state.activityId) {
      // A timeline event's activity is optional but if one isn't selected then
      // it must have at least one other field present.
      const allFieldsBlank = [
        'categoryAc',
        'categoryNav',
        'categoryTraining',
        'categoryWeather',
        'description',
        'estimatedTime',
      ].every(key => isEmpty(state[key]))

      if (allFieldsBlank) {
        errors.base =
          translations.activerecord.errors.models.session_template_timeline.attributes.base.activity_or_information
      }
    }

    if (isEmpty(errors)) {
      onSave(state)
    } else {
      setErrors(errors)
    }
  }

  return (
    <Modal className="timeline-editor__modal">
      <h1>
        {event.id
          ? translations.ubf.modal.timeline.edit_event
          : translations.ubf.modal.timeline.new_event}
      </h1>

      {isEmpty(errors) ? null : (
        <div className="error">{Object.values(errors).join(', ')}</div>
      )}

      <form action="" onSubmit={handleSubmit}>
        <div className="timeline-editor__modal-input">
          <div>
            {translations.forms.placeholders.activity_search.activity_name}
          </div>
          <DynamicSelect
            clearable={false}
            currentValue={state.activityId?.toString() || ''}
            multi={false}
            name="activity_id"
            noResultsText={translations.messages.activities.not_found}
            onSelect={({ target: { value } }) =>
              updateState({ activityId: value ? parseInt(value) : null })
            }
            placeholderText={
              translations.forms.placeholders.activity_search.activity_name
            }
            values={activitiesDataToSelectValues(activitiesData)}
          />
        </div>

        <InputRow
          multiline
          name="description"
          onChange={description => updateState({ description })}
          value={state.description}
        />

        <InputRow
          category="weather"
          multiline
          name="category_weather"
          onChange={categoryWeather => updateState({ categoryWeather })}
          value={state.categoryWeather}
        />

        <InputRow
          category="ac"
          multiline
          name="category_ac"
          onChange={categoryAc => updateState({ categoryAc })}
          value={state.categoryAc}
        />

        <InputRow
          category="nav"
          multiline
          name="category_nav"
          onChange={categoryNav => updateState({ categoryNav })}
          value={state.categoryNav}
        />

        <InputRow
          category="training"
          multiline
          name="category_training"
          onChange={categoryTraining => updateState({ categoryTraining })}
          value={state.categoryTraining}
        />

        <InputRow
          category="estimated-time"
          name="estimated_time"
          multiline
          onChange={estimatedTime => updateState({ estimatedTime })}
          value={state.estimatedTime}
        />

        <div className="timeline-editor__modal-actions">
          <Button onClick={onCancel}>{translations.ubf.cancel}</Button>
          <Button type="submit">{translations.ubf.save}</Button>
        </div>
      </form>
    </Modal>
  )
}
