// Copyright Northcote Technology Ltd
import React, { useContext, useMemo, useReducer, useState } from 'react'
import { keyBy, sortBy } from 'lodash'
import { useDispatch } from 'react-redux'

import TranslationsContext from './contexts/Translations'
import partitionActivityAndSessionGradings from '../src/lib/partitionActivityAndSessionGradings'
import { updateGradings } from '../redux/app'
import {
  gradingObsFromRecordings,
  mergeObsWithGradings,
  selectMoodsForOb,
} from '../src/lib/gradingObsFromRecordings'
import { personName } from '../src/lib/peopleHelper'

import Button from './Button'
import Icon from './Icon'
import InitialsAvatar from './common/InitialsAvatar'
import MoodSelector2 from './MoodSelector2'

function detectConflictsByResultId(gradingObsByResultId) {
  const lookup = {}
  for (const resultId in gradingObsByResultId) {
    lookup[resultId] = gradingObsByResultId[resultId].some(gradingObs =>
      gradingObs.observations.some(({ moodPicks }) => moodPicks.length > 1)
    )
  }
  return lookup
}

function detectUnresolvedConflicts(gradingObsByResultId) {
  let hasUnresolvedConflicts = false

  for (const resultId in gradingObsByResultId) {
    gradingObsByResultId[resultId].forEach(gradingObs => {
      if (gradingObs.observations.find(({ moods }) => moods.length !== 1)) {
        hasUnresolvedConflicts = true
        return
      }
    })
  }

  return hasUnresolvedConflicts
}

function filterGradingObConflicts(gradingObs) {
  const gradingObsWithConflicts = []
  gradingObs.forEach(({ gradingId, observations }) => {
    const conflictingObs = observations.filter(
      ({ moodPicks }) => moodPicks.length > 1
    )

    if (conflictingObs.length > 0) {
      gradingObsWithConflicts.push({
        gradingId,
        observations: conflictingObs,
      })
    }
  })
  return gradingObsWithConflicts
}

function GradingObConflict({ ob, onChangeMood, session }) {
  const { behavioursData } = session
  const behaviour = behavioursData[ob.behaviourId]

  if (!behaviour) return

  return (
    <div className="apply-recording-obs__conflict">
      <div>
        <MoodSelector2
          multiple={true}
          onChange={newMoods => onChangeMood(ob.behaviourId, newMoods)}
          options={behaviour.moods}
          value={ob.moods}
        />
      </div>
      <div>{behaviour.name}</div>
    </div>
  )
}

function GradingObConflicts({ grading, observations, onChangeMood, session }) {
  const { activityId, qualificationId } = grading
  const { activitiesData, qualificationsData } = session
  const gradingName = activityId
    ? activitiesData[activityId]?.name
    : qualificationsData[qualificationId]?.name

  return (
    <div className="apply-recording-obs__grading">
      <h2>{gradingName}</h2>

      {observations.map(ob => (
        <GradingObConflict
          key={ob.behaviourId}
          ob={ob}
          onChangeMood={onChangeMood}
          session={session}
        />
      ))}
    </div>
  )
}

function sortGradingObs(gradingObsByResultId, resultId, session) {
  const gradingObConflicts = filterGradingObConflicts(
    gradingObsByResultId[resultId]
  )

  if (gradingObConflicts.length === 0) {
    return []
  }

  const result = session.gradingSessionResults.find(({ id }) => id === resultId)
  const [gradeByActivityGradings, gradeBySessionGradings] =
    partitionActivityAndSessionGradings(result.gradings, session)
  const sortedGradingIds = gradeByActivityGradings
    .flatMap(gradings => gradings.map(({ id }) => id))
    .concat(gradeBySessionGradings.map(({ id }) => id))
  const gradingObsByGradingId = keyBy(gradingObConflicts, 'gradingId')
  const gradingsById = keyBy(result.gradings, 'id')

  return sortedGradingIds
    .map(id => gradingObsByGradingId[id])
    .filter(gradingOb => gradingOb)
    .map(({ gradingId, observations }) => {
      const grading = gradingsById[gradingId]
      return { grading, observations }
    })
}

function ObConflictsForResult({
  gradingObsByResultId,
  onSelectGradingObMood,
  resultId,
  session,
}) {
  const translations = useContext(TranslationsContext)
  const sortedGradingObs = sortGradingObs(
    gradingObsByResultId,
    resultId,
    session
  )

  if (sortedGradingObs.length === 0) {
    return (
      <div className="apply-recording-obs__noConflict">
        <p>
          <Icon icon="green-check" />{' '}
          {translations.ubf.modal.apply_recording_obs.no_conficts}
        </p>
      </div>
    )
  }

  return (
    <div className="apply-recording-obs__gradings">
      <div className="apply-recording-obs__gradings-inner">
        {sortedGradingObs.map(({ grading, observations }) => (
          <GradingObConflicts
            grading={grading}
            key={grading.id}
            observations={observations}
            onChangeMood={(behaviourId, moods) =>
              onSelectGradingObMood(grading.id, behaviourId, moods)
            }
            session={session}
          />
        ))}
      </div>
    </div>
  )
}

function PersonAvatar({ hasConflicts, onClick, person, selected }) {
  return (
    <button
      className="apply-recording-obs__person"
      onClick={onClick}
      type="button"
    >
      <div className={selected ? null : 'semi-transparent'}>
        <InitialsAvatar person={person} />
        <span>{personName(person)}</span>
      </div>
      {hasConflicts ? <Icon icon="alert-error" /> : null}
    </button>
  )
}

export default function ApplyRecordingObs({ onCancel, onSave, session }) {
  const translations = useContext(TranslationsContext)
  const sortedResults = sortBy(session.gradingSessionResults, ({ person }) =>
    personName(person)
  )
  const [gradingObsByResultId, setMoods] = useReducer(
    selectMoodsForOb,
    session,
    gradingObsFromRecordings
  )
  const hasConflictsByResultId = useMemo(
    () => detectConflictsByResultId(gradingObsByResultId),
    [gradingObsByResultId]
  )
  const [selectedResultId, setSelectedResultId] = useState(() => {
    const firstWithConflicts = sortedResults.find(
      ({ id }) => hasConflictsByResultId[id]
    )
    return (firstWithConflicts || sortedResults[0]).id
  })
  const hasUnresolvedConflicts = useMemo(
    () => detectUnresolvedConflicts(gradingObsByResultId),
    [gradingObsByResultId]
  )
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const handleSave = async () => {
    setIsSaving(true)
    const gradings = mergeObsWithGradings(session, gradingObsByResultId)
    await dispatch(updateGradings(session.id, gradings))
    onSave()
  }

  return (
    <div className="apply-recording-obs">
      <h1 className="apply-recording-obs__header">
        {translations.ubf.modal.apply_recording_obs.title}
      </h1>

      <div className="apply-recording-obs__people">
        {sortedResults.map(({ id, person }) => (
          <PersonAvatar
            key={id}
            hasConflicts={hasConflictsByResultId[id]}
            onClick={() => setSelectedResultId(id)}
            person={person}
            selected={id === selectedResultId}
          />
        ))}
      </div>

      <ObConflictsForResult
        gradingObsByResultId={gradingObsByResultId}
        onSelectGradingObMood={(gradingId, behaviourId, moods) => {
          setMoods({
            behaviourId,
            gradingId,
            moods,
            resultId: selectedResultId,
          })
        }}
        resultId={selectedResultId}
        session={session}
      />

      {hasUnresolvedConflicts ? (
        <p className="apply-recording-obs__conflictMessage">
          <Icon icon="alert-error" />{' '}
          {translations.ubf.modal.apply_recording_obs.conflicts_message}
        </p>
      ) : null}

      <div className="apply-recording-obs__buttons">
        <Button disabled={isSaving} onClick={onCancel}>
          {translations.ubf.cancel}
        </Button>
        <Button
          disabled={hasUnresolvedConflicts || isSaving}
          onClick={handleSave}
        >
          {translations.ubf.save}
        </Button>
      </div>
    </div>
  )
}
