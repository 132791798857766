// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'

export default class ModalReject extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    urlToSubmit: PropTypes.string.isRequired,
    authenticityToken: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.refForm = React.createRef()
  }

  handleClick = () => {
    this.setState({ showModal: true })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  handleSaveModal = () => {
    this.refForm.current.submit()
  }

  contentModal = () => {
    return (
      <form
        ref={this.refForm}
        method="post"
        action={this.props.urlToSubmit}
        acceptCharset="UTF-8"
      >
        <input name="utf8" type="hidden" value="✓" />
        <input
          type="hidden"
          name="authenticity_token"
          value={this.props.authenticityToken}
        />
        <input type="hidden" name="_method" value="PATCH" />
        <input type="hidden" name="approved" value="false" />
        <textarea
          name="reject_comment"
          className="modal-textarea"
          placeholder={this.props.translations.ubf.reject_comment}
        />
      </form>
    )
  }

  render() {
    return (
      <Fragment>
        <Modal
          title={this.props.translations.ubf.reject_title}
          visible={this.state.showModal}
          buttonSave={true}
          onSaveModal={this.handleSaveModal}
          onCloseModal={this.handleCloseModal}
          translations={this.props.translations}
        >
          {this.contentModal()}
        </Modal>
        <button className="btn delete-btn right" onClick={this.handleClick}>
          {this.props.translations.ubf.reject_button}
        </button>
      </Fragment>
    )
  }
}
