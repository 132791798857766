// Copyright Northcote Technology Ltd
import React, { useLayoutEffect, useRef } from 'react'

const AutoExpandingTextarea = ({ value, ...props }) => {
  const textareaRef = useRef(null)

  useLayoutEffect(() => {
    const textarea = textareaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }, [value])

  return (
    <textarea
      ref={textareaRef}
      style={{
        resize: 'none',
        overflow: 'hidden',
      }}
      value={value}
      {...props}
    />
  )
}

export default AutoExpandingTextarea
