// Copyright Northcote Technology Ltd
import React, { Component } from 'react'

import PropTypes from 'prop-types'

export default class ColorPicker extends Component {
  static propTypes = {
    currentColor: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selecting: false,
    }
  }

  onSelect = color => {
    this.props.onUpdate(color)
    this.setState({ selecting: false })
  }

  render() {
    const { colors, currentColor } = this.props
    const { selecting } = this.state

    return (
      <div className="color-picker center-content">
        <span
          className="color-picker-option"
          style={{ backgroundColor: currentColor }}
          onClick={() => this.setState({ selecting: true })}
        />
        {selecting && (
          <div className="color-picker-selector">
            {colors.map(color => (
              <span
                key={color}
                className="color-picker-option"
                style={{ backgroundColor: color }}
                onClick={() => this.onSelect(color)}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}
