// Copyright Northcote Technology Ltd
import { SET_PROGRESS } from '../actionTypes'

const loading = (state = { progress: 0 }, action) => {
  switch (action.type) {
    case SET_PROGRESS: {
      return Object.assign({}, state, {
        progress: action.payload.progress,
      })
    }
    default: {
      return state
    }
  }
}

export default loading
