// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelectWithState from './DynamicSelectWithState'
import TimeInput from './TimeInput'

export default class CustomFieldInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.array,
    currentValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    noResultsText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { name, type, value, currentValue, noResultsText, onChange } =
      this.props

    switch (type) {
      case 'text':
      case 'number':
        return (
          <input
            type={type}
            id={name}
            name={name}
            placeholder={name}
            onChange={onChange}
            className={'select__control'}
            value={currentValue || ''}
          />
        )
      case 'date':
        return (
          <input
            type="date"
            className={`select__control ${
              !currentValue ? 'date-placeholder' : ''
            }`}
            name={name}
            onChange={onChange}
            value={currentValue || ''}
          />
        )
      case 'time':
        return (
          <TimeInput onChange={onChange} label={name} value={currentValue} />
        )
      case 'select':
      case 'multiselect':
        return (
          <DynamicSelectWithState
            clearable={true}
            multi={type == 'multiselect'}
            name={name}
            noResultsText={noResultsText}
            placeholderText={name}
            values={value}
            currentValue={currentValue || ''}
            onUpdate={onChange}
            prefixClass={'select'}
          />
        )
      default:
        return null
    }
  }
}
