// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InitialsAvatar extends Component {
  static propTypes = {
    person: PropTypes.object,
    name: PropTypes.string,
    classes: PropTypes.string,
    isCrew: PropTypes.bool,
  }

  initials = () => {
    if (this.props.isCrew) return null
    if (this.props.person) return this.initialsFromPerson()
    return this.initialsFromName()
  }

  initialsFromPerson = () => {
    const { firstNames, lastNames, nickname } = this.props.person

    let first = nickname
      ? this.firstLetter(nickname)
      : this.firstLetter(firstNames)
    let second = this.firstLetter(lastNames)
    return first + second
  }

  initialsFromName = () => {
    const { name } = this.props

    let words = name.split(' ')
    let first = this.firstLetter(words[0])
    let second = this.firstLetter(words.pop())
    return first + second
  }

  firstLetter = word => word.charAt(0).toUpperCase()

  render = () => {
    const { classes } = this.props

    return (
      <div className={'avatar__initials ' + (classes ? classes : '')}>
        {this.initials()}
      </div>
    )
  }
}
