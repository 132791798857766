// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import { update_request } from '../../src/lib/requestToServer'

export default class TermsAcceptance extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    termsAccepted: PropTypes.bool.isRequired,
    privacyLink: PropTypes.string,
    privacyLinkText: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      visible:
        !props.termsAccepted &&
        props.privacyLink &&
        props.privacyLink.length > 0,
    }
  }

  onAccept = () => {
    update_request('/my_settings/', { terms_accepted: true }, 0).then(() => {
      window.location.reload()
    })
  }

  onCancelled = () => {
    window.location = 'users/sign_out'
  }

  render() {
    const { translations, privacyLink, privacyLinkText } = this.props
    const { visible } = this.state

    return (
      <Modal
        title={translations.messages.terms_acceptance.title}
        visible={visible}
        buttonSave={true}
        onSaveModal={this.onAccept}
        onCloseModal={this.onCancelled}
        translations={translations}
        saveButtonText={translations.messages.terms_acceptance.accept}
        cancelButtonText={translations.messages.terms_acceptance.reject}
        zIndex={900}
        extraClasses={'mt30'}
      >
        <div className="terms-acceptance">
          <div className="terms-acceptance__link">
            <a href={privacyLink} rel="noopener noreferrer" target="_blank">
              {privacyLinkText}
            </a>
          </div>
        </div>
      </Modal>
    )
  }
}
