// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import localizedDate from 'src/lib/localizedDate'
import FlagLabel from './FlagLabel'
import { GRADING_TIMELINE_HEIGHT } from './GradingTimeline'
import gradingProps from 'proptypes/gradingProps'

class GradingPoint extends Component {
  static propTypes = {
    grading: PropTypes.shape(gradingProps).isRequired,
    scale: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ isActive: true })
  }

  handleMouseLeave = () => {
    this.setState({ isActive: false })
  }

  clickLink = () => {
    const url = this.props.grading.sessionLink
    if (url) {
      window.location.href = url
    }
  }

  render() {
    const { grading, scale, t } = this.props

    const { isActive } = this.state

    const { graded, category, isSubmitted, sessionLink, sessionName } = grading

    const gradedDate = moment(graded).toDate()
    const gradingPosition = scale(gradedDate)
    const localizedValidFromDate = localizedDate(gradedDate)

    return (
      <span
        className={classNames({
          'grading-point': true,
          'grading-point--link': sessionLink,
        })}
        data-graded-date={localizedValidFromDate}
        data-grade-category={category}
        data-submitted={isSubmitted}
        onClick={this.clickLink}
        style={{
          height: GRADING_TIMELINE_HEIGHT,
          left: gradingPosition - GRADING_TIMELINE_HEIGHT / 2,
          width: GRADING_TIMELINE_HEIGHT,
        }}
      >
        <div
          className={classNames({
            'grading-point__marker': true,
            'grading-point__marker--unsubmitted': !isSubmitted,
            [`grading-point__marker--${category}`]: true,
          })}
          style={{
            height: GRADING_TIMELINE_HEIGHT,
            width: GRADING_TIMELINE_HEIGHT,
          }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <FlagLabel
            isVisible={isActive}
            pointing="left"
            textLines={[sessionName].concat(
              isSubmitted ? [] : [t('ubf.unsubmitted')],
              [localizedValidFromDate]
            )}
            verticalAlignment="top"
          />
        </div>
      </span>
    )
  }
}

export default GradingPoint
