// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class MainMenuButtons extends Component {
  static propTypes = {
    enableWhenOffline: PropTypes.array.isRequired,
    buttonList: PropTypes.array.isRequired,
    translation: PropTypes.object.isRequired,
    online: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      buttonList: this.props.buttonList,
    }
  }

  isAvailabileOffline = path => {
    return this.props.enableWhenOffline.includes(path)
  }

  buttonTarget = path => {
    return path && this.props.online ? '_blank' : ''
  }

  buttonPath = path => {
    return this.props.online || this.props.enableWhenOffline.includes(path)
      ? path
      : '#'
  }

  translation = translation_key => {
    return this.props.translation[translation_key.split('.')[1]]
  }

  buttonClass = (translationKey, path) => {
    const statusClass =
      this.props.online || this.isAvailabileOffline(path)
        ? ''
        : ' tile--disabled'
    return translationKey.split('.')[1].replace(/\W|_/g, '-') + statusClass
  }

  render() {
    const { buttonList } = this.state

    return (
      <ul className="tile-board">
        {buttonList.map((item, index) => (
          <li key={index} className="tile-board__item">
            <a
              className={
                'tile tile--' +
                this.buttonClass(item.translation_key, item.path)
              }
              href={this.buttonPath(item.path)}
              target={this.buttonTarget(item.new_tab)}
            >
              {this.translation(item.translation_key)}
            </a>
          </li>
        ))}
      </ul>
    )
  }
}
