// Copyright Northcote Technology Ltd
import React, { useContext, useMemo } from 'react'
import { sortBy, uniq } from 'lodash'

import TranslationsContext from '../contexts/Translations'

export default function TrainingTopics(props) {
  const translations = useContext(TranslationsContext)
  const { activitiesData, activityIds, trainingTopicsData } = props
  const trainingTopics = useMemo(() => {
    const trainingTopicIds = activityIds.flatMap(
      id => activitiesData[id].training_topic_ids
    )
    return sortBy(
      uniq(trainingTopicIds).map(id => trainingTopicsData[id]),
      'name'
    )
  }, [activitiesData, activityIds, trainingTopicsData])

  if (trainingTopics.length === 0) {
    return (
      <p className="centred-message">
        {translations.messages.grading_session_templates.no_training_topics}
      </p>
    )
  }

  return (
    <table className="table table-compact table-maxContent">
      <thead>
        <tr>
          <th>{translations.activerecord.attributes.training_topic.name}</th>
          <th>
            {translations.activerecord.attributes.training_topic.frequency}
          </th>
        </tr>
      </thead>
      <tbody>
        {trainingTopics.map(({ frequency, id, name }) => (
          <tr key={id}>
            <td>{name}</td>
            <td className="text-center">{frequency}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
