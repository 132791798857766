// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

// Turbo Frames don't emit load events so we have to manually cater for this
// with the following workaround. This allows us to mount React-Rails
// components and include the date picker polyfill on newly-loaded content.
//
// Hopefully this PR will be resurrected so we don't have to do this:
//   https://github.com/hotwired/turbo/pull/59
export default class extends Controller {
  connect() {
    this.mountReactRailsComponents()
  }

  disconnect() {
    this.unmountReactRailsComponents()
  }

  mountReactRailsComponents() {
    if (!window.ReactRailsUJS) return

    this.reactRailsElements.forEach(element => {
      // Assume that the React component hasn't yet been initialized if it
      // doesn't have any children.
      if (!element.hasChildNodes()) {
        window.ReactRailsUJS.handleMount(element)
      }
    })
  }

  unmountReactRailsComponents() {
    if (!window.ReactRailsUJS) return

    this.reactRailsElements.forEach(element => {
      window.ReactRailsUJS.handleUnmount(element)
    })
  }

  get reactRailsElements() {
    return this.element.querySelectorAll('[data-react-class]')
  }
}
