// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlexLayout, { Layout } from 'flexlayout-react'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'

import GradingForm from './GradingForm'
import InitialsAvatar from '../common/InitialsAvatar'
import { isMaximized } from '../../redux/actions'
import { updateGradings } from '../../redux/app'
import { personName } from '../../src/lib/peopleHelper'
import { updateSessionResultAbstract } from '../../redux/app'
import PersonSelector from './PersonSelector'
import { Actions } from 'flexlayout-react'

function addSessionTabs(flexlayoutModel, session, translations) {
  const resultsSortedByPersonName = sortBy(
    session.gradingSessionResults,
    result => personName(result.person)
  )

  const { gradeAsCrew } = session.gradingSessionTemplate
  const results = gradeAsCrew
    ? resultsSortedByPersonName.slice(0, 1)
    : resultsSortedByPersonName

  results.forEach(({ id, person }) => {
    const name = gradeAsCrew ? translations.ubf.crew : personName(person)

    flexlayoutModel.layout.children[0].children[1].children[0].children.push({
      type: 'tab',
      name,
      component: { type: 'form', id },
      id: id,
    })
  })

  return flexlayoutModel
}

class GradingFormsGroup extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    incompleteMode: PropTypes.bool.isRequired,
    isMaximized: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    session: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    updateGradings: PropTypes.func.isRequired,
    updateSessionResultAbstract: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { session, translations } = props
    const flexlayoutModel = addSessionTabs(
      {
        global: {
          tabEnableClose: false,
          enableEdgeDock: false,
          tabSetEnableMaximize: false,
          tabSetEnableDrag: false,
          tabSetEnableDrop: false,
          tabSetEnableDivide: false,
          tabEnableRename: false,
          borderEnableDrop: false,
          tabSetHeaderHeight: 75,
          tabSetTabStripHeight: 75,
          splitterSize: 0,
        },
        layout: {
          type: 'row',
          children: [
            {
              type: 'row',
              children: [
                {
                  type: 'tabset',
                  enableMaximize: false,
                  enableTabStrip: false,
                  height: 95,
                  children: [
                    {
                      type: 'tab',
                      component: { type: 'selector' },
                    },
                  ],
                },
                {
                  type: 'row',
                  children: [
                    {
                      type: 'tabset',
                      enableMaximize: false,
                      enableTabStrip: false,
                      selected: 0,
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      session,
      translations
    )

    this.state = {
      model: FlexLayout.Model.fromJson(flexlayoutModel),
      seletedResultId:
        flexlayoutModel.layout.children[0].children[1].children[0].children[0]
          .id,
    }
  }

  componentDidMount() {
    const {
      session: {
        gradingSessionTemplate: { defaultViewName },
      },
      isMaximized,
    } = this.props

    isMaximized(defaultViewName === 'grading_only')
  }

  factory(node) {
    const {
      directUploadsUrl,
      online,
      session,
      session: {
        gradingSessionTemplate: { gradeAsCrew },
      },
      translations,
    } = this.props
    const { model, seletedResultId } = this.state

    var component = node.getComponent()

    switch (component.type) {
      case 'selector': {
        const sortedResults = sortBy(session.gradingSessionResults, result =>
          personName(result.person)
        )

        return (
          <PersonSelector
            crewName={translations.ubf.crew}
            people={sortedResults}
            gradeAsCrew={gradeAsCrew}
            selectedId={seletedResultId}
            onUpdate={id => {
              model.doAction(Actions.selectTab(id))
              this.setState({ seletedResultId: id })
            }}
          />
        )
      }
      case 'form': {
        const { incompleteMode } = this.props
        const resultId = component.id
        const result = session.gradingSessionResults.find(
          ({ id }) => id === resultId
        )

        return (
          <GradingForm
            directUploadsUrl={directUploadsUrl}
            incompleteMode={incompleteMode}
            onGradings={this.handleGradings}
            online={online}
            result={result}
            session={session}
            translations={translations}
            onUpdateAbstract={this.onUpdateAbstract}
          />
        )
      }
    }
  }

  handleGradings = gradings => {
    this.props.updateGradings(this.props.session.id, gradings)
  }

  onUpdateAbstract = (sessionId, resultId, abstract) => {
    this.props.updateSessionResultAbstract(sessionId, resultId, abstract)
  }

  iconFactory = node => {
    const {
      session: {
        gradingSessionTemplate: { gradeAsCrew },
      },
    } = this.props

    return (
      <InitialsAvatar
        classes={gradeAsCrew ? 'icon icon--users' : ''}
        isCrew={gradeAsCrew}
        name={node.getName()}
      />
    )
  }

  render() {
    const classMapper = className => {
      var additionalClass = ''
      if (className === 'flexlayout__layout') {
        additionalClass = 'gradings-form-component'
      }
      if (className === 'flexlayout__tab') {
        additionalClass = 'gradings-form-component'
      }
      return className + ' ' + additionalClass
    }

    return (
      <Layout
        model={this.state.model}
        factory={this.factory.bind(this)}
        classNameMapper={classMapper}
        iconFactory={this.iconFactory}
      />
    )
  }
}

export default connect(null, {
  isMaximized,
  updateGradings,
  updateSessionResultAbstract,
})(GradingFormsGroup)
