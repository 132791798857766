// Copyright Northcote Technology Ltd
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['admin', 'menu']

  connect() {
    this.element.addEventListener('click', this.menuClickHandler)
  }

  disconnect() {
    this.element.removeEventListener('click', this.menuClickHandler)
  }

  menuClickHandler = event => {
    if (event.target.classList.contains('app-menu-icon-administer')) {
      this.toggleAdmin(event)
    }
  }

  toggleAdmin() {
    event.preventDefault()
    event.stopPropagation()

    this.adminTarget.classList.toggle('is-open')
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('is-open')
    if (this.hasAdminTarget) this.adminTarget.classList.remove('is-open')
  }
}
