// Copyright Northcote Technology Ltd
import React from 'react'
import classnames from 'classnames'

import Icon from './Icon'

export default function IconAction(props) {
  const { children, color, disabled, icon, inline, onClick, size } = props
  const className = classnames({
    'icon-action': true,
    'icon-action--inline': inline,
  })

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <Icon color={color} icon={icon} size={size || '20px'} />
      {children ? <div>{children}</div> : null}
    </button>
  )
}
