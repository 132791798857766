// Copyright Northcote Technology Ltd
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { moodsLabels } from '../src/lib/behaviourUtils'

export default class MoodOption extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    editable: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  }

  handleClick = () => {
    const { checked, editable, onChange, value } = this.props

    if (!editable) return

    onChange(value, !checked)
  }

  render() {
    const { checked, editable, name, value } = this.props
    const moodType = moodsLabels[value]

    const wrapperClassName = classNames({
      mood: true,
      [`mood--${moodType}`]: true,
      'mood--editable': editable,
      'mood--selected': checked,
    })

    return (
      <div className={wrapperClassName} onClick={this.handleClick}>
        {name && (
          <input disabled={!checked} name={name} type="hidden" value={value} />
        )}
        <span></span>
      </div>
    )
  }
}
